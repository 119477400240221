import dayjs from 'dayjs';
import { IEventDetailsVenueStoreState, EventDetailsVenueStoreState } from './EventDetailsVenueStoreState';
import {
  IEventDetailsTicketQuotaStoreState,
  EventDetailsTicketQuotaStoreState,
} from './EventDetailsTicketQuotaStoreState';

export interface IEventDetailsStoreState {
  title: string;
  subtitle: string;
  slug: string;
  posterUrl: string;
  optionPosterUrl: string;
  date: dayjs.Dayjs;
  venue: IEventDetailsVenueStoreState;
  notAvailableForPurchase: boolean;
  notAvailableForPurchaseReason: string;
  ticketQuotas: Array<IEventDetailsTicketQuotaStoreState>;
  isDistributionAvailable: boolean;
  minPrice: number;
  maxPrice: number;
  currency: string;
  description: string;
  domains: Array<number>;
}

export class EventDetailsStoreState implements IEventDetailsStoreState {
  title: string;
  subtitle: string;
  slug: string;
  posterUrl: string;
  optionPosterUrl: string;
  date: dayjs.Dayjs;
  venue: IEventDetailsVenueStoreState;
  notAvailableForPurchase: boolean;
  notAvailableForPurchaseReason: string;
  ticketQuotas: Array<IEventDetailsTicketQuotaStoreState>;
  isDistributionAvailable: boolean;
  minPrice: number;
  maxPrice: number;
  currency: string;
  description: string;
  domains: Array<number>;

  constructor(dto?: any) {
    this.title = dto && dto.Title ? dto.Title : '';
    this.subtitle = dto && dto.SubTitle ? dto.SubTitle : '';
    this.slug = dto && dto.Slug ? dto.Slug : '';
    this.posterUrl = dto && dto.PosterUrl ? dto.PosterUrl : '';
    this.optionPosterUrl = dto && dto.OptionPosterUrl ? dto.OptionPosterUrl : '';
    this.date = dto && dto.EventDate ? dayjs(dto.EventDate) : dayjs();
    this.venue = dto && dto.Venue ? new EventDetailsVenueStoreState(dto.Venue) : null;
    this.notAvailableForPurchase = dto && dto.NotAvailableForPurchase ? dto.NotAvailableForPurchase : false;
    this.notAvailableForPurchaseReason =
      dto && dto.NotAvailableForPurchaseReason ? dto.NotAvailableForPurchaseReason : '';
    this.ticketQuotas =
      dto && dto.TicketQuotas
        ? dto.TicketQuotas.map((item) => new EventDetailsTicketQuotaStoreState(item)).sort((a, b) =>
            a.slug > b.slug ? -1 : 1
          )
        : [];
    this.isDistributionAvailable = dto && dto.IsDistributionAvailable ? dto.IsDistributionAvailable : false;
    this.currency = dto && dto.Currency ? dto.Currency : '';
    this.description = dto && dto.Description ? dto.Description : '';
    this.minPrice = dto && dto.MinPrice ? dto.MinPrice : 0;
    this.maxPrice = dto && dto.MaxPrice ? dto.MaxPrice : 0;
    this.domains = dto && dto.Domains ? dto.Domains : [];
  }
}
