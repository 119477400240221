import React, { FC, memo, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useStyles } from './styles';

type TimerProps = {
  endTime: dayjs.Dayjs;
  onStop?: () => void;
  onTick?: () => void;
};

const Timer: FC<TimerProps> = ({ endTime, onStop, onTick }) => {
  const { classes } = useStyles();
  let timer: any;
  const intervalMs = 1000;

  const [currentTime, setCurrentTime] = useState(dayjs());
  const [isStarted, setIsStarted] = useState(false);

  useEffect(() => {
    if (dayjs(endTime).isAfter(currentTime)) {
      start();
    }
  }, [isStarted]);

  useEffect(() => {
    onTick();
  }, [currentTime]);

  useEffect(() => {
    if (dayjs(currentTime).isAfter(endTime)) {
      onStop();
    }
    return () => stop();
  }, [isStarted]);

  const tick = () => {
    const currentTime = dayjs();
    if (dayjs(currentTime).isAfter(endTime)) {
      stop();
      setCurrentTime(currentTime);
    } else {
      setCurrentTime(currentTime);
    }
  };

  const start = () => {
    timer = setInterval(tick, intervalMs);
    setIsStarted(true);
  };

  const stop = () => {
    clearInterval(timer);
    setIsStarted(false);
  };

  const getLeftTimeString = (): string => {
    const diffSec = dayjs(endTime).diff(currentTime, 's');
    const min = Math.floor(diffSec / 60);
    const sec = diffSec - min * 60;
    return `${min < 10 ? '0' : ''}${min}:${sec < 10 ? '0' : ''}${sec}`;
  };

  return isStarted && <span className={classes.container}>{getLeftTimeString()}</span>;
};

export default memo(Timer);
