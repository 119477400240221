import React, { FC } from 'react';
import styled from 'styled-components';

import { AnimatePresence, motion } from 'framer-motion';
import UserTicketItem from './userTicketItem';
import { PopUp } from '../../store/animations';
import { TicketType } from '../../core/models/tickets';

interface IUserTicketsListProps {
  tickets: TicketType[];
}

export const UserTicketsList: FC<IUserTicketsListProps> = ({ tickets }) => (
  <AnimatePresence>
    <TicketsList variants={PopUp} initial={'enter'} animate={'exit'}>
      {tickets.map((ticket, index) => (
        <TicketsListItem key={index}>
          <UserTicketItem ticket={ticket} />
        </TicketsListItem>
      ))}
    </TicketsList>
  </AnimatePresence>
);

const TicketsList = styled(motion.ul)`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const TicketsListItem = styled.li`
  margin-bottom: 10px;
`;
