import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Page, PageContent, PageFooter, TextInput } from '../../../components/UI';
import { IUserStoreState } from '../../../store/userV1/types';
import CopyButton from '../../../components/UI/copyButton';
import { useRoutesHelper } from '../../../helpers/routesHelper';
import { IGlobalStoreState } from '../../../store';

const ShareLinkPage: FC = () => {
  const user = useSelector<IGlobalStoreState, IUserStoreState>((state) => state.user);
  const isRedirection = useSelector<IGlobalStoreState, boolean>((state) => state.app.isRedirection);

  const { token, eventSlug } = useParams<ShareLinkParams>();
  const { getProfileRoute, generateReferralUrlByToken } = useRoutesHelper();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (!user) {
      navigate(getProfileRoute());
      return;
    }
  }, [user]);

  if (isRedirection || !token) {
    return null;
  }

  const referralLink = generateReferralUrlByToken(user, eventSlug, token);

  return (
    <Page isForm title={t('Share.EventLink')}>
      <PageContent>
        <Title>{t<string>('Share.EventLink')}</Title>
      </PageContent>
      <Footer>
        <ReferralLinkInput type="text" readOnly tabIndex={-1} value={referralLink} />
        <CopyButton value={referralLink} title={t('Share.CopyLink')} text={t('Share.CopyLink')} />
      </Footer>
    </Page>
  );
};

const Footer = styled(PageFooter as any)`
  padding: 0 25px 20px 25px;

  > button,
  > input {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
`;

const Title = styled.h1`
  font-size: 14px;
  margin: 0 0 15px 0;
`;

const ReferralLinkInput = styled(TextInput as any)`
  color: ${(props) => props.theme.colors.accent};
  padding: 15px 20px;

  &::placeholder {
    color: ${(props) => props.theme.colors.accent};
    opacity: 1;
  }

  &:focus {
    border-color: ${(props) => props.theme.colors.text};
  }
`;

export default ShareLinkPage;

type ShareLinkParams = {
  token: string;
  eventSlug: string;
};
