import React, { FC } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';

import { useTranslation } from 'react-i18next';
import { AnimatePresence } from 'framer-motion';
import { Button, TextInput } from '../UI';
import { FieldError, Fieldset, FieldWrapper } from './commonForm';
import { PopUp } from '../../store/animations';

export type PasswordRecoveryFormType = {
  email: string;
};

type PasswordRecoveryFormProps = {
  submitTitle: string;
  onSubmit: (LoginFormType) => void;
  isLoading: boolean;
};

export const PasswordRecoveryForm: FC<PasswordRecoveryFormProps> = ({ submitTitle, onSubmit, isLoading }) => {
  const { t } = useTranslation();

  const ValidationSchema = Yup.object().shape({
    email: Yup.string().required(t('PasswordRecovery.EmailRequired')).email(t('PasswordRecovery.EmailIncorrect')),
  });

  const initialValues = {
    email: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={(values: PasswordRecoveryFormType) => {
        if (onSubmit) {
          onSubmit(values);
        }
      }}
    >
      {({ isValid, handleChange, handleBlur, values }) => (
        <StyledForm autoComplete="on">
          <StyledFieldset>
            <Field name="email" type="email">
              {({ field, form }) => (
                <FieldWrapper noted={!!form.touched.email && !!form.errors && !!form.errors.email}>
                  <TextInput
                    placeholder={t('PasswordRecovery.EnterEmail')}
                    type="email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    error={!!form.touched.email && !!form.errors && !!form.errors.email}
                    {...field}
                  />
                  <AnimatePresence>
                    {!!form.touched.email && !!form.errors && !!form.errors.email && (
                      <FieldError variants={PopUp} initial={'enter'} animate={'exit'} key="error">
                        {form.errors.email}
                      </FieldError>
                    )}
                  </AnimatePresence>
                </FieldWrapper>
              )}
            </Field>
          </StyledFieldset>
          <Button isLoading={isLoading} type="submit" disabled={!isValid || isLoading}>
            {submitTitle}
          </Button>
        </StyledForm>
      )}
    </Formik>
  );
};

const StyledFieldset = styled(Fieldset)`
  flex: 1;
`;

const StyledForm = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
