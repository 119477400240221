import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { AnimatePresence, motion } from 'framer-motion';
import { useDispatch, useSelector } from '../../configureStore';
import { hideMenu } from '../../store/menu/actions';
import { PagesRoutes } from '../../store/routes';
import { OnlineCardPaymentLink, PaymentDocumentsLink, RLink, UserAgreementLink } from '../../components/UI';

export const Menu = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isShow = useSelector((state) => state.menu.isShow);

  const handleLinkClick = () => {
    dispatch(hideMenu());
  };
  const containerVariants = {
    hidden: {
      x: '-100%',
      transition: { duration: 0.3 },
    },
    visible: {
      x: '0%',
    },
    close: {
      x: '-100%',
      transition: { duration: 0.3 },
    },
  };

  return (
    <AnimatePresence>
      {isShow && (
        <Container
          variants={containerVariants}
          initial={'hidden'}
          animate={'visible'}
          exit={'close'}
          key="menu"
          isShow={isShow}
        >
          <Navigation>
            <MenuBlock>
              <Title>{t<string>('Menu.AboutUs')}</Title>
              <List>
                <ListItem>
                  <RLink to={PagesRoutes.Contacts} title={t('Menu.Contacts')} onClick={handleLinkClick}>
                    {t('Menu.Contacts')}
                  </RLink>
                </ListItem>
              </List>
            </MenuBlock>
            <MenuBlock grow>
              <Title>{t<string>('Menu.Payment')}</Title>
              <List>
                <ListItem>
                  <RLink to={PagesRoutes.PaymentsInfo} title={t('Menu.PaymentMethods')} onClick={handleLinkClick}>
                    {t('Menu.PaymentMethods')}
                  </RLink>
                </ListItem>
                <ListItem>
                  <OnlineCardPaymentLink onClick={handleLinkClick} />
                </ListItem>
                <ListItem>
                  <PaymentDocumentsLink onClick={handleLinkClick} />
                </ListItem>
              </List>
            </MenuBlock>
            <MenuBlock footer>
              <List>
                <ListItem>
                  <UserAgreementLink onClick={handleLinkClick} />
                </ListItem>
              </List>
            </MenuBlock>
          </Navigation>
        </Container>
      )}
    </AnimatePresence>
  );
};

type MenuType = {
  isShow: boolean;
  hideMenu?: () => void;
};

type MenuBlockType = Partial<{
  grow: boolean;
  footer: boolean;
}>;

const Container = styled(motion.aside)<MenuType>`
  position: fixed;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  z-index: 800;
  padding: 0;
  margin: 0;
  top: 0;
  bottom: 0;
  max-width: ${(props) => props.theme.maxWidth};
  display: ${(props) => !props.isShow && 'none'};
  background-color: rgba(0, 0, 0, 0.2);
`;

const Navigation = styled.nav`
  background-color: ${(props) => props.theme.colors.bg};
  max-width: calc(100% - 25px);
  padding: 130px 25px 25px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const MenuBlock = styled(motion.section)<MenuBlockType>`
  color: ${(props) => props.theme.colors.text};
  font-size: 14px;
  margin-bottom: ${(props) => !props.footer && '25px'};
  flex: ${(props) => props.grow && 1};
`;

const Title = styled.h2`
  font-size: 14px;
  font-family: ${(props) => props.theme.fonts.lighterFont};
  line-height: 18px;
  margin: 0 0 15px 0;
  padding: 0;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.accent};
`;

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const ListItem = styled.li`
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;
