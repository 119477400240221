import React, { FC } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles()(() => ({
  container: {
    display: 'flex',
    flexWrap: 'nowrap',
    gap: '8px',
  },
  icOk: {
    height: '20px',
    width: '20px',
  },
  icNotOk: {
    color: 'red',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.875rem',
  },
}));

export const BookCheckInfoItem: FC<BookCheckInfoItemType> = ({ title, isOk }) => {
  const { classes } = useStyles();
  return (
    <Grid container className={classes.container}>
      <Grid item>
        {!isOk && <CloseIcon viewBox={'2 0 20 20'} fontSize={'small'} className={classes.icNotOk} />}
        {isOk && <img className={classes.icOk} src={'content/img/icOk.svg'} alt={'icOk'} />}
      </Grid>
      <Grid item className={classes.title}>
        {title}
      </Grid>
    </Grid>
  );
};

type BookCheckInfoItemType = {
  title: string;
  isOk: boolean;
};
