import { AppSettings } from '../settings/appSettings';

export class Utils {
  private static _instance: Utils;

  constructor() {
    if (typeof Utils._instance === 'undefined') {
      Utils._instance = this;
    }
    return Utils._instance;
  }

  public static getTicketsPostfixByCount(count: number): string {
    switch (count) {
      case 1:
        return 'Book.Ticket';
      case 2:
      case 3:
      case 4:
        return 'Book.MultipleTicketsBeforeFive';
      default:
        return 'Book.Tickets';
    }
  }

  public static isIOs(): boolean {
    return /iPad|iPhone|iPod/.test(navigator.userAgent);
  }

  public static parseQueryString(query: string): any {
    const params = query.replace('?', '').split('&');
    let result: any = {};
    for (const p in params) {
      const field = Utils.replaceAt(params[p], params[p].indexOf('='), ' ').split(' ');
      result[field[0]] = field[1];
    }
    return result;
  }

  public static toPageTitle(source: string): string {
    return source && source.trim() ? `${source.trim()} | ${AppSettings.APP_NAME}` : AppSettings.APP_NAME;
  }

  public static toMetaKeywords(keywords: Array<string>): string {
    return keywords.join(',');
  }

  public static formatPhoneNumber(phone: string): string {
    return phone.replace(/[-| |)|(]/g, '');
  }

  public static getEmojiFlagByCurrency(currency: string): string {
    let result = '';
    switch (currency) {
      case 'BYN':
        result = 'Br';
        break;
      case 'RUB':
        result = '₽';
        break;
      case 'EUR':
        result = '€';
        break;
      case 'UAH':
        result = '₴';
        break;
      case 'USD':
        result = '$';
        break;
      default:
        result = '';
        break;
    }
    return result;
  }

  public static round(value: number, fraction = 2): number {
    const c = Math.pow(10, fraction);
    return Math.round(value * c) / c;
  }

  public static isEqual(x: any, y: any): boolean {
    if (x === y) {
      return true;
    }
    // if both x and y are null or undefined and exactly the same
    if (!(x instanceof Object) || !(y instanceof Object)) {
      return false;
    }
    // if they are not strictly equal, they both need to be Objects
    if (x.constructor !== y.constructor) {
      return false;
    }
    // they must have the exact same prototype chain, the closest we can do is
    // test there constructor.
    for (let p in x) {
      if (!x.hasOwnProperty(p)) {
        continue;
      }
      // other properties were tested using x.constructor === y.constructor
      if (!y.hasOwnProperty(p)) {
        return false;
      }
      // allows to compare x[ p ] and y[ p ] when set to undefined
      if (x[p] === y[p]) {
        continue;
      }
      // if they have the same strict value or identity then they are equal
      if (typeof x[p] !== 'object') {
        return false;
      }
      // Numbers, Strings, Functions, Booleans must be strictly equal
      if (!Utils.isEqual(x[p], y[p])) {
        return false;
      }
      // Objects and Arrays must be tested recursively
    }
    for (let p in y) {
      if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) {
        return false;
      }
      // allows x[ p ] to be set to undefined
    }
    return true;
  }

  // private methods

  private static numberToDigitsArray(value: number): number[] {
    return value
      .toString()
      .split('')
      .map((item) => +item);
  }

  private static replaceAt(source: string, index: number, replace: string): string {
    return source.substring(0, index) + replace + source.substring(index + 1);
  }
}
