import React, { FC, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ICheckoutStoreState } from '../../../../store/checkoutV1/types';
import { PageContent } from '../../../../components/UI';
import { IPaymentForm, PaymentFormV1 } from '../../../../components/forms';
import { Header, Title } from '../checkoutPageV1';
import { IUserStoreState } from '../../../../store/userV1/types';
import { useInitiateV1 } from '../../../../helpers/customHooks';
import { IBookStoreState } from '../../../../store/bookV1/types';
import { IDistributionStoreState } from '../../../../store/distributionV1/types';

export const PointsSelectorV1: FC<PointsSelectorPropsType> = memo(
  ({
    checkout,
    book,
    redirectUrl,
    checkoutSteps,
    user,
    isAuthorized,
    handlePaymentFormSubmit,
    distribution,
    isLoading,
  }) => {
    const { t } = useTranslation();
    const { initiate } = useInitiateV1();

    if (redirectUrl) {
      window.location.href = redirectUrl;
    }

    const handleUsePointsChange = useCallback(async (usePoints: boolean) => {
      await initiate(book, distribution, usePoints);
    }, []);

    return (
      <PageContent roundCorners>
        <Header>
          {t('Checkout.Step')} 2 {t('Checkout.Of')} {checkoutSteps}
        </Header>
        <Title>{t<string>('Checkout.PayByCard')}</Title>
        {!redirectUrl && isAuthorized && (
          <PaymentFormV1
            isLoading={isLoading}
            checkout={checkout}
            user={user}
            onUsePointsChange={(usePoints: boolean) => handleUsePointsChange(usePoints)}
            onSubmit={(formModel: IPaymentForm) => {
              if (formModel) {
                handlePaymentFormSubmit(formModel.usePoints);
              }
            }}
          />
        )}
      </PageContent>
    );
  }
);

type PointsSelectorPropsType = {
  isLoading: boolean;
  checkout: ICheckoutStoreState;
  book: IBookStoreState;
  distribution: IDistributionStoreState;
  redirectUrl: string;
  checkoutSteps: number;
  isAuthorized: boolean;
  user: IUserStoreState;
  handlePaymentFormSubmit: (usePoints: boolean) => void;
};
