import { IVenueSectorStoreState, VenueSectorStoreState } from './types';

export interface IProfileTicketQuotaStoreState {
  id: number;
  title: string;
  total: number;
  left: number;
  currency: string;
  price: number;
  isPrivate?: boolean;
  isAvailableForDistribution?: boolean;
  sector: IVenueSectorStoreState;
}

export class ProfileTicketQuotaStoreState implements IProfileTicketQuotaStoreState {
  id: number;
  title: string;
  total: number;
  left: number;
  currency: string;
  price: number;
  isPrivate?: boolean;
  isAvailableForDistribution?: boolean;
  sector: IVenueSectorStoreState;

  constructor(dto?: any) {
    this.title = dto && dto.Title ? dto.Title : '';
    this.id = dto && dto.Id ? dto.Id : 0;
    this.total = dto && dto.TicketsTotal ? dto.TicketsTotal : 0;
    this.left = dto && dto.TicketsLeft ? dto.TicketsLeft : 0;
    this.currency = dto && dto.Currency ? dto.Currency : '';
    this.price = dto && dto.PricePerTicket ? dto.PricePerTicket : 0;
    this.sector = dto && dto.Sector ? new VenueSectorStoreState(dto.Sector) : null;
    this.isPrivate = dto && dto.IsPrivate ? dto.IsPrivate : null;
    this.isAvailableForDistribution = dto && dto.IsAvailableForDistribution ? dto.IsAvailableForDistribution : null;
  }
}
