import React from 'react';
import { Divider, Grid, Skeleton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  container: {
    width: '343px',
  },
  header: {
    padding: '0px 16px 24px 16px',
  },
  divider: {
    borderColor: '#A8A8A8',
  },
  content: {
    padding: '10px 16px',
  },
  footer: {
    padding: '24px 16px 24px 16px',
  },
  skeleton: {
    transform: 'scale(1)',
  },
}));

export const OrderSkeleton = () => {
  const { classes } = useStyles();
  return (
    <Grid item className={classes.container}>
      <Grid className={classes.header} container item direction={'column'} justifyContent={'start'}>
        <Skeleton className={classes.skeleton} animation={'wave'} height={'115px'} width={'100%'} />
      </Grid>
      <Divider className={classes.divider} />
      <Grid className={classes.content} container item direction={'column'} justifyContent={'start'}>
        <Skeleton className={classes.skeleton} animation={'wave'} height={'170px'} width={'100%'} />
      </Grid>
      <Divider className={classes.divider} />
      <Grid container item className={classes.footer} direction={'column'} justifyContent={'start'}>
        <Skeleton className={classes.skeleton} animation={'wave'} height={'90px'} width={'100%'} />
      </Grid>
    </Grid>
  );
};
