import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { useDispatch } from '../../../../store';
import { useRoutesHelper } from '../../../../helpers/routesHelper';
import { AlertType, KeyErrors, Pages } from '../../../../store/enums';
import { setResultInfo } from '../../../../store/resultInformarion/actions';
import { Button, PageFooter } from '../../../../components/UI';
import { FormError } from '../../../../components/forms';
import { useLazyGetTokenQuery } from '../../../../core/api/shareSlice';

export const PrivateSellControls: FC<PrivateSellControlsType> = ({ ticketsOrRow, eventSlug, sectorSlug }) => {
  const [getTicketShareToken, { isFetching: isFetchingShareToken }] = useLazyGetTokenQuery();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getResultStatusRoute } = useRoutesHelper();

  const handleGetLinkClick = async (e) => {
    if (e) {
      e.preventDefault();
    }
    const token = await getTicketShareToken({ eventSlug, sectorSlug, tickets: ticketsOrRow });

    try {
      navigate(`/${Pages.Share}/${eventSlug}/${token.data}`);
    } catch (e) {
      dispatch(
        setResultInfo({
          type: AlertType.Error,
          key: KeyErrors.QuotaNotFound,
          title: 'Alert.SomethingWentWrong',
          message: 'Alert.CheckLinkNoLocationFound',
        })
      );
      navigate(getResultStatusRoute(AlertType.Error, KeyErrors.QuotaNotFound), { replace: true });
    }
  };

  return (
    <FooterStyled>
      <Button
        disabled={!ticketsOrRow || isFetchingShareToken}
        isLoading={isFetchingShareToken}
        onClick={(e) => handleGetLinkClick(e)}
      >
        {t<string>('Share.GetTheLink')}
      </Button>
    </FooterStyled>
  );
};

const FooterStyled = styled(PageFooter as any)`
  padding: 0 25px 20px 25px;

  > button,
  > input {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
`;

const ErrorWrapper = styled(FormError as any)`
  width: 100%;
`;

type PrivateSellControlsType = {
  ticketsOrRow: number;
  eventSlug: string;
  sectorSlug: string;
};
