import React, { FC, useEffect, useLayoutEffect } from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Button, PageContent } from '../../../components/UI';
import { Utils } from '../../../services';
import { Routes } from '../../../store/routes';
import { useLazyGetCurrentAccountQuery } from '../../../core/api/accountSlice';
import { useLazyGetUserEventsV1Query } from '../../../core/api/eventsSlice';
import { useLazyGetUserTicketsV1Query } from '../../../core/api/ticketsSlice';
import { UserStats } from '../../../components/userV1';
import { ProfileSkeletonV1 } from '../../../components/skeletons/profileSkeletonV1';
import { useSelector } from '../../../configureStore';

const ProfilePage: FC = () => {
  const isAuthorized = useSelector((state) => state.app.isAuthorized);
  const user = useSelector((state) => state.userV1);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const [getCurrentUser, { isFetching }] = useLazyGetCurrentAccountQuery();
  const [getUserEvents] = useLazyGetUserEventsV1Query();
  const [getUserTickets] = useLazyGetUserTicketsV1Query();

  useEffect(() => {
    if (!isAuthorized) {
      navigate(Routes.LoginV1, { replace: true });
    }
  }, []);

  useEffect(() => {
    if (isAuthorized && !user.phoneNumberConfirmed && user.emailConfirmed) {
      navigate(Routes.EditProfileV1);
    }
  }, [isAuthorized, navigate, user?.phoneNumberConfirmed]);

  useLayoutEffect(() => {
    getCurrentUser();
    getUserEvents();
    getUserTickets();
  }, []);

  const handleEditBtnClick = (e, path: string) => {
    if (e) {
      e.preventDefault();
    }
    navigate(path);
  };

  return (
    <PageContent center>
      <Helmet>
        <title>{Utils.toPageTitle(user?.fullName || user?.referralSlug)}</title>
      </Helmet>
      {isFetching && <ProfileSkeletonV1 />}
      {!isFetching && (
        <>
          <AvatarWrapper>
            <ProfileImage>
              {user.imageUrl && <Avatar src={user.imageUrl} alt={t('Profile.YourAvatar')} />}
              {!user.imageUrl && <ProfileImageTitle>{t<string>('Profile.Avatar')}</ProfileImageTitle>}
            </ProfileImage>
            <Button
              round
              edit
              title={t('Profile.EditProfile')}
              onClick={(e) => handleEditBtnClick(e, Routes.EditProfileV1)}
            />
          </AvatarWrapper>
          <UserInfo>
            <FullName>{user.fullName}</FullName>
            <UserStats stats={user.stats} />
          </UserInfo>
          <ButtonsWrapper>
            <Button title={t('Profile.GoToMyEvents')} onClick={(e) => handleEditBtnClick(e, Routes.UserEventsV1)}>
              {t<string>('Profile.MyEvents')}
            </Button>
            <Button
              title={t('Profile.GoToPurchasedTickets')}
              onClick={(e) => handleEditBtnClick(e, Routes.UserTicketsV1)}
            >
              {t<string>('Profile.MyTickets')}
            </Button>
          </ButtonsWrapper>
        </>
      )}
    </PageContent>
  );
};

export const AvatarWrapper = styled.section`
  position: relative;
`;

export const UserInfo = styled.section`
  width: 80%;
`;

export const FullName = styled.h1`
  font-size: 18px;
  font-family: ${(props) => props.theme.fonts.boldFont};
  margin: 10px 0;
  text-align: center;
`;

export const ButtonsWrapper = styled.section`
  width: 100%;

  > a,
  > button {
    margin-top: 10px;
  }
`;

const ProfileImage = styled.div`
  width: 130px;
  height: 130px;
  border: 1px solid ${(props) => props.theme.colors.text};
  align-self: center;
  border-radius: 50%;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
`;

const ProfileImageTitle = styled.span`
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  position: absolute;
`;

const Avatar = styled.img`
  flex: 1;
  object-fit: contain;
  z-index: 1;
  max-width: 130px;
  max-height: 130px;
`;

export default ProfilePage;
