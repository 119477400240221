import dayjs from 'dayjs';
import React, { FC } from 'react';
import styled from 'styled-components';
import { ListItem } from '../eventDetails';

type EventDateType = {
  date: dayjs.Dayjs;
};

export const EventDate: FC<EventDateType> = ({ date }) => {
  const day = dayjs(date).date();
  const month = dayjs(date).format('MMMM YYYY');
  const time = dayjs(date).format('LT');
  return (
    <ListItem>
      <DateTimeItem dateTime={dayjs(date).format()}>
        <DayTimeWrapper>
          <Day>{day}</Day>
          <Time>{time}</Time>
        </DayTimeWrapper>
        <MonthYearWrapper>
          <Month>{month}</Month>
        </MonthYearWrapper>
      </DateTimeItem>
    </ListItem>
  );
};

const DateTimeItem = styled.time`
  display: flex;
  flex-direction: column;
  width: 180px;
  top: -10px;
  position: relative;
  padding-left: 35px;
  font-family: ${(props) => props.theme.fonts.boldFont};

  &:before {
    content: '';
    position: absolute;
    width: 7px;
    height: 7px;
    background: ${(props) => props.theme.colors.accent};
    top: 6px;
    border-radius: 50%;
    left: -4px;
  }
`;

const DayTimeWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const MonthYearWrapper = styled.div`
  text-align: right;
  margin-top: 15px;
`;

const Day = styled.span`
  font-size: 64px;
  line-height: 40px;
  font-family: ${(props) => props.theme.fonts.bolderFont};
`;

const Title = styled.span`
  font-size: 14px;
  margin-bottom: 5px;
`;

const Subtitle = styled.span`
  font-size: 12px;
`;

const Time = styled(Subtitle as any)`
  text-align: right;
  margin-left: 10px;
  padding-bottom: 8px;
  border-bottom: 2px solid ${(props) => props.theme.colors.text};
  flex: 1;
`;

const Month = styled(Title as any)``;
