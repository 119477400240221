import { baseSlice } from './apiSlice';
import { ContractInfo, ContractInfoContainer, IMenuStoreState, MenuStoreState } from '../../store/menu/types';
import { EventHelper } from '../../helpers';
import { initContract } from '../../store/menu/actions';
import { publicContracts } from '../constants/constants';

const publicContractApi = baseSlice.injectEndpoints({
  endpoints: (build) => ({
    getUserAgreements: build.query<IMenuStoreState, void>({
      query: () => {
        const domain = window.location.hostname;
        const dtype = EventHelper.getDomainType(domain);
        return {
          url: publicContracts.publicContracts,
          params: { dtype },
        };
      },
      transformResponse: (response) => {
        const bonusPrograms = response['BonusProgram']
          ? <ContractInfoContainer[]>(
              response['BonusProgram'].map((t) => new ContractInfoContainer(t.Language, t.FileUrl))
            )
          : ([] as ContractInfoContainer[]);
        const publicContracts = response['PublicContract']
          ? <ContractInfoContainer[]>(
              response['PublicContract'].map((t) => new ContractInfoContainer(t.Language, t.FileUrl))
            )
          : ([] as ContractInfoContainer[]);

        const result = new ContractInfo(publicContracts, bonusPrograms);

        return new MenuStoreState(result);
      },
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        dispatch(initContract(data.contracts));
      },
    }),
  }),
});

export const { useLazyGetUserAgreementsQuery } = publicContractApi;
