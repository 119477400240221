import { userEventsReducerV1, userReducerV1, userTicketsReducerV1 } from './reducers';

// export const sellTicket = (
//   ticketId: string,
//   email: string,
//   price: number
// ): ((
//   dispatch: Dispatch<any>,
//   getState: () => IGlobalStoreState,
//   thunkService: ThunkService
// ) => Promise<ITicketStoreState>) => {
//   return async (dispatch: Dispatch<any>, getState: () => IGlobalStoreState, thunkService: ThunkService) => {
//     dispatch(AppActions.showSpinner());
//     try {
//       const routeSrv = new RouteService();
//       const model = new SellTicketInfo(ticketId, email, price);
//       const resultLinkTemplate = `${routeSrv.getBaseUrl()}/userV1/my-tickets`;
//       model.returnUrl = `${resultLinkTemplate}`;
//       const ticketV1 = await thunkService.api.tickets.sellTicket(model);
//       await dispatch(getUserTickets());
//       return ticketV1;
//     } finally {
//       dispatch(AppActions.hideSpinner());
//     }
//   };
// };
// export const boughtTicket = (
//   ticketId: string
// ): ((
//   dispatch: Dispatch<any>,
//   getState: () => IGlobalStoreState,
//   thunkService: ThunkService
// ) => Promise<ITicketStoreState>) => {
//   return async (dispatch: Dispatch<any>, getState: () => IGlobalStoreState, thunkService: ThunkService) => {
//     dispatch(AppActions.showSpinner());
//     try {
//       const routeSrv = new RouteService();
//       const model = new BoughtTicketInfo(ticketId);
//       const boughtResultLinkTemplate = `${routeSrv.getBaseUrl()}/userV1/my-tickets`;
//       model.returnUrl = `${boughtResultLinkTemplate}`;
//       const ticketV1 = await thunkService.api.tickets.boughtTicket(model);
//       await dispatch(getUserTickets());
//       return ticketV1;
//     } finally {
//       dispatch(AppActions.hideSpinner());
//     }
//   };
// };
// export const cancelSellTicket = (
//   ticketId: string
// ): ((
//   dispatch: Dispatch<any>,
//   getState: () => IGlobalStoreState,
//   thunkService: ThunkService
// ) => Promise<IProfileTicketStoreState>) => {
//   return async (dispatch: Dispatch<any>, getState: () => IGlobalStoreState, thunkService: ThunkService) => {
//     dispatch(AppActions.showSpinner());
//     try {
//       const ticketV1 = await thunkService.api.tickets.cancelSellTicket(ticketId);
//       await dispatch(getUserTickets());
//       return ticketV1;
//     } finally {
//       dispatch(AppActions.hideSpinner());
//     }
//   };
// };
// export const resendTicket = (
//   ticketId: string
// ): ((
//   dispatch: Dispatch<any>,
//   getState: () => IGlobalStoreState,
//   thunkService: ThunkService
// ) => Promise<IProfileTicketStoreState>) => {
//   return async (dispatch: Dispatch<any>, getState: () => IGlobalStoreState, thunkService: ThunkService) => {
//     dispatch(AppActions.showSpinner());
//     const state = getState();
//     try {
//       await thunkService.api.tickets.resendTicket(ticketId);
//       let ticketV1 = state.userTickets.find((t) => t.ticketId === ticketId);
//       ticketV1.sentAt = dayjs();
//       dispatch(updateTicket(ticketV1));
//       return ticketV1;
//     } finally {
//       dispatch(AppActions.hideSpinner());
//     }
//   };
// };

export const { update, phoneCurrentUpdate, sendPhoneCode, clear, userInit } = userReducerV1.actions;
export const { clearEvents, initDistributionEventsV1 } = userEventsReducerV1.actions;
export const { clearTickets, updateTicket, initTickets } = userTicketsReducerV1.actions;
