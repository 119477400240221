import { IEventStoreState } from './types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const eventsReducer = createSlice({
  name: 'eventsReducer',
  initialState: new Array<IEventStoreState>(),
  reducers: {
    initEvents: (state, action: PayloadAction<Array<IEventStoreState>>) => {
      return [...action.payload];
    },
  },
});

export default eventsReducer;
