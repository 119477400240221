import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useRoutesHelper } from '../../../../helpers/routesHelper';
import CopyButton from '../../../../components/UI/copyButton';
import { IEventStoreState } from '../../../../store/event/types';
import { IUserStoreState } from '../../../../store/userV1/types';
import { TextInput } from '../../../../components/UI';

export const SellControls: FC<SellControlsType> = ({
  isLoading,
  event,
  sectorSlug,
  rowSlug,
  user,
  ticketsOrRow,
  eventSlug,
}) => {
  const { t } = useTranslation();

  const { domainTypes } = event;
  const { generateReferralUrlV1 } = useRoutesHelper();
  const referralLink = generateReferralUrlV1(user, domainTypes, eventSlug, sectorSlug, rowSlug, ticketsOrRow);

  return (
    <>
      <ReferralLinkInput type="text" readOnly tabIndex={-1} value={referralLink} />
      <CopyButton isLoading={isLoading} value={referralLink} title={t('Share.CopyLink')} text={t('Share.CopyLink')} />
    </>
  );
};

const ReferralLinkInput = styled(TextInput as any)`
  color: ${(props) => props.theme.colors.accent};
  padding: 15px 20px;

  &::placeholder {
    color: ${(props) => props.theme.colors.accent};
    opacity: 1;
  }

  &:focus {
    border-color: ${(props) => props.theme.colors.text};
  }
`;

type SellControlsType = {
  isLoading: boolean;
  event: IEventStoreState;
  user: IUserStoreState;
  eventSlug: string;
  sectorSlug: string;
  rowSlug: string;
  ticketsOrRow: number;
};
