import * as React from 'react';
import { FC, memo, useCallback, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Skeleton } from '@mui/material';
import { ISeatStoreState } from '../../store/event/types';
import PanAndZoomImage from '../../helpers/PanAndZoomImage/PanAndZoomImage';
import { AppTheme } from '../../settings/appTheme';
import { IVenueSectorStoreState } from '../../store/ticket/types';
import { useGetSectorSchemaQuery } from '../../core/api/eventsSlice';

const useStyles = makeStyles()(() => ({
  placeSelectorWrapper: {
    display: 'inline-flex',
    flexDirection: 'column',
    height: 'calc(70vh - 185px)',
  },
  title: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    '&::after': {
      content: '""',
      width: '100%',
      display: 'block',
      background: '#fff',
      height: '1px',
      position: 'absolute',
      left: 0,
      top: '50%',
      zIndex: -1,
    },
    '&::before': {
      content: '""',
      width: '100%',
      background: '#fff',
      position: 'absolute',
      height: '1px',
      right: 0,
      top: '50%',
      display: 'block',
      zIndex: -1,
    },
  },
  schema: {
    zIndex: 0,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      maxWidth: '100%',
      maxHeight: '100%',
      objectFit: 'cover',
      '& text': {
        fontFamily: `${AppTheme.fonts.boldFont} !important`,
        userSelect: 'none',
      },
      '& .scene': {
        text: {
          fill: `${AppTheme.colors.accent}`,
        },
      },
      '& .disabled .seat': {
        fill: '#313131',
        color: `${AppTheme.colors.bg}`,
        borderColor: '2px green',
        cursor: 'pointer',
      },
      '& .seat': {
        '&.seat-schema': {
          display: 'none',
          text: {
            fill: 'black',
          },
        },
        '&.available': {
          fill: 'rgb(102, 102, 102)',
          cursor: 'pointer',
        },
        '&.selected': {
          cursor: 'pointer',
          fill: `${AppTheme.colors.accent}`,
          '&.seat-schema': {
            display: 'block',
          },
        },
      },
    },
  },
  skeletonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    zIndex: 0,
    minHeight: 'calc(100% - 50px)',
  },
  titleText: {
    fontSize: '14px',
    background: '#1C1C1C',
    width: '300px',
    textAlign: 'center',
  },
}));

export const PlaceSelector: FC<PlaceSelectorProps> = memo(
  ({ caption, selectedSeats, maxSelectCount, sector, onChange, venueSlug }) => {
    const { classes } = useStyles();

    const { data: scheme, isLoading } = useGetSectorSchemaQuery({
      venueSlug,
      sectorSlug: sector.slug,
      schemaVersion: sector.schema.version,
    });

    const handleSeatClick = useCallback(
      (e: any) => {
        const arraySlugs = e.target.closest('.available').id.split('_');
        const [sectorSlug, rowSlug, seatSlug] = arraySlugs;

        const row = sector.rows.find((r) => r.slug === rowSlug);
        const seat = row && row.seats.find((s) => s.slug === seatSlug);
        const quotaId = seat && seat.quotaId;
        if (sectorSlug && rowSlug && seatSlug && onChange) {
          onChange(quotaId, sectorSlug, rowSlug, seatSlug);
        }
      },
      [sector.rows]
    );

    useEffect(() => {
      for (const row of sector.rows) {
        for (const seat of row.seats) {
          const seatName = `${sector.slug}_${row.slug}_${seat.slug}`;
          const seatElement = document.getElementById(seatName);
          if (seatElement) {
            seatElement.addEventListener('click', (e) => handleSeatClick(e));
          }
        }
      }
      return () => {
        for (const row of sector.rows) {
          for (const seat of row.seats) {
            const seatName = `${sector.slug}_${row.slug}_${seat.slug}`;
            const seatElement = document.getElementById(seatName);
            if (seatElement) {
              seatElement.removeEventListener('click', (e) => handleSeatClick(e));
            }
          }
        }
      };
    }, [handleSeatClick, sector.rows, sector.slug, scheme?.schema]);

    useEffect(() => {
      for (const row of sector.rows) {
        for (const seat of row.seats) {
          const seatName = `${sector.slug}_${row.slug}_${seat.slug}`;
          const seatElement = document.getElementById(seatName);
          if (seatElement) {
            const isSelected = selectedSeats.some(
              (s) => s.seatSlug === seat.slug && s.rowSlug === row.slug && s.sectorSlug === sector.slug
            );

            if (isSelected) {
              seatElement.classList.add('selected');
              seatElement.classList.add('available');
            } else {
              seatElement.classList.remove('selected');
              if (maxSelectCount > selectedSeats.length) {
                seatElement.classList.add('available');
              } else {
                seatElement.classList.remove('available');
              }
            }
            seatElement.classList.add('seat');
          }
        }
      }
    }, [maxSelectCount, selectedSeats, scheme?.schema]);

    return (
      <section className={classes.placeSelectorWrapper}>
        {caption && (
          <h1 className={classes.title}>
            <span className={classes.titleText}>{caption}</span>
          </h1>
        )}
        {isLoading && (
          <div className={classes.skeletonWrapper}>
            <Skeleton animation="wave" variant="rectangular" width="30vh" height="auto" />
          </div>
        )}
        {!isLoading && (
          <PanAndZoomImage>
            <div className={classes.schema} dangerouslySetInnerHTML={{ __html: scheme.schema }} />
          </PanAndZoomImage>
        )}
      </section>
    );
  }
);

type PlaceSelectorProps = {
  caption?: string;
  sector: IVenueSectorStoreState;
  venueSlug?: string;
  selectedSeats: ISeatStoreState[];
  maxSelectCount: number;

  onChange?: (quotaId: number, sectorSlug: string, rowSlug: string, seatSlug: string) => void;
};
