import React, { FC, memo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export const BookTotalPrices: FC<BookTotalPricesPropsType> = memo(
  ({ ticketPrice, totalPrice, border, sectorTitle, currency }) => {
    const { t } = useTranslation();

    return (
      <PricesWrapperV1 border={border}>
        <PriceList>
          {!sectorTitle && !!ticketPrice && (
            <PriceItemWrapper>
              <PriceTitle>{t<string>('Book.TicketPrice')}</PriceTitle>
              <Price>
                {ticketPrice} {currency}
              </Price>
            </PriceItemWrapper>
          )}
          {!!sectorTitle && !!ticketPrice && (
            <PriceItemWrapper>
              <PriceTitle>{sectorTitle}</PriceTitle>
              <Price>
                {ticketPrice} {currency}
              </Price>
            </PriceItemWrapper>
          )}
        </PriceList>
        <TotalPriceWrapper>
          <TotalPriceTitle>{t('Book.Total')}</TotalPriceTitle>
          <TotalPrice>
            {totalPrice} {currency}
          </TotalPrice>
        </TotalPriceWrapper>
      </PricesWrapperV1>
    );
  }
);

export const PricesWrapperV1 = styled.section<{ border: boolean }>`
  border-top: ${(props) => props.border && `1px solid ${props.theme.colors.accent}`};
  padding-top: ${(props) => props.border && '15px'};
`;

const PriceList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const PriceItemWrapper = styled.li`
  display: flex;
  align-items: baseline;
  margin-bottom: 5px;
  color: ${(props) => props.theme.colors.textSecond};
  font-family: ${(props) => props.theme.fonts.lighterFont};
`;

const TotalPriceWrapper = styled(PriceItemWrapper)`
  margin-top: 15px;
  color: ${(props) => props.theme.colors.text};
`;

export const PriceTitle = styled.label`
  font-size: 12px;
`;

export const Price = styled(PriceTitle)`
  flex: 1;
  text-align: right;
`;

const TotalPriceTitle = styled(PriceTitle)`
  font-size: 14px;
  font-family: ${(props) => props.theme.fonts.regularFont};
`;

const TotalPrice = styled(Price)`
  font-size: 14px;
  font-family: ${(props) => props.theme.fonts.boldFont};
`;

type BookTotalPricesPropsType = {
  ticketPrice?: number;
  totalPrice: number;
  currency: string;
  sectorTitle?: string;
  border?: boolean;
};
