import { Grid, Skeleton } from '@mui/material';
import React, { FC } from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  container: {
    display: 'flex',
    flexWrap: 'nowrap',
    gap: '16px',
    padding: '16px 0',
  },
  icon: {
    height: '20px',
    width: '20px',
  },
  content: {
    gap: '8px',
    justifyContent: 'center',
  },
  title: {
    fontSize: '0.875rem',
  },
  subTitle: {
    fontSize: '0.75rem',
    color: '#A8A8A8',
  },
}));

type EventInfoItem = {
  title: string;
  icon: string;
  subTitle?: string;
};

export const BookEventInfoItem: FC<EventInfoItem> = ({ title, subTitle = null, icon }) => {
  const { classes } = useStyles();
  const images = {
    mail: 'content/img/icMail.svg',
    location: 'content/img/icLocation.svg',
    calendar: 'content/img/icCalendar.svg',
  };

  return (
    <Grid container className={classes.container}>
      <Grid item>
        <img className={classes.icon} src={`${images[icon]}`} alt={`${icon}`} />
      </Grid>
      <Grid container item direction={'column'} className={classes.content}>
        {!title && (
          <>
            <Skeleton animation={'wave'} width={'30%'} height={'20px'} />
            <Skeleton animation={'wave'} width={'30%'} height={'20px'} />
          </>
        )}
        {title && (
          <>
            <Grid item className={classes.title}>
              {title}
            </Grid>
            {subTitle && (
              <Grid item className={classes.subTitle}>
                {subTitle}
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};
