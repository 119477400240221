import dayjs from 'dayjs';

import { IEventStoreState, EventStoreState, ISchemaInformation, SchemaInformation } from '../event/types';

export interface IVenueSectorRowSeatStoreState {
  quotaId: number;
  slug: string;
  title: string;
}

export class VenueSectorRowSeatStoreState implements IVenueSectorRowSeatStoreState {
  public quotaId: number;
  public slug: string;
  public title: string;

  constructor(dto?: any) {
    this.slug = dto && dto.Slug ? dto.Slug : '';
    this.title = dto && dto.Title ? dto.Title : '';
    this.quotaId = dto && dto.TicketQuotaId ? dto.TicketQuotaId : 0;
  }
}

export interface IVenueSectorRowStoreState {
  slug: string;
  sectorSlug: string;
  title: string;
  seats: IVenueSectorRowSeatStoreState[];
}

export class VenueSectorRowStoreState implements IVenueSectorRowStoreState {
  public slug: string;
  public sectorSlug: string;
  public title: string;
  public seats: IVenueSectorRowSeatStoreState[];

  constructor(dto?: any) {
    this.slug = dto && dto.Slug ? dto.Slug : '';
    this.sectorSlug = dto && dto.SectorSlug ? dto.SectorSlug : '';
    this.title = dto && dto.Title ? dto.Title : '';
    this.seats = dto && dto.Seats ? dto.Seats.map((s: any) => new VenueSectorRowSeatStoreState(s)) : [];
  }
}

export interface IVenueSectorStoreState {
  slug: string;
  noSeats: boolean;
  title: string;
  rows: IVenueSectorRowStoreState[];
  ticketQuotas: Array<IBookTicketQuotaStoreState>;
  schema: ISchemaInformation;
  schemaUrl: string;
}

export class VenueSectorStoreState implements IVenueSectorStoreState {
  public slug: string;
  public noSeats: boolean;
  public title: string;
  public rows: IVenueSectorRowStoreState[];
  public ticketQuotas: Array<IBookTicketQuotaStoreState>;
  public schema: ISchemaInformation;
  public schemaUrl: string;

  constructor(dto?: any) {
    this.slug = dto && dto.Slug ? dto.Slug : '';
    this.title = dto && dto.Title ? dto.Title : '';
    this.noSeats = dto && dto.NoSeats ? dto.NoSeats : false;
    this.rows = dto && dto.Rows ? dto.Rows.map((r: any) => new VenueSectorRowStoreState(r)) : [];
    this.ticketQuotas =
      dto && dto.TicketQuotas
        ? dto.TicketQuotas.map((item) => new BookTicketQuotaStoreState(item)).sort((a, b) => (a.slug > b.slug ? -1 : 1))
        : [];
    this.schema = dto && dto.Schema ? new SchemaInformation(dto.Schema) : null;
    this.schemaUrl = dto && dto.schemaUrl ? dto.schemaUrl : null;
  }
}

export interface ITokenTicketQuotaStoreState {
  quotaId: number;
  left: number;
  sectorSlug: string;
}

export class TokenTicketQuotaStoreState implements ITokenTicketQuotaStoreState {
  quotaId: number;
  left: number;
  sectorSlug: string;

  constructor(dto?: any) {
    this.quotaId = dto && dto.QuotaId ? dto.QuotaId : 0;
    this.left = dto && dto.Left ? dto.Left : 0;
    this.sectorSlug = dto && dto.SectorSlug ? dto.SectorSlug : '';
  }
}

export interface IBookTicketQuotaStoreState {
  id: number;
  title: string;
  total: number;
  left: number;
  currency: string;
  price: number;
  sectorId: number;
  isPrivate?: boolean;
  isAvailableForDistribution?: boolean;
}

export class BookTicketQuotaStoreState implements IBookTicketQuotaStoreState {
  id: number;
  title: string;
  total: number;
  left: number;
  currency: string;
  price: number;
  sectorId: number;
  isPrivate?: boolean;
  isAvailableForDistribution?: boolean;

  constructor(dto?: any) {
    this.title = dto && dto.Title ? dto.Title : '';
    this.id = dto && dto.Id ? dto.Id : 0;
    this.total = dto && dto.TicketsTotal ? dto.TicketsTotal : 0;
    this.left = dto && dto.TicketsLeft ? dto.TicketsLeft : 0;
    this.currency = dto && dto.Currency ? dto.Currency : '';
    this.price = dto && dto.PricePerTicket ? dto.PricePerTicket : 0;
    this.sectorId = dto && dto.SectorId ? dto.SectorId : 0;
    this.isPrivate = dto && dto.IsPrivate ? dto.IsPrivate : false;
    this.isAvailableForDistribution = dto && dto.IsAvailableForDistribution ? dto.IsAvailableForDistribution : null;
  }
}

export interface ITicketStoreState {
  masterTicketId: string;
  ticketId: string;
  status: string;
  issuedTo: string;
  saleUser: string;
  currency: string;
  price: number;
  issuedAt: dayjs.Dayjs;
  event: IEventStoreState;
  sentAt?: dayjs.Dayjs;
  ticketQuota?: IBookTicketQuotaStoreState;
  rowTitle?: string;
  seatTitle?: string;
  seatId?: number;
  token: string;
  expiredPaymentTime: dayjs.Dayjs;
  percentageComission: number;
  comissionDescription: string;
  priceComission: number;
}

export class TicketStoreState implements ITicketStoreState {
  masterTicketId: string;
  ticketId: string;
  status: string;
  issuedTo: string;
  saleUser: string;
  currency: string;
  price: number;
  issuedAt: dayjs.Dayjs;
  event: IEventStoreState;
  sentAt?: dayjs.Dayjs;
  ticketQuota?: IBookTicketQuotaStoreState;
  rowTitle?: string;
  seatTitle?: string;
  seatId?: number;
  token: string;
  expiredPaymentTime: dayjs.Dayjs;
  percentageComission: number;
  comissionDescription: string;
  priceComission: number;

  constructor(dto?: any) {
    this.masterTicketId = dto && dto.MasterTicketId ? dto.MasterTicketId : '';
    this.ticketId = dto && dto.TicketId ? dto.TicketId : '';
    this.status = dto && dto.Status ? dto.Status : '';
    this.issuedTo = dto && dto.IssuedTo ? dto.IssuedTo : '';
    this.currency = dto && dto.Currency ? dto.Currency : '';
    this.price = dto && dto.Price ? dto.Price : 0;
    this.issuedAt = dto && dto.IssuedAt ? dayjs(dto.IssuedAt) : dayjs();
    this.event = dto && dto.Event ? new EventStoreState(dto.Event) : null;
    this.sentAt = null;
    this.ticketQuota = dto && dto.TicketQuota ? new BookTicketQuotaStoreState(dto.TicketQuota) : null;
    this.rowTitle = dto && dto.RowTitle ? dto.RowTitle : '';
    this.seatTitle = dto && dto.SeatTitle ? dto.SeatTitle : '';
    this.seatId = dto && dto.SeatId ? dto.SeatId : 0;
    this.saleUser = dto && dto.SaleUser ? dto.SaleUser : '';
    this.token = dto && dto.Token ? dto.Token : '';
    this.expiredPaymentTime = dto && dto.ExpiredPaymentTime ? dayjs(dto.ExpiredPaymentTime) : dayjs();
    this.percentageComission = dto && dto.PercentageComission ? dto.PercentageComission : null;
    this.comissionDescription = dto && dto.ComissionDescription ? dto.ComissionDescription : '';
    this.priceComission = dto && dto.PriceComission ? dto.PriceComission : '';
  }
}

export interface IIssueTicketInfo {
  ticketId: string;
  issueToMyself: boolean;
  issueToEmail: string;
}

export class IssueTicketInfo implements IIssueTicketInfo {
  ticketId: string;
  issueToMyself: boolean;
  issueToEmail: string;

  constructor(ticketId: string, email: string, self = false) {
    this.ticketId = ticketId;
    this.issueToEmail = email;
    this.issueToMyself = self;
  }
}

export interface ISellTicketInfo {
  ticketId: string;
  issueToEmail: string;
  price: number;
  returnUrl: string;
}

export class SellTicketInfo implements ISellTicketInfo {
  ticketId: string;
  issueToEmail: string;
  price: number;
  returnUrl: string;

  constructor(ticketId: string, email: string, price: number) {
    this.ticketId = ticketId;
    this.issueToEmail = email;
    this.price = price;
  }
}

export interface IBoughtTicketInfo {
  ticketId: string;
  returnUrl: string;
}

export class BoughtTicketInfo implements IBoughtTicketInfo {
  ticketId: string;
  returnUrl: string;

  constructor(ticketId: string) {
    this.ticketId = ticketId;
  }
}

export interface ISellCardTokenInfo {
  amount: number;
  currency: string;
  referenceNumber: string;
  customerEmail: string;
}

export class SellCardTokenInfo implements ISellCardTokenInfo {
  amount: number;
  currency: string;
  referenceNumber: string;
  customerEmail: string;

  constructor(amount: number, currency: string, referenceNumber: string, customerEmail: string) {
    this.amount = amount;
    this.currency = currency;
    this.referenceNumber = referenceNumber;
    this.customerEmail = customerEmail;
  }
}
