import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { useScript } from '../../../../helpers/customHooks';
import { AuthSettings } from '../../../../settings/appSettings';
import { Pages } from '../../../../store/enums';
import { useGoogleTokenMutation } from '../../../../core/api/authSlice';
import { Routes } from '../../../../store/routes';

export const GoogleLogin = () => {
  const navigate = useNavigate();

  const [googleUser, setGoogleUser] = useState<googleUserInfo>(null);
  const googleButtonRef = useRef();

  const [googleLogin] = useGoogleTokenMutation();

  useScript(AuthSettings.GOOGLE_SDK, () => {
    window.google.accounts.id.initialize({
      client_id: AuthSettings.GOOGLE_CLIENT_ID,
      callback: (user) => {
        setGoogleUser(user);
      },
      auto_select: false,
    });
    window.google.accounts.id.renderButton(googleButtonRef.current, {
      type: 'standard',
      theme: 'outline',
      shape: 'pill',
      locale: 'en',
      width: 302,
      text: 'continue_with',
      logo_alignment: 'center',
    });
  });

  useEffect(() => {
    if (!googleUser) {
      return;
    }
    const token = {
      access_token: googleUser.credential,
      client_id: googleUser.clientId,
    };
    (async () => await googleLogin(token))()
      .then(() => {
        navigate(Routes.ProfileV1, { replace: true });
      })
      .catch(() => {
        navigate(Routes.LoginV1, { replace: true });
      });
  }, [googleUser]);

  return (
    <Container>
      <div ref={googleButtonRef} />
    </Container>
  );
};
const Container = styled.div`
  width: auto;
  height: 44px;
`;

type googleUserInfo = {
  clientId: string;
  credential: string;
};

declare global {
  interface Window {
    google: {
      accounts: {
        id: {
          initialize: (option) => void;
          renderButton: (element, options) => void;
        };
      };
    };
  }
}
