import React, { FC } from 'react';
import styled from 'styled-components';

import { PageContent } from './page';

type MessagePropsType = {
  title: string;
  description?: string;
  icon?: string;

  renderButtons?: () => JSX.Element;
};

export const Message: FC<MessagePropsType> = ({ title, renderButtons, icon, description }) => {
  return (
    <Container icon={icon}>
      <Title>{title}</Title>
      {description && <Description>{description}</Description>}
      {renderButtons && renderButtons()}
    </Container>
  );
};

const Container = styled(PageContent)<{ icon: string }>`
  justify-content: center;

  &:before {
    content: ${(props) => (props.icon ? `url(${props.icon})` : 'none')};
    text-align: center;
  }
`;

const Title = styled.h1`
  font-size: 16px;
  text-align: center;
  font-family: ${(props) => props.theme.fonts.boldFont};
`;

const Description = styled(Title)<{ theme: { fonts: string } }>`
  margin-bottom: 30px;
  font-size: 14px;
  line-height: 18px;
  font-family: ${(props) => props.theme.fonts.regularFont};
`;

export const MessageButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > button {
    margin-top: 20px;
  }
`;
