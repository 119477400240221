export enum account {
  me = `/accounts/me`,
  confirmPhone = `/accounts/confirmphone`,
  changePassword = `/accounts/changepassword`,
  updateCurrentAccount = `/accounts/me`,
  resetPassword = `/accounts/resetpassword`,
  confirmResetPassword = `/accounts/confirmresetpassword`,
  phone = `/accounts/phone`,
  signUp = `/accounts/signup`,
}

export enum auth {
  token = `/auth/token`,
  googleToken = `/auth/googleToken`,
  facebookToken = `/auth/facebookToken`,
  ping = `/auth/ping`,
}

export enum events {
  locations = `/events/locations`,
  events = `/events`,
  list = `/events/list`,
  my = `/events/my`,
  categories = `/events/categories`,
  dates = `/events/calendar/dates`,
  genres = `/events/genres`,
}

export enum home {
  regionalInfo = `/home/regionalInfo`,
  appSettings = `/home/appsettings`,
}

export enum tickets {
  my = `/tickets/my`,
  issue = `/tickets/issue`,
  send = `/tickets/send`,
}

export enum payment {
  cancel = `/payments/cancel`,
  complete = `/payments/complete`,
  initiate = `/payments/initiate`,
}

export enum publicContracts {
  publicContracts = `/publicContracts`,
}
