import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { isEmpty } from 'lodash';
import EventHelper from './eventHelper';
import { AppSettings } from '../settings/appSettings';
import { IBookStoreState } from '../store/bookV1/types';
import { AlertType, BookStatus, Pages } from '../store/enums';
import { store } from '../index';
import { PagesRoutes, Routes } from '../store/routes';
import { IUserStoreState } from '../store/userV1/types';

export const useRoutesHelper = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const changeDomainTopLevel = (domainTopLevel: string, hostName: string): string => {
    const parseHostName = hostName.split('.');
    const hostDomainToplevel = parseHostName[parseHostName.length - 1];
    return `${location.protocol}//${hostName.replace(hostDomainToplevel, domainTopLevel)}`;
  };

  const handleEventLink = (eventDomainTypes, callback) => {
    const domain = location.host;

    if (domain.includes(AppSettings.LOCALHOST)) {
      navigate(callback);
      return;
    }

    const { getDomainType, getDomainTopLevel } = EventHelper;
    const sortEventDomainTypes = [...eventDomainTypes].sort((a, b) => a - b);

    const domainType = getDomainType(domain);
    const isDomainExist = sortEventDomainTypes.includes(domainType);

    if (isDomainExist || isEmpty(eventDomainTypes)) {
      navigate(callback);
      return;
    }

    const [firstDomain] = eventDomainTypes;
    const domainName = getDomainTopLevel(firstDomain);
    const changedDomain = changeDomainTopLevel(domainName, domain);
    open(`${changedDomain}${callback}`);
  };

  const backRoute = () => {
    const isEventDetailsPage = isActivePage(Pages.EventDetails);
    if (history.length === 1 && isEventDetailsPage) {
      location.replace(location.origin);
      return;
    }
    navigate(-1);
  };

  const getBookEventRoute = (eventSlug: string) => `/book/${eventSlug}`;
  const getBookSectorRouteV1 = (eventSlug: string, sectorSlug: string) =>
    `/v1/${Pages.BookV1}/${eventSlug}/${sectorSlug}`;

  const getBookQuotaSectorV1 = (eventSlug: string, quotaId: string, sectorSlug: string) =>
    `/v1/${Pages.BookV1}/${eventSlug}/${quotaId}/${sectorSlug}`;
  const getSellEventRouteV1 = (eventSlug: string) => `/v1/${Pages.Sell}/${eventSlug}`;
  const getSellEventRoute = (eventSlug: string) => `/${Pages.Sell}/${eventSlug}`;

  const getBookRoute = (
    eventSlug: string,
    quotaId?: string,
    sectorSlug?: string,
    rowSlug?: string,
    tickets?: number,
    sell = false
  ): string => {
    const page = `/v1/${sell ? Pages.Sell : Pages.BookV1}/${eventSlug}`;
    const quota = !!quotaId ? `/${quotaId}` : '';
    const sector = !!sectorSlug ? `/${sectorSlug}` : '';
    const sectorRow = !!sector && !!rowSlug ? `/${rowSlug}` : '';
    const ticketsCount = !!sector && !sectorRow && !!tickets ? `/${tickets}` : '';

    return page + sector + quota + sectorRow + ticketsCount;
  };

  const createReferralLink = (referralSlug: string) => `${getBaseUrl()}/r/${referralSlug}`;

  const getBookRouteFromBookV1 = (book: IBookStoreState, sell = false): string => {
    if (!book || !book.event) {
      return null;
    }
    return getBookRoute(book.event.slug, null, book.sectorSlug, null, null, sell);
  };

  const getCheckoutRouteFromBook = (book: IBookStoreState): string =>
    `${getBookRouteFromBookV1(book)}/${Pages.Checkout}`;

  const getBookResultRoute = (status: BookStatus = null): string => `/${Pages.BookV1}/${Pages.BookResult}/${status}`;

  const getRouteData = (): string[] => {
    const routeData = window.location.pathname.split('/');
    return routeData.filter((routeSegment) => !!routeSegment);
  };

  const getWidgetToken = (): string => {
    const routeData = getRouteData();
    const [widget, , slug] = routeData;
    let token = '';
    switch (widget) {
      case Pages.Widget:
        token = slug;
        break;
      default:
        token = '';
        break;
    }
    return token;
  };

  const getWidgetBookResultRoute = (status: BookStatus = null, token: string): string =>
    `/${Pages.Widget}/${Pages.BookV1}/${token}/${Pages.BookResult}/${status}`;

  const isActivePage = (pageName: string, subPageName?: string, options?: Array<string>): boolean => {
    if (window.location && window.location.pathname) {
      const routeData = window.location.pathname.substr(1).split('/');
      if (routeData.length) {
        const [currentPageName, currentSubPageName] = routeData;

        const isCurrentPage = pageName.toLowerCase().replace('/', '') === currentPageName.toLowerCase();

        if (!!subPageName) {
          const isCurrentSubPage =
            !!currentSubPageName && subPageName.toLowerCase() === currentSubPageName.toLowerCase();

          if (!!options) {
            let isCurrentOptionsPage = true;
            options.forEach((o) => {
              const isExistOpt = routeData.indexOf(o) > -1;
              if (!isExistOpt) {
                isCurrentOptionsPage = false;
              }
            });
            return isCurrentPage && isCurrentSubPage && isCurrentOptionsPage;
          }
          return isCurrentPage && isCurrentSubPage;
        }
        return isCurrentPage;
      }
    }
    return false;
  };

  const getProfileRouteV1 = (): string => {
    const state = store.getState();
    const isAuthorized = state.app.isAuthorized && state.user && state.user.email;

    if (isAuthorized && !state.user.phoneNumberConfirmed) {
      return Routes.EditProfileV1;
    } else if (isAuthorized) {
      return Routes.ProfileV1;
    } else {
      return Routes.LoginV1;
    }
  };

  const getProfileRoute = (): string => {
    const state = store.getState();
    const isAuthorized = state.app.isAuthorized && state.user && state.user.email;

    if (isAuthorized && !state.user.phoneNumberConfirmed) {
      return PagesRoutes.EditProfile;
    } else if (isAuthorized) {
      return PagesRoutes.Profile;
    } else {
      return PagesRoutes.Login;
    }
  };

  const getEventRouteV1 = (eventSlug: string): string => `/v1/${Pages.EventDetails}/${eventSlug}`;
  const getEventRoute = (eventSlug: string): string => `/event/${eventSlug}`;

  const getBaseUrl = (): string => location.origin || `${location.protocol}//${location.host}`;

  const getHostReferralUrl = (eventDomainTypes) => {
    const domain = location.host;

    const { getDomainType, getDomainTopLevel } = EventHelper;
    const domainType = getDomainType(domain);

    const isDomainExist = eventDomainTypes.includes(domainType);

    if (domain.includes(AppSettings.LOCALHOST) || isDomainExist || isEmpty(eventDomainTypes)) {
      return getBaseUrl();
    }

    const [firstDomain] = eventDomainTypes;
    const domainName = getDomainTopLevel(firstDomain);
    return changeDomainTopLevel(domainName, domain);
  };

  const generateReferralUrlV1 = (
    user: IUserStoreState,
    domainTypes: number[],
    eventSlug?: string,
    sectorSlug?: string,
    rowSlug?: string,
    tickets?: number
  ): string =>
    `${getHostReferralUrl(domainTypes)}/${Pages.Referral}/${user.referralSlug}${eventSlug ? `/${eventSlug}` : ''}${
      !!eventSlug && !!sectorSlug ? `/${sectorSlug}` : ''
    }${!!eventSlug && !!sectorSlug && !!rowSlug ? `/${rowSlug}` : ''}${
      !!eventSlug && !!sectorSlug && !rowSlug && !!tickets ? `/${tickets}` : ''
    }`;

  const generateReferralUrl = (eventSlug: string, referralSlug: string, domainTypes: number[]): string =>
    `${getHostReferralUrl(domainTypes)}/referral/${referralSlug}/${eventSlug}`;

  const getResultStatusRoute = (status, key) => {
    const isSuccess = status !== AlertType.Error;

    return `/${isSuccess ? Pages.Success : Pages.Failed}/${key}`;
  };

  const getWidgetResultStatusRoute = (status, key) => {
    const isSuccess = status !== AlertType.Error;

    return `/${Pages.Widget}/${isSuccess ? Pages.Success : Pages.Failed}/${key}`;
  };

  const getWidgetCheckoutRouteFromBook = (book: IBookStoreState, token: string): string => {
    if (!book || !book.event) return null;
    return `/${Pages.Widget}/${Pages.BookV1}/${token}/${Pages.Checkout}`;
  };

  const getWidgetRouteFromToken = (token: string): string => {
    if (!token) return null;
    return `/${Pages.Widget}/${Pages.BookV1}/${token}`;
  };

  const getUserTicketsRouteV1 = () => `/v1/${Pages.User}/${Pages.UserTickets}`;

  const getUserTicketPurchaseRouteV1 = (masterTicketId: string, ticketId: string): string =>
    `${getUserTicketsRouteV1()}/purchase/${masterTicketId}/${ticketId}`;

  const getUserTicketRouteV1 = (masterTicketId: string, ticketId: string): string =>
    `${getUserTicketsRouteV1()}/${masterTicketId}/${ticketId}`;

  const getUserTicketSellRouteV1 = (masterTicketId: string, ticketId: string): string =>
    `${getUserTicketsRouteV1()}/sell/${masterTicketId}/${ticketId}`;

  const getUserTicketsRoute = () => `/user/my-tickets`;

  const getUserTicketPurchaseRoute = (masterTicketId: string, ticketId: string): string =>
    `${getUserTicketsRoute()}/purchase/${masterTicketId}/${ticketId}`;

  const getUserTicketRoute = (ticketId: number): string => `${getUserTicketsRoute()}/${ticketId}`;

  const getUserTicketSellRoute = (masterTicketId: string, ticketId: string): string =>
    `${getUserTicketsRoute()}/sell/${masterTicketId}/${ticketId}`;

  const getCheckoutRouteV1 = (eventSlug: string) => `/v1/${Pages.BookV1}/${eventSlug}/${Pages.Checkout}`;
  const getCheckoutRoute = (eventSlug: string) => `/book/${eventSlug}/${Pages.Checkout}`;

  const getEventCategoryRouteV1 = (categorySlug: string): string => `/${Pages.CategoriesV1}/${categorySlug}`;
  const getGenreEventsRoute = (genreSlug: string): string => `/${Pages.Genres}/${genreSlug}`;

  const getLocation = (domain?: string): string => {
    const isDev = location.host.includes('dev');
    let { host } = location;
    if (domain) {
      host = isDev ? `dev.${domain}` : domain;
    }
    return `${location.protocol}//${host}${location.pathname}${location.search}`;
  };

  const getResetPasswordRedirectUrlV1 = () => `${getBaseUrl()}/v1/${Pages.User}/${Pages.ResetPassword}`;
  const getResetPasswordRedirectUrl = () => `${getBaseUrl()}/user/${PagesRoutes.ResetPassword}`;

  const getEventsLocationV1 = (city: string) => `/v1?city=${city}`;
  const getEventsLocation = (city: string) => `?city=${city}`;

  const generateReferralUrlByToken = (user: IUserStoreState, eventSlug: string, token: string): string => {
    return !!token && `${getBaseUrl()}/tr/${user.referralSlug}/${eventSlug}/${token}`;
  };

  const getTicketGroupRoute = (eventSlug: string, ticketGroupId: number): string => `/book/${eventSlug}/${ticketGroupId}`;

  return {
    getProfileRouteV1,
    getEventRouteV1,
    getUserTicketsRouteV1,
    getUserTicketRouteV1,
    getUserTicketPurchaseRouteV1,
    getUserTicketSellRouteV1,
    getCheckoutRouteV1,
    getSellEventRouteV1,
    getBookRouteFromBookV1,
    getBookSectorRouteV1,
    getBookQuotaSectorV1,
    getEventCategoryRouteV1,
    getResetPasswordRedirectUrlV1,
    getEventsLocationV1,

    handleEventLink,
    changeDomainTopLevel,
    backRoute,
    getCheckoutRouteFromBook,
    getBookResultRoute,
    getWidgetToken,
    getWidgetBookResultRoute,
    isActivePage,
    getProfileRoute,
    getBookRoute,
    getEventRoute,
    getBaseUrl,
    generateReferralUrlV1,
    generateReferralUrl,
    getResultStatusRoute,
    getWidgetCheckoutRouteFromBook,
    getWidgetRouteFromToken,
    getWidgetResultStatusRoute,
    getUserTicketRoute,
    getUserTicketSellRoute,
    getUserTicketPurchaseRoute,
    getUserTicketsRoute,
    getCheckoutRoute,
    getBookEventRoute,
    getSellEventRoute,
    getGenreEventsRoute,
    getLocation,
    getResetPasswordRedirectUrl,
    getEventsLocation,
    generateReferralUrlByToken,
    createReferralLink,
    getTicketGroupRoute,
  };
};
