import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { CostTitle } from '../bookPageV1';
import { SelectedSeatsType } from './footerV1';

export const TicketsInfo: FC<TicketsInfoPropsType> = memo(({ selectedSeats, total, currency }) => {
  const { t } = useTranslation();

  if (!selectedSeats.length) {
    return null;
  }

  return (
    <>
      <Container>
        {t('Book.SelectedTickets')}
        {selectedSeats.map((ticket, index) => (
          <Row key={index}>
            <Data>
              <CostTitle>{ticket.title}</CostTitle>
            </Data>
            <Data>
              <CostTitle>{ticket.price}</CostTitle>
            </Data>
          </Row>
        ))}
      </Container>
      <Container>
        <Row>
          <Data>
            <TotalPriceTitle>{t('Book.Total')}</TotalPriceTitle>
          </Data>
          <Data>
            <TotalPrice>
              {total} {currency}
            </TotalPrice>
          </Data>
        </Row>
      </Container>
    </>
  );
});
const PriceTitle = styled.label`
  font-size: 12px;
`;

const Price = styled(PriceTitle)`
  flex: 1;
  text-align: right;
`;

const TotalPrice = styled(Price)`
  font-size: 14px;
  font-family: ${(props) => props.theme.fonts.boldFont};
`;
const TotalPriceTitle = styled(PriceTitle)`
  font-size: 14px;
  font-family: ${(props) => props.theme.fonts.regularFont};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 2px 0;
  border-top: 1px solid ${(props) => props.theme.colors.accent};
  width: 55%;
  margin-left: auto;
  min-width: 330px;
`;
const Row = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;

const Data = styled.div`
  width: 50%;
`;

export type TicketsInfoPropsType = {
  selectedSeats: SelectedSeatsType[];
  currency: string;
  total: number;
};
