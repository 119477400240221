import React, { useRef, useState } from 'react';
import { Chip, ClickAwayListener, Grid, List, ListItem, Paper, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  container: {
    position: 'relative',
  },
  sortChip: {
    color: 'inherit',
    border: '1px solid rgba(107, 107, 107, 0.6)',
    padding: 12,
    margin: 0,
    '.MuiChip-icon': {
      marginRight: 0,
    },
    ':hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  paper: {
    position: 'absolute',
    marginTop: '4px',
    padding: '17px 24px',
    background: 'linear-gradient(180.62deg, rgba(28, 28, 28, 1) -91.79%, rgba(18, 17, 17, 1) 157.66%)',
    boxShadow: '4px -4px 16px rgb(0 0 0 / 63%), -4px 4px 16px rgba(0, 0, 0, 0.63)',
    borderRadius: '12px',
    zIndex: 1,
  },
  wrapperTitle: {
    padding: 0,
  },
  sortTitle: {
    fontSize: '0.75rem',
    [theme.breakpoints.down(425)]: {
      fontSize: '0.625rem',
    },
  },
  selectSortTitle: {
    paddingLeft: 4,
    color: theme.palette.secondary.main,
    fontSize: '0.75rem',
    [theme.breakpoints.down(425)]: {
      fontSize: '0.625rem',
    },
  },
  activeLink: {
    textDecoration: 'none',
    color: theme.palette.secondary.main,
  },
  link: {
    color: 'white',
    textDecoration: 'none',
    ':visited': {
      color: 'white',
    },
    ':hover': {
      color: theme.palette.secondary.main,
    },
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  listItem: {
    padding: '0 0 0 0',
  },
  titleQuantity: {
    color: 'white',
    textDecoration: 'none',
    fontSize: '0.75rem',
    cursor: 'pointer',
    ':visited': {
      color: 'white',
    },
    ':hover': {
      color: theme.palette.secondary.main,
    },
  },
}));

export const SortButton = () => {
  const { classes } = useStyles();
  const sortBy = ['Price', 'Deal Score', 'Best Seat'];

  const [isOpen, setIsOpen] = useState(false);
  const [selectTitle, setSelectTitle] = useState(sortBy.at(0));
  const ref = useRef();

  const handleOpenClick = () => {
    setIsOpen((open) => !open);
  };

  const handleAwayClick = () => setIsOpen(false);

  const handleSortSelect = (sortType: string) => {
    setSelectTitle(sortType);
    setIsOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleAwayClick} ref={ref.current}>
      <Grid className={classes.container}>
        <Chip
          className={classes.sortChip}
          label={
            <Typography component={'span'} className={classes.sortTitle}>
              Sorted by
              <Typography component={'span'} className={classes.selectSortTitle}>
                {selectTitle}
              </Typography>
            </Typography>
          }
          icon={<img src={'./content/img/sortIcon.svg'} alt={'sortIcon'} />}
          onClick={handleOpenClick}
        />
        {isOpen && (
          <Paper className={classes.paper} ref={ref}>
            <List className={classes.list}>
              {sortBy.map((title, index) => (
                <ListItem key={index} className={classes.listItem}>
                  <Typography
                    component={'div'}
                    style={title === selectTitle ? { color: '#FFBF00' } : undefined}
                    className={classes.titleQuantity}
                    onClick={() => handleSortSelect(title)}
                  >
                    {title}
                  </Typography>
                </ListItem>
              ))}
            </List>
          </Paper>
        )}
      </Grid>
    </ClickAwayListener>
  );
};
