import React, { FC, memo, useEffect, useState } from 'react';
import { Skeleton, Slider } from '@mui/material';
import { isEqual } from 'lodash';
import { useLocation } from 'react-router';
import { makeStyles } from 'tss-react/mui';
import { useSearchParams } from 'react-router-dom';
import { useDebouncedFunction } from '../../helpers/customHooks';
import { useUtils } from '../../services/useUtils';
import { FilterParams } from '../../store/enums';

const useStyles = makeStyles()((theme) => ({
  rail: {
    backgroundColor: '#6B6B6B',
  },
  track: {
    background:
      'radial-gradient(127.6% 7975000.38% at -14.6% -5099.56%, #FFF388 0%, #FFF388 20.83%, #FFDE52 35.94%, #FFBF00 50%, #FF7700 63.54%, #FF3D00 98.44%)',
    border: '0px solid currentColor',
  },
  thumb: {
    background: '#1C1C1C',
    width: '24px',
    height: '24px',
    border: '1px solid #FFFFFF',
  },
  slider: {
    [theme.breakpoints.down(425)]: {
      height: '2px',
    },
  },
  tooltip: {
    fontSize: '1rem',
    lineHeight: '18px',
    backgroundColor: 'transparent',
    top: '0px',
  },
  skeleton: {
    transform: 'scale(1)',
  },
}));

export const RangePriceSlider: FC<RangePriceSliderType> = memo(
  ({ currency, priceRange }) => {
    const { classes } = useStyles();
    const location = useLocation();

    const [values, setValues] = useState<number[]>(priceRange);
    const [searchParams, setSearchParams] = useSearchParams();

    const { getEmojiFlagByCurrency } = useUtils();

    const symbolCurrency = getEmojiFlagByCurrency(currency);

    const tooltipText = (value: number) => `${symbolCurrency} ${value}`;

    const setPriceParams = (from: number, to: number) => {
      setSearchParams((params) => {
        params.set(FilterParams.Price, `${from}-${to}`);
        return params;
      });
    };
    const debouncedPriceParams = useDebouncedFunction(setPriceParams, 300);

    const handleChange = async (event: Event, newValue: number[], activeThumb: number) => {
      if (!Array.isArray(newValue)) {
        return;
      }

      const [min, max] = newValue;
      const minDistance = 50;

      if (max - min < minDistance) {
        if (activeThumb === 0) {
          const clamped = Math.min(min, max - minDistance);
          setValues([clamped, clamped + minDistance]);
        } else {
          const clamped = Math.max(max, minDistance);
          setValues([clamped - minDistance, clamped]);
        }
      } else {
        setValues([min, max]);
      }

      debouncedPriceParams(min, max);
    };

    useEffect(() => {
      if (searchParams.has(FilterParams.Price)) {
        const paramRange = searchParams.get(FilterParams.Price).split('-');
        setValues([+paramRange.at(0), +paramRange.at(1)]);
      } else {
        setValues(priceRange);
      }
    }, [location.search, priceRange]);

    if (!priceRange.at(0)) {
      return <Skeleton className={classes.skeleton} animation={'wave'} width={'100%'} height={'100%'} />;
    }

    return (
      <Slider
        className={classes.slider}
        classes={{
          rail: classes.rail,
          track: classes.track,
          thumb: classes.thumb,
          valueLabel: classes.tooltip,
        }}
        value={values}
        min={priceRange.at(0)}
        max={priceRange.at(1)}
        onChange={handleChange}
        valueLabelDisplay={'on'}
        valueLabelFormat={tooltipText}
        getAriaValueText={tooltipText}
        disableSwap
      />
    );
  },
  (prevProps, nextProps) => isEqual(prevProps, nextProps)
);

type RangePriceSliderType = {
  priceRange: number[];
  currency: string;
};
