import { AuthStoreState, IAuthStoreState } from './types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = new AuthStoreState();
const authReducer = createSlice({
  name: 'authReducer',
  initialState: initialState,
  reducers: {
    authorize: (state, action) => {
      return { ...action.payload } as IAuthStoreState;
    },
    deAuthorize: () => {
      return initialState;
    },
  },
});

export default authReducer;
