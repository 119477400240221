import React, { useEffect } from 'react';
import { createTheme, Dialog, Skeleton, ThemeProvider } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import styled from 'styled-components';
import { Ticket } from './ticket';
import { useDispatch, useSelector } from '../../configureStore';
import { Page, PageHeader } from '../../components/UI';
import { Poster } from '../../components/event';
import { useGetBookEventQuery } from '../../core/api/eventsSlice';
import { useRoutesHelper } from '../../helpers/routesHelper';
import { PagesRoutes } from '../../store/routes';
import { setResultInfo } from '../../store/resultInformarion/actions';
import { AlertType, KeyErrors } from '../../store/enums';
import { distributionInit } from '../../store/distribution/actions';
import { clearBook } from '../../store/book/actions';

const dialogTheme = createTheme({
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: ({ theme }) => ({
          borderRadius: '15px 16px',
          background: '#1C1C1C',
          width: '410px',
          padding: '13px 20px 16px 20px',
          filter: 'drop-shadow(0px 50px 80px rgba(0, 0, 0, 0.7))',
          [theme.breakpoints.down('sm')]: {
            width: '322px',
            padding: '13px 15px 16px 15px',
          },
        }),
      },
    },
  },
});

export const Referral = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isRedirection = useSelector((state) => state.app.isRedirection);
  const isFinalized = useSelector((state) => state.app.isFinalized);

  const { eventSlug, distributorSlug } = useParams<ReferralPageParamsType>();

  const { data: event, isFetching } = useGetBookEventQuery({ eventSlug, distributorSlug });

  const { getResultStatusRoute } = useRoutesHelper();

  useEffect(() => {
    if (isRedirection || !isFinalized) {
      return;
    }
    dispatch(clearBook());
  }, []);

  useEffect(() => {
    if (distributorSlug) {
      dispatch(distributionInit(distributorSlug));
    }
    if (!eventSlug) {
      navigate(PagesRoutes.Main);
      return;
    }

    if (!isFetching && !event) {
      dispatch(
        setResultInfo({
          type: AlertType.Error,
          key: KeyErrors.EventNotFound,
          title: 'Alert.SomethingWentWrong',
          message: 'Alert.CheckLinkNoEventFound',
        })
      );
      navigate(getResultStatusRoute(AlertType.Error, KeyErrors.EventNotFound), { replace: true });
      return;
    }
  }, [distributorSlug, event, eventSlug, isFetching]);

  useEffect(() => {
    const element = document.getElementById('root');
    element.style.filter = 'blur(2px)';

    return () => {
      element.removeAttribute('style');
    };
  }, []);

  return (
    <Page>
      <Header diagonal={true} stretch={false}>
        <Poster src={event?.posterUrl} alt={event?.title} title={event?.title} />
      </Header>
      <ThemeProvider theme={dialogTheme}>
        <Dialog open scroll={'body'}>
          {isFetching && <Skeleton animation="wave" variant="rectangular" height="80vh" />}
          {!isFetching && <Ticket event={event} />}
        </Dialog>
      </ThemeProvider>
    </Page>
  );
};

const Header = styled(PageHeader)`
  justify-content: flex-end;
  max-height: 60vh;
`;

type ReferralPageParamsType = {
  eventSlug: string;
  distributorSlug: string;
};
