import React, { FC, memo, useEffect, useState } from 'react';
import { Grid, Skeleton, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { EventCarouselV1 } from './eventCarouselV1';
import { IEventShortInformation } from '../../store/event/types';
import { useRoutesHelper } from '../../helpers/routesHelper';
import { EventCategoryPage } from '../../containers/pages/events/eventCategoryPageV1';

const useStyles = makeStyles()(() => ({
  gridPopularEvents: {
    marginBottom: 50,
  },
  headingText: {
    textTransform: 'uppercase',
    fontSize: 14,
    lineHeight: '14px',
    cursor: 'default',
  },
  gridPopular: {
    marginTop: 50,
  },
}));

export const EventsV1: FC<EventPropsType> = memo(({ city, load, title, reloadDependencyValue }) => {
  const { classes } = useStyles();
  const { getEventRouteV1 } = useRoutesHelper();

  const [events, setEvents] = useState<IEventShortInformation[]>([]);
  const [isLoading, setLoading] = useState(false);

  const eventsCount = 6;

  useEffect(() => {
    setLoading(true);
    load().then((res) => {
      setEvents(res);
      setLoading(false);
    });
  }, [reloadDependencyValue]);

  const getEventsByLocation = () => {
    const filteredEvents = events.filter((event) => event?.city === city);
    const sortedEvents = [
      ...events.filter((event) => event.city === city),
      ...events.filter((event) => event.city !== city),
    ];
    return filteredEvents.length >= eventsCount ? filteredEvents : sortedEvents;
  };
  const eventsLocation = city && getEventsByLocation();

  return (
    <Grid item lg={12} xs={12} sm={12} md={12} xl={12} className={classes.gridPopularEvents}>
      <div>
        {title && isLoading && (
          <Typography variant="h6" className={classes.headingText}>
            <Skeleton variant="rectangular" width={140} height={25} />
          </Typography>
        )}

        {!isLoading && (
          <Typography variant="h6" className={classes.headingText}>
            {city && eventsLocation?.length >= eventsCount ? `${title}, ${city}` : title}
          </Typography>
        )}
      </div>
      <EventCarouselV1
        events={city ? eventsLocation : events}
        isLoading={isLoading}
        getItemLink={(itemKey) => getEventRouteV1(itemKey)}
      />
    </Grid>
  );
});

type EventPropsType = {
  load: () => Promise<IEventShortInformation[]> | Promise<EventCategoryPage[]>;
  reloadDependencyValue?: any;
  title?: string;
  city?: string;
};
