import React, { FC, memo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { IEventStoreState, ISeatStoreState } from '../../../../store/event/types';
import { IVenueSectorStoreState } from '../../../../store/ticket/types';
import { TicketCountSelectorV1 } from '../../../../components/book';
import { BookTotalPrices } from '../pricesWrapperV1';
import { usePriceCalculate } from '../../../../helpers/customHooks';

type TicketsCountSelectorPropsType = {
  event: IEventStoreState;
  sector: IVenueSectorStoreState;
  quotaId: string;
  seats: ISeatStoreState[];
  handleTicketsCount: (count: number) => void;
};

export const TicketsCountSelectorV1: FC<TicketsCountSelectorPropsType> = memo(
  ({ sector, quotaId, event, seats, handleTicketsCount }) => {
    const { t } = useTranslation();

    const { sectorTitle, currency, total, quota, tickets, ticketPrice } = usePriceCalculate(
      sector,
      event,
      seats,
      quotaId
    );
    if (!quota) {
      return null;
    }
    const maxPurchase = quota.left < event.maxPurchase ? quota.left : event.maxPurchase;

    return (
      <TicketsPriceWrapper>
        {sector.noSeats && (
          <>
            <Title>{t('Book.TicketCount')}</Title>
            <TicketSelectorsWrapper>
              <TicketCountSelectorV1
                max={maxPurchase - seats.filter((s) => s.quotaId !== +quotaId).length}
                sector={sector}
                tickets={tickets.length}
                handleTicketsCount={(count) => handleTicketsCount(count)}
              />
            </TicketSelectorsWrapper>
          </>
        )}
        {!sector.noSeats && <Title>{t('Book.TicketsPrice')}</Title>}
        <BookTotalPrices
          border={sector.noSeats}
          ticketPrice={ticketPrice}
          currency={currency}
          totalPrice={total}
          sectorTitle={sectorTitle}
        />
      </TicketsPriceWrapper>
    );
  }
);

const TicketsPriceWrapper = styled.div`
  padding-top: 30px;
`;

const Title = styled.h1`
  font-size: 14px;
  margin: 0 0 15px 0;
`;

const TicketSelectorsWrapper = styled.section`
  margin-bottom: 30px;
`;
