import styled from 'styled-components';

interface IImageProps {
  overlay?: boolean;
}

export const Image = styled.img<IImageProps>`
  object-fit: cover;
  object-position: top;
  width: 100%;
  height: 100%;
  filter: ${(props) => (props.overlay ? 'brightness(50%)' : 'none')};
  transform: translateZ(0);
`;
