import { createMigrate, persistReducer, persistStore } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/es/storage';
import { combineReducers, configureStore, isRejectedWithValue, Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import { PersistedState } from 'redux-persist/es/types';
import { TypedUseSelectorHook, useDispatch as useReduxDispatch, useSelector as useSelectorRedux } from 'react-redux';
import menuReducer from './store/menu/reducers';
import regionReducer from './store/region/reducers';
import appSettingsReducer from './store/appSettings/reducers';
import authReducer from './store/auth/reducers';
import bookReducerV1 from './store/bookV1/reducers';
import checkoutReducerV1 from './store/checkoutV1/reducers';
import distributionReducerV1 from './store/distributionV1/reducers';
import eventsReducer from './store/event/reducers';
import headerReducer from './store/header/reducer';
import locationReducer from './store/location/reducers';
import resultInfoReducer from './store/resultInformarion/reducers';
import shareReducer from './store/share/reducers';
import { userEventsReducerV1, userReducerV1, userTicketsReducerV1 } from './store/userV1/reducers';
import appReducer from './store/app/reducers';
import { baseSlice, baseSliceV2 } from './core/api/apiSlice';
import { setResultInfo } from './store/resultInformarion/actions';
import { AlertType, KeyErrors } from './store/enums';
import { hideSplash, setError } from './store/app/actions';
import { distributionSlice } from './store/distribution/reducers';
import { bookReducer } from './store/book/reducers';
import { checkoutReducer } from './store/checkout/reducers';
import { store } from './index';
import { userEventsReducer, userReducer, userTicketsReducer } from './store/user/reducers';

const migrations: any = {
  0: (state: PersistedState & GlobalStateType) => ({ ...state }),
  1: (state: PersistedState & GlobalStateType) => ({
    ...state,
    app: {
      ...state.app,
      isRedirection: false,
    },
  }),
  2: (state: PersistedState & GlobalStateType) => ({
    ...state,
    user: {
      ...state.user,
      balances: [],
    },
  }),
};

const persistConfig: any = {
  key: 'fanaticka',
  storage,
  stateReconciler: autoMergeLevel2,
  version: 3,
  migrate: createMigrate(migrations, { debug: true }),
  whitelist: [
    'app',
    'auth',
    'bookV1',
    'book',
    'checkout',
    'checkoutV1',
    'distributionV1',
    'distribution',
    'userV1',
    'user',
    'region',
    'userTickets',
  ],
};

const rootReducer = combineReducers({
  app: appReducer.reducer,
  menu: menuReducer.reducer,
  region: regionReducer.reducer,
  appSettings: appSettingsReducer.reducer,
  auth: authReducer.reducer,
  bookV1: bookReducerV1.reducer,
  book: bookReducer.reducer,
  checkoutV1: checkoutReducerV1.reducer,
  checkout: checkoutReducer.reducer,
  distributionV1: distributionReducerV1.reducer,
  distribution: distributionSlice.reducer,
  events: eventsReducer.reducer,
  header: headerReducer.reducer,
  location: locationReducer.reducer,
  resultInfo: resultInfoReducer.reducer,
  share: shareReducer.reducer,
  user: userReducer.reducer,
  userTickets: userTicketsReducer.reducer,
  userEvents: userEventsReducer.reducer,
  userV1: userReducerV1.reducer,
  userTicketsV1: userTicketsReducerV1.reducer,
  userEventsV1: userEventsReducerV1.reducer,
  [baseSlice.reducerPath]: baseSlice.reducer,
  [baseSliceV2.reducerPath]: baseSliceV2.reducer,
});

const persistedReducer = persistReducer<any, any>(persistConfig, rootReducer);

export const rtkQueryServerError: Middleware =
  ({ dispatch }: MiddlewareAPI<AppDispatch>) =>
  (next) =>
  (action) => {
    if (isRejectedWithValue(action) && action.payload.status === 500) {
      dispatch(
        setResultInfo({
          type: AlertType.Error,
          key: KeyErrors.ServerError,
          title: 'Alert.ServerError',
          message: action.payload.data.Details ?? 'Alert.SomethingWentWrong',
        })
      );
      dispatch(hideSplash());
    }

    return next(action);
  };

export const rtkQueryBadRequest: Middleware =
  ({ dispatch }: MiddlewareAPI<AppDispatch>) =>
  (next) =>
  (action) => {
    if (isRejectedWithValue(action) && action.payload.status === 412) {
      const message = action.payload.data.Details;

      dispatch(setError({ message, type: 'error' }));
    }

    return next(action);
  };

export default () => {
  const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: true,
        serializableCheck: false,
        immutableCheck: false,
      }).concat(baseSlice.middleware, baseSliceV2.middleware, rtkQueryServerError, rtkQueryBadRequest),
    devTools: process.env.NODE_ENV !== 'production',
  });

  const persistor = persistStore(store);
  return { store, persistor };
};

export type GlobalStateType = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export const useSelector: TypedUseSelectorHook<GlobalStateType> = useSelectorRedux;
export const useDispatch = () => useReduxDispatch<AppDispatch>();
