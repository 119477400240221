import React, { FC, memo, useEffect, useRef, useState } from 'react';
import { Chip, ClickAwayListener, Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DropDownPaper } from '../../../components/book/dropDownPaper';
import { TicketGroupTEType } from '../book';

const useStyles = makeStyles()((theme) => ({
  container: {
    position: 'relative',
  },
  sortChip: {
    color: 'inherit',
    border: '1px solid rgba(107, 107, 107, 0.6)',
    paddingLeft: '13px',
    '.MuiChip-icon': {
      marginRight: 0,
      marginLeft: 0,
    },
    '.MuiChip-label': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    ':hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  sortTitle: {
    padding: '0 8px',
    fontSize: '0.75rem',
    paddingLeft: '3px',
    paddingRight: '20px',
    [theme.breakpoints.down(425)]: {
      fontSize: '0.625rem',
    },
  },
}));

export const TicketsSelector: FC<TicketsSelectorType> = memo(({ ticketGroup, handleTicketsCount, setTitle }) => {
  const { classes } = useStyles();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedQuantityTickets, setSelectedQuantityTickets] = useState<number>(null);
  const ref = useRef();

  const handleOpenClick = () => {
    setIsOpen((open) => !open);
  };

  const handleAwayClick = () => setIsOpen(false);

  useEffect(() => {
    handleTicketsCount(selectedQuantityTickets, ticketGroup);
    setTitle(
      selectedQuantityTickets
        ? `${selectedQuantityTickets} tickets by $${ticketGroup.retail_price} USD each`
        : `Choose the number of tickets`
    );
  }, [selectedQuantityTickets]);

  const handleQuantitySelect = (selectTitle: number) => {
    setSelectedQuantityTickets(selectTitle);
    setIsOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleAwayClick} ref={ref.current}>
      <Grid className={classes.container}>
        <Chip
          className={classes.sortChip}
          label={
            <Typography component={'span'} className={classes.sortTitle}>
              {selectedQuantityTickets ? `${selectedQuantityTickets} tickets` : `Quantity`}
            </Typography>
          }
          icon={isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          onClick={handleOpenClick}
        />
        {isOpen && (
          <DropDownPaper
            ref={ref}
            data={ticketGroup.splits}
            selectTitle={selectedQuantityTickets}
            onChangeClick={handleQuantitySelect}
          />
        )}
      </Grid>
    </ClickAwayListener>
  );
});

type TicketsSelectorType = {
  ticketGroup: TicketGroupTEType;
  handleTicketsCount?: (count: number, ticketGroup: TicketGroupTEType) => void;
  setTitle: (title: string) => void;
};
