import { createSlice } from '@reduxjs/toolkit';
import { ILocationStoreState } from './types';

const locationReducer = createSlice({
  name: 'locationReducer',
  initialState: null as ILocationStoreState,
  reducers: {
    initLocation: (state, action) => {
      return action.payload;
    },
  },
});

export default locationReducer;
