import React from 'react';
import { Route, Routes as Switch } from 'react-router-dom';
import styled from 'styled-components';

import { motion } from 'framer-motion';
import { Page } from '../../../components/UI';
import { Routes } from '../../../store/routes';
import RegistrationPage from './registrationPage';
import LoginPage from './loginPage';
import EmailConfirmPage from './emailConfirmPage';
import ProfilePage from './profilePage';
import EditProfilePage from './editProfilePage';
import UserEventsPage from './userEventsPage';
import UserTicketsPage from './userTicketsPage';
import PasswordRecoveryPage from './password/passwordRecoveryPage';
import ResetPasswordPage from './password/resetPasswordPage';
import { Pages } from '../../../store/enums';
import { AppTheme } from '../../../settings/appTheme';

const UserPageV1 = () => (
  <Page bg={AppTheme.img.backgrounds.bg2} fullsize>
    <RouteContainer>
      <Switch>
        <Route key="registration" path={Pages.Registration} element={<RegistrationPage />} />
        <Route key="login" path={Pages.Login} element={<LoginPage />} />
        <Route key="profile" path={Pages.Profile} element={<ProfilePage />} />
        <Route key="edit-profile" path={Pages.EditProfile} element={<EditProfilePage />} />
        <Route key="email-confirm" path={Routes.UserConfirmEmailV1} element={<EmailConfirmPage />} />
        <Route key="user-events" path={Pages.UserEvents} element={<UserEventsPage />} />
        <Route key="user-tickets" path={Pages.UserTickets} element={<UserTicketsPage />} />
        <Route key="password-recovery" path={Pages.PasswordRecovery} element={<PasswordRecoveryPage />} />
        <Route key="password-reset" path={Pages.ResetPassword} element={<ResetPasswordPage />} />
      </Switch>
    </RouteContainer>
  </Page>
);
const RouteContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`;

export default UserPageV1;
