import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import {
  Page,
  PageContent,
  PageHeader,
  PageTitle,
  PaymentDocumentsLink,
  UserAgreementLink,
} from '../../../components/UI';
import { PaymentLogos } from '../../../components/payment';

export const PaymentsInfoPage = () => {
  const { t } = useTranslation();

  return (
    <Page title={t('PaymentsInfo.PaymentMethods')}>
      <Header>
        <PageTitle>{t<string>('PaymentsInfo.PaymentMethods')}</PageTitle>
      </Header>
      <PageContent>
        <H2>{t<string>('PaymentsInfo.TicketPaymentMethod')}</H2>
        <P>{t('PaymentsInfo.AfterPressingButton')}</P>
        <P>{t('PaymentsInfo.RedirectToPaymentPage')}</P>
        <P>{t<string>('PaymentsInfo.ProofOfPayment')}</P>
        <P>
          <PaymentDocumentsLink primary />
        </P>
        <P>{t('PaymentsInfo.AcceptCreditCards')}</P>
        <LogosWrapper>
          <PaymentLogos />
        </LogosWrapper>
        <P>
          {t('PaymentsInfo.BankCardPayments')}. {t('PaymentsInfo.SystemPaymentPage')}{' '}
          {t('PaymentsInfo.SafetyRequirements')}
        </P>
        <P>{t<string>('PaymentsInfo.Refund')}</P>
        <P>
          {t('PaymentsInfo.ReturnConditions')} <UserAgreementLink primary />
        </P>
      </PageContent>
    </Page>
  );
};

const Header = styled(PageHeader as any)`
  min-height: unset;
  height: unset;
  text-align: left;
  padding: 20px 25px 0 25px;
  align-items: start;
`;

const H2 = styled.h2`
  font-size: 16px;
`;

const P: any = styled.p`
  margin: 0 0 20px 0;
  font-size: 14px;
`;

const LogosWrapper = styled.div`
  margin-bottom: 45px;
`;
