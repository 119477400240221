import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import { useDispatch } from '../../../store';
import { IEventStoreState, SeatStoreState } from '../../../store/event/types';
import { IVenueSectorStoreState } from '../../../store/ticket/types';
import { ISelectorItem, Page, PageContent, Selector } from '../../../components/UI';
import { PlaceSelector, SchemaSectorSelectorV1 } from '../../../components/book';
import { AnalyticService, Utils } from '../../../services';
import { CheckoutHelper, EventHelper } from '../../../helpers';
import { IBookStoreState } from '../../../store/bookV1/types';
import { IDistributionStoreState } from '../../../store/distributionV1/types';
import { Routes } from '../../../store/routes';
import { useRoutesHelper } from '../../../helpers/routesHelper';
import { FooterV1 } from './bookComponents/footerV1';
import { clearBook, initBook, setSector } from '../../../store/bookV1/actions';
import { setResultInfo } from '../../../store/resultInformarion/actions';
import { AlertType, KeyErrors } from '../../../store/enums';
import { TicketsCountSelectorV1 } from './bookComponents/ticketsCountSelectorV1';
import { distributionInitV1 } from '../../../store/distributionV1/actions';
import { ICheckoutStoreState } from '../../../store/checkoutV1/types';
import { CheckoutSkeleton } from '../../../components/skeletons/checkoutSkeleton';
import { useLazyGetEventByIdQuery } from '../../../core/api/eventsSlice';
import { useLazyGetTicketQuotaByTokenQuery } from '../../../core/api/shareSlice';
import { BookSkeleton } from '../../../components/skeletons/bookSkeleton';
import { useCheckoutV1, useInitiateV1 } from '../../../helpers/customHooks';
import { GlobalStateType } from '../../../configureStore';

const BookPageV1 = () => {
  const analyticSrv = new AnalyticService();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    getResultStatusRoute,
    getEventRouteV1,
    getCheckoutRoute,
    getBookEventRoute,
    getBookSectorRouteV1,
    getBookQuotaSectorV1,
  } = useRoutesHelper();

  const [seats, setSeats] = useState([]);
  const [showSeatsSelector, setShowSeatsSelector] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const event = useSelector<GlobalStateType, IEventStoreState>((state) => state.bookV1.event);
  const book = useSelector<GlobalStateType, IBookStoreState>((state) => state.bookV1);
  const isRedirection = useSelector<GlobalStateType, boolean>((state) => state.app.isRedirection);
  const isFinalized = useSelector<GlobalStateType, boolean>((state) => state.app.isFinalized);
  const distribution = useSelector<GlobalStateType, IDistributionStoreState>((state) => state.distributionV1);
  const checkout = useSelector<GlobalStateType, ICheckoutStoreState>((state) => state.checkoutV1);

  const { eventSlug, sectorSlug, distributorSlug, token, quotaId } = useParams<BookParamsType>();

  const [getEventById, { isFetching: isFetchingEventById }] = useLazyGetEventByIdQuery();
  const [getTicketQuotaByShareToken, { isFetching: isFetchingTicketByToken }] = useLazyGetTicketQuotaByTokenQuery();

  const caption = `${t('Book.RowNumbering')}`;

  const handlePlaceSelect = (quotaId: number, sectorSlug: string, rowSlug: string, seatSlug: string) => {
    setSeats((seats) => {
      if (seats.some((s) => s.sectorSlug === sectorSlug && s.rowSlug === rowSlug && s.seatSlug === seatSlug)) {
        return seats.filter((s) => s.sectorSlug !== sectorSlug || s.rowSlug !== rowSlug || s.seatSlug !== seatSlug);
      }
      const sector = event.sectors.find((x) => x.slug === sectorSlug);
      return book.event.maxPurchase > seats.length
        ? [...seats, new SeatStoreState(quotaId, sectorSlug, sector.title, rowSlug, seatSlug)]
        : seats;
    });
  };

  const { checkoutCancel } = useCheckoutV1();
  const { initiate } = useInitiateV1();

  const initiateHandleCheckout = async (book): Promise<void> => {
    setIsLoading(true);
    if (CheckoutHelper.getIsProcessed(checkout) && !CheckoutHelper.getIsCompleted(checkout)) {
      await checkoutCancel(checkout);
    }
    const event = await getEventById({ id: eventSlug, distributorSlug: null, token }).unwrap();
    dispatch(initBook({ event, sectorSlug, seats: book.seats }));

    await initiate(book, distribution);

    analyticSrv.trackEvent('AddToCart');
    navigate(getCheckoutRoute(book.event.slug));
    setIsLoading(false);
  };

  useEffect(() => {
    if (isRedirection || !isFinalized) {
      return;
    }

    if (distributorSlug) {
      dispatch(distributionInitV1(distributorSlug));
    }

    if (!eventSlug) {
      navigate(Routes.Main);
      return;
    }

    (async () => await getEventById({ id: eventSlug, distributorSlug: null, token }))().then((result) => {
      const { data: event } = result;

      if (!event || !event.slug) {
        dispatch(clearBook());
        dispatch(
          setResultInfo({
            type: AlertType.Error,
            key: KeyErrors.EventNotFound,
            title: 'Alert.SomethingWentWrong',
            message: 'Alert.CheckLinkNoEventFound',
          })
        );
        navigate(getResultStatusRoute(AlertType.Error, KeyErrors.EventNotFound), { replace: true });
        return;
      }

      if (token) {
        (async () => getTicketQuotaByShareToken(token))().then((result) => {
          const { data: q } = result;

          if (q.left === 0) {
            dispatch(
              setResultInfo({
                type: AlertType.Error,
                key: KeyErrors.QuotaNotFound,
                title: 'Alert.SomethingWentWrong',
                message: 'Alert.CheckLinkNoLocationFound',
              })
            );
            navigate(getResultStatusRoute(AlertType.Error, KeyErrors.QuotaNotFound), { replace: true });
            return;
          }

          if (event.notAvailableForPurchase) {
            navigate(getEventRouteV1(event.slug));
            return;
          }

          const sector = event.sectors.find((x) => x.slug === q.sectorSlug);
          if (!sector) {
            navigate(getBookEventRoute(event.slug));
            return;
          }

          if (!EventHelper.hasSectorTickets(sector)) {
            dispatch(
              setResultInfo({
                type: AlertType.Error,
                key: KeyErrors.NoTickets,
                title: 'Alert.SomethingWentWrong',
                message: 'Alert.NoTicketsForCurrentEvent',
                buttons: [
                  {
                    title: 'Result.GoToMainPage',
                    callback: () => navigate(getBookSectorRouteV1(event.slug, sector.slug), { replace: true }),
                  },
                ],
              })
            );
            navigate(getResultStatusRoute(AlertType.Error, KeyErrors.NoTickets), { replace: true });
            return;
          }

          let filteredSeats = seats.filter((s) => s.quotaId !== q.quotaId);
          for (let index = 0; index < q.left; index++) {
            filteredSeats = [...filteredSeats, new SeatStoreState(q.quotaId, q.sectorSlug, sector.title)];
          }

          setSeats(filteredSeats);
          dispatch(initBook({ event, sectorSlug: q.sectorSlug, seats: filteredSeats, token })).then((book) => {
            (async () => await initiateHandleCheckout(book.payload))();
          });
        });
        return;
      }

      if (event.notAvailableForPurchase) {
        navigate(getEventRouteV1(event.slug));
        return;
      }

      if (!sectorSlug) {
        dispatch(initBook({ event, sectorSlug, seats: book.seats }));
        return;
      }

      if (!event || !event.sectors) {
        dispatch(initBook({ event, sectorSlug, seats: book.seats }));
        return;
      }
      const sector = event.sectors.find((x) => x.slug === sectorSlug);
      if (!EventHelper.hasSectorTickets(sector)) {
        dispatch(
          setResultInfo({
            type: AlertType.Error,
            key: KeyErrors.NoTickets,
            title: 'Alert.SomethingWentWrong',
            message: 'Alert.NoTicketsForCurrentEvent',
            buttons: [
              {
                title: 'Result.GoToMainPage',
                callback: () => navigate(getBookSectorRouteV1(event.slug, sector.slug), { replace: true }),
              },
            ],
          })
        );
        navigate(getResultStatusRoute(AlertType.Error, KeyErrors.NoTickets), { replace: true });
        return;
      }

      dispatch(initBook({ event, sectorSlug, seats: book.seats }));
    });
  }, [isFinalized]);

  useEffect(() => {
    if (book.sectorSlug === sectorSlug || !sectorSlug) {
      return;
    }

    dispatch(setSector(sectorSlug));
  }, [sectorSlug]);

  useEffect(() => {
    analyticSrv.trackEvent('TicketsDetails');
  }, []);

  const sector = event && event.sectors && event.sectors.find((x) => x.slug === sectorSlug);
  const isInteractiveSchema = !!sector && !!sector.schema;

  const handleSchemaSectorSelect = useCallback(
    (sectorSlug: string) => {
      setShowSeatsSelector(false);
      navigate(getBookSectorRouteV1(eventSlug, sectorSlug));
      setShowSeatsSelector(true);
    },
    [eventSlug]
  );

  const getQuotaSelectorItems = (event: IEventStoreState, sector: IVenueSectorStoreState): ISelectorItem[] =>
    sector &&
    sector.ticketQuotas.map((q) => {
      const capacity = !sector.noSeats ? q.left : 0;
      return {
        to: getBookQuotaSectorV1(event.slug, sector.slug, q.id.toString()),
        title: q.title,
        subtitle: capacity > 0 ? `${capacity} ${Utils.getTicketsPostfixByCount(capacity)}` : null,
      } as ISelectorItem;
    });

  const handleTicketsCount = useCallback(
    (count: number) => {
      let filteredSeats = seats.filter((s) => s.quotaId !== quotaId);

      for (let index = 0; index < count; index++) {
        const sector = event.sectors.find((x) => x.slug === sectorSlug);
        filteredSeats = [...filteredSeats, new SeatStoreState(+quotaId, sectorSlug, sector.title)];
      }
      setSeats(filteredSeats);
    },
    [quotaId, sectorSlug]
  );

  if ((token && isRedirection && isFetchingTicketByToken) || isLoading) {
    return <CheckoutSkeleton />;
  }

  return (
    <>
      {isFetchingEventById && !token && <BookSkeleton />}
      {!token && !isFetchingEventById && event && (
        <Page
          isForm
          title={`${t('Book.TicketPurchase')} | ${event.title} - ${event.subtitle}`}
          description={EventHelper.getMetaDescription(event)}
          keywords={[event.title, event.venue.title, t('Book.BuyTicket'), t('Book.Concert')]}
          imageUrl={event.posterUrl}
        >
          <PageContent>
            <SchemaSectorSelectorV1
              venue={event.venue}
              sectors={event.sectors}
              selectedSector={book.sectorSlug}
              onChange={(sectorSlug: string) => handleSchemaSectorSelect(sectorSlug)}
            />
            {isInteractiveSchema && showSeatsSelector && event && sector && (
              <PlaceSelector
                caption={caption}
                venueSlug={event.venue.slug}
                sector={sector}
                selectedSeats={seats}
                maxSelectCount={event.maxPurchase}
                onChange={handlePlaceSelect}
              />
            )}
            {!isInteractiveSchema && <Selector items={getQuotaSelectorItems(event, sector)} />}
            {!isInteractiveSchema && (
              <TicketsCountSelectorV1
                handleTicketsCount={handleTicketsCount}
                event={event}
                seats={seats}
                sector={sector}
                quotaId={quotaId}
              />
            )}
          </PageContent>
          <FooterV1
            event={event}
            sectorSlug={sectorSlug}
            seats={seats}
            isLoading={isLoading}
            distribution={distribution}
            paymentHandleClick={initiateHandleCheckout}
          />
        </Page>
      )}
    </>
  );
};

export const CostTitle = styled.span`
  font-size: 12px;
  font-family: ${(props) => props.theme.fonts.lighterFont};
`;

export default BookPageV1;

type BookParamsType = {
  rowSlug: string;
  tickets: string;
  eventSlug: string;
  sectorSlug: string;
  distributorSlug: string;
  token: string;
  quotaId: string;
};
