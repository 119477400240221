import React, { FC, memo } from 'react';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { RedirectTitle } from '../checkout';
import { StripeSettings } from '../../../settings/appSettings';
import { CheckoutForm } from './checkoutForm';

const stripePromise = loadStripe(StripeSettings.STRIPE_KEY);

export const Content: FC<ContentPropsType> = memo(({ isNeedForm, redirectUrl, paymentToken }) => {
  const { t } = useTranslation();

  if (redirectUrl) {
    window.location.href = redirectUrl;
  }

  //:ToDo TEMP EXAMPLE
  const appearance = {
    variables: {
      fontFamily: ' "Gill Sans", sans-serif',
      fontLineHeight: '1.5',
      borderRadius: '10px',
      colorBackground: '#F6F8FA',
      colorPrimaryText: '#ffffff',
    },
    rules: {
      '.Block': {
        backgroundColor: 'var(--colorBackground)',
        boxShadow: 'none',
        padding: '12px',
      },
      '.Input': {
        padding: '12px',
      },
      '.Input:disabled, .Input--invalid:disabled': {
        color: 'lightgray',
      },
      '.Tab': {
        padding: '10px 12px 8px 12px',
        border: 'none',
      },
      '.Tab:hover': {
        border: 'none',
        boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)',
      },
      '.Tab--selected, .Tab--selected:focus, .Tab--selected:hover': {
        border: 'none',
        backgroundColor: '#fff',
        boxShadow:
          '0 0 0 1.5px var(--colorPrimaryText), 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)',
      },
      '.Label': {
        fontWeight: '500',
      },
    },
  };

  const options: StripeElementsOptions = {
    locale: 'en-GB',
    clientSecret: paymentToken,
    loader: 'always',
    appearance: {
      ...{ theme: 'night', variables: { borderRadius: '24px' } },
    },
  };

  return (
    <>
      {!redirectUrl && isNeedForm && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      )}
      {redirectUrl && (
        <RedirectWrapper>
          <RedirectTitle>{t('Checkout.PaymentRedirectionMessage')}</RedirectTitle>
        </RedirectWrapper>
      )}
    </>
  );
});

const RedirectWrapper = styled.div`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

type ContentPropsType = {
  paymentToken: string;
  redirectUrl: string;
  isNeedForm: boolean;
};
