import { ResultInfoType } from './types';
import { createSlice } from '@reduxjs/toolkit';

export const resultInfoStoreState: ResultInfoType = {
  type: null,
  key: null,
  message: null,
  title: null,
  buttons: [],
};

const resultInfoReducer = createSlice({
  name: 'resultInfoReducer',
  initialState: resultInfoStoreState,
  reducers: {
    resultInfo: (state, action) => {
      return { ...action.payload };
    },
    clearInfo: (state) => {
      state.type = null;
      state.key = null;
      state.message = null;
      state.title = null;
      state.buttons = [];
    },
  },
});

export default resultInfoReducer;
