import styled from 'styled-components';


export const PageFooter: any = styled('footer' as any)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 35px 25px 35px;

  > button,
  > a {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
`;

export default PageFooter;
