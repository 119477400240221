import styled from 'styled-components';

export const PageTitle = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-family: ${(props) => props.theme.fonts.lighterFont};
  color: ${(props) => props.theme.colors.accent};
  text-transform: uppercase;
  text-align: left;
`;

export default PageTitle;
