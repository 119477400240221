import { IEventStoreState, ISeatStoreState } from '../event/types';

export interface IBookStoreState {
  event: IEventStoreState;
  sectorSlug?: string;
  seats: ISeatStoreState[];
  token?: string;
}

export class BookStoreState implements IBookStoreState {
  public event: IEventStoreState;
  public sectorSlug?: string;
  public seats: ISeatStoreState[];
  public token?: string;

  constructor() {
    this.event = null;
    this.sectorSlug = '';
    this.seats = [];
    this.token = '';
  }
}
