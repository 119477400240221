import { baseSlice } from './apiSlice';
import { AuthStoreState, IAuthStoreState, TokenRequestStoreState } from '../../store/auth/types';
import { AppAuthorize, AppDeAuthorize } from '../../store/app/actions';
import { authorize, deAuthorize } from '../../store/auth/actions';
import { auth } from '../constants/constants';
import { AuthSettings } from '../../settings/appSettings';
import { LoginFormType } from '../../components/forms';
import { clear, clearEvents, clearTickets } from '../../store/user/actions';
import { PingType } from '../models/auth';

type ModelType = {
  access_token: string;
  client_id: string;
};
const authApi = baseSlice.injectEndpoints({
  endpoints: (build) => ({
    token: build.mutation<IAuthStoreState, LoginFormType>({
      query: (modelLogin: LoginFormType) => {
        const { password, email } = modelLogin;

        const model = new TokenRequestStoreState();
        model.username = email;
        model.password = password;
        model.client_id = AuthSettings.CLIENT_ID;
        model.client_secret = AuthSettings.CLIENT_SECRET;
        model.grant_type = 'password';

        return {
          url: auth.token,
          method: 'POST',
          body: JSON.stringify(model),
        };
      },
      transformResponse: (response: { data: IAuthStoreState }) => new AuthStoreState(response),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        dispatch(AppAuthorize());
        dispatch(authorize(data));
      },
    }),
    googleToken: build.mutation<IAuthStoreState, ModelType>({
      query: (body: ModelType) => ({
        url: auth.googleToken,
        method: 'POST',
        body: JSON.stringify(body),
      }),
      transformResponse: (response) => new AuthStoreState(response),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        dispatch(AppAuthorize());
        dispatch(authorize(data));
      },
    }),
    facebookToken: build.mutation<IAuthStoreState, ModelType>({
      query: (body: ModelType) => ({
        url: auth.facebookToken,
        method: 'POST',
        body: JSON.stringify(body),
      }),
      transformResponse: (response) => new AuthStoreState(response),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        dispatch(AppAuthorize());
        dispatch(authorize(data));
      },
    }),
    ping: build.query<PingType, void>({
      query: () => auth.ping,
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;

        if (!data?.user) {
          dispatch(clear());
          dispatch(clearEvents());
          dispatch(clearTickets());
          dispatch(AppDeAuthorize());
          dispatch(deAuthorize());
        }
      },
    }),
  }),
});

export const { useTokenMutation, useGoogleTokenMutation, useFacebookTokenMutation, useLazyPingQuery } = authApi;
