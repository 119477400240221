import React, { FC } from 'react';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';
import { ISelectorItem, SelectorItem } from './selectorItem';
import { Label } from '../label';

interface ISelectorProps {
  title?: string;
  items: ISelectorItem[];
}

export const Selector: FC<ISelectorProps> = ({ items, title }) => {
  const { t } = useTranslation();
  if (!items || !items.length) {
    return null;
  }
  return (
    <Container>
      {title && <Title>{t(title)}</Title>}
      {items.map((item, index) => (
        <SelectorItem key={index} {...item} />
      ))}
    </Container>
  );
};

const Container = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const Title = styled(Label as any)``;
