import * as React from 'react';
import styled from 'styled-components';
import { FC } from 'react';

export interface ICheckboxSelectorItem {
  title: string;
  value: string;
  note?: string;
  selected?: boolean;
  disabled?: boolean;

  onSelect?: (value: string) => void;
}

export const CheckboxSelectorItem: FC<ICheckboxSelectorItem> = ({
  note,
  disabled,
  onSelect,
  selected,
  value,
  title,
}) => {
  const handleItemClick = (e: any) => {
    if (!disabled && onSelect) {
      onSelect(value);
    }
  };
  return (
    <Container disabled={disabled}>
      <Wrapper onClick={(e) => handleItemClick(e)}>
        <TitleWrapper>
          <Checkbox type="checkbox" readOnly checked={selected} value={value} disabled={disabled} />
          <Title>{title}</Title>
        </TitleWrapper>
        <NoteWrapper>{note && <Title>{note}</Title>}</NoteWrapper>
      </Wrapper>
    </Container>
  );
};

const Checkbox = styled.input`
  margin-right: 10px;
`;

const Container: any = styled.li`
  padding: 0 25px;
  margin: 0 -25px;
  opacity: ${(props: any) => props.disabled && 0.3};

  &:hover {
    background-color: ${(props: any) => !props.disabled && props.theme.colors.accent};

    > a > div > span {
      color: black;
    }
  }
`;

const Wrapper = styled.div`
  padding: 10px 0;
  margin-bottom: -1px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.accent};
  cursor: pointer;
`;

const TitleWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const Title = styled.p`
  padding: 0;
  margin: 0;
  font-size: 14px;
`;

const NoteWrapper = styled.div`
  display: flex;
  align-items: center;
`;
