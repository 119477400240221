import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Button } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

const useStyles = makeStyles()((theme) => ({
  clearFilters: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    padding: 0,
    textTransform: 'none',
    color: '#A8A8A8',
    gap: '5px',
    transform: 'translateY(110%)',
    ':hover': {
      backgroundColor: 'transparent',
    },
    ':active': {
      boxShadow: 'none',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
    },
    '.MuiButton-endIcon': {
      marginLeft: 0,
      marginRight: 0,
      display: 'block',
    },
    [theme.breakpoints.down(425)]: {
      fontSize: '0.75rem',
    },
  },
}));

export const ClearAllButton = () => {
  const { classes } = useStyles();

  const [, setSearchParams] = useSearchParams();

  const handleClearClick = () => {
    setSearchParams('');
  };

  return (
    <Button
      disableRipple
      className={classes.clearFilters}
      endIcon={<img src={'content/img/icDelete.svg'} alt={'delete'} />}
      onClick={handleClearClick}
      sx={{ textWrap: 'nowrap' }}
    >
      Clear All
    </Button>
  );
};
