import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { AnalyticService } from '../../services';
import { useCheckout, useCheckoutHelper, useQuery } from '../../helpers/customHooks';
import { useRoutesHelper } from '../../helpers/routesHelper';
import { useDispatch, useSelector } from '../../configureStore';
import { setResultInfo } from '../../store/resultInformarion/actions';
import { AlertType, KeyErrors, KeySuccess, StripePaymentStatus } from '../../store/enums';
import { PagesRoutes } from '../../store/routes';
import { ResultInfoButtonConfigType } from '../../store/resultInformarion/types';
import { clearPayment, redo } from '../../store/checkout/actions';
import { clearBook } from '../../store/bookV1/actions';
import { CheckoutType } from '../../store/checkout/types';

export const StripeBookResultPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const analyticSrv = new AnalyticService();
  const query = useQuery();

  const paymentIntentId = query.get('payment_intent');
  const redirectStatus = query.get('redirect_status');

  const { checkoutComplete, checkoutCancel } = useCheckout();
  const { isProcessed, isCompleted } = useCheckoutHelper();

  const { getResultStatusRoute, getCheckoutRoute, getUserTicketsRoute } = useRoutesHelper();

  const checkout = useSelector((state) => state.checkout);
  const book = useSelector((state) => state.book);
  const isAuthorized = useSelector((state) => state.app.isAuthorized);

  const isComplete = checkout?.complete?.isCompleted;
  const isSuccess = checkout?.complete?.isSuccess;
  const isProcessing = checkout?.complete?.isProcessing;

  const handlePaymentFiled = () => {
    checkoutCancel(checkout).then(() => {
      dispatch(
        setResultInfo({
          type: AlertType.Error,
          key: KeyErrors.PaymentFailed,
          title: 'Result.PaymentErrorHasOccurred',
          message: 'Result.PleaseTryAgainLater',
          buttons: [
            {
              title: 'Result.GoToMainPage',
              callback: () => navigate(PagesRoutes.Main, { replace: true }),
            },
          ],
        })
      );
      navigate(getResultStatusRoute(AlertType.Error, KeyErrors.PaymentFailed), { replace: true });
    });
  };

  const handlePaymentCompletion = (checkout: CheckoutType) => {
    const completeResult = checkout.complete;
    let alertType: AlertType;
    let alertKey: KeySuccess | KeyErrors;
    let title = completeResult.userMessage;
    let message = completeResult.userMessageDetails;
    let callbacks: Array<ResultInfoButtonConfigType> = [];

    if (completeResult.isProcessing) {
      alertType = AlertType.Success;
      alertKey = KeySuccess.PaymentInProcess;
      title = 'Alert.WaitForTheServerToCompleteThePayment';
      callbacks = [
        {
          title: 'Result.GoToMainPage',
          callback: () => navigate(PagesRoutes.Main, { replace: true }),
        },
      ];
      navigate(getResultStatusRoute(alertType, alertKey), { replace: true });
    } else if (!completeResult.isCompleted) {
      alertType = AlertType.Error;
      alertKey = KeyErrors.PaymentFailed;
      callbacks = [
        {
          title: 'Result.TryAgain',
          callback: () => {
            dispatch(redo());
            navigate(getCheckoutRoute(book.event.slug), { replace: true });
          },
        },
        {
          title: 'Result.Cancel',
          callback: () => {
            checkoutCancel(checkout).then(() => {
              navigate(PagesRoutes.Main, { replace: true });
            });
          },
        },
      ];
      navigate(getResultStatusRoute(alertType, alertKey), { replace: true });
    } else {
      alertType = completeResult.isSuccess ? AlertType.Success : AlertType.Error;
      alertKey = completeResult.isSuccess ? KeySuccess.PaymentSucceeded : KeyErrors.PaymentFailed;
      message = isAuthorized && completeResult.isSuccess ? 'Result.TicketsAvailabilityMessage' : message;
      callbacks = [
        {
          title: 'Result.GoToMainPage',
          callback: () => navigate(PagesRoutes.Main, { replace: true }),
        },
      ];

      if (completeResult.isSuccess && isAuthorized) {
        callbacks.push({
          title: 'Result.MyTickets',
          callback: () => navigate(getUserTicketsRoute(), { replace: true }),
        });
      }

      if (completeResult.isSuccess) {
        analyticSrv.trackEvent('Purchase', {
          value: checkout.initiate.priceTotal,
          currency: book.event.currency,
        });
      }
      if (isProcessed(checkout) && !isCompleted(checkout)) {
        (async () => await checkoutCancel(checkout))();
      }
      navigate(getResultStatusRoute(alertType, alertKey), { replace: true });
    }

    dispatch(
      setResultInfo({
        type: alertType,
        key: alertKey,
        title,
        message,
        buttons: callbacks,
      })
    );
  };

  useEffect(() => {
    const cancel = redirectStatus === StripePaymentStatus.canceled;
    if (cancel) {
      checkoutCancel(checkout).then(() => {
        dispatch(
          setResultInfo({
            type: AlertType.Error,
            key: KeyErrors.PaymentFailed,
            title: 'Result.PaymentErrorHasOccurred',
            message: 'Result.SorryYourPurchaseHasBeenCancelled',
            buttons: [
              {
                title: 'Result.GoToMainPage',
                callback: () => {
                  navigate(PagesRoutes.Main, { replace: true });
                },
              },
            ],
          })
        );
        navigate(getResultStatusRoute(AlertType.Error, KeyErrors.PaymentFailed), { replace: true });
      });
    }
  }, [redirectStatus]);

  useEffect(() => {
    if (!checkout.complete) {
      return;
    }

    const succeeded = redirectStatus === StripePaymentStatus.succeeded;
    if (isCompleted(checkout) && succeeded) {
      handlePaymentCompletion(checkout);

      dispatch(clearPayment());
      dispatch(clearBook());
    } else {
      handlePaymentFiled();
    }
  }, [isComplete, isSuccess, isProcessing, redirectStatus]);

  useEffect(() => {
    (async () => await checkoutComplete(paymentIntentId))();
  }, [paymentIntentId]);

  return null;
};
