import { AlertColor } from '@mui/material/Alert/Alert';

export type AlertNotificationType = {
  type: AlertColor;
  message: string;
  messageDetails?: string;
};

export interface IAppStoreState {
  isInitializing: boolean;
  isFinalized: boolean;
  isAuthorized: boolean;
  isLoading: boolean;
  error: AlertNotificationType;
  isRedirection: boolean;
}

export class AppStoreState implements IAppStoreState {
  isInitializing: boolean;
  isFinalized: boolean;
  isAuthorized: boolean;
  isLoading: boolean;
  error: AlertNotificationType;
  isRedirection: boolean;

  constructor() {
    this.isInitializing = true;
    this.isFinalized = false;
    this.isAuthorized = false;
    this.isLoading = false;
    this.error = null;
    this.isRedirection = false;
  }
}

export interface IActionResponseStoreState {
  message: string;
  details: string;
}

export class ActionResponseStoreState implements IActionResponseStoreState {
  message: string;
  details: string;

  constructor(dto?: any) {
    this.message = dto && dto.Message ? dto.Message : '';
    this.details = dto && dto.MessageDetails ? dto.MessageDetails : '';
  }
}
