import { BookStoreState, IBookStoreState } from './types';
import { IEventStoreState, ISeatStoreState } from '../event/types';
import bookReducerV1 from './reducers';
import { createAsyncThunk } from '@reduxjs/toolkit';

type InitBookType = {
  event: IEventStoreState;
  sectorSlug?: string;
  seats?: ISeatStoreState[];
  token?: string;
};
export const initBook = createAsyncThunk<Promise<IBookStoreState>, InitBookType>(
  'bookV1/initBook',
  async ({ seats, token = '', sectorSlug, event }, { dispatch }) => {
    const book = new BookStoreState();
    book.event = event;
    book.sectorSlug = sectorSlug;
    book.seats = seats;
    book.token = token;
    dispatch(clearBook());
    dispatch(init(book));
    return Promise.resolve(book);
  }
);

export const { clearBook, setSector, init } = bookReducerV1.actions;
