import React, { FC, memo } from 'react';
import { enGB, ru } from 'date-fns/locale';
import {
  addDays,
  addMonths,
  differenceInCalendarMonths,
  format,
  isSameDay,
  lastDayOfMonth,
  startOfMonth,
} from 'date-fns';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { LanguageSetting } from '../../../settings/appSettings';

type RenderDaysType = {
  dates: Date[];
  selectedDate: Date;
  onDateChanged: (day: Date) => void;
};

const useStyles = makeStyles()(() => ({
  selectedStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
    width: '33px',
    height: '44px',
    borderRadius: '16px',
    backgroundColor: '#FFBF00',
    justifyContent: 'center',
  },
  dateDayItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
    margin: '0 0 0 0px',
    width: '33px',
    height: '39px',
    flexShrink: 1,
    justifyContent: 'center',
  },
  dayLabel: {
    fontSize: '12px',
    margin: '6px 0 0 0',
    color: 'gray',
  },
  dateLabel: {
    fontSize: '17px',
    lineHeight: '17px',
    position: 'relative',
    top: 5,
  },
  monthYearLabel: {
    alignSelf: 'flex-start',
    zIndex: 3,
    fontSize: '15px',
    fontWeight: 'bold',
    position: 'sticky',
    top: 0,
    left: 0,
    width: 'max-content',
    margin: '0 14px 12px 0',
    textTransform: 'capitalize',
  },
  monthContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  daysContainer: {
    display: 'flex',
    zIndex: 1,
  },

  dateListScrollable: {
    display: 'flex',
    margin: '2px 0 2px 0px',
    WebkitOverflowScrolling: 'touch',
    '&::-webkit-scrollbar': {
      WebkitAppearance: 'none',
      display: 'none',
    },
  },
}));

export const RenderDays: FC<RenderDaysType> = memo(({ dates, selectedDate, onDateChanged }) => {
  const { classes } = useStyles();
  const { i18n } = useTranslation();

  if (!dates) {
    return null;
  }

  const dayFormat = 'iiiiii';
  const dateFormat = 'd';
  const monthFormat = 'LLLL';

  const formatOption = i18n.language === LanguageSetting.Ru ? { locale: ru } : { locale: enGB };
  const startDate = new Date();
  const lastDate = addDays(startDate, dates.length);

  const months = [];
  let days = [];

  const isSelectDay = (month, day) => isSameDay(addDays(month, day), selectedDate);

  const onDateClick = (day) => {
    onDateChanged(day);
  };

  for (let month = 0; month <= differenceInCalendarMonths(lastDate, startDate); month++) {
    const start = month === 0 ? Number(format(startDate, dateFormat)) - 1 : 0;
    const startMonth = startOfMonth(addMonths(startDate, month));
    const end =
      month === differenceInCalendarMonths(lastDate, startDate)
        ? Number(format(lastDate, dateFormat))
        : Number(format(lastDayOfMonth(startMonth), dateFormat));
    for (let day = start; day < end; day++) {
      days.push(
        <div
          className={isSelectDay(startMonth, day) ? classes.selectedStyle : classes.dateDayItem}
          key={Number(addDays(startMonth, day))}
          onClick={() => onDateClick(addDays(startMonth, day))}
        >
          <div className={classes.dateLabel}>{format(addDays(startMonth, day), dateFormat)}</div>
          <div className={classes.dayLabel} style={{ color: isSelectDay(startMonth, day) ? 'white' : '' }}>
            {format(addDays(startMonth, day), dayFormat, formatOption)}
          </div>
        </div>
      );
    }
    months.push(
      <div className={classes.monthContainer} key={Number(startMonth)}>
        <span className={classes.monthYearLabel}>{format(startMonth, monthFormat, formatOption)}</span>
        <div className={classes.daysContainer}>{days}</div>
      </div>
    );
    days = [];
  }
  return (
    <div id={'container'} className={classes.dateListScrollable}>
      {months}
    </div>
  );
});
