import * as ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import { AnalyticsSettings, AppSettings } from '../settings/appSettings';

export class AnalyticService {
  trackEvent(data: string, value?: any) {
    if (!AppSettings.IS_DEV) {
      ReactGA.event({
        category: 'main',
        action: data,
        label: 'no',
      });

      const ReactPixel = require('react-facebook-pixel').default;
      ReactPixel.track(data, value);
    }
  }

  trackPageView(data: string) {
    if (!AppSettings.IS_DEV) {
      ReactGA.pageview(data);
    }
  }

  initialize() {
    if (!AppSettings.IS_DEV) {
      ReactGA.initialize(AnalyticsSettings.GOOGLE_TRACKING_ID);
      const ReactPixel = require('react-facebook-pixel').default;
      ReactPixel.init('528267061237134', {}, { debug: false, autoConfig: true });
      ReactPixel.pageView();
    }
    TagManager.initialize({ gtmId: 'GTM-PC5SSP6' });
  }
}
