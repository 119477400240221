import appSettingsReducer from './reducers';

// export const initWidgetApp = createAsyncThunk('appSettings/initWidgetApp', async (token: string, { dispatch }) => {
//   dispatch(init());
//   try {
//     dispatch(getSettings(token));
//     dispatch(hideSplash());
//   } catch (e) {
//   } finally {
//     await dispatch(finalize());
//   }
// });

export const { appSettingInit } = appSettingsReducer.actions;
