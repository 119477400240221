import React, { useEffect, useRef } from 'react';
import { useGesture } from '@use-gesture/react';
import { Button } from '@mui/material';
import { useSpring, animated, to } from '@react-spring/web';
import { useSelector } from 'react-redux';
import { IGlobalStoreState } from '../../store';
import { useStyles } from './PanAndZoomImageStyles';

const PanAndZoomImage = ({ children }) => {
  const { classes } = useStyles();
  const ref = useRef(null);

  const ZOOM_FACTOR = useSelector<IGlobalStoreState, number>((state) => state.appSettings.bookSchemeZoomFactor);
  const MAX_ZOOM = useSelector<IGlobalStoreState, number>((state) => state.appSettings.bookSchemeMaxZoom);
  const MIN_ZOOM = useSelector<IGlobalStoreState, number>((state) => state.appSettings.bookSchemeMinZoom);

  useEffect(() => {
    const handler = (e) => e.preventDefault();
    document.addEventListener('gesturestart', handler);
    document.addEventListener('gesturechange', handler);
    document.addEventListener('gestureend', handler);

    return () => {
      document.removeEventListener('gesturestart', handler);
      document.removeEventListener('gesturechange', handler);
      document.removeEventListener('gestureend', handler);
    };
  }, []);

  const [style, api] = useSpring(() => ({
    x: 0,
    y: 0,
    scale: 1,
    config: {
      mass: 0,
      tension: 0,
      friction: 0,
      clamp: true,
      duration: 100,
      damping: 0,
    },
  }));

  const transform = to([style.x, style.y, style.scale], (x, y, scale) => `translate(${x}px, ${y}px) scale(${scale})`);

  useGesture(
    {
      onDrag: ({ pinching, cancel, offset: [x, y], ...rest }) => {
        if (pinching) return cancel();
        api.start({ x, y });
      },
      onPinch: ({ origin: [ox, oy], first, offset: [d], memo }) => {
        if (first) {
          const { width, height, x, y } = ref.current.getBoundingClientRect();
          const tx = ox - (x + width / 2);
          const ty = oy - (y + height / 2);
          memo = [style.x.get(), style.y.get(), tx, ty];
        }

        api.start({ scale: d });
        return memo;
      },
    },
    {
      target: ref,
      drag: {
        bounds: { top: -200, right: 200, bottom: 200, left: -200 },
      },
      pinch: {
        scaleBounds: { min: MIN_ZOOM, max: MAX_ZOOM },
        rubberband: true,
        pointer: { touch: true },
      },
    },
  );

  const handleInZoom = () => {
    if (style.scale.get() >= MAX_ZOOM) {
      return;
    }

    api.set({ scale: style.scale.get() + ZOOM_FACTOR });
  };

  const handleOutZoom = () => {
    if (style.scale.get() <= MIN_ZOOM) {
      return;
    }

    api.set({ scale: style.scale.get() - ZOOM_FACTOR });
  };

  return (
    <div className={classes.wrapperContainer}>
      <div className={classes.buttonGroup}>
        <Button className={classes.buttonInZoom} onClick={handleInZoom} />
        <Button className={classes.buttonOutZoom} onClick={handleOutZoom} />
      </div>
      <animated.div className={classes.container} ref={ref} style={{ transform }}>
        {children}
      </animated.div>
    </div>
  );
};

export default PanAndZoomImage;
