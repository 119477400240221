import React, { FC, useCallback, useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import Autocomplete from '@mui/material/Autocomplete';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { PopupLocationV1 } from './popupLocationV1';
import { useRoutesHelper } from '../../helpers/routesHelper';
import { useLazySearchEventsV1Query } from '../../core/api/eventsSlice';

type StyleProps = {
  color: string;
};

const useStyles = makeStyles<StyleProps>({ name: 'SearchInput' })((theme, { color }) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    height: '54px',
    borderTopLeftRadius: 35,
    borderBottomLeftRadius: 35,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  locationPaper: {
    height: '54px',
    borderTopRightRadius: 35,
    borderBottomRightRadius: 35,
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  gridLocation: {
    minWidth: 72,
  },
  gridSearch: {
    paddingRight: 3,
  },
  autocomplete: {
    marginBottom: '25px',
    marginTop: '34px',
  },
  autocompleteGridContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  searchIcon: {
    color: '#FFBF00',
  },
  btnLocation: {
    padding: 10,
    height: 47,
    width: 47,
  },
  locationIcon: {
    color,
  },
  popper: {
    inset: '3px auto auto 0px !important',
  },
}));

export type searchType = {
  id: string;
  value: string;
};

export const SearchInputV1: FC<SearchInputType> = ({ city, setCity }) => {
  const { getEventRouteV1 } = useRoutesHelper();

  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState<string>('');
  const [options, setOptions] = useState<searchType[]>([]);
  const [openPopup, setOpenPopup] = useState(false);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const [searchEvents, { isLoading }] = useLazySearchEventsV1Query();

  const handleSearchIconClick = () => {
    setOpen(false);
  };

  const handleAutocompleteOnClick = (event, value) => {
    const eventShortInformation = value as searchType;
    navigate(getEventRouteV1(eventShortInformation.id));
  };

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  useEffect(() => {
    if (search === '' || !search) {
      return;
    }

    searchEvents(search)
      .unwrap()
      .then((options) => {
        setOptions(options);
      });
  }, [search]);

  const handleClickOpen = () => {
    setOpenPopup(true);
  };
  const handleClose = useCallback(() => {
    setOpenPopup(false);
  }, []);

  const propsLocationIcon = {
    color: city ? '#FFBF00' : 'rgba(0, 0, 0, 0.54)',
  };

  const { classes } = useStyles(propsLocationIcon);

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center" spacing={0}>
      <PopupLocationV1 setCity={setCity} open={openPopup} handleClose={handleClose} />
      <Grid item lg={8} xs={12} sm={8} md={8} xl={8}>
        <Autocomplete
          id="search"
          open={open}
          noOptionsText={t<string>('Dashboard.NothingFound')}
          loadingText={t<string>('Dashboard.Loading')}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) => option.value}
          onChange={handleAutocompleteOnClick}
          options={options}
          loading={isLoading}
          className={classes.autocomplete}
          classes={{
            popper: classes.popper,
          }}
          renderInput={(params) => (
            <div ref={params.InputProps.ref}>
              <Grid container className={classes.autocompleteGridContainer}>
                <Grid item xs={11} sm={11} md={11} lg={11} xl={11} className={classes.gridSearch}>
                  <Paper className={classes.root}>
                    <IconButton
                      className={classes.iconButton}
                      onClick={handleSearchIconClick}
                      disabled={false}
                      size="large"
                    >
                      <SearchIcon className={classes.searchIcon} />
                    </IconButton>
                    <InputBase
                      {...params}
                      value={search}
                      onChange={(event) => setSearch(event.target.value)}
                      className={classes.input}
                      placeholder={t<string>('Dashboard.TypeEventName')}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={1} sm={1} md={1} lg={1} xl={1} className={classes.gridLocation}>
                  <Paper className={classes.locationPaper}>
                    <IconButton onClick={handleClickOpen} className={classes.btnLocation} size="large">
                      <LocationOnOutlinedIcon className={classes.locationIcon} />
                    </IconButton>
                  </Paper>
                </Grid>
              </Grid>
            </div>
          )}
        />
      </Grid>
    </Grid>
  );
};

type SearchInputType = {
  city?: string;
  setCity?: (city: string) => void;
};
