import { createSlice } from '@reduxjs/toolkit';
import { RegionalInfoStoreState } from './types';
import dayjs from 'dayjs';
import Constants from '../constants';

const regionReducer = createSlice({
  name: 'regionReducer',
  initialState: new RegionalInfoStoreState(),
  reducers: {
    setRegion: (state, action) => {
      state = { ...action.payload };
      state.lastCheckedAt = dayjs().add(Constants.RegionalInfoExpiresInHours, 'hour');
      return state;
    },
  },
});

export default regionReducer;
