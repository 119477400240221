import * as React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ContactInfo } from '../settings/contactInfo';

export const TestBanner = () => {
  const { t } = useTranslation();
  return (
    <Banner>
      <a href={ContactInfo.WebsiteUrl} title={t('ClickToGoToBuyRealTickets')}>
        <Container>
          <p>{t<string>('ThisIsATestVersionOfTheSite')}</p>
          <p>{t<string>('ClickToGoToBuyRealTickets')}</p>
        </Container>
      </a>
    </Banner>
  );
};

const Banner = styled.aside`
  background-color: ${(props) => props.theme.colors.accent};

  a {
    text-decoration: none;
  }
`;

const Container = styled.div`
  padding: 15px 25px;

  p {
    text-align: center;
    font-size: 14px;
    color: ${(props) => props.theme.colors.text};
    margin: 0;
    text-transform: uppercase;
  }

  @media screen and (min-width: 576px) {
    p {
      font-size: 18px;
    }
  }
`;
