export interface IHeaderStoreState {
  showPlayer: boolean;
  showCitySelector: boolean;
}

export class HeaderStoreState implements IHeaderStoreState {
  showPlayer: boolean;
  showCitySelector: boolean;

  constructor() {
    this.showPlayer = false;
    this.showCitySelector = false;
  }
}
