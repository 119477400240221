import React, { FC, memo } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { IBookStoreState } from '../../../store/bookV1/types';
import { Button } from '../../../components/UI';
import Constants from '../../../store/constants';
import { useRoutesHelper } from '../../../helpers/routesHelper';

export const Ticket: FC<{ event: IBookStoreState }> = memo(({ event }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { getBookEventRoute } = useRoutesHelper();

  if (!event.event) {
    return null;
  }

  const date = `${dayjs(event.event.date).locale('en').format(Constants.DateFormat)}, ${dayjs(event.event.date).format(
    'LT'
  )}`;
  const address = `${event.event.venue.country} ${event.event.venue.city}, ${event.event.venue.address}`;

  let min = 0;
  let max = 0;
  let currency = '';

  for (let i = 0; i < event.event.sectors.length; i++) {
    const { ticketQuotas } = event.event.sectors[i];
    for (let j = 0; j < ticketQuotas.length; j++) {
      currency = ticketQuotas[j].currency;
      if (ticketQuotas[j].price > max) {
        max = ticketQuotas[j].price;
      } else {
        min = ticketQuotas[j].price;
      }
    }
  }

  const price = min ? `${min}-${max} ${currency}` : `${max} ${currency}`;

  const handleRouteClick = () => {
    navigate(getBookEventRoute(event.event.slug));
  };

  const WALLET_PATH = '../../../content/img/wallet.svg';
  const LOGO_PATH = '../../../content/img/logo.svg';
  const BARCODE_PATH = '../../../content/img/barcode.svg';

  const { notAvailableForPurchase } = event.event;
  const buttonTitle = notAvailableForPurchase ? t('Event.NoTicketsAvailable') : t('Ticket.ChooseSeat');

  return (
    <Container>
      <Header>
        <ImageLogo src={LOGO_PATH} alt={'logo'} />
        <Title>{t('Ticket.YourTicketIsReady')}</Title>
        <SubTitle>{t('Ticket.NowChooseSeat')}</SubTitle>
      </Header>
      <TicketContent>
        <ImageEvent src={event.event.posterUrl} alt={event.event.slug} />
        <DescriptionTicket>
          <DescriptionTitle>{event.event.artist.title}</DescriptionTitle>
          <DescriptionDate>{date}</DescriptionDate>
          <DescriptionAddress>{address}</DescriptionAddress>
          <PriceContainer>
            {event.event.sectors.length ? (
              <PriceContent>
                <ImageWallet src={WALLET_PATH} alt={'wallet'} />
                <Price>{price}</Price>
              </PriceContent>
            ) : null}
          </PriceContainer>
          <ContainerBorder>
            <Border />
          </ContainerBorder>
          <Barcode src={BARCODE_PATH} alt={'barcode'} />
        </DescriptionTicket>
      </TicketContent>
      <Footer>
        <Button disabled={notAvailableForPurchase} onClick={handleRouteClick}>
          {buttonTitle}
        </Button>
      </Footer>
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 8px;
`;

const Title = styled.div`
  text-transform: uppercase;
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 24px;
  color: white;
  padding-top: 25px;
  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 18px;
    padding-top: 15px;
  }
`;

const SubTitle = styled.div`
  color: ${(props) => props.theme.colors.accent};
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 20px;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  @media (max-width: 600px) {
    font-size: 16px;
    line-height: 16px;
  }
`;

const ImageLogo = styled.img`
  width: 210px;
  height: 42px;
  position: relative;
  left: 23px;
  @media (max-width: 600px) {
    width: 147px;
    height: 33px;
    left: ${Constants.LeftMarginLogo};
  }
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding-top: 9px;
  @media (max-width: 600px) {
    padding-top: 2px;
  }
`;

const TicketContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const ImageEvent = styled.img`
  width: 100%;
  height: 280px;
  object-fit: cover;
  border-radius: 16px 16px 0 0;
  @media (max-width: 600px) {
    height: 170px;
  }
`;

const DescriptionTicket = styled(TicketContent)`
  position: relative;
  bottom: 14px;
  background: ${(props) => props.theme.colors.text};
  height: 100%;
  border-radius: 16px;
`;

const DescriptionTitle = styled.div`
  font-family: ${(props) => props.theme.fonts.blackCapsFont};
  font-weight: 900;
  font-size: 31px;
  line-height: 26px;
  color: #1c1c1c;
  align-self: center;
  margin-top: 35px;
  margin-bottom: 25px;
  @media (max-width: 600px) {
    font-size: 24px;
    margin-top: 18px;
    margin-bottom: 10px;
  }
`;

const DescriptionDate = styled.div`
  font-family: ${(props) => props.theme.fonts.boldFont};
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #1c1c1c;
  align-self: center;
  padding-bottom: 8px;
  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const DescriptionAddress = styled.div`
  font-family: ${(props) => props.theme.fonts.boldCapsFont};
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  color: #ffbf00;
  text-align: center;
  padding: 0 20px;
  @media (max-width: 600px) {
    font-size: 12px;
    line-height: 16px;
  }
`;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  @media (max-width: 600px) {
    padding: 0;
  }
`;

const PriceContent = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
`;

const ImageWallet = styled.img`
  width: 21px;
  height: 20px;
  @media (max-width: 600px) {
    width: 17px;
    height: 14px;
  }
`;

const Price = styled.span`
  font-family: ${(props) => props.theme.fonts.boldFont};
  font-size: 19px;
  line-height: 20px;
  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 16px;
  }
`;

const ContainerBorder = styled.div`
  height: 36px;
  width: 100%;
  padding: 0 40px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  @media (max-width: 600px) {
    height: 30px;
  }

  &:before {
    content: '';
    position: absolute;
    left: -20px;
    width: 40px;
    height: 100%;
    border-radius: 50%;
    background: #1c1c1c;
    @media (max-width: 600px) {
      width: 35px;
    }
  }

  &:after {
    content: '';
    position: absolute;
    right: -20px;
    width: 40px;
    height: 100%;
    border-radius: 50%;
    background: #1c1c1c;
    @media (max-width: 600px) {
      width: 35px;
    }
  }
`;

const Border = styled.div`
  width: 100%;
  height: 1px;
  background-image: linear-gradient(87deg, black 50%, transparent 0%);
  background-size: 12px 1px;
  background-repeat: repeat-x;
  padding: 0 20px;
`;

const Barcode = styled.img`
  margin: 5px auto;
  display: flex;
  width: 100%;
  height: 62px;
  padding: 0 40px 5px 40px;
  @media (max-width: 600px) {
    padding: 0 40px 10px 40px;
    margin: 0 auto;
  }
`;
