import * as React from 'react';
import styled from 'styled-components';

import { ICheckboxSelectorItem, CheckboxSelectorItem } from './checkboxSelectorItem';
import { Label } from '../label';

interface ICheckboxSelectorProps {
  title?: string;
  subtitle?: string;
  items: ICheckboxSelectorItem[];

  onSelect?: (value: string) => void;
}

export const CheckboxSelector = (props: ICheckboxSelectorProps) => {
  if (!props.items || !props.items.length) {
    return null;
  }

  const handleSelect = (value: string) => {
    if (props.onSelect) {
      props.onSelect(value);
    }
  };

  return (
    <Container>
      {props.title && <Title>{props.title}</Title>}
      {props.subtitle && <Subtitle>{props.subtitle}</Subtitle>}
      {props.items.map((item, index) => (
        <CheckboxSelectorItem key={index} {...item} onSelect={(value) => handleSelect(value)} />
      ))}
    </Container>
  );
};

const Container = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const Title = styled(Label as any)``;

const Subtitle = styled(Title as any)`
  display: block;
`;
