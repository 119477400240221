import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ICheckoutStoreState } from '../../../../store/checkoutV1/types';
import { PageContent } from '../../../../components/UI';
import { ContentV1 } from './contentV1';
import { Header, Title } from '../checkoutPageV1';
import { IUserStoreState } from '../../../../store/userV1/types';
import { CheckoutHelper } from '../../../../helpers';

export const PaymentCheckoutFormV1: FC<PaymentFormPropsType> = memo(
  ({ checkout, user, isAuthorized, checkoutSteps, redirectUrl }) => {
    const { t } = useTranslation();

    const step = isAuthorized && !!user && !!user.email ? 3 : 2;
    const needForm = CheckoutHelper.getIsNeedPaymentForm(checkout);
    const { paymentToken } = checkout.process;

    return (
      <PageContent roundCorners>
        <Header>
          {t('Checkout.Step')} {step} {t('Checkout.Of')} {checkoutSteps}
        </Header>
        <Title>{t<string>('Checkout.PayByCard')}</Title>
        <ContentV1 isNeedForm={needForm} redirectUrl={redirectUrl} paymentToken={paymentToken} />
      </PageContent>
    );
  }
);

type PaymentFormPropsType = {
  checkout: ICheckoutStoreState;
  isAuthorized: boolean;
  user: IUserStoreState;
  checkoutSteps: number;
  redirectUrl: string;
};
