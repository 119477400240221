import React, { FC, memo } from 'react';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';
import { IUserBalanceStoreState, IUserStatsStoreState } from '../../store/userV1/types';
import { UserStatItem } from './userStatItem';
import { Utils } from '../../services';

interface IUserStatsProps {
  stats: IUserStatsStoreState;
}

export const UserStats: FC<IUserStatsProps> = memo(({ stats }) => {
  const { t } = useTranslation();

  if (!stats) {
    return null;
  }

  const renderBalances = (balances: Array<IUserBalanceStoreState>) => {
    if (!balances || !balances.length) {
      return <UserStatItem title={t('Profile.Points')} count={0} icon="star" />;
    }

    return (
      <>
        {balances.map((item, index) => (
          <UserStatItem
            key={index}
            title={`${t('Profile.Points')} ${Utils.getEmojiFlagByCurrency(item.currency)}`}
            count={item.amount}
            icon="star"
          />
        ))}
      </>
    );
  };

  return (
    <>
      <StatsList>
        <UserStatItem title={t('Profile.Events')} count={stats.events} icon="meet" />
        <UserStatItem title={t('Profile.SoldTicket')} count={stats.ticketsSold} icon="soldTicket" />
      </StatsList>
      <StatsList>{renderBalances(stats?.balances)}</StatsList>
    </>
  );
});

export const StatsList = styled('ul')`
  display: flex;
  list-style: none;
  padding: 0;
  align-items: unset;
  margin: 25px 0;
`;
