import dayjs from 'dayjs';
import { DistributionStoreState } from './types';
import Constants from '../constants';
import distributionReducerV1 from './reducers';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { GlobalStateType } from '../../configureStore';

export const distributionInitV1 = createAsyncThunk<void, string, { state: GlobalStateType }>(
  'distributionV1/distributionInit',
  (distributorSlug: string, { dispatch, getState }) => {
    const state = getState();
    const isDistributorSlugExisted = !!state.distributionV1 && state.distributionV1.distributorSlug === distributorSlug;
    if (!distributorSlug || isDistributorSlugExisted) {
      return;
    }
    const expirationDate = dayjs().add(Constants.DistributionExpirationMins, 'm');
    const distribution = new DistributionStoreState(distributorSlug, expirationDate);
    dispatch(init(distribution));
  }
);

export const { init, distributionClearV1 } = distributionReducerV1.actions;
