import React, { FC, memo, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { isEmpty } from 'lodash';
import { useRoutesHelper } from '../../../helpers/routesHelper';
import { useSelector } from '../../../configureStore';
import { useStartDistributeMutation } from '../../../core/api/eventsSlice';
import { PagesRoutes } from '../../../store/routes';
import { Button, PageFooter } from '../../../components/UI';
import { EventDetailsType } from '../../../core/models/eventDetails';
import { UserEventType } from '../../../core/models/events';

type EventDetailsFooterType = {
  event: EventDetailsType;
  isInitializing: boolean;
};

export const EventDetailsFooter: FC<EventDetailsFooterType> = memo(({ event, isInitializing }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { getBookEventRoute, getSellEventRoute } = useRoutesHelper();

  const isAuthorized = useSelector((state) => state.app.isAuthorized);
  const userEvents = useSelector((state) => state.userEvents);

  const [startDistributeRequest, { isLoading: isLoadingStartDistribute }] = useStartDistributeMutation();

  if (!event) {
    return null;
  }
  const isDomainsEmpty = isEmpty(event.domains);

  const isEventDetailsDistributed = (event: EventDetailsType, userEvents?: UserEventType[]): boolean =>
    !!event && userEvents.some((e) => e.title === event.title);

  const isDistributed = isEventDetailsDistributed(event, userEvents);
  const sellTicketButtonTitle = isDistributed ? t('Event.SellTicket') : t('Event.BecomeDistributor');
  const showDistributionButton = isAuthorized && !isInitializing && event.isDistributionAvailable;

  const getBookTicketsButtonTitle = () => {
    if (!event.isTicketsAvailable) {
      return t('Event.NoTicketsAvailable');
    }
    if (!event.isRegionAvailable) {
      return t('Event.NotAvailableForYourRegion');
    }
    if (isDomainsEmpty) {
      return t('Event.TemporarilyUnavailable');
    }

    return t('Event.BuyTicket');
  };

  const bookTicketsButtonTitle = getBookTicketsButtonTitle();

  const handleOnBookClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (e) {
      e.preventDefault();
    }
    navigate(getBookEventRoute(event.slug));
  };

  const handleOnDistributeClick = (e: MouseEvent<HTMLButtonElement>, event: EventDetailsType) => {
    if (e) {
      e.preventDefault();
    }

    if (isEventDetailsDistributed(event, userEvents)) {
      navigate(getSellEventRoute(event.slug));
      return;
    }

    (async () => await startDistributeRequest(event.id))().then(() => {
      navigate(PagesRoutes.UserEvents);
    });
  };

  return (
    <PageFooter>
      <Button disabled={!event.isTicketsAvailable || isDomainsEmpty} onClick={(e) => handleOnBookClick(e)}>
        {bookTicketsButtonTitle}
      </Button>
      {showDistributionButton && (
        <Button
          disabled={isDomainsEmpty || isLoadingStartDistribute || !event.isTicketsAvailable}
          isLoading={isLoadingStartDistribute}
          onClick={(e) => handleOnDistributeClick(e, event)}
        >
          {sellTicketButtonTitle}
        </Button>
      )}
    </PageFooter>
  );
});
