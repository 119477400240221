import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { RedirectTitle } from '../widgetCheckoutPage';

export const CheckoutContent: FC<CheckoutContentPropsType> = memo(({ isNeedForm, redirectUrl, paymentToken }) => {
  const { t } = useTranslation();

  if (redirectUrl) {
    window.location.href = redirectUrl;
  }
  return (
    <>
      {redirectUrl && (
        <RedirectWrapper>
          <RedirectTitle>{t('CheckoutWidget.PaymentRedirectionMessage')}</RedirectTitle>
        </RedirectWrapper>
      )}
    </>
  );
});

const RedirectWrapper = styled.div`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

type CheckoutContentPropsType = {
  isNeedForm: boolean;
  redirectUrl: string;
  paymentToken: string;
};
