import React from 'react';
import styled from 'styled-components';
import { Skeleton } from '@mui/material';
import { Page, PageContent } from '../UI';
import PageFooter from '../UI/page/pageFooter';

export const BookSkeleton = () => {
  const skeletonOverrideStyle = {
    borderRadius: '12px',
  };
  return (
    <Page>
      <Content>
        <SchemeContainer>
          <Skeleton animation="wave" variant="rectangular" width="100%" height="100%" style={skeletonOverrideStyle} />
        </SchemeContainer>
        <TicketGroupsContainer>
          <Skeleton animation="wave" variant="rectangular" width="100%" height="100%" style={skeletonOverrideStyle} />
        </TicketGroupsContainer>
      </Content>
    </Page>
  );
};

const Content = styled(PageContent)`
  gap: 16px;
  padding: 20px 16px 25px 16px;

  @media (max-width: 425px) {
    padding: 13px 8px;
  }
`;
export const SchemeContainer = styled.section`
  display: flex;
  flex-direction: column;
  background: linear-gradient(180.62deg, rgba(28, 28, 28, 0) -91.79%, rgba(18, 17, 17, 0.41) 157.66%);
  box-shadow: 4px -4px 16px rgb(0 0 0 / 63%), -4px 4px 16px rgba(0, 0, 0, 0.63);
  border-radius: 12px;
  height: 502px;

  @media (max-width: 425px) {
    height: 322px;
  }
`;

const TicketGroupsContainer = styled(SchemeContainer)`
  height: 530px;
`;
