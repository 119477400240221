import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { useDispatch } from '../../../store';
import { AlertType, BookStatus, KeyErrors, KeySuccess } from '../../../store/enums';
// import { checkoutClear, checkoutComplete } from '../../../store/checkout/actions';
import { ICheckoutStoreState } from '../../../store/checkoutV1/types';
import { CheckoutHelper } from '../../../helpers';
import { setResultInfo } from '../../../store/resultInformarion/actions';
import { useQuery } from '../../../helpers/customHooks';
import { useRoutesHelper } from '../../../helpers/routesHelper';
import { ResultInfoButtonConfigType } from '../../../store/resultInformarion/types';
import { GlobalStateType } from '../../../configureStore';

const WidgetBookResultPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const checkout = useSelector<GlobalStateType, ICheckoutStoreState>((state) => state.checkoutV1);
  const isAuthorized = useSelector<GlobalStateType, boolean>((state) => state.app.isAuthorized);
  const userMessageDetails = useSelector<GlobalStateType, string>(
    (state) => state.checkoutV1?.complete?.result?.userMessageDetails
  );
  const userMessage = useSelector<GlobalStateType, string>((state) => state.checkoutV1?.complete?.result?.userMessage);
  const isCompleted = useSelector<GlobalStateType, boolean>((state) => state.checkoutV1?.complete?.result?.isCompleted);
  const isProcessing = useSelector<GlobalStateType, boolean>(
    (state) => state.checkoutV1?.complete?.result?.isProcessing
  );
  const isSuccess = useSelector<GlobalStateType, boolean>((state) => state.checkoutV1?.complete?.result?.isSuccess);

  const [isHandlingResult, setIsHandlingResult] = useState(false);
  const { type, token } = useParams<ParamsType>();

  const query = useQuery();
  const refNumber = query.get('refnumber');

  const { getWidgetRouteFromToken, getWidgetResultStatusRoute } = useRoutesHelper();

  const handlePaymentCompletion = (checkout: ICheckoutStoreState) => {
    if (!checkout || !checkout.complete || !checkout.complete.result) {
      return;
    }
    const completeResult = checkout.complete.result;
    let alertType: AlertType;
    let alertKey: KeyErrors | KeySuccess;
    const title = completeResult.userMessage;
    let message = completeResult.userMessageDetails;
    let callbacks: Array<ResultInfoButtonConfigType> = [];

    if (completeResult.isProcessing) {
      alertType = AlertType.Warning;
      alertKey = KeySuccess.PaymentInProcess;
    } else if (!completeResult.isCompleted) {
      alertType = AlertType.Warning;
      alertKey = KeyErrors.PaymentFailed;
    } else {
      alertType = completeResult.isSuccess ? AlertType.Success : AlertType.Error;
      alertKey = completeResult.isSuccess ? KeySuccess.PaymentSucceeded : KeyErrors.PaymentFailed;
      message = isAuthorized && completeResult.isSuccess ? 'WidgetBook.TicketsAvailabilityMessage' : message;
      // dispatch(checkoutClear());
    }

    callbacks = [
      {
        title: 'WidgetBook.ToTheTicketSelectionPage',
        callback: () => navigate(getWidgetRouteFromToken(token)),
      },
    ];
    dispatch(
      setResultInfo({
        type: alertType,
        key: alertKey,
        title,
        message,
        buttons: callbacks,
      })
    );

    navigate(getWidgetResultStatusRoute(alertType, alertKey));
  };

  const handlePaymentResult = useCallback(
    (checkout) => {
      if (refNumber) {
        const cancel = type === BookStatus.Timeout;
        const final = (checkout: ICheckoutStoreState) => {
          setIsHandlingResult(false);
          handlePaymentCompletion(checkout);
        };
        if (CheckoutHelper.getIsCompleted(checkout)) {
          final(checkout);
        } else if (!isHandlingResult) {
          setIsHandlingResult(true);
          // (async () => dispatch(checkoutComplete({ refNumber, cancel })))()
          //   .then(() => {
          //     final(checkout);
          //   })
          //   .catch(() => {
          //     final(checkout);
          //   });
        }
      }
    },
    [isHandlingResult, type, refNumber]
  );

  useEffect(() => {
    if (type === BookStatus.Timeout) {
      // dispatch(checkoutClear());
      handlePaymentResult(checkout);
      dispatch(
        setResultInfo({
          type: AlertType.Error,
          key: KeyErrors.PaymentTimeout,
          title: 'Alert.SomethingWentWrong',
          message: 'Alert.PaymentTimedOut',
          buttons: [
            {
              title: 'WidgetBook.ToTheTicketSelectionPage',
              callback: () => navigate(getWidgetRouteFromToken(token)),
            },
          ],
        })
      );
      const BEPAID_CLASS_NAME = 'bepaid-app';
      const bePaid = document.getElementsByClassName(BEPAID_CLASS_NAME)[0];
      bePaid.remove();

      navigate(getWidgetResultStatusRoute(AlertType.Error, KeyErrors.PaymentTimeout), { replace: true });
    }
  }, []);

  useEffect(() => {
    handlePaymentResult(checkout);
  }, [userMessageDetails, isCompleted, isProcessing, isSuccess, userMessage]);

  return null;
};

export default WidgetBookResultPage;

type ParamsType = {
  type: string;
  token: string;
};
