import React, { FC, memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { unwrapResult } from '@reduxjs/toolkit';
import styled from 'styled-components';
import { CheckoutHelper, EventHelper } from '../../../../helpers';
import { Button, PageFooter } from '../../../../components/UI';
import { IEventStoreState, ISeatStoreState } from '../../../../store/event/types';
import { TicketsInfo } from './ticketInfoV1';
import { initBook } from '../../../../store/bookV1/actions';
import { FormError, FormInfo } from '../../../../components/forms';
import { IDistributionStoreState } from '../../../../store/distributionV1/types';
import { useDispatch } from '../../../../store';
import { useHandleSeatSelectedV1, useTicketTotalCalculateV1 } from '../../../../helpers/customHooks';
import { setError } from '../../../../store/app/actions';

export const FooterV1: FC<FooterPropsType> = memo(
  ({ event, sectorSlug, seats, distribution, isLoading, paymentHandleClick }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { total, currency } = useTicketTotalCalculateV1(event, seats);
    const selectedSeats = useHandleSeatSelectedV1(seats, event);

    const { maxPurchase } = event;
    const seatsCount = seats.length;
    const isMaxPurchase = seatsCount >= maxPurchase;

    useEffect(() => {
      if (seatsCount >= maxPurchase) {
        dispatch(setError({ type: 'warning', message: 'Book.YouHaveReachedYourEventTicketPurchaseLimit' }));
        return;
      }
    }, [isMaxPurchase]);

    const sector = event && event?.sectors && event?.sectors.find((x) => x?.slug === sectorSlug);

    if (!event) {
      return null;
    }

    const hasTickets = EventHelper.getHasTickets(event);
    const isSectorSelected = !!sectorSlug;
    const isSeatsSelected = isSectorSelected && seats && seats.length > 0;
    const isCheckoutEnabled = !isLoading && hasTickets && isSeatsSelected && !event.notAvailableForPurchase;
    let buttonTitle = t('Book.GoToPayment');
    if (!hasTickets) {
      buttonTitle = t('Book.TicketsAreNotAvailableForSale');
    } else if (!isSectorSelected) {
      buttonTitle = t('Book.ChooseSector');
    } else if (!isSeatsSelected && !sector?.noSeats) {
      buttonTitle = t('Book.ChooseSeats');
    } else if (event.notAvailableForPurchase) {
      buttonTitle = event.notAvailableForPurchaseReason;
    }

    const handleCheckoutClick = (e) => {
      if (e) {
        e.preventDefault();
      }

      dispatch(initBook({ event, sectorSlug, seats }))
        .then(unwrapResult)
        .then((book) => {
          paymentHandleClick(book);
        });
    };

    const renderDistributionInfo = () => {
      const distributionInfo = CheckoutHelper.getDistributionInfo(null, distribution);
      if (!distributionInfo) {
        return null;
      }

      return <Warning>{t(distributionInfo, { distributor: distribution.distributorSlug })}</Warning>;
    };

    return (
      <Container>
        {!!sector && !sector?.noSeats && (
          <TicketsInfo selectedSeats={selectedSeats} total={total} currency={currency} />
        )}
        {renderDistributionInfo()}
        <Button disabled={!isCheckoutEnabled} onClick={(e) => handleCheckoutClick(e)}>
          {buttonTitle}
        </Button>
      </Container>
    );
  }
);

const ErrorWrapper: any = styled(FormError)`
  width: 100%;
  text-align: center;
`;

const Warning = styled(FormInfo)`
  padding: 10px 0 0 0;
`;

const Container = styled(PageFooter)`
  align-items: initial;
  padding: 0 25px 20px 25px;

  > button,
  > input {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
`;

type FooterPropsType = {
  event: IEventStoreState;
  sectorSlug: string;
  seats: ISeatStoreState[];
  isLoading: boolean;
  distribution: IDistributionStoreState;
  paymentHandleClick: (book) => void;
};

export type SelectedSeatsType = {
  title: string;
  price: string;
  seatId?: string;
};
