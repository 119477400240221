import { Grid, Skeleton } from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  skeletonContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    [theme.breakpoints.down(425)]: {
      gap: '3px',
    },
  },
  skeleton: {
    height: '42px',
    width: '100%',
    [theme.breakpoints.down(425)]: {
      height: '37px',
    },
  },
}));

export const TicketGroupsSkeleton = () => {
  const { classes } = useStyles();
  return (
    <Grid className={classes.skeletonContainer}>
      {Array.from(new Array(11)).map((value, index) => (
        <Skeleton key={index} animation="wave" variant="rectangular" className={classes.skeleton} />
      ))}
    </Grid>
  );
};
