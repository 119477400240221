import React, { FC, memo, useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import styled from 'styled-components';
import dayjs from 'dayjs';
import * as Yup from 'yup';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useTranslation } from 'react-i18next';
import { AnimatePresence, motion } from 'framer-motion';
import { IUserStoreState } from '../../store/userV1/types';
import { FieldError, FieldInfo, Fieldset, FieldsetLabel, FieldWrapper } from './commonForm';
import { Button, InputWrapper, TextInput } from '../UI';
import CopyButton from '../UI/copyButton';
import { PopUp } from '../../store/animations';
import Constants from '../../store/constants';
import RegExpRules from '../../store/regExpRules';
import { IEditProfileForm } from '../../containers/pages/userV1/editProfilePage';
import UserHelper from '../../helpers/userHelper';
import { useRoutesHelper } from '../../helpers/routesHelper';
import { UserType } from '../../store/user/types';
import { useUtils } from '../../services/useUtils';

type EditProfileFormNewType = {
  user: UserType;
  submitTitle: string;
  onSendCodeClick: (phone: string) => void;
  onSubmit: (formModel: IEditProfileForm) => void;
  isLoading: boolean;
};

export const EditProfileForm: FC<EditProfileFormNewType> = memo(
  ({ user, submitTitle, onSendCodeClick, onSubmit, isLoading }) => {
    const [resendCodeSecLeft, setResendCodeSecLeft] = useState(0);
    const { t } = useTranslation();
    const { createReferralLink } = useRoutesHelper();
    const { formatPhoneNumber } = useUtils();

    let sendCodeTimer: any;
    const isPhoneConfirmed = user.phoneNumberConfirmed;

    const referralLink = createReferralLink(user.referralSlug);
    const isSendCodeBtnDisabled = resendCodeSecLeft > 0;
    const isCodeSent = !!user.phoneCodeSentAt;
    const phoneNumber = user.phoneNumber || '+';

    const codeSentMessage =
      isSendCodeBtnDisabled &&
      `${t(user.phoneCodeSentMessage, {
        phone: user.phoneNumber,
        seconds: resendCodeSecLeft,
      })}`;

    const phoneCodeValidate = (code: string, phone: string) =>
      (!user.phoneNumberConfirmed || phone !== user.phoneNumber) && !code && t('EditProfileForm.CodeIsRequired');

    const formatPhone = (e: any) => {
      if (e) {
        e.preventDefault();
      }
      const { value } = e.target;
      if (!value || value[0] !== '+') {
        e.target.value = `+${value}`;
      }
    };

    const setSendCodeAvailabilityTimer = (user: IUserStoreState) => {
      const isCodeSendingAvailable = UserHelper.isPhoneCodeSendingAvailable(user);
      if (!isCodeSendingAvailable) {
        sendCodeTimer = setInterval(() => {
          const secLeft = user.phoneCodeSentAt.clone().add(Constants.ResendCodeInSecs, 's').diff(dayjs(), 's');
          setResendCodeSecLeft(secLeft);
          if (secLeft === 0) {
            clearInterval(sendCodeTimer);
          }
        }, 1000);
      }
    };

    useEffect(() => {
      const isCodeSendChanged = !dayjs(user.phoneCodeSentAt).isSame(dayjs(user.phoneCodeSentAt));
      if (isCodeSendChanged) {
        setSendCodeAvailabilityTimer(user);
      }
    }, [resendCodeSecLeft]);

    useEffect(() => {
      setSendCodeAvailabilityTimer(user);
    }, [user.phoneCodeSentAt]);

    useEffect(() => () => clearInterval(sendCodeTimer), []);

    const validationSchema = Yup.object().shape({
      email: Yup.string().required(t('EditProfileForm.EmailRequired')).email(t('EditProfileForm.EmailIncorrect')),
      fullName: Yup.string().required(t('EditProfileForm.FirstNameAndLastNameRequired')).nullable(),
      phoneNumber: Yup.string()
        .required(t('EditProfileForm.PhoneRequired'))
        .test(
          'singlePlus',
          t('EditProfileForm.PhoneCanContainOnlyOnePlusCharacterAtTheBeginning'),
          (phoneNumber) => (phoneNumber.match(/\+/g) || [])?.length === 1
        )
        .matches(RegExpRules.Phone, t('EditProfileForm.ThePhoneCanOnlyContainNumbers'))
        .test(
          'format',
          t('EditProfileForm.InvalidRegionFormat'),
          (phoneNumber) => !!phoneNumber && isValidPhoneNumber(phoneNumber)
        ),
      referralSlug: Yup.string()
        .required(t('EditProfileForm.ReferralNameRequired'))
        .matches(RegExpRules.ReferralSlug, t('EditProfileForm.TheNameCanOnlyContainLettersNumbers'))
        .nullable(),
      oldPassword: Yup.mixed().test(
        'match',
        t('EditProfileForm.PasswordRequired'),
        (oldPassword, context) => !context.parent.newPassword || !!oldPassword
      ),
      newPassword: Yup.mixed()
        .test(
          'match',
          t('EditProfileForm.PasswordRequired'),
          (newPassword, context) => !context.parent.oldPassword || !!newPassword
        )
        .test(
          'minLength',
          `${t('EditProfileForm.PasswordMustBeAtLeast', { min: Constants.MinPasswordLength })}`,
          (newPassword) => !newPassword || newPassword.length >= Constants.MinPasswordLength
        ),
      newPasswordConfirm: Yup.mixed().test(
        'match',
        t('EditProfileForm.PasswordsNotMatch'),
        (passwordConfirm, context) => passwordConfirm === context.parent.newPassword
      ),
    });

    return (
      <Formik
        initialValues={{
          ...user,
          code: '',
          phoneNumber,
          oldPassword: '',
          newPassword: '',
          newPasswordConfirm: '',
        }}
        onSubmit={(values: IEditProfileForm) => {
          if (onSubmit) {
            onSubmit({
              ...values,
              phoneNumber: formatPhoneNumber(values.phoneNumber),
            });
          }
        }}
        validationSchema={validationSchema}
      >
        {({ isValid, handleChange, handleBlur, values }) => (
          <StyledForm autoComplete="on">
            <StyledFieldset>
              <Field name="image">
                {({ field, form }) => (
                  <FieldWrapper>
                    <ProfileImage>
                      {form.values.imageUrl && (
                        <Avatar src={form.values.imageUrl} alt={t('EditProfileForm.YourAvatar')} />
                      )}
                      {!form.values.imageUrl && (
                        <ProfileImageTitle>{t<string>('EditProfileForm.Avatar')}</ProfileImageTitle>
                      )}
                    </ProfileImage>
                  </FieldWrapper>
                )}
              </Field>
              <Field name="fullName">
                {({ field, form }) => (
                  <FieldWrapper noted={!!form.touched.fullName && !!form.errors && !!form.errors.fullName}>
                    <TextInput
                      placeholder={t('EditProfileForm.EnterYourFirstAndLastName')}
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.fullName}
                      error={!!form.touched.fullName && !!form.errors && !!form.errors.fullName}
                      {...field}
                    />
                    <AnimatePresence>
                      {!!form.touched.fullName && !!form.errors && !!form.errors.fullName && (
                        <FieldError variants={PopUp} initial={'enter'} animate={'exit'} key="error">
                          {form.errors.fullName}
                        </FieldError>
                      )}
                    </AnimatePresence>
                  </FieldWrapper>
                )}
              </Field>
              <Field name="email">
                {({ field, form }) => (
                  <FieldWrapper noted={!!form.touched.email && !!form.errors && !!form.errors.email}>
                    <InputWrapper valid={form.values.emailConfirmed}>
                      <TextInput
                        placeholder="E-mail"
                        type="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        disabled={form.values.emailConfirmed}
                        error={!!form.touched.email && !!form.errors && !!form.errors.email}
                        {...field}
                      />
                    </InputWrapper>
                    <AnimatePresence>
                      {!!form.touched.email && !!form.errors && !!form.errors.email && (
                        <FieldError variants={PopUp} initial={'enter'} animate={'exit'} key="error">
                          {form.errors.email}
                        </FieldError>
                      )}
                    </AnimatePresence>
                  </FieldWrapper>
                )}
              </Field>
            </StyledFieldset>
            <StyledFieldset>
              <Field name="phoneNumber">
                {({ field, form }) => (
                  <>
                    <FieldWrapper
                      inline={!isPhoneConfirmed || (phoneNumber && !isPhoneConfirmed !== field.value)}
                      noted={
                        (!!form.touched.phoneNumber && !!form.errors && !!form.errors.phoneNumber) ||
                        !!isSendCodeBtnDisabled
                      }
                    >
                      <InputWrapper valid={user.phoneNumberConfirmed && phoneNumber === field.value}>
                        <TextInput
                          {...field}
                          type="tel"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.phoneNumber}
                          title={t('EditProfileForm.InternationalPhoneNumber')}
                          placeholder={t('EditProfileForm.YourPhone')}
                          onKeyUp={(e) => formatPhone(e)}
                          error={!!form.touched.phoneNumber && !!form.errors && !!form.errors.phoneNumber}
                        />
                      </InputWrapper>

                      {(!isPhoneConfirmed || phoneNumber !== field.value) && (
                        <AnimatePresence>
                          <StyledAContainer
                            variants={PopUp}
                            initial={'enter'}
                            animate={'exit'}
                            key="send-code-btn-wrapper"
                          >
                            <Button
                              key="send-code-btn"
                              type="button"
                              width="auto"
                              disabled={
                                !form.values.phoneNumber ||
                                (!!form.errors && !!form.errors.phoneNumber) ||
                                isSendCodeBtnDisabled ||
                                isLoading
                              }
                              onClick={() => {
                                if (onSendCodeClick) {
                                  onSendCodeClick(formatPhoneNumber(field.value));
                                }
                              }}
                            >
                              {t<string>('EditProfileForm.SendCode')}
                            </Button>
                          </StyledAContainer>
                        </AnimatePresence>
                      )}
                    </FieldWrapper>
                    {!!form.touched.phoneNumber && !!form.errors && !!form.errors.phoneNumber && (
                      <AnimatePresence>
                        <FieldError variants={PopUp} initial={'enter'} animate={'exit'} key="error">
                          {t(form.errors.phoneNumber)}
                        </FieldError>
                      </AnimatePresence>
                    )}
                    {isSendCodeBtnDisabled && (
                      <AnimatePresence>
                        <FieldInfo key="field-info" variants={PopUp} initial={'enter'} animate={'exit'}>
                          {codeSentMessage}
                        </FieldInfo>
                      </AnimatePresence>
                    )}
                  </>
                )}
              </Field>
              <Field name="code" validate={(code: string) => phoneCodeValidate(code, values.phoneNumber)}>
                {({ field, form }) => (
                  <AnimatePresence>
                    {isCodeSent && (!isPhoneConfirmed || user.phoneNumber !== values.phoneNumber) && (
                      <FieldWrapper
                        key="field-wrapper"
                        noted={!!form.touched.code && !!form.errors && !!form.errors.code}
                      >
                        <TextInput
                          {...field}
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.code}
                          placeholder={t('EditProfileForm.ConfirmationCode')}
                          error={!!form.touched.code && !!form.errors && !!form.errors.code}
                        />
                        <AnimatePresence>
                          {!!form.touched.code && !!form.errors && !!form.errors.code && (
                            <FieldError variants={PopUp} initial={'enter'} animate={'exit'} key="error">
                              {t(form.errors.code)}
                            </FieldError>
                          )}
                        </AnimatePresence>
                      </FieldWrapper>
                    )}
                  </AnimatePresence>
                )}
              </Field>
            </StyledFieldset>
            <StyledFieldset>
              <Field name="referralSlug">
                {({ field, form }) => (
                  <>
                    <CopyButtonFieldWrapper
                      inline
                      noted={!!form.touched.referralSlug && !!form.errors && !!form.errors.referralSlug}
                    >
                      <TextInput
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.referralSlug}
                        placeholder={t('EditProfileForm.EnterYourReferralName')}
                        error={!!form.touched.referralSlug && !!form.errors && !!form.errors.referralSlug}
                        {...field}
                      />
                      <CopyButton value={referralLink} title={t('EditProfileForm.CopyReferralLink')} />
                    </CopyButtonFieldWrapper>
                    <AnimatePresence>
                      {!!form.touched.referralSlug && !!form.errors && !!form.errors.referralSlug && (
                        <FieldError variants={PopUp} initial={'enter'} animate={'exit'} key="error">
                          {t('EditProfileForm.ReferralNameRequired')}
                        </FieldError>
                      )}
                    </AnimatePresence>
                  </>
                )}
              </Field>
            </StyledFieldset>
            {isPhoneConfirmed && (
              <StyledFieldset>
                <StyledFieldsetLabel>{t('EditProfileForm.ChangePassword')}</StyledFieldsetLabel>
                <Field name="oldPassword">
                  {({ field, form }) => (
                    <FieldWrapper noted={!!form.touched.oldPassword && !!form.errors && !!form.errors.oldPassword}>
                      <TextInput
                        type="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.oldPassword}
                        {...field}
                        placeholder={t('EditProfileForm.CurrentPassword')}
                        error={!!form.touched.oldPassword && !!form.errors && !!form.errors.oldPassword}
                        autoComplete="off"
                      />
                      <AnimatePresence>
                        {!!form.touched.oldPassword && !!form.errors && !!form.errors.oldPassword && (
                          <FieldError variants={PopUp} initial={'enter'} animate={'exit'} key="error">
                            {t(form.errors.oldPassword)}
                          </FieldError>
                        )}
                      </AnimatePresence>
                    </FieldWrapper>
                  )}
                </Field>
                <Field name="newPassword">
                  {({ field, form }) => (
                    <FieldWrapper noted={!!form.touched.newPassword && !!form.errors && !!form.errors.newPassword}>
                      <TextInput
                        type="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.newPassword}
                        {...field}
                        placeholder={t('EditProfileForm.NewPassword')}
                        error={!!form.touched.newPassword && !!form.errors && !!form.errors.newPassword}
                        autoComplete="off"
                      />
                      <AnimatePresence>
                        {!!form.touched.newPassword && !!form.errors && !!form.errors.newPassword && (
                          <FieldError variants={PopUp} initial={'enter'} animate={'exit'} key="error">
                            {t(form.errors.newPassword)}
                          </FieldError>
                        )}
                      </AnimatePresence>
                    </FieldWrapper>
                  )}
                </Field>
                <Field name="newPasswordConfirm">
                  {({ field, form }) => (
                    <FieldWrapper
                      noted={!!form.touched.newPasswordConfirm && !!form.errors && !!form.errors.newPasswordConfirm}
                    >
                      <TextInput
                        type="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.newPasswordConfirm}
                        {...field}
                        placeholder={t('EditProfileForm.ConfirmPassword')}
                        error={!!form.touched.newPasswordConfirm && !!form.errors && !!form.errors.newPasswordConfirm}
                        autoComplete="off"
                      />
                      <AnimatePresence>
                        {!!form.touched.newPasswordConfirm && !!form.errors && !!form.errors.newPasswordConfirm && (
                          <FieldError variants={PopUp} initial={'enter'} animate={'exit'} key="error">
                            {t(form.errors.newPasswordConfirm)}
                          </FieldError>
                        )}
                      </AnimatePresence>
                    </FieldWrapper>
                  )}
                </Field>
              </StyledFieldset>
            )}
            <Button disabled={isLoading || !isValid} isLoading={isLoading} type="submit">
              {submitTitle}
            </Button>
          </StyledForm>
        )}
      </Formik>
    );
  }
);

const StyledForm = styled(Form)`
  width: 100%;

  button[type='submit'] {
    margin-top: 25px;
  }
`;

const StyledFieldset = styled(Fieldset)`
  /*margin-bottom: 20px;*/
`;

const StyledFieldsetLabel = styled(FieldsetLabel)<{ theme: { colors: { accent: string } } }>`
  text-align: left;
  color: ${(props) => props.theme.colors.accent};
`;

interface IProfileImageProps {
  noImage?: boolean;
}

const ProfileImage = styled.div<IProfileImageProps>`
  width: 130px;
  height: 130px;
  border: ${(props) => (props.noImage ? `1px solid ${props.theme.colors.text}` : 'none')};
  align-self: center;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${(props) => (props.noImage ? 30 : 0)}px;
`;

const ProfileImageTitle = styled.span`
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  position: absolute;
`;

const Avatar = styled.img`
  flex: 1;
  object-fit: contain;
  z-index: 1;
  max-width: 130px;
  max-height: 130px;
`;

const CopyButtonFieldWrapper = styled(FieldWrapper)`
  > button {
    max-width: 150px;
  }
`;

const StyledAContainer = styled(motion.div)`
  flex: 0 0 auto !important;
`;
