import { ShareStoreState } from './types';
import { createSlice } from '@reduxjs/toolkit';

const initialState = new ShareStoreState();

const shareReducer = createSlice({
  name: 'shareReducer',
  initialState,
  reducers: {
    init: (state, action) => {
      state = { ...action.payload };
      return state;
    },
    clear: () => {
      return initialState;
    },
  },
});

export default shareReducer;
