import * as PT from '../paymentV1/types';

export interface ICheckoutCompleteStoreState {
  result: PT.IPaymentCompleteResult;
}

export interface ICheckoutStoreState extends PT.IPaymentProcessInfo {
  init: PT.IPaymentInitiateResult;
  process: PT.IPaymentProcessResult;
  complete: ICheckoutCompleteStoreState;
  isRedoing?: boolean;
}

export class CheckoutStoreState extends PT.PaymentProcessInfo implements ICheckoutStoreState {
  init: PT.IPaymentInitiateResult;
  process: PT.IPaymentProcessResult;
  complete: ICheckoutCompleteStoreState;
  isRedoing?: boolean;

  constructor() {
    super();
    this.init = null;
    this.process = null;
    this.complete = null;
    this.isRedoing = false;
  }
}
