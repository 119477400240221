import React, { FC } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { TicketCountSelectorV1 } from '../../../../components/book';
import { useRoutesHelper } from '../../../../helpers/routesHelper';
import { IEventStoreState } from '../../../../store/event/types';
import { Prices } from './price';

export const TicketsCountSelector: FC<TicketsCountSelectorType> = ({ ticketsOrRow, event, sectorSlug, eventSlug }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getBookRoute } = useRoutesHelper();

  if (!event) {
    return null;
  }
  const sector = event.sectors.find((x) => x.slug === sectorSlug);
  if (!sector || !event) {
    return null;
  }
  const quota = sector.ticketQuotas.find((x) => x.isPrivate);

  if (!quota) {
    return null;
  }

  const handleTicketsCount = (count: number) => {
    navigate(getBookRoute(eventSlug, null, sectorSlug, null, count, true));
  };

  return (
    <TicketsPriceWrapper>
      <Title>{t<string>('Share.TicketCount')}</Title>
      <TicketSelectorsWrapper>
        <TicketCountSelectorV1
          max={quota.left}
          sector={sector}
          tickets={ticketsOrRow}
          handleTicketsCount={(count) => handleTicketsCount(count)}
        />
      </TicketSelectorsWrapper>
      <Prices ticketsOrRow={ticketsOrRow} quota={quota} event={event} />
    </TicketsPriceWrapper>
  );
};

const TicketsPriceWrapper = styled.div`
  padding-top: 30px;
`;

const TicketSelectorsWrapper = styled.section`
  margin-bottom: 30px;
`;

const Title = styled.h1`
  font-size: 14px;
  margin: 0 0 15px 0;
`;

type TicketsCountSelectorType = {
  ticketsOrRow: number;
  event: IEventStoreState;
  sectorSlug: string;
  eventSlug: string;
};
