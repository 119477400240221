import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { Grid, Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { AnalyticService } from '../../services';
import { useDispatch, useSelector } from '../../configureStore';
import { citySelectorHide, citySelectorShow } from '../../store/header/actions';
import { useGetGenresQuery, useLazyGetEventsByTypeQuery } from '../../core/api/eventsSlice';
import { Footer } from './footer';
import { DatePicker } from '../../components/datePickerV2/datePicker';
import { Events } from '../../components/eventsV2/events';
import { DatepickerCarousel } from '../../components/datePickerV2/datepickerCarousel';
import { GenresEventsCarousel } from '../../components/eventCarouselV2/genresEventsCarousel';
import { SearchInput } from '../../components/searchInput/searchInput';
import { GenreCarousel } from '../../components/eventCarouselV2/genreCarousel';
import { EventType } from '../../core/models/events';
import { FilterParams } from '../../store/enums';
import { useUtils } from '../../services/useUtils';
import { SellEventButton } from '../../components/event/sellEventButton';

const useStyles = makeStyles()(() => ({
  gridCategories: {
    marginBottom: 43,
  },
  containerPlayer: {
    position: 'absolute',
    width: '100%',
    height: '470px',
    top: 0,
    zIndex: -1,
  },
  gradientPlayer: {
    position: 'absolute',
    zIndex: 10,
    background: 'linear-gradient(#000000 0%, rgba(0, 0, 0, 0.4) 23.03%, rgba(28, 28, 28, 0.3) 81.88%, #1C1C1C 100%)',
    minWidth: '100%',
    minHeight: '100%',
  },
  contentPage: {
    marginTop: '134px',
    zIndex: 1,
    padding: 16,
  },
  headingText: {
    textTransform: 'uppercase',
    fontSize: 14,
    lineHeight: '14px',
    cursor: 'default',
  },
  searchInput: {
    padding: '84px 0px 45px 0px',
  },
  videoPlayer: {
    height: 'inherit',
    objectFit: 'cover',
    width: '100%',
    zIndex: 0,
  },
  contentPlayer: {
    position: 'relative',
    height: '100%',
    width: '100%',
  },
}));

export const Dashboard = () => {
  const dispatch = useDispatch();
  const analyticSrv = new AnalyticService();
  const { t } = useTranslation();
  const { toPageTitle } = useUtils();

  const [searchParams] = useSearchParams();

  const cityParam = searchParams.get(FilterParams.City)?.replace(/\+/g, ' ')?.replace(/%2C/g, ',');
  const genreParam = +searchParams.get(FilterParams.Genre) || '';

  const showDatepicker = useSelector((state) => state.appSettings.showDashboardDatepicker);
  const showPopularEvents = useSelector((state) => state.appSettings.showDashboardPopularEvents);
  const showGenres = useSelector((state) => state.appSettings.showDashboardCategories);
  const showPopularTours = useSelector((state) => state.appSettings.showDashboardPopularTours);
  const showDashboardEventsByGenres = useSelector((state) => state.appSettings.showDashboardEventsByCategories);
  const dashboardCarouselColumnEventCount = useSelector((state) => state.appSettings.dashboardCarouselColumnEventCount);
  const dashboardCarouselEventCount = useSelector((state) => state.appSettings.dashboardCarouselEventCount);

  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [hidePopularTours, setHideDashboardPopularTours] = useState<boolean>(false);

  const { classes } = useStyles();

  useEffect(() => {
    analyticSrv.trackEvent('EventsList');
    dispatch(citySelectorShow());

    return () => {
      dispatch(citySelectorHide());
    };
  }, []);

  const { data: genres, isFetching: isLoadingGenres } = useGetGenresQuery();
  const [getEventsByType] = useLazyGetEventsByTypeQuery();

  const loadEvents = async (
    eventType: EventType,
    search?: string,
    date?: Date,
    count?: number,
    city?: string,
    genreId?: number | string
  ) => {
    const { data } = await getEventsByType({
      eventType,
      search,
      date,
      count,
      city,
      genreId,
    });

    return data;
  };

  const loadEventsByType = async (eventType) => {
    const events = await loadEvents(eventType);

    if (events.length <= 1) {
      setHideDashboardPopularTours(true);
    }

    return events;
  };

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center" className={classes.contentPage}>
      <Helmet>
        <title>{toPageTitle('')}</title>
      </Helmet>
      <div className={classes.containerPlayer}>
        <div className={classes.contentPlayer}>
          <div className={classes.gradientPlayer} />
          <video className={classes.videoPlayer} preload="metadata" autoPlay loop muted playsInline>
            <source type="video/mp4" src="content/videos/demo_video.mp4" />
          </video>
        </div>
      </div>

      <Grid item lg={12} xs={12} sm={12} md={12} xl={12} className={classes.searchInput}>
        <SearchInput city={cityParam} />
      </Grid>

      <Grid item>
        <SellEventButton />
      </Grid>

      {showPopularEvents && (
        <Events
          city={cityParam}
          load={() => loadEventsByType(EventType.Popular)}
          title={t<string>('Dashboard.PopularEvents')}
        />
      )}

      {showDashboardEventsByGenres && !cityParam && (
        <Grid item lg={12} xs={12} sm={12} md={12} xl={12} className={classes.gridCategories}>
          <div>
            <Typography variant="h6" className={classes.headingText}>
              категории
            </Typography>
          </div>
          <GenreCarousel genres={genres} isLoading={isLoadingGenres} />
        </Grid>
      )}
      {showDatepicker && (
        <Grid item lg={12} xs={12} sm={12} md={12} xl={12}>
          <DatePicker selectedDate={selectedDate} city={cityParam} onDateChanged={setSelectedDate} genres={genres} />
        </Grid>
      )}

      {showDatepicker && (
        <Grid item lg={12} xs={12} sm={12} md={12} xl={12}>
          <DatepickerCarousel
            reloadDependencyValue={{ selectedDate, city: cityParam, selectedGenreId: genreParam }}
            load={() =>
              loadEvents(EventType.Date, cityParam, selectedDate, dashboardCarouselEventCount, '', genreParam)
            }
            defaultRowsCount={dashboardCarouselColumnEventCount}
          />
        </Grid>
      )}

      {showPopularTours && !hidePopularTours && (
        <Events title={t<string>('Dashboard.PopularTours')} load={() => loadEventsByType(EventType.Tour)} />
      )}

      {showGenres && <GenresEventsCarousel genres={genres} isLoadingGenres={isLoadingGenres} />}
      <Footer />
    </Grid>
  );
};
