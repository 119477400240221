import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Helmet from 'react-helmet';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { AnimatePresence } from 'framer-motion';
import { IGlobalStoreState, useDispatch } from '../../../store';
import { PageContent } from '../../../components/UI';
import { RegistrationForm, RegistrationFormType } from '../../../components/forms';
import { AlertNotificationType } from '../../../store/app/types';
import { Utils } from '../../../services';
import { PopUp } from '../../../store/animations';
import { useRoutesHelper } from '../../../helpers/routesHelper';
import { AlertType, KeySuccess } from '../../../store/enums';
import { ModalWindow } from './socialNetworkLogin/modalWindow';
import { Divider, isMobile } from './loginPage';
import { useRegisterUserMutation } from '../../../core/api/accountSlice';
import { setError } from '../../../store/app/actions';

const RegistrationPage = () => {
  const isInitializing = useSelector<IGlobalStoreState, boolean>((state) => state.app.isInitializing);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getResultStatusRoute } = useRoutesHelper();

  const [registerUser, { isLoading }] = useRegisterUserMutation();

  if (isInitializing) {
    return null;
  }

  const handleFormSubmit = (formModel: RegistrationFormType) => {
    if (formModel) {
      registerUser(formModel)
        .unwrap()
        .then(() => {
          navigate(getResultStatusRoute(AlertType.Info, KeySuccess.EmailSent), { replace: true });
        })
        .catch(({ message }: AlertNotificationType) => {
          dispatch(setError({ type: 'error', message }));
        });
    }
  };

  return (
    <AnimatePresence>
      <PageContent center variants={PopUp} initial={'enter'} animate={'exit'}>
        <Helmet>
          <title>{Utils.toPageTitle(t('Registration.Registration'))}</title>
        </Helmet>
        <Header>{t('Registration.Registration')}</Header>
        <RegistrationForm
          submitTitle={t('Login.SignUp')}
          onSubmit={(formModel: RegistrationFormType) => handleFormSubmit(formModel)}
          isLoading={isLoading}
        />
        <Divider isMobile={isMobile}>{t('Login.Or')}</Divider>
        <ModalWindow />
      </PageContent>
    </AnimatePresence>
  );
};
const Header = styled.h1`
  font-family: ${(props) => props.theme.fonts.boldFont};
  font-size: 18px;
  text-transform: uppercase;
  text-align: center;
`;

export default RegistrationPage;
