import React, { FC, memo } from 'react';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { PopUp } from '../../store/animations';

export interface IItemProps {
  icon: 'star' | 'ticket' | 'meet' | 'soldTicket';
}

interface IUserStatItemProps extends IItemProps {
  title: string;
  count: number;
  index?: number;
}

export const UserStatItem: FC<IUserStatItemProps> = memo(({ icon, title, count, index }) => (
  <ItemContainer icon={icon}>
    <AnimatePresence>
      <Title key={count} variants={PopUp} initial={'enter'} animate={'exit'}>
        {title}
      </Title>
      <Count key={index} variants={PopUp} initial={'enter'} animate={'exit'}>
        {count}
      </Count>
    </AnimatePresence>
  </ItemContainer>
));

export const ItemContainer = styled('li')<IItemProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:before {
    content: '';
    background: ${(props) => `url(${props.theme.img.icons[props.icon]}) center no-repeat`};
    display: block;
    width: 26px;
    height: 26px;
    background-size: contain;
    margin-bottom: 10px;
  }
`;

export const Title = styled(motion.span)`
  font-size: 12px;
  text-align: center;
  flex: 1;
`;

export const Count = styled(motion.span)`
  font-family: ${(props) => props.theme.fonts.boldFont};
  font-size: 18px;
  text-align: center;
  margin-top: 10px;
`;
