import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ICheckoutStoreState } from '../../../../store/checkoutV1/types';
import { PageContent } from '../../../../components/UI';
import { CheckoutContent } from './checkoutContent';
import { Header, Title } from '../widgetCheckoutPage';
import { CheckoutHelper } from '../../../../helpers';

export const PaymentForm: FC<PaymentFormPropsType> = memo(({ checkout, checkoutSteps, redirectUrl }) => {
  const { t } = useTranslation();
  const step = 2;

  const needForm = CheckoutHelper.getIsNeedPaymentForm(checkout);
  const { paymentToken } = checkout.process;

  return (
    <PageContent roundCorners>
      <Header>
        {t('CheckoutWidget.Step')} {step} {t('CheckoutWidget.Of')} {checkoutSteps}
      </Header>
      <Title>{t('CheckoutWidget.PayByCard')}</Title>
      <CheckoutContent isNeedForm={needForm} paymentToken={paymentToken} redirectUrl={redirectUrl} />
    </PageContent>
  );
});

type PaymentFormPropsType = {
  checkout: ICheckoutStoreState;
  checkoutSteps: number;
  redirectUrl: string;
};
