import React, { FC } from 'react';
import { Box, Grid, Link, Skeleton, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useNavigate } from 'react-router';
import { AppTheme } from '../../settings/appTheme';
import { IEventCategory } from '../../store/event/types';
import { useRoutesHelper } from '../../helpers/routesHelper';

const useStyles = makeStyles<void, 'gridTileImage' | 'gradientImg'>()((_theme, _params, classes) => ({
  box: {
    overflow: 'scroll',
    overflowY: 'hidden',
    '&::-webkit-scrollbar': {
      height: `${AppTheme.scrollbarHeight}`,
    },
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
    margin: '0px',
  },
  gridTile: {
    cursor: 'pointer',
    width: 157,
    height: 'min-content',
    marginRight: 10,
    marginBottom: 20,
  },
  gridTileImage: {
    height: '105px',
    width: '144px',
    objectFit: 'cover',
    borderRadius: '8px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
  },
  skeletonImage: {
    height: '105px',
    width: '144px',
    borderRadius: '10px',
  },
  skeletonText: {
    height: 20,
    marginTop: '10px',
  },
  titleText: {
    fontSize: 16,
    lineHeight: '16px',
    marginTop: 10,
  },
  gridImg: {
    position: 'relative',
    '&:hover': {
      [`& .${classes.gridTileImage}, .${classes.gradientImg}`]: {
        filter: 'brightness(60%)',
      },
    },
  },
  gradientImg: {
    position: 'absolute',
    width: '144px',
    height: '42px',
    left: '0px',
    bottom: '5px',
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)',
    opacity: 0.7,
    borderRadius: '0px 0px 8px 8px',
  },
  linkStyle: {
    '&:hover': {
      textDecoration: 'none',
      color: 'rgb(178, 178, 178)',
      filter: 'brightness(60%)',
    },
  },
}));

export const CategoryCarouselV1: FC<CategoryCarouselType> = ({ categories, isLoading }) => {
  const navigate = useNavigate();

  const { classes } = useStyles();
  const { getEventCategoryRouteV1 } = useRoutesHelper();

  return (
    <Box className={`carousel ${classes.box}`}>
      <Grid container className={classes.gridList}>
        {categories.length > 0 &&
          !isLoading &&
          categories.map((item) => (
            <Grid item key={item.slug} className={classes.gridTile}>
              <Link className={classes.linkStyle} onClick={() => navigate(getEventCategoryRouteV1(item.slug))}>
                <Grid container direction="row">
                  <Grid item lg={12} xs={12} sm={12} md={12} xl={12}>
                    <div className={classes.gridImg}>
                      <img src={item.imageUrl} alt={item.title} className={classes.gridTileImage} />
                      <div className={classes.gradientImg} />
                    </div>
                  </Grid>
                  <Grid item lg={12} xs={12} sm={12} md={12} xl={12}>
                    <Typography className={classes.titleText}>{item.title}</Typography>
                  </Grid>
                </Grid>
              </Link>
            </Grid>
          ))}

        {isLoading &&
          Array.from(new Array(6)).map((value, index) => (
            <Grid key={index} item className={classes.gridTile}>
              <Grid container spacing={0} direction="row">
                <Grid item lg={12} xs={12} sm={12} md={12} xl={12}>
                  <Skeleton animation="wave" variant="rectangular" className={classes.skeletonImage} />
                </Grid>
                <Grid item lg={12} xs={12} sm={12} md={12} xl={12}>
                  <Skeleton animation="wave" variant="rectangular" width="75%" className={classes.skeletonText} />
                </Grid>
              </Grid>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

type CategoryCarouselType = {
  categories: IEventCategory[];
  isLoading: boolean;
};
