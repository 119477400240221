import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { Skeleton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import PanAndZoomImage from '../../helpers/PanAndZoomImage/PanAndZoomImage';
import { AppTheme } from '../../settings/appTheme';
import { IVenueSectorStoreState } from '../../store/ticket/types';
import { IVenueStoreState } from '../../store/event/types';
import { useGetVenueSchemaQuery } from '../../core/api/eventsSlice';

type StyleProps = {
  height: string;
};

const useStyles = makeStyles<StyleProps>()((theme, { height }) => ({
  skeletonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    zIndex: 0,
  },
  placeSelectorWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: '14px',
    margin: '0 0 20px 0',
  },
  schema: {
    textAlign: 'center',
    transition: 'height 0.5s ease-out',
    height,
    willChange: 'height',
    '& svg': {
      maxWidth: '100%',
      maxHeight: '100%',
      '& text': {
        fontFamily: `${AppTheme.fonts.boldFont} !important`,
        userSelect: 'none',
      },
      '$ .scene': {
        text: {
          fill: `${AppTheme.colors.accent}`,
        },
      },
      '&.disabled, .sector': {
        fill: '#313131',
        color: `${AppTheme.colors.bg}`,
      },
      '& .sector': {
        '& .sector-schema': {
          display: 'none',
          text: {
            fill: 'black',
          },
        },
        '&.available': {
          fill: 'rgb(102, 102, 102)',
          cursor: 'pointer',
          '&:hover': {
            fill: `${AppTheme.colors.accent}`,
          },
        },
        '&.selected': {
          cursor: 'unset',
          fill: `${AppTheme.colors.accent}`,
          '& .sector-schema': {
            display: 'block',
          },
        },
      },
    },
  },
}));

export const SchemaSectorSelectorV1: FC<SchemaSectorSelectorProps> = memo(
  ({ venue, sectors, selectedSector, onChange }) => {
    const [selectedSectorSlug, setSelectedSectorSlug] = useState<string>(selectedSector);

    const { data: scheme, isFetching } = useGetVenueSchemaQuery({
      venueSlug: venue.slug,
      schemaVersion: venue.schema.version,
    });

    const heightStyle = {
      height: selectedSectorSlug ? '30vh' : '50vh',
    };

    const { classes } = useStyles(heightStyle);

    const handleSectorClick = useCallback(
      (e: any) => {
        const sectorSlug = e.target.closest('.available').id;
        if (sectorSlug && sectors.some((q) => q.slug === sectorSlug) && onChange) {
          onChange(sectorSlug);
          setSelectedSectorSlug(sectorSlug);
        }
      },
      [onChange, sectors]
    );

    useEffect(() => {
      for (const sector of sectors) {
        const sectorElement = document.getElementById(sector.slug);
        if (sectorElement) {
          sectorElement.classList.add('available');
          sectorElement.addEventListener('click', (e) => handleSectorClick(e));
        }
      }

      return () => {
        for (const sector of sectors) {
          const sectorElement = document.getElementById(sector.slug);
          if (sectorElement) {
            sectorElement.removeEventListener('click', handleSectorClick);
          }
        }
      };
    }, [scheme?.id]);

    useEffect(() => {
      for (const sector of sectors.filter((s) => !!s)) {
        const sectorElement = document.getElementById(sector.slug);
        if (sectorElement) {
          if (sector.slug === selectedSectorSlug) {
            sectorElement.classList.add('selected');
          } else {
            sectorElement.classList.remove('selected');
          }
        }
      }
    }, [selectedSectorSlug]);

    return (
      <section className={classes.placeSelectorWrapper}>
        {isFetching && (
          <div className={classes.skeletonWrapper}>
            <Skeleton animation="wave" variant="rectangular" width="55vw" height="40vh" />
          </div>
        )}
        {!isFetching && (
          <PanAndZoomImage>
            <div className={classes.schema} dangerouslySetInnerHTML={{ __html: scheme?.schema }} />
          </PanAndZoomImage>
        )}
      </section>
    );
  }
);

type SchemaSectorSelectorProps = {
  venue: IVenueStoreState;
  sectors: Array<IVenueSectorStoreState>;
  selectedSector?: string;

  onChange?: (sectorSlug: string) => void;
};
