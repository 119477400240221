import React, { FC, forwardRef } from 'react';
import { List, ListItem, Paper, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useUtils } from '../../services/useUtils';

const useStyles = makeStyles()((theme) => ({
  paper: {
    position: 'absolute',
    marginTop: '4px',
    padding: '16px 22px',
    background: 'linear-gradient(180.62deg, rgba(28, 28, 28, 1) -91.79%, rgba(18, 17, 17, 1) 157.66%)',
    boxShadow: '4px -4px 16px rgb(0 0 0 / 63%), -4px 4px 16px rgba(0, 0, 0, 0.63)',
    borderRadius: '12px',
    zIndex: 1,
    height: '125px',
    width: '111px',
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    height: '100%',
    overflow: 'auto',
  },
  listItem: {
    padding: '0 0 0 0',
  },
  titleQuantity: {
    color: 'white',
    textDecoration: 'none',
    fontSize: '0.75rem',
    cursor: 'pointer',
    ':visited': {
      color: 'white',
    },
    ':hover': {
      color: theme.palette.secondary.main,
    },
  },
}));

type DropDownPaperType = {
  ref: any;
  selectTitle: number;
  data: any[];
  onChangeClick: (quantity: number) => void;
};

export const DropDownPaper: FC<DropDownPaperType> = forwardRef<any, any>(
  ({ selectTitle, data, onChangeClick }, ref) => {
    const { classes } = useStyles();
    const { getTicketsPostfixByCount } = useUtils();

    return (
      <Paper className={classes.paper} ref={ref}>
        <List className={classes.list}>
          {data.map((quantity, index) => (
            <ListItem key={index} className={classes.listItem}>
              <Typography
                component={'div'}
                style={quantity === selectTitle ? { color: '#FFBF00' } : undefined}
                className={classes.titleQuantity}
                onClick={() => onChangeClick(quantity)}
              >
                {`${quantity} ${getTicketsPostfixByCount(quantity)}`}
              </Typography>
            </ListItem>
          ))}
        </List>
      </Paper>
    );
  }
);
