import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Helmet from 'react-helmet';

import { useTranslation } from 'react-i18next';
import { AnimatePresence } from 'framer-motion';
import { useDispatch } from '../../../store';
import { PageContent, RLink } from '../../../components/UI';
import { LoginFormType } from '../../../components/forms';
import { Routes } from '../../../store/routes';
import { Utils } from '../../../services';
import { PopUp } from '../../../store/animations';
import { AppDeAuthorize, setError } from '../../../store/app/actions';
import { ModalWindow } from './socialNetworkLogin/modalWindow';
import { deAuthorize } from '../../../store/auth/actions';
import { useTokenMutation } from '../../../core/api/authSlice';
import { CheckoutHelper } from '../../../helpers';
import { useCheckoutV1 } from '../../../helpers/customHooks';
import { ICheckoutStoreState } from '../../../store/checkoutV1/types';
import { clear, clearEvents, clearTickets } from '../../../store/userV1/actions';
import { GlobalStateType } from '../../../configureStore';
import { LoginFormV1 } from '../../../components/forms/loginFormV1';

export const isMobile = window.matchMedia('only screen and (max-width: 479px)').matches;

const LoginPage = () => {
  const isAuthorized = useSelector<GlobalStateType, boolean>((state) => state.app.isAuthorized);
  const isInitializing = useSelector<GlobalStateType, boolean>((state) => state.app.isInitializing);
  const checkout = useSelector<GlobalStateType, ICheckoutStoreState>((state) => state.checkoutV1);

  const [login, { isLoading }] = useTokenMutation();

  const { checkoutCancel } = useCheckoutV1();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const logout = async () => {
    dispatch(clear());
    dispatch(clearEvents());
    dispatch(clearTickets());
    dispatch(AppDeAuthorize());
    dispatch(deAuthorize());

    if (CheckoutHelper.getIsProcessed(checkout) && !CheckoutHelper.getIsCompleted(checkout)) {
      await checkoutCancel(checkout);
    }
  };

  const handleFormSubmit = (model: LoginFormType) => {
    if (!model) {
      return;
    }

    login(model)
      .unwrap()
      .then(() => {
        if (CheckoutHelper.getIsProcessed(checkout) && !CheckoutHelper.getIsCompleted(checkout)) {
          (async () => await checkoutCancel(checkout))();
        }
        navigate(Routes.ProfileV1, { replace: true });
      })
      .catch((error: string) => {
        dispatch(setError({ type: 'error', message: error }));
        (async () => await logout())();
      });
  };

  useEffect(() => {
    if (isInitializing) {
      return;
    }

    if (isAuthorized) {
      navigate(Routes.ProfileV1, { replace: true });
    }
  }, [isAuthorized, isInitializing, navigate]);

  return (
    <AnimatePresence>
      <PageContent center variants={PopUp} initial={'enter'} animate={'exit'}>
        <Helmet>
          <title>{Utils.toPageTitle(t('Login.Login'))}</title>
        </Helmet>
        <Header>{t<string>('Login.Login')}</Header>
        <LoginFormV1
          isLoading={isLoading}
          submitTitle={t('Login.Login')}
          onSubmit={(formModel: LoginFormType) => handleFormSubmit(formModel)}
        />
        <Link to={Routes.RegistrationV1}>{t('Login.SignUp')}</Link>
        <Divider isMobile={isMobile}>{t('Login.Or')}</Divider>
        <ModalWindow />
      </PageContent>
    </AnimatePresence>
  );
};
const Header = styled.h1`
  font-family: ${(props) => props.theme.fonts.boldFont};
  font-size: 18px;
  text-transform: uppercase;
  text-align: center;
`;

const Link = styled(RLink)<{ theme: { fonts: { boldFont: string } } }>`
  margin-top: 15px;
  text-transform: uppercase;
  font-size: 14px;
  font-family: ${(props) => props.theme.fonts.boldFont};
`;

export const Divider = styled.div<{ isMobile: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  padding: 30px 0;
  user-select: none;

  &:after {
    content: '';
    position: absolute;
    height: 0.5px;
    width: ${(props) => (props.isMobile ? '33%' : '35%')};
    display: block;
    left: 10%;
    background: #fff;
    top: 50%;
  }

  &:before {
    content: '';
    position: absolute;
    height: 0.5px;
    width: ${(props) => (props.isMobile ? '33%' : '35%')};
    display: block;
    right: 10%;
    background: #fff;
    top: 50%;
  }
`;

export default LoginPage;
