import appReducer from './reducers';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const hideSplash = () => (): void => {
  document.body.classList.remove('splash-show');
};

export const redirectApp = createAsyncThunk('app/redirectApp', async (url: string, { dispatch }) => {
  await dispatch(redirect());
  location.replace(url);
  return Promise.resolve();
});

export const { redirect, AppDeAuthorize, setError, clearError, finalize, init, AppAuthorize } = appReducer.actions;
