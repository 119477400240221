import React from 'react';
import { ButtonBase, Theme, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { IGlobalStoreState, useDispatch } from '../../store';
import { playerHide, playerShow } from '../../store/header/actions';
import { useUtils } from '../../services/useUtils';
import {AppSettings} from "../../settings/appSettings";

const useStyles = makeStyles<{ disabled: boolean }, 'imageBackdrop'>()((theme: Theme, { disabled }, classes) => ({
  button: {
    position: 'relative',
    height: '42px',
    '& .MuiTouchRipple-root': {
      borderRadius: '24px',
    },
    '&:hover, &$focusVisible': {
      zIndex: 1,
      [`& .${classes.imageBackdrop}`]: {
        opacity: 0.25,
      },
    },
  },
  imageBackdrop: {
    borderRadius: '24px',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.secondary.main,
    opacity: disabled ? 0.5 : 1,
    transition: theme.transitions.create('opacity'),
  },
  imageButton: {
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '24px',
  },
  imageTitle: {
    borderRadius: '24px',
    position: 'relative',
    top: '1px',
    textTransform: 'uppercase',
    padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
    opacity: disabled ? 0.5 : 1,
  },
}));

export const SellEventButton = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isShowPlayer = useSelector<IGlobalStoreState, boolean>((state) => state.header.showPlayer);
  const { classes } = useStyles({ disabled: true });

  const handleModalBtnClick = () => (isShowPlayer ? dispatch(playerHide()) : dispatch(playerShow()));

  return (
    <ButtonBase
      focusRipple
      onClick={() => handleModalBtnClick()}
      className={classes.button}
      style={{ marginBottom: '110px' }}
      disabled
    >
      <span className={classes.imageBackdrop} />
      <span className={classes.imageButton}>
        <Typography component="span" variant="subtitle1" color="inherit" className={classes.imageTitle}>
          {t<string>('Dashboard.EarnOnATicket')}
        </Typography>
      </span>
    </ButtonBase>
  );
};
