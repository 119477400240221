import dayjs from 'dayjs';

export interface ISocialNetworkLogin {
  access_token: string;
  client_id: string;
}

export interface IDistributionInfo {
  distributorSlug?: string;
}

export class DistributionInfo implements IDistributionInfo {
  distributorSlug?: string;

  constructor(distributorSlug?: string) {
    this.distributorSlug = distributorSlug || null;
  }
}

export interface IUserRegistrationStoreState extends IDistributionInfo {
  email: string;
  password: string;
  isDistributor?: boolean;
}

export class UserRegistrationStoreState extends DistributionInfo implements IUserRegistrationStoreState {
  email: string;
  password: string;
  returnUrl: string;
  isDistributor?: boolean;

  constructor(email: string, password: string, returnUrl: string, isDistributor = true, distributorSlug?: string) {
    super(distributorSlug);
    this.email = email;
    this.password = password;
    this.returnUrl = returnUrl;
    this.isDistributor = isDistributor;
  }
}

export interface IUserBalanceStoreState {
  amount: number;
  currency: string;
}

export class UserBalanceStoreState {
  amount: number;
  currency: string;

  constructor(dto?: any) {
    this.amount = dto && dto.Amount ? dto.Amount : 0;
    this.currency = dto && dto.Currency ? dto.Currency : '';
  }
}

export interface IUserStatsStoreState {
  events: number;
  ticketsSold: number;
  ticketsPurchased: number;
  balances: Array<IUserBalanceStoreState>;
}

export class UserStatsStoreState implements IUserStatsStoreState {
  events: number;
  ticketsSold: number;
  ticketsPurchased: number;
  balances: Array<IUserBalanceStoreState>;

  constructor(dto?: any) {
    this.events = dto && dto.Events ? dto.Events : 0;
    this.ticketsSold = dto && dto.TicketsSold ? dto.TicketsSold : 0;
    this.ticketsPurchased = dto && dto.TicketsPurchased ? dto.TicketsPurchased : 0;
    this.balances = dto && dto.Balances ? dto.Balances.map((item) => new UserBalanceStoreState(item)) : [];
  }
}

export interface IUpdateUserStoreState {
  fullName: string;
  referralSlug: string;
}

export class UpdateUserStoreState implements IUpdateUserStoreState {
  fullName: string;
  referralSlug: string;

  constructor(fullName: string, referralSlug: string) {
    this.fullName = fullName;
    this.referralSlug = referralSlug;
  }
}

export interface IUserStoreState {
  email: string;
  emailConfirmed: boolean;
  phoneNumber: string;
  phoneNumberConfirmed: boolean;
  imageUrl: string;
  fullName: string;
  referralSlug: string;
  referredBySlug: string;
  stats: IUserStatsStoreState;
  phoneCodeSentAt?: dayjs.Dayjs;
  phoneCodeSentMessage?: string;
}

export class UserStoreState implements IUserStoreState {
  email: string;
  emailConfirmed: boolean;
  phoneNumber: string;
  phoneNumberConfirmed: boolean;
  fullName: string;
  referralSlug: string;
  referredBySlug: string;
  imageUrl: string;
  stats: IUserStatsStoreState;
  phoneCodeSentAt?: dayjs.Dayjs;
  phoneCodeSentMessage?: string;

  constructor(dto?: any) {
    this.email = dto && dto.Email ? dto.Email : '';
    this.emailConfirmed = dto && dto.EmailConfirmed ? dto.EmailConfirmed : false;
    this.phoneNumber = dto && dto.PhoneNumber ? dto.PhoneNumber : '';
    this.phoneNumberConfirmed = dto && dto.PhoneNumberConfirmed ? dto.PhoneNumberConfirmed : false;
    this.fullName = dto && dto.FullName ? dto.FullName : '';
    this.referralSlug = dto && dto.ReferralSlug ? dto.ReferralSlug : '';
    this.referredBySlug = dto && dto.ReferredBySlug ? dto.ReferredBySlug : '';
    this.imageUrl = dto && dto.ImageUrl ? dto.ImageUrl : '';
    this.stats = dto && dto.Stats ? new UserStatsStoreState(dto.Stats) : null;
    this.phoneCodeSentAt = null;
    this.phoneCodeSentMessage = null;
  }
}

export interface IResetPasswordConfirmInfo {
  email: string;
  newPassword: string;
  token: string;
}

export class ResetPasswordConfirmInfo {
  email: string;
  newPassword: string;
  token: string;

  constructor(email: string, password: string, token: string) {
    this.email = email;
    this.newPassword = password;
    this.token = token;
  }
}

export interface IChangePasswordInfo {
  currentPassword: string;
  newPassword: string;
}

export class ChangePasswordInfo implements IChangePasswordInfo {
  currentPassword: string;
  newPassword: string;

  constructor(currentPassword: string, newPassword: string) {
    this.currentPassword = currentPassword;
    this.newPassword = newPassword;
  }
}

export type PhoneNumberType = {
  phoneNumber: string;
};
export type ResetPasswordType = {
  email: string;
  returnUrl: string;
};

export type RegisterUserType = {
  email: string;
  password: string;
};

export type GetTicketByIdType = {
  masterTicketId: string;
  ticketId?: string;
};

export type IssueTicketType = {
  ticketId: string;
  email: string;
};
