import { Skeleton } from '@mui/material';
import React from 'react';

export const UserEventsSkeleton = () => {
  const ticketStyle = {
    transform: 'scale(1)',
    marginBottom: '10px',
  };
  return (
    <>
      {Array.from(new Array(3)).map((item, index) => (
        <Skeleton key={index} animation={'wave'} height={200} width={'100%'} sx={ticketStyle} />
      ))}
    </>
  );
};
