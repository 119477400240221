import { useTranslation } from 'react-i18next';
import { AppSettings } from '../settings/appSettings';

export const useUtils = () => {
  const { t } = useTranslation();

  const getTicketsPostfixByCount = (count: number): string => {
    if (!count) {
      return;
    }
    switch (count) {
      case 1:
        return t('Book.Ticket');
      case 2:
      case 3:
      case 4:
        return t('Book.MultipleTicketsBeforeFive');
      default:
        return t('Book.Tickets');
    }
  };

  const getEmojiFlagByCurrency = (currency: string): string => {
    if (!currency) {
      return;
    }
    switch (currency.toUpperCase()) {
      case 'BYN':
        return 'Br';
      case 'RUB':
        return '₽';
      case 'EUR':
        return '€';
      case 'UAH':
        return '₴';
      case 'USD':
        return '$';
      default:
        return '';
    }
  };

  const toPageTitle = (source: string): string =>
    source && source.trim() ? `${source.trim()} | ${AppSettings.APP_NAME}` : AppSettings.APP_NAME;

  const toMetaKeywords = (keywords: Array<string>): string => keywords.join(',');

  const formatPhoneNumber = (phone: string): string => phone.replace(/[-| |)|(]/g, '');

  const round = (value: number, fraction = 2): number => {
    if (!value) return;

    const c = 10 ** fraction;
    return Math.round(value * c) / c;
  };

  return {
    getTicketsPostfixByCount,
    getEmojiFlagByCurrency,
    toPageTitle,
    toMetaKeywords,
    formatPhoneNumber,
    round,
  };
};
