import React, { FC } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';

import { useTranslation } from 'react-i18next';
import Constants from '../../store/constants';
import { RLink } from '../UI';
import { useRoutesHelper } from '../../helpers/routesHelper';
import { TicketType } from '../../core/models/tickets';

const UserTicketItem: FC<UserTicketItemPropsType> = ({ ticket }) => {
  const { t } = useTranslation();
  const { getUserTicketRoute } = useRoutesHelper();

  if (!ticket || !ticket.ticketId) {
    return null;
  }

  const isIssued = !!ticket.issuedTo;
  const issueTitle = !isIssued ? t('MyTickets.GetTicket') : t('MyTickets.TicketIssued');

  const { event } = ticket;

  const renderTicketSector = (ticket: TicketType) => {
    if (!ticket || !ticket.ticketId) {
      return null;
    }

    let title = ticket?.sector;
    if (ticket.row) {
      title += ` ${t('MyTickets.Row')} ${ticket.row}`;
    }
    if (ticket.seat) {
      title += ` ${t('MyTickets.Seat')} ${ticket.seat}`;
    }
    return <InfoItem>{title}</InfoItem>;
  };

  return (
    <Container>
      <Content>
        <Header>
          <Title>{event.title}</Title>
        </Header>
        <InfoWrapper>
          <InfoItem>{dayjs(event.eventDate).format(`${Constants.DateFormat}, LT`)}</InfoItem>
          <InfoItem>{`${event.city}, ${event.title}`}</InfoItem>
          <InfoItem>{`${event.hall}`}</InfoItem>
          {renderTicketSector(ticket)}
        </InfoWrapper>
        <Footer>
          <Link to={getUserTicketRoute(ticket.ticketId)} title={issueTitle}>
            <Action disabled={isIssued}>{issueTitle}</Action>
          </Link>
        </Footer>
      </Content>
      <Poster bg={event.posterUrl} />
    </Container>
  );
};

const Link = styled(RLink)`
  display: block;

  &:hover {
    filter: brightness(80%);
  }
`;

const Container = styled('article')<{ theme: { colors: string } }>`
  display: flex;
  border-top: 1px solid ${(props) => props.theme.colors.text};
  border-bottom: 1px solid ${(props) => props.theme.colors.text};
  min-height: 210px;
  height: 35vw;
  max-height: 25vh;
`;

const Poster = styled.div<{ bg: string; theme: { colors: string } }>`
  flex: 1;
  width: 50%;
  background: url(${(props) => props.bg}) top no-repeat;
  background-size: cover;
  mask-position: 0 0;
  mask-size: auto;
  mask-image: radial-gradient(
    circle at calc(100% + 12px) 50%,
    transparent 42px,
    ${(props) => props.theme.colors.text} 43px
  );
  position: relative;

  &:after {
    content: '';
    position: absolute;
    background: white;
    width: 100%;
    height: 100%;
    clip-path: circle(44px at calc(100% + 12px));
  }
`;

const Content = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 50%;
  border-right: 1px dashed ${(props) => props.theme.colors.text};
  padding: 10px 20px;
`;

const Header = styled.header``;

const Title = styled.h1`
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  font-size: 18px;
  font-family: ${(props) => props.theme.fonts.bolderFont};
`;

const InfoWrapper = styled.section`
  flex: 1;
  padding: 10px 0;
`;

const InfoItem = styled.p`
  margin: 5px 0;
  font-size: 12px;
`;

const Footer = styled.footer``;

const Action = styled.span<{ theme: { fonts: string; colors: string }; disabled: boolean }>`
  font-size: 12px;
  font-family: ${(props) => props.theme.fonts.boldFont};
  color: ${(props) => props.theme.colors.accent};
  text-transform: uppercase;
  opacity: ${(props: any) => (props.disabled ? 0.7 : 1)};
`;

export default UserTicketItem;

type UserTicketItemPropsType = {
  ticket: TicketType;
};
