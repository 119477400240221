export default class Constants {
  public static DefaultTicketsCount = 1;
  public static DistributionExpirationMins = 24 * 60;
  public static DateFormat = 'DD MMMM YYYY';
  public static ResendCodeInSecs = 30;
  public static BulletDelimiter = '\u2022';
  public static RegionalInfoExpiresInHours = 24;
  public static MinPasswordLength = 6;
  public static ResendTicketInMins = 5;
  public static LeftMarginLogo = '12px';
}
