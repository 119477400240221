import React from 'react';
import { Skeleton } from '@mui/material';
import { Page, PageContent } from '../UI';
import { Header as HeaderEventInfo } from '../event';
import { Header, Title } from '../../containers/pages';
import PageFooter from '../UI/page/pageFooter';

export const CheckoutSkeleton = () => {
  const posterOverrideStyle = {
    position: 'absolute',
    objectPosition: '50% 20%',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    transform: 'scale(1)',
  };

  const buttonOverrideStyle = {
    width: '100%',
    height: '44px',
    borderRadius: '24px',
    transform: 'scale(1)',
  };

  return (
    <Page>
      <HeaderEventInfo>
        <Skeleton animation={'wave'} height={'60vh'} width={'100%'} sx={posterOverrideStyle} />
      </HeaderEventInfo>
      <PageContent roundCorners>
        <Header>
          <Skeleton animation={'wave'} width={'100px'} sx={{ transform: 'scale(1)', display: 'inline-block' }} />
        </Header>
        <Title>
          <Skeleton animation={'wave'} width={'85%'} sx={{ display: 'inline-block', transform: 'scale(1)' }} />
        </Title>
        <Skeleton animation="wave" sx={buttonOverrideStyle} />
        <Skeleton animation="wave" sx={{ ...buttonOverrideStyle, marginTop: '10px' }} />
      </PageContent>
      <PageFooter>
        <Skeleton animation="wave" sx={buttonOverrideStyle} />
      </PageFooter>
    </Page>
  );
};
