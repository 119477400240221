import React, { FC } from 'react';
import styled from 'styled-components';
import { RLink } from '..';

export interface ISelectorItem {
  to: string;
  title: string;
  subtitle?: string;
  note?: string;
}

export const SelectorItem: FC<ISelectorItem> = ({ title, note, to, subtitle }) => (
  <Container key={title}>
    <Link to={to} title={title}>
      <TitleWrapper>
        <Title>{title}</Title>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
      </TitleWrapper>
      <NoteWrapper>
        {note && <Title>{note}</Title>}
        <Arrow />
      </NoteWrapper>
    </Link>
  </Container>
);

const Container = styled.li`
  padding: 0 25px;
  margin: 0 -25px;

  &:hover {
    background-color: ${(props) => props.theme.colors.accent};

    > a > div > span {
      color: black;
    }
  }
`;

const Link = styled(RLink as any)`
  padding: 10px 0;
  margin-bottom: -1px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.accent};

  &:hover {
    color: unset;
  }
`;

const TitleWrapper = styled.div`
  flex: 1;
`;

const Title = styled.p`
  padding: 0;
  margin: 0;
  font-size: 14px;
`;

const Subtitle = styled.span`
  font-size: 12px;
  color: ${(props) => props.theme.colors.textSecond};
`;

const NoteWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Arrow = styled.span`
  background: url(${(props) => props.theme.img.icons.back}) 0 no-repeat;
  width: 23px;
  height: 23px;
  border: none;
  display: inline-block;
  background-size: contain;
  transform: rotate(180deg);

  &:hover {
    cursor: pointer;
  }
`;
