import eventsReducer from './reducers';

// export const getWidgetEvent = createAsyncThunk<Promise<IEventStoreState>, string>(
//   'event/getWidgetEvent',
//   async (token: string, { dispatch }) => {
//     dispatch(showSpinner());
//     const event = await thunkService.api.widget.getWidgetEvent(token);
//     dispatch(hideSpinner());
//     return Promise.resolve(event);
//   }
// );

// export const stopDistribute =
//   (
//     slug: string
//   ): ((dispatch: Dispatch<any>, getState: () => IGlobalStoreState, thunkService: ThunkService) => Promise<void>) =>
//   async (dispatch: Dispatch<any>, getState: () => IGlobalStoreState, thunkService: ThunkService) => {
//     dispatch(showSpinner());
//     await thunkService.api.events.stopDistribute(slug);
//     dispatch(hideSpinner());
//     return Promise.resolve();
//   };

export const { initEvents } = eventsReducer.actions;
