import * as React from 'react';
import styled from 'styled-components';
import { Image } from '../UI';
import { AppTheme } from '../../settings/appTheme';

export const PaymentLogos = () => (
  <LogosWrapper>
    {AppTheme.img.payment.logos.map((logo, index) => (
      <PaymentLogo key={index} logoUrl={logo.url} title={logo.title} />
    ))}
  </LogosWrapper>
);

interface IPaymentLogoProps {
  logoUrl: string;
  title?: string;
  websiteUrl?: string;
}

export const PaymentLogo = (props: IPaymentLogoProps) => {
  const { logoUrl, websiteUrl, title } = props;
  const img = (
    <Picture>
      <source srcSet={`${logoUrl}.webp`} type="image/webp" />
      <Image src={`${logoUrl}.png`} alt={`Логотип ${title}`} />
    </Picture>
  );
  return websiteUrl ? (
    <a href={websiteUrl} title={`Перейти на веб-сайт ${title}`} target="_blank" rel="noreferrer">
      {img}
    </a>
  ) : (
    img
  );
};

const LogosWrapper = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  background: ${(props) => props.theme.colors.text};
  margin: 0 -25px -25px -25px;
  padding: 10px 25px;
  justify-content: space-around;
`;

const Picture = styled.picture`
  margin: 10px;

  img {
    max-width: 35vw;
  }
`;

export default PaymentLogos;
