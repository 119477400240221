import React from 'react';
import styled from 'styled-components';
import { Skeleton } from '@mui/material';
import { SkeletonEventCarousel } from './skeletonEventCarousel';

export const GenreEventsSkeleton = () => {
  const textOverrideStyle = {
    height: 20,
    marginTop: '10px',
  };
  return (
    <>
      {Array.from(new Array(3)).map((value, index) => (
        <Container key={index}>
          <Title>
            <Skeleton animation="wave" variant="rectangular" width="25%" sx={textOverrideStyle} />
          </Title>
          <SkeletonEventCarousel skeletonCount={3} />
        </Container>
      ))}
    </>
  );
};

const Container = styled.div`
  margin-left: 25px;
  margin-right: 25px;
  width: 100%;
`;
const Title = styled.div`
  margin-bottom: 20px;
`;
