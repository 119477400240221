import React, { Suspense, useEffect } from 'react';
import { Navigate, Route, Routes as Switch } from 'react-router-dom';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';
import dayjs from 'dayjs';
import { useLocation, useNavigate } from 'react-router';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { IntercomProvider } from 'react-use-intercom';
import { useDispatch } from '../store';
import { finalize, hideSplash, redirectApp } from '../store/app/actions';
import { PagesRoutes, Routes } from '../store/routes';
import BookPageV1 from './pages/bookV1/bookPageV1';
import CheckoutPageV1 from './pages/checkoutV1/checkoutPageV1';
import UserPageV1 from './pages/userV1/userPageV1';
import UserTicketDetailsPageV1 from './pages/userV1/userTicketDetailsPage';
import SharePage from './pages/share/sharePage';
import ShareLinkPage from './pages/share/shareLinkPage';
import WidgetBookPage from './pages/widget/widgetBookPage';
import WidgetCheckoutPage from './pages/widgetCheckout/widgetCheckoutPage';
import WidgetBookResultPage from './pages/widget/widgetBookResultPage';
import { ContactsPage } from './pages/staticV1';
import { AnalyticService } from '../services';
import { AlertType, KeyErrors, Pages } from '../store/enums';
import { DashboardPageV1 } from './pages/events/dashboardPageV1';
import { EventCategoryPageV1 } from './pages/events/eventCategoryPageV1';
import { initLocation } from '../store/location/actions';
import { AppTheme } from '../settings/appTheme';
import { IntercomSettings } from '../settings/appSettings';
import { ReferralPage } from './pages/referralPageV1/referralPage';
import { useRoutesHelper } from '../helpers/routesHelper';
import { InformationSuccessV1 } from './pages/informationV1/informationSuccessV1';
import { InformationFailedV1 } from './pages/informationV1/informationFailedV1';
import { useLazyGetAppSettingsQuery, useLazyGetRegionQuery } from '../core/api/homeSlice';
import { useLazyGetEventsQuery, useLazyGetUserEventsQuery } from '../core/api/eventsSlice';
import { useLazyGetUserAgreementsQuery } from '../core/api/publicContractsSlice';
import { AlertError } from '../components/alert/alertError';
import StripeBookResultPageV1 from './pages/bookV1/stripeBookResultPageV1';
import { EventDetails } from './eventDetails/eventDetails';
import { EventDetailsPageV1, EventsPageV1 } from './pages';
import { Dashboard } from './dashboard/dashboard';
import { GenreEvents } from './genreEvents/genreEvents';
import { Book } from './book/book';
import { Checkout } from './checkout/checkout';
import { StripeBookResultPage } from './book/stripeBookResultPage';
import { InformationSuccess } from './information/informationSuccess';
import { InformationFailed } from './information/informationFailed';
import { useSelector } from '../configureStore';
import { Header } from './header/header';
import { User } from './user';
import UserTicketDetailsPage from './user/userTicketDetailsPage';
import { ErrorBoundary } from './errorBoundary/errorBoundary';
import { Share } from './share/share';
import { Referral } from './referral/referral';
import { useLazyPingQuery } from '../core/api/authSlice';
import { Menu } from './menu/menu';
import { PaymentsInfo } from './static/paymentsInfo';
import { Contacts } from './static/contacts';

const theme = createTheme({
  palette: {
    primary: {
      main: '#fff',
      light: '#1C1C1C',
      dark: '#000',
      contrastText: '#fff',
    },
    secondary: {
      main: '#FFBF00',
      light: '#4B4B4B',
      dark: 'rgba(255, 255, 255, 0.08)',
      contrastText: '#FFFFFF',
    },
  },
  typography: {
    fontFamily: [AppTheme.fonts.regularFont].join(','),
  },
  components: {
    MuiFab: {
      styleOverrides: {
        root: {
          backgroundColor: '#1C1C1C',
          ':hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.08)',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          ':hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.08)',
          },
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(61, 61, 61, 0.31)',
        },
      },
    },
  },
});

const App = () => {
  const analyticSrv = new AnalyticService();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const isMenuShow = useSelector((state) => state.menu.isShow);
  const isRedirecting = useSelector((state) => state.app.isRedirection);
  const header = useSelector((state) => state.header);
  const useNewDashboard = useSelector((state) => state.appSettings.useNewDashboard);
  const isFinalized = useSelector((state) => state.app.isFinalized);
  const resultType = useSelector((state) => state.resultInfo.type);
  const resultKey = useSelector((state) => state.resultInfo.key);
  const region = useSelector((state) => state.region);
  const isAuthorized = useSelector((state) => state.app.isAuthorized);

  const [getRegion] = useLazyGetRegionQuery();
  const [getEvents] = useLazyGetEventsQuery();
  const [getSetting] = useLazyGetAppSettingsQuery();
  const [getUserAgreements] = useLazyGetUserAgreementsQuery();
  const [getUserEvents] = useLazyGetUserEventsQuery();
  const [pingRequest] = useLazyPingQuery();

  const { isActivePage, getLocation } = useRoutesHelper();

  const isServerError = KeyErrors.ServerError === resultKey;

  useEffect(() => {
    if (!isAuthorized) {
      return;
    }
    pingRequest();
  }, [location]);

  useEffect(() => {
    analyticSrv.trackPageView(location.pathname + location.search);

    if (!location.search) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  const updateViewPortHeight = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  useEffect(() => {
    const isAfter = dayjs().isAfter(region.lastCheckedAt);
    if (!region || !region.lastCheckedAt || isAfter) {
      (async () => await getRegion())();
    }
  }, []);

  useEffect(() => {
    analyticSrv.initialize();
    try {
      if (region.isDomainRequired) {
        const newLocation = getLocation(region.domainName);
        if (newLocation !== window.location.href) {
          dispatch(redirectApp(newLocation));
          return;
        }
      }
      dispatch(initLocation(region.city));

      const isWidgetPage = isActivePage(Pages.Widget);
      if (isWidgetPage) {
        // const token = routeSrv.getwidgetToken();
        // dispatch(initWidgetApp(token));
      } else {
        (async () => await getSetting())().then((response) => {
          const { data } = response;
          if (!data.useNewDashboard) {
            (async () => await getEvents('*'))();
          }
          (async () => await getUserEvents())();
          (async () => await getUserAgreements())();
          dispatch(hideSplash());
          dispatch(finalize());
        });
      }
    } catch (e) {
      const splashError = document.getElementById('splashErrorId');
      splashError.hidden = false;
    }
  }, []);

  useEffect(() => {
    document.addEventListener('touchstart', () => {}, false);
    window.addEventListener('resize', () => {
      updateViewPortHeight();
    });
    window.addEventListener('scroll', () => {
      updateViewPortHeight();
    });
  }, []);

  useEffect(
    () => () => {
      document.removeEventListener('touchstart', () => {});
      window.removeEventListener('resize', () => {
        updateViewPortHeight();
      });
      window.removeEventListener('scroll', () => {
        updateViewPortHeight();
      });
    },
    []
  );

  useEffect(() => {
    updateViewPortHeight();

    if (isMenuShow || isFinalized || header) {
      !isFinalized || header.showPlayer
        ? document.body.classList.add('no-scroll')
        : document.body.classList.remove('no-scroll');
    }
  }, [header.showPlayer, isFinalized, isMenuShow]);

  const { getResultStatusRoute } = useRoutesHelper();

  useEffect(() => {
    if (isServerError) {
      navigate(getResultStatusRoute(AlertType.Error, KeyErrors.ServerError), { replace: true });
    }
  }, [isServerError]);

  return (
    <ErrorBoundary>
      <Suspense>
        <IntercomProvider appId={IntercomSettings.APP_ID} autoBoot>
          <ThemeProvider theme={AppTheme}>
            <MuiThemeProvider theme={theme}>
              <AppContainer noScroll={!!resultType}>
                <GlobalStyles theme={AppTheme} />
                {!isRedirecting && (
                  <Container>
                    <Header />
                    <AlertError />
                    <Switch location={location}>
                      <Route path={Routes.Main} element={useNewDashboard ? <DashboardPageV1 /> : <EventsPageV1 />} />
                      <Route path={Routes.MainSortLocation} element={<DashboardPageV1 />} />
                      <Route path={Routes.Category} element={<EventCategoryPageV1 />} />
                      <Route path={Routes.Event} element={<EventDetailsPageV1 />} />
                      <Route path={Routes.StripeBookResultV1} element={<StripeBookResultPageV1 />} />
                      <Route path={Routes.CheckoutV1} element={<CheckoutPageV1 />} />
                      <Route path={Routes.Referral} element={<ReferralPage />} />
                      <Route path={Routes.TokenReferral} element={<BookPageV1 />} />
                      <Route path={Routes.RegistrationReferral} element={<BookPageV1 />} />
                      <Route path={`/v1/${Pages.BookV1}`} element={<BookPageV1 />}>
                        <Route path={Routes.BookEventSlug} element={<BookPageV1 />}>
                          <Route path={Routes.BookSectorSlug} element={<BookPageV1 />}>
                            <Route path={Routes.BookQuotaId} element={<BookPageV1 />} />
                          </Route>
                        </Route>
                      </Route>
                      <Route path={Routes.Share} element={<SharePage />}>
                        <Route path={Routes.ShareSectorSlug} element={<SharePage />}>
                          <Route path={Routes.ShareTickets} element={<SharePage />} />
                        </Route>
                      </Route>
                      <Route path={Routes.ShareLink} element={<ShareLinkPage />} />
                      <Route path={Routes.UserTicketDetails} element={<UserTicketDetailsPageV1 />} />
                      <Route path={Routes.UserV1} element={<UserPageV1 />} />
                      <Route path={Routes.Contacts} element={<ContactsPage />} />
                      <Route path={Routes.WidgetBookResult} element={<WidgetBookResultPage />} />
                      <Route path={Routes.WidgetBookResultType} element={<WidgetBookResultPage />} />
                      <Route path={Routes.WidgetBook} element={<WidgetBookPage />} />
                      <Route path={Routes.WidgetCheckout} element={<WidgetCheckoutPage />} />
                      <Route path={Routes.InformationSuccess} element={<InformationSuccessV1 />} />
                      <Route path={Routes.InformationError} element={<InformationFailedV1 />} />
                      <Route path={PagesRoutes.EventDetails} element={<EventDetails />} />
                      <Route path={PagesRoutes.Main} element={<Dashboard />} />
                      <Route path={PagesRoutes.RegistrationReferral} element={<User />} />
                      <Route path={PagesRoutes.GenreEvents} element={<GenreEvents />} />
                      <Route path={PagesRoutes.Book} element={<Book />} />
                      <Route path={PagesRoutes.Checkout} element={<Checkout />} />
                      <Route path={PagesRoutes.StripeBookResult} element={<StripeBookResultPage />} />
                      <Route path={PagesRoutes.InformationSuccess} element={<InformationSuccess />} />
                      <Route path={PagesRoutes.InformationError} element={<InformationFailed />} />
                      <Route path={PagesRoutes.User} element={<User />} />
                      <Route path={PagesRoutes.UserTicketDetails} element={<UserTicketDetailsPage />} />
                      <Route path={PagesRoutes.Share} element={<Share />} />
                      <Route path={PagesRoutes.Referral} element={<Referral />} />
                      <Route path={PagesRoutes.PaymentsInfo} element={<PaymentsInfo />} />
                      <Route path={PagesRoutes.Contacts} element={<Contacts />} />
                      <Route path={'*'} element={<Navigate to={PagesRoutes.Main} replace />} />
                    </Switch>
                    <Menu />
                  </Container>
                )}
              </AppContainer>
            </MuiThemeProvider>
          </ThemeProvider>
        </IntercomProvider>
      </Suspense>
    </ErrorBoundary>
  );
};

type GlobalStylesType = {
  theme: {
    colors: {
      text: string;
      bg: string;
    };
    fonts: {
      regularFont: string;
    };
  };
};

const GlobalStyles = createGlobalStyle<GlobalStylesType>`
  html {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  body {
    color: ${(props) => props.theme.colors.text};
    font-family: ${(props) => props.theme.fonts.regularFont};
    font-weight: normal;
    background-color: ${(props) => props.theme.colors.bg};

    &.no-scroll {
      overflow: hidden;
    }

    &.splash-show {
      .spinner {
        background-color: transparent;
      }
    }
  }

  * {
    box-sizing: border-box;
    font-weight: normal;
  }
`;

const AppContainer = styled.div<{ noScroll: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${(props) => props.theme.colors.bg};
  overflow-x: hidden;
  overflow-y: ${(props) => (props.noScroll ? 'hidden' : 'auto')};
  max-height: ${(props) => (props.noScroll ? '100vh' : 'unset')};
`;
const Container = styled.div`
  min-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: ${(props) => props.theme.maxWidth};
  width: 100%;
  box-shadow: 0 0 15px 2px black;
  background: ${(props) => props.theme.colors.bg};
  position: relative;
`;

export default App;
