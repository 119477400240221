import React, { FC, memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { setError } from '../../../store/app/actions';
import { Button, PageFooter } from '../../../components/UI';
import { EventBookType } from '../../../core/models/events';
import { SelectedTicketGroupType } from '../book';
import { useDispatch } from '../../../configureStore';
import { useRoutesHelper } from '../../../helpers/routesHelper';
import { AnalyticService } from '../../../services';

export const Footer: FC<FooterType> = memo(({ event, selectedTicketGroups, isLoading, paymentHandleClick }) => {
  const analyticSrv = new AnalyticService();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { getCheckoutRoute } = useRoutesHelper();

  const seatsCount = selectedTicketGroups.reduce((acc, ticketGroup) => acc + ticketGroup.count, 0);

  const isMaxPurchase = seatsCount > event?.maxPurchase;

  useEffect(() => {
    if (isMaxPurchase) {
      dispatch(setError({ type: 'warning', message: 'Book.YouHaveReachedYourEventTicketPurchaseLimit' }));
      return;
    }
  }, [isMaxPurchase]);

  if (!event) {
    return null;
  }

  const isSeatsSelected = selectedTicketGroups?.length > 0;

  const isCheckoutEnabled = !isLoading && isSeatsSelected && event.isTicketsAvailable && !isMaxPurchase;

  let buttonTitle = t('Book.GoToPayment');
  if (!event.isTicketsAvailable) {
    buttonTitle = t('Book.TicketsAreNotAvailableForSale');
  } else if (!isSeatsSelected) {
    buttonTitle = t('Book.ChooseSeats');
  }

  const handleCheckoutClick = (e) => {
    if (e) {
      e.preventDefault();
    }

    paymentHandleClick().then(() => {
      analyticSrv.trackEvent('AddToCart');
      navigate(getCheckoutRoute(event.slug));
    });
  };

  return (
    <Container>
      <Button disabled={!isCheckoutEnabled} onClick={(e) => handleCheckoutClick(e)}>
        {buttonTitle}
      </Button>
    </Container>
  );
});

const Container = styled(PageFooter)`
  align-items: initial;
  padding: 0 25px 0 25px;

  > button,
  > input {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
`;

type FooterType = {
  event: EventBookType;
  selectedTicketGroups: SelectedTicketGroupType[];
  isLoading: boolean;
  paymentHandleClick: () => Promise<void>;
};

export type SelectedSeatsType = {
  title: string;
  price: string;
  seatId?: string;
};
