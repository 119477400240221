import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';
import { Page } from '../../../components/UI';
import { EventCheckoutHeaderV1 } from '../../../components/event';
import { IEmailForm } from '../../../components/forms';
import { setEmailV1 } from '../../../store/checkoutV1/actions';
import { CheckoutHelper, EventHelper } from '../../../helpers';
import { ICheckoutStoreState } from '../../../store/checkoutV1/types';
import { useDispatch } from '../../../store';
import { CheckoutEmailForm } from './widgetCheckoutComponents/checkoutEmailForm';
import { PaymentForm } from './widgetCheckoutComponents/checkoutPaymentForm';
import { GlobalStateType } from '../../../configureStore';

const WidgetCheckoutPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const checkoutSteps = 2;
  //ToDo: create error handler
  // const [errors, setErrors] = useState(null);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const { token } = useParams<{ token: string }>();

  const checkout = useSelector<GlobalStateType, ICheckoutStoreState>((state) => state.checkoutV1);
  const isInitializing = useSelector<GlobalStateType, boolean>((state) => state.app.isInitializing);
  // const error = useSelector<IGlobalStoreState, IErrorStoreState>((state) => state.app.error);
  const isAuthorized = useSelector<GlobalStateType, boolean>((state) => state.app.isAuthorized);

  useEffect(() => {
    init(checkout);
  }, []);

  // useEffect(() => {
  //   setErrors({ form: error?.details || error?.message });
  // }, [error?.details, error?.message]);

  useEffect(() => {
    const isAvailableForProcessing = CheckoutHelper.getIsAvailableForProcessing(checkout) && !isAuthorized;

    if (isAvailableForProcessing) {
      handlePaymentFormSubmit(token);
    }
  }, [checkout?.email, isAuthorized, token]);

  useEffect(() => {
    const IsInProcess = CheckoutHelper.getIsProcessed(checkout) && CheckoutHelper.getIsInProcess(checkout);
    if (IsInProcess) {
      init(checkout);
    }
  }, [checkout?.process, checkout?.complete?.result?.isProcessing]);

  useEffect(() => {
    if (CheckoutHelper.isRedirectingMethod(checkout)) {
      setRedirectUrl(checkout.process.paymentRedirectUrl);
    }
  }, [checkout?.process, checkout?.process?.paymentRedirectUrl, checkout?.process?.paymentProvider]);

  const handleEmailFormSubmit = useCallback(
    (formModel: IEmailForm) => {
      if (formModel) {
        dispatch(setEmailV1(formModel.email));
      }
    },
    [dispatch]
  );

  const handlePaymentFormSubmit = (token: string) => {
    // dispatch(widgetCheckoutProcess(token));
  };

  const init = (checkout: ICheckoutStoreState) => {
    if (CheckoutHelper.getIsProcessed(checkout) && !CheckoutHelper.getIsCompleted(checkout)) {
      // dispatch(checkCurrentCheckout());
      return;
    }
  };

  const isInitiated = CheckoutHelper.getIsInitiated(checkout);
  if (!isInitiated || isInitializing || !checkout) {
    return null;
  }

  const isEmailSet = CheckoutHelper.getIsEmailSet(checkout);
  const isProcessed = CheckoutHelper.getIsProcessed(checkout);
  const { event } = checkout.init;

  let posterUrl = event.optionPosterUrl;
  if (posterUrl === '') {
    posterUrl = event.posterUrl;
  }

  return (
    <Page
      title={`${t('CheckoutWidget.TicketPurchase')} | ${event.title} - ${event.subtitle}`}
      description={EventHelper.getMetaDescription(event)}
      keywords={[event.title, event.venue.title, t('CheckoutWidget.BuyTicket'), t('CheckoutWidget.Concert')]}
      imageUrl={posterUrl}
    >
      <EventCheckoutHeaderV1 checkout={checkout} isAuthorized={isAuthorized} />
      {isInitiated && !isEmailSet && (
        <CheckoutEmailForm
          email={checkout.email}
          checkoutSteps={checkoutSteps}
          isAuthorized={isAuthorized}
          handleEmailFormSubmit={handleEmailFormSubmit}
        />
      )}
      {isProcessed && <PaymentForm checkout={checkout} checkoutSteps={checkoutSteps} redirectUrl={redirectUrl} />}
    </Page>
  );
};

export const Header = styled.h2`
  font-size: 14px;
  margin: 0 0 10px 0;
  text-align: center;
  font-family: ${(props) => props.theme.fonts.boldFont};
`;

export const RedirectTitle = styled(Header)``;

export const Title = styled.h1`
  font-size: 14px;
  text-align: center;
`;

export default WidgetCheckoutPage;
