import { IRegionalInfoStoreState, RegionalInfoStoreState } from '../../store/region/types';
import { setRegion } from '../../store/region/actions';
import { AppSettingsState, IAppSettingsState } from '../../store/appSettings/types';
import { appSettingInit } from '../../store/appSettings/actions';
import { init } from '../../store/app/actions';
import { baseSlice } from './apiSlice';
import { home } from '../constants/constants';

const homeApi = baseSlice.injectEndpoints({
  endpoints: (build) => ({
    getRegion: build.query<IRegionalInfoStoreState, void>({
      query: () => home.regionalInfo,
      onQueryStarted: async (city, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        dispatch(setRegion(data));
      },
      transformResponse: (response) => new RegionalInfoStoreState(response),
    }),
    getAppSettings: build.query<IAppSettingsState, void>({
      query: () => home.appSettings,
      transformResponse: (response) => new AppSettingsState(response),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        dispatch(appSettingInit(data));
        dispatch(init());
      },
    }),
  }),
});

export const { useLazyGetRegionQuery, useLazyGetAppSettingsQuery } = homeApi;
