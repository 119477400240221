import * as React from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { AnimatePresence, motion } from 'framer-motion';
import { IEventStoreState } from '../../store/event/types';
import { Image, RLink } from '../UI';
import Constants from '../../store/constants';
import { PopUp } from '../../store/animations';
import { useRoutesHelper } from '../../helpers/routesHelper';
import { FC } from 'react';

interface IEventHeadlineProps {
  event: IEventStoreState;
  isMain?: boolean;
  isDistributed?: boolean;
}

const EventHeadline: FC<IEventHeadlineProps> = ({ event, isDistributed, isMain }) => {
  const { t } = useTranslation();
  const { getEventRoute } = useRoutesHelper();

  let linkTitle = event.title;

  if (isDistributed) {
    linkTitle += t('EventPage.DistributingMarkTitle');
  }

  return (
    <AnimatePresence>
      <Link to={getEventRoute(event.slug)} title={linkTitle}>
        <EventContainer variants={PopUp} initial={'enter'} animate={'exit'} main={isMain}>
          <Poster src={event.posterUrl} title={event.title} alt={event.title} />
          <HeaderContainer main={isMain}>
            <Title main={isMain} isDistributed={isDistributed}>
              {event.title}
            </Title>
            <Date main={isMain} dateTime={dayjs(event.date).format(Constants.DateFormat)}>
              {dayjs(event.date).locale('ru').format(Constants.DateFormat)}
            </Date>
            <City main={isMain}>
              {event.venue.city}, {event.venue.country}
            </City>
          </HeaderContainer>
        </EventContainer>
      </Link>
    </AnimatePresence>
  );
};

const Link = styled(RLink)`
  display: block;

  &:hover {
    filter: brightness(80%);
  }
`;

const EventContainer = styled(motion.div)<{ main: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: ${(props) => (props.main ? '100vw' : '50vw')};
  min-height: ${(props) => (props.main ? '410px' : '264px')};
  max-height: ${(props) => (props.main ? '560px' : '364px')};
  position: relative;
`;

const HeaderContainer = styled.header<{ main: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.main ? 'center' : 'flex-start')};
  padding: ${(props) => (props.main ? '47px' : '37px')} 25px;
  z-index: 1;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
`;

const Title = styled.h1<{ main: boolean; isDistributed: boolean }>`
  font-family: ${(props) => props.theme.fonts.bolderFont};
  font-size: ${(props) => (props.main ? '42px' : '28px')};
  line-height: ${(props) => (props.main ? '52px' : '34px')};
  margin: 0 0 1px 0;
  order: ${(props) => (props.main ? 2 : 1)};
  text-align: ${(props) => (props.main ? 'center' : 'left')};

  &:after {
    content: ${(props) => props.isDistributed && '""'};
    position: relative;
    width: ${(props) => (props.main ? '38px' : '25px')};
    height: ${(props) => (props.main ? '38px' : '25px')};
    display: inline-block;
    margin-left: 8px;
    background: url(${(props) => props.theme.img.icons.plane}) center center no-repeat;
    background-size: contain;
  }
`;

const SubTitle = styled.h2<{ main: boolean }>`
  font-size: ${(props) => (props.main ? '24px' : '18px')};
  line-height: ${(props) => (props.main ? '29px' : '22px')};
  margin: 0 0 1px 0;
  text-align: ${(props) => (props.main ? 'center' : 'left')};
`;

const Date = styled.time<{ main: boolean }>`
  font-size: ${(props) => (props.main ? '24px' : '18px')};
  line-height: ${(props) => (props.main ? '29px' : '22px')};
  margin: 0 0 1px 0;
  text-align: ${(props) => (props.main ? 'center' : 'left')};
  order: ${(props) => (props.main ? 1 : 2)};
`;

const City = styled(SubTitle)`
  order: 3;
  color: ${(props) => props.theme.colors.accent};
`;

const Poster = styled(Image)`
  object-position: 50% 20%;
`;

export default EventHeadline;
