import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { PointsSelector } from './checkoutComponents/pointsSelector';
import { EmailCheckoutForm } from './checkoutComponents/emailCheckoutForm';
import { PaymentCheckoutForm } from './checkoutComponents/paymentCheckoutForm';
import { AnalyticService, Utils } from '../../services';
import { useDispatch, useSelector } from '../../configureStore';
import { useCheckout, useCheckoutHelper, useEventHelpers, usePrevious } from '../../helpers/customHooks';
import { useRoutesHelper } from '../../helpers/routesHelper';
import { IEmailForm } from '../../components/forms';
import { setEmail } from '../../store/checkout/actions';
import { Page } from '../../components/UI';
import { CheckoutType } from '../../store/checkout/types';
import { EventCheckoutHeader } from '../../components/event/eventCheckoutHeader/eventCheckoutHeader';

export const Checkout = () => {
  const analyticSrv = new AnalyticService();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [redirectUrl, setRedirectUrl] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const checkout = useSelector((state) => state.checkout);
  const isInitializing = useSelector((state) => state.app.isInitializing);
  const book = useSelector((state) => state.book);
  const user = useSelector((state) => state.user);
  const isAuthorized = useSelector((state) => state.app.isAuthorized);
  const distribution = useSelector((state) => state.distribution);

  const { checkoutProcess } = useCheckout();
  const { isEmailSet, isInProcess, isInitiated, isProcessed, isAvailableForProcessing, isRedirectingMethod } =
    useCheckoutHelper();
  const { getEventMetaDescription } = useEventHelpers();

  const checkoutSteps = isAuthorized ? 3 : 2;

  const { eventSlug, sectorSlug, rowSlug, tickets } = useParams<ParamsType>();

  const { getEventRoute, getBookEventRoute } = useRoutesHelper();
  const prevCheckout = usePrevious(checkout);
  const prevBook = usePrevious(book);
  const isChangedCheckout = Utils.isEqual(checkout, prevCheckout);
  const isChangedBook = Utils.isEqual(book, prevBook);

  const handlePaymentFormSubmit = useCallback(
    async (usePoints: boolean) => {
      setLoading(true);
      await checkoutProcess(checkout, distribution, usePoints);
      analyticSrv.trackEvent('PurchaseProcess');
      setLoading(false);
    },
    [checkout, distribution]
  );

  const init = useCallback(
    async (checkout: CheckoutType) => {
      if (!book || !book.event) {
        navigate(getBookEventRoute(eventSlug));
        return;
      } else if (!book.event.isTicketsAvailable) {
        navigate(getEventRoute(book.event.slug));
        return;
      }

      if (!isInitiated(checkout)) {
        navigate(getBookEventRoute(book.event.slug));
        return;
      } else if (checkout.isRedoing) {
        return;
      } else if (isAvailableForProcessing(checkout) && !isAuthorized) {
        await handlePaymentFormSubmit(checkout.usePoints);
      }
    },
    [isChangedCheckout, isChangedBook, eventSlug, isAuthorized, rowSlug, sectorSlug, tickets]
  );

  useEffect(() => {
    (async () => await init(checkout))();
  }, []);

  const handleEmailFormSubmit = (formModel: IEmailForm) => {
    if (formModel) {
      dispatch(setEmail(formModel.email));
      analyticSrv.trackEvent('InitiateCheckout');
    }
  };

  useEffect(() => {
    if (isRedirectingMethod(checkout)) {
      setRedirectUrl(checkout.process.paymentRedirectUrl);
    }
  }, [isChangedCheckout]);

  useEffect(() => {
    const isEmailChanged = prevCheckout?.email !== checkout?.email;
    const isAvailableProcessing = isEmailChanged && isAvailableForProcessing(checkout) && !isAuthorized;
    if (isAvailableProcessing) {
      (async () => await handlePaymentFormSubmit(checkout.usePoints))();
    }
  }, [isAuthorized, checkout?.email]);

  useEffect(() => {
    const IsInProcess = isProcessed(prevCheckout) && isInProcess(checkout);

    if (IsInProcess) {
      (async () => await init(checkout))();
    }
  }, [isChangedCheckout]);

  const isInitiate = isInitiated(checkout);
  if (!isInitiate || isInitializing || !checkout) {
    return null;
  }

  const isEmail = isEmailSet(checkout);
  const isProcess = isProcessed(checkout);
  const { event, ticketGroups } = book;

  const totalPrice = ticketGroups.reduce((acc, { price, count }) => {
    acc = price * count;
    return acc;
  }, 0);

  return (
    <Page
      title={`${t('Checkout.TicketPurchase')} | ${event.title} - ${event.subTitle}`}
      description={getEventMetaDescription(event)}
      keywords={[event.title, event.venue.title, t('Checkout.BuyTicket'), t('Checkout.Concert')]}
      imageUrl={event.posterUrl}
    >
      <EventCheckoutHeader checkout={checkout} isAuthorized={isAuthorized} totalPrice={totalPrice} />
      {isInitiate && !isEmail && (
        <EmailCheckoutForm
          checkoutSteps={checkoutSteps}
          checkout={checkout}
          isAuthorized={isAuthorized}
          user={user}
          handleEmailFormSubmit={handleEmailFormSubmit}
          distribution={distribution}
        />
      )}
      {isEmail && !isProcess && (
        <PointsSelector
          checkout={checkout}
          book={book}
          distribution={distribution}
          redirectUrl={redirectUrl}
          checkoutSteps={checkoutSteps}
          isAuthorized={isAuthorized}
          user={user}
          handlePaymentFormSubmit={handlePaymentFormSubmit}
          isLoading={isLoading}
        />
      )}
      {isProcess && (
        <PaymentCheckoutForm
          checkoutSteps={checkoutSteps}
          checkout={checkout}
          isAuthorized={isAuthorized}
          user={user}
          redirectUrl={redirectUrl}
        />
      )}
    </Page>
  );
};

export const Header = styled.h2`
  font-size: 14px;
  margin: 0 0 10px 0;
  text-align: center;
  font-family: ${(props) => props.theme.fonts.boldFont};
`;

export const RedirectTitle = styled(Header)``;

export const Title = styled.h1`
  font-size: 14px;
  text-align: center;
`;

type ParamsType = {
  eventSlug: string;
  sectorSlug: string;
  rowSlug: string;
  tickets: string;
};
