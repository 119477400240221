import React, { FC } from 'react';
import { IEventStoreState } from '../../../../store/event/types';
import { IBookTicketQuotaStoreState } from '../../../../store/ticket/types';
import { BookTotalPrices } from '../../bookV1';

export const Prices: FC<PriceType> = ({ quota, event, ticketsOrRow }) => {
  const defaultPaymentMethod = event.paymentMethods && event.paymentMethods.length > 0 ? event.paymentMethods[0] : null;
  const ticketPrice = quota.price;
  const ticketsCount = ticketsOrRow;
  let total = ticketPrice * ticketsCount;
  let { currency } = quota;
  if (defaultPaymentMethod.useConvertion) {
    total = Math.round(total * defaultPaymentMethod.convertionRate * 100) / 100;
    currency = defaultPaymentMethod.convertionCurrency;
  }
  return <BookTotalPrices border ticketPrice={ticketPrice} totalPrice={total} currency={currency} />;
};

type PriceType = {
  event: IEventStoreState;
  quota: IBookTicketQuotaStoreState;
  ticketsOrRow: number;
};
