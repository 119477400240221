import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { makeStyles } from 'tss-react/mui';
import { Grid, Skeleton } from '@mui/material';
import DOMPurify from 'dompurify';
import { useDispatch, useSelector } from '../../configureStore';
import { useRoutesHelper } from '../../helpers/routesHelper';
import { useGetBookEventQuery } from '../../core/api/eventsSlice';
import { PagesRoutes } from '../../store/routes';
import { setResultInfo } from '../../store/resultInformarion/actions';
import { AlertType, KeyErrors } from '../../store/enums';
import { Footer } from './shareComponents/footer';
import { Page, PageContent } from '../../components/UI';
import { AppTheme } from '../../settings/appTheme';

const useStyles = makeStyles()(() => ({
  schemeContainer: {
    height: '60vh',
    width: '100%',
  },
  skeletonContainer: {
    display: 'flex',
    justifyContent: 'center',
    zIndex: 0,
  },
  scheme: {
    textAlign: 'center',
    height: 'inherit',
    '& svg': {
      maxWidth: '100%',
      maxHeight: '100%',
      '& text': {
        fontFamily: `${AppTheme.fonts.boldFont} !important`,
        userSelect: 'none',
      },
      '$ .scene': {
        text: {
          fill: `${AppTheme.colors.accent}`,
        },
      },
      '&.disabled, .sector': {
        fill: '#313131',
        color: `${AppTheme.colors.bg}`,
      },
      '& .sector': {
        '& .sector-schema': {
          display: 'none',
          text: {
            fill: 'black',
          },
        },
        '&.available': {
          fill: 'rgb(102, 102, 102)',
          cursor: 'pointer',
          '&:hover': {
            fill: `${AppTheme.colors.accent}`,
          },
        },
      },
    },
  },
}));

export const Share = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { classes } = useStyles();

  const { getResultStatusRoute } = useRoutesHelper();

  const user = useSelector((state) => state.user);
  const isRedirection = useSelector((state) => state.app.isRedirection);
  const isFinalized = useSelector((state) => state.app.isFinalized);

  const { eventSlug } = useParams<{ eventSlug: string }>();

  const { data: event, isError, isFetching } = useGetBookEventQuery({ eventSlug });

  useEffect(() => {
    if (!event?.isTicketsAvailable && !isFetching) {
      navigate(PagesRoutes.UserEvents);
    }
  }, [event?.isTicketsAvailable]);

  useEffect(() => {
    if (isRedirection || !isFinalized) {
      return;
    }
    if (!eventSlug) {
      navigate(PagesRoutes.Main);
      return;
    }
  }, [eventSlug, isFinalized, isRedirection]);

  useEffect(() => {
    if (isError) {
      dispatch(
        setResultInfo({
          type: AlertType.Error,
          key: KeyErrors.EventNotFound,
          title: 'Alert.SomethingWentWrong',
          message: 'Alert.CheckLinkNoEventFound',
        })
      );
      navigate(getResultStatusRoute(AlertType.Error, KeyErrors.EventNotFound), { replace: true });
      return;
    }
  }, [isError]);

  useEffect(() => {
    if (!user) {
      navigate(PagesRoutes.Login, { replace: true });
      return;
    } else if (!user.phoneNumberConfirmed) {
      navigate(PagesRoutes.EditProfile, { replace: true });
    }
  }, [user]);

  useEffect(() => {
    if (!isFetching && !event) {
      navigate(PagesRoutes.UserEvents);
    }
  }, [event, isFetching]);

  return (
    <>
      {isFetching && (
        <Grid className={classes.skeletonContainer}>
          <Skeleton animation={'wave'} variant={'rectangular'} width={'40vw'} height={'40vh'} />
        </Grid>
      )}
      {!isFetching && (
        <Page
          isForm
          title={`${t('Share.TicketSell')} | ${event?.title} ${event?.subTitle ? `- ${event?.subTitle}` : ''}`}
        >
          <PageContent>
            <Grid className={classes.schemeContainer}>
              <div
                className={classes.scheme}
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(event?.venue?.schema) }}
              />
            </Grid>
          </PageContent>
          <Footer isLoading={isFetching} event={event} eventSlug={eventSlug} user={user} />
        </Page>
      )}
    </>
  );
};
