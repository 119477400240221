import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Header, Title } from '../checkout';
import { PageContent } from '../../../components/UI';
import { Content } from './content';
import { IUserStoreState } from '../../../store/userV1/types';
import { CheckoutType } from '../../../store/checkout/types';
import { useCheckoutHelper } from '../../../helpers/customHooks';

export const PaymentCheckoutForm: FC<PaymentFormPropsType> = memo(
  ({ checkout, user, isAuthorized, checkoutSteps, redirectUrl }) => {
    const { t } = useTranslation();
    const { isNeedPaymentForm } = useCheckoutHelper();

    const step = isAuthorized && !!user && !!user.email ? 3 : 2;
    const isNeedForm = isNeedPaymentForm(checkout);
    const { paymentToken } = checkout.process;

    return (
      <PageContent roundCorners>
        <Header>
          {t('Checkout.Step')} {step} {t('Checkout.Of')} {checkoutSteps}
        </Header>
        <Title>{t<string>('Checkout.PayByCard')}</Title>
        <Content isNeedForm={isNeedForm} redirectUrl={redirectUrl} paymentToken={paymentToken} />
      </PageContent>
    );
  }
);

type PaymentFormPropsType = {
  checkout: CheckoutType;
  isAuthorized: boolean;
  user: IUserStoreState;
  checkoutSteps: number;
  redirectUrl: string;
};
