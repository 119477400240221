import React, { ButtonHTMLAttributes, FC, memo, useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { PopUp } from '../../store/animations';

export const Button: FC<ButtonType> = memo(
  ({ advertising, children, copy, disabled, edit, onClick, outline, round, small, title, type, width, isLoading }) => {
    let mouseUpTimer;
    let touchEndTimer;

    const [active, setActive] = useState(false);
    const [hover, setHover] = useState(false);
    const [isTouched, setIsTouched] = useState(false);

    useEffect(
      () => () => {
        clearTimeout(mouseUpTimer);
        clearTimeout(touchEndTimer);
      },
      [mouseUpTimer, touchEndTimer]
    );

    const handleMouseEnter = (e: any) => {
      if (disabled || isTouched) {
        return;
      }
      if (e) {
        e.preventDefault();
      }
      setHover(true);
    };

    const handleMouseLeave = (e: any) => {
      if (disabled || isTouched) {
        return;
      }
      if (e) {
        e.preventDefault();
      }
      setHover(false);
      setActive(false);
    };

    const handleMouseDown = (e: any) => {
      if (disabled) {
        return;
      }
      if (e) {
        e.preventDefault();
      }
      setActive(true);
    };

    const handleMouseUp = (e: any) => {
      if (disabled) {
        return;
      }
      if (e) {
        e.preventDefault();
      }
      mouseUpTimer = setTimeout(() => {
        setActive(false);
        setHover(false);
      }, 200);
    };

    const handleTouchStart = () => {
      if (disabled) {
        return;
      }
      setActive(true);
      setHover(false);
    };

    const handleTouchEnd = () => {
      if (disabled) {
        return;
      }
      touchEndTimer = setTimeout(() => {
        setActive(false);
        setHover(false);
        setIsTouched(true);
      }, 200);
    };

    const loaderOverrideStyle = {
      position: 'absolute',
      left: '10px',
      right: 0,
      top: 0,
      bottom: 0,
      margin: 'auto 0',
    };

    return (
      <AnimatePresence>
        <StyledButton
          variants={PopUp}
          initial={'enter'}
          animate={'exit'}
          disabled={disabled}
          type={type}
          round={round}
          outline={outline}
          copy={copy}
          title={title}
          active={active}
          hover={hover}
          small={small}
          edit={edit}
          advertising={advertising}
          width={width}
          onClick={(e) => {
            if (onClick) {
              onClick(e);
            }
          }}
          onMouseEnter={(e) => handleMouseEnter(e)}
          onMouseLeave={(e) => handleMouseLeave(e)}
          onMouseDown={(e) => handleMouseDown(e)}
          onMouseUp={(e) => handleMouseUp(e)}
          onTouchStart={() => handleTouchStart()}
          onTouchEnd={() => handleTouchEnd()}
        >
          {isLoading && <CircularProgress size={26} sx={loaderOverrideStyle} />}
          {children}
        </StyledButton>
      </AnimatePresence>
    );
  }
);

const StyledButton = styled(motion.button)<ButtonType & ButtonState>`
  display: inline-block;
  color: ${(props) => {
    let result = props.theme.colors.text;
    if ((props.active || props.hover) && !props.disabled) {
      result = props.theme.colors.text;
    } else if (props.outline) {
      result = props.disabled ? props.theme.colors.accentDisabled : props.theme.colors.accent;
    } else if (props.disabled) {
      result = props.theme.colors.textSecond;
    }
    return `${result} !important`;
  }};
  background-color: ${(props) => {
    let result = props.edit ? props.theme.colors.bg : props.theme.colors.accent;
    if (props.active && !props.disabled && !props.advertising) {
      result = props.theme.colors.accentDimmed;
    } else if (props.hover && !props.disabled) {
      result = '#D4A108';
    } else if (props.outline) {
      result = 'transparent';
    } else if (props.disabled) {
      result = props.theme.colors.accentDisabled;
    } else if (props.advertising) {
      result = props.theme.colors.accent;
    }
    return `${result} !important`;
  }};
  cursor: ${(props) => {
    if (props.disabled) {
      return 'not-allowed';
    } else {
      return 'pointer';
    }
  }};
  transition: background-color, color, width 0.2s ease-in;
  text-transform: uppercase;
  text-align: center;
  font-size: 14px;
  font-family: ${(props) => (props.round ? props.theme.fonts.fontLighter : props.theme.fonts.boldFont)};
  line-height: 15px;
  position: ${(props) => (props.edit ? 'absolute' : 'relative')};
  padding: ${(props) => (props.copy || props.small || props.edit ? '0' : props.advertising ? '11px' : '15px')};
  right: ${(props) => props.edit && 0};
  bottom: ${(props) => props.edit && 0};
  width: ${(props) => {
    if (props.copy) {
      return '45px';
    } else if (props.small) {
      return '24px';
    } else if (props.edit) {
      return '35px';
    } else if (props.advertising) {
      return '210px';
    } else {
      return props.width || '100%';
    }
  }};
  height: ${(props) => {
    if (props.copy) {
      return '45px';
    } else if (props.small) {
      return '24px';
    } else if (props.edit) {
      return '35px';
    } else if (props.advertising) {
      return '36px';
    } else {
      return 'unset';
    }
  }};
  border-radius: ${(props) => (props.round ? '50%' : '24px')};
  border: ${(props) => {
    if (props.outline) {
      return `1px solid ${props.disabled ? props.theme.colors.accentDisabled : props.theme.colors.accent}`;
    } else if (props.edit) {
      return `1px solid ${props.theme.colors.text}`;
    } else if (props.advertising) {
      return `1px solid ${props.theme.colors.accent}`;
    } else {
      return 'none';
    }
  }};
  outline: none;
  z-index: ${(props) => props.edit && 1};

  &:before {
    content: ${(props) => (props.copy || props.edit) && '""'};
    background: url(${(props) => (props.copy ? props.theme.img.icons.copy : props.theme.img.icons.edit)}) center
      no-repeat;
    width: ${(props) => (props.copy ? '25px' : '20px')};
    height: ${(props) => (props.copy ? '25px' : '20px')};
    display: inline-block;
    background-size: contain;
    filter: ${(props) => props.edit && (props.active || props.hover) && 'brightness(0)'};
  }
`;

type ButtonPropsType = {
  round?: boolean;
  outline?: boolean;
  copy?: boolean;
  small?: boolean;
  edit?: boolean;
  advertising?: boolean;
  width?: string;
  isLoading?: boolean;
};

type ButtonState = {
  active?: boolean;
  hover?: boolean;
  isTouched?: boolean;
};

type ButtonType = ButtonPropsType & ButtonHTMLAttributes<HTMLButtonElement>;
