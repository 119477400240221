import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { unwrapResult } from '@reduxjs/toolkit';

import { useDispatch } from '../../../store';
import { IEventStoreState, ISeatStoreState, SeatStoreState } from '../../../store/event/types';
import { Page, PageContent } from '../../../components/UI';
import { PlaceSelector, SchemaSectorSelectorV1 } from '../../../components/book';
import { clearBook, initBook } from '../../../store/bookV1/actions';
import { EventHelper } from '../../../helpers';
import { IBookStoreState } from '../../../store/bookV1/types';
import { AlertType, KeyErrors } from '../../../store/enums';
import { setResultInfo } from '../../../store/resultInformarion/actions';
import { useRoutesHelper } from '../../../helpers/routesHelper';
import { TicketsCountSelector } from './widgetComponents/ticketsCountSelector';
import { WidgetFooter } from './widgetComponents/widgetFooter';
import { deAuthorize } from '../../../store/auth/actions';
import { GlobalStateType } from '../../../configureStore';

const WidgetBookPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const event = useSelector<GlobalStateType, IEventStoreState>((state) => state.bookV1.event);
  const isLoading = useSelector<GlobalStateType, boolean>((state) => state.app.isLoading);
  const book = useSelector<GlobalStateType, IBookStoreState>((state) => state.bookV1);
  const isRedirection = useSelector<GlobalStateType>((state) => state.app.isRedirection);
  const isAuthorized = useSelector<GlobalStateType>((state) => state.app.isAuthorized);

  const [isCheckoutInitiating, setIsCheckoutInitiating] = useState(false);
  const [seats, setSeats] = useState<ISeatStoreState[]>([]);
  const [sectorSlug, setSectorSlug] = useState(null);
  const [quotaId, setQuotaId] = useState(null);
  const [showSeatsSelector, setShowSeatsSelector] = useState(false);

  const { getResultStatusRoute } = useRoutesHelper();
  const { token } = useParams<{ token: string }>();

  useEffect(() => {
    if (isAuthorized) {
      dispatch(deAuthorize());
    }

    dispatch(clearBook());
    // (async () => dispatch(getWidgetEvent(token)))()
    //   .then(unwrapResult)
    //   .then((event: any) => {
    //     if (!event || !event.slug) {
    //       dispatch(clearBook());
    //       dispatch(
    //         setResultInfo({
    //           type: AlertType.Error,
    //           key: KeyErrors.EventNotFound,
    //           title: 'Alert.SomethingWentWrong',
    //           message: 'Alert.CheckLinkNoEventFound',
    //         })
    //       );
    //       navigate(getResultStatusRoute(AlertType.Error, KeyErrors.EventNotFound), { replace: true });
    //       return;
    //     }
    //     dispatch(initBook({ event }));
    //   });
  }, []);

  const handleTicketsCount = useCallback(
    (count: number) => {
      let filteredSeatsByQuotaId = seats.filter((s) => s.quotaId !== quotaId);
      for (let index = 0; index < count; index++) {
        const sector = event.sectors.find((x) => x.slug === sectorSlug);
        filteredSeatsByQuotaId = [...filteredSeatsByQuotaId, new SeatStoreState(quotaId, sectorSlug, sector.title)];
      }
      setSeats(filteredSeatsByQuotaId);
    },
    [seats, event?.slug]
  );

  const handleSchemaSectorSelect = (sectorSlug: string) => {
    setSectorSlug(sectorSlug);
    setShowSeatsSelector(false);
    const sector = event && event.sectors && event.sectors.find((x) => x.slug === sectorSlug);
    const quota = sector && sector.ticketQuotas[0];
    if (quota !== null) {
      setQuotaId(quota.id);
    }
    setShowSeatsSelector(true);
  };

  const handlePlaceSelect = (quotaId: number, sectorSlug: string, rowSlug: string, seatSlug: string) => {
    setSeats((seats) => {
      if (seats.some((s) => s.sectorSlug === sectorSlug && s.rowSlug === rowSlug && s.seatSlug === seatSlug)) {
        return seats.filter((s) => s.sectorSlug !== sectorSlug || s.rowSlug !== rowSlug || s.seatSlug !== seatSlug);
      }
      const sector = event.sectors.find((x) => x.slug === sectorSlug);
      return book.event.maxPurchase > seats.length
        ? [...seats, new SeatStoreState(quotaId, sectorSlug, sector.title, rowSlug, seatSlug)]
        : seats;
    });
  };

  if (isRedirection || isLoading || !event || isCheckoutInitiating) return null;

  const sector = event && event.sectors && event.sectors.find((x) => x.slug === sectorSlug);
  const isInteractiveSchema = !!sector && !!sector.schema;

  let posterUrl = event.optionPosterUrl;
  if (posterUrl === '') {
    posterUrl = event.posterUrl;
  }

  return (
    <Page
      isForm
      title={`${t('WidgetBook.TicketPurchase')} | ${event.title} - ${event.subtitle}`}
      description={EventHelper.getMetaDescription(event)}
      keywords={[event.title, event.venue.title, t('WidgetBook.BuyTicket'), t('WidgetBook.Concert')]}
      imageUrl={posterUrl}
    >
      <PageContent>
        {
          <SchemaSectorSelectorV1
            venue={event.venue}
            sectors={event.sectors}
            selectedSector={sectorSlug}
            onChange={(sectorSlug: string) => handleSchemaSectorSelect(sectorSlug)}
          />
        }
        {isInteractiveSchema && showSeatsSelector && event && sector && (
          <PlaceSelector
            venueSlug={event.venue.slug}
            sector={sector}
            selectedSeats={seats}
            maxSelectCount={event.maxPurchase}
            onChange={handlePlaceSelect}
          />
        )}
        {!isInteractiveSchema && (
          <TicketsCountSelector
            handleTicketsCount={handleTicketsCount}
            event={event}
            sector={sector}
            seats={seats}
            quotaId={quotaId}
          />
        )}
      </PageContent>
      <WidgetFooter
        book={book}
        event={event}
        seats={seats}
        sectorSlug={sectorSlug}
        isLoading={isLoading}
        setIsCheckoutInitiating={setIsCheckoutInitiating}
        token={token}
      />
    </Page>
  );
};

export default WidgetBookPage;
