import { baseSlice, baseSliceV2 } from './apiSlice';
import { IProfileTicketStoreState, ProfileTicketStoreState } from '../../store/ticket/ProfileTicketStoreState';
import { initTickets } from '../../store/userV1/actions';
import { tickets } from '../constants/constants';
import { IIssueTicketInfo, ITicketStoreState, TicketStoreState } from '../../store/ticket/types';
import { toCamel } from './eventsSlice';
import { initiateTickets } from '../../store/user/actions';
import { SendTicketType, TicketType } from '../models/tickets';

const ticketsApi = baseSlice.injectEndpoints({
  endpoints: (build) => ({
    getUserTicketsV1: build.query<IProfileTicketStoreState[], void>({
      query: () => tickets.my,
      transformResponse: (response: { Tickets: IProfileTicketStoreState[] }) =>
        response.Tickets.map((item) => new ProfileTicketStoreState(item)),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        dispatch(initTickets(data));
      },
    }),
    issueTicket: build.mutation<ITicketStoreState, IIssueTicketInfo>({
      query: (body) => ({
        url: tickets.issue,
        method: 'POST',
        body: JSON.stringify(body),
      }),
      transformResponse: (response) => new TicketStoreState(response),
    }),
  }),
});

export const { useLazyGetUserTicketsV1Query, useIssueTicketMutation } = ticketsApi;

const ticketsApiV2 = baseSliceV2.injectEndpoints({
  endpoints: (build) => ({
    getUserTickets: build.query<TicketType[], void>({
      query: () => tickets.my,
      transformResponse: (response) => toCamel(response),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        dispatch(initiateTickets(data));
      },
    }),
    sendTicket: build.mutation<void, SendTicketType>({
      query: (body) => ({
        url: tickets.send,
        method: 'POST',
        body: JSON.stringify(body),
      }),
    }),
  }),
});
export const { useLazyGetUserTicketsQuery, useSendTicketMutation } = ticketsApiV2;
