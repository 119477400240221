import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import { AppSettings } from './settings/appSettings';
import dayjs from 'dayjs';
import { EventHelper } from './helpers/eventHelper';
import { DomainType } from './store/enums';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      fallbackLng: 'en',
      preload: ['en', 'ru'],
      ns: ['translation'],
      defaultNS: 'translation',
      supportedLngs: ['en', 'ru'],
      debug: AppSettings.IS_DEV,
      backend: {
        loadPath: `${AppSettings.BASE_API_URL}/share/locales/{{lng}}/{{ns}}.json`,
      },
      react: {
        useSuspense: true,
      },
    },
    (err) => {
      if (err && AppSettings.IS_DEV) {
        return console.error(err);
      }
    }
  );

const isDomainRu = EventHelper.getDomainType(window.location.host) === DomainType.Ru;
if (isDomainRu) {
  i18n.changeLanguage('ru');
  dayjs.locale('ru');
} else {
  i18n.changeLanguage('en');
  dayjs.locale('en');
}

export default i18n;
