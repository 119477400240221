export interface IEventDetailsTicketQuotaStoreState {
  left: number;
  currency: string;
  price: number;
  isPrivate?: boolean;
  isAvailableForDistribution?: boolean;
}

export class EventDetailsTicketQuotaStoreState implements IEventDetailsTicketQuotaStoreState {
  left: number;
  currency: string;
  price: number;
  isPrivate?: boolean;
  isAvailableForDistribution?: boolean;

  constructor(dto?: any) {
    this.left = dto && dto.TicketsLeft ? dto.TicketsLeft : 0;
    this.currency = dto && dto.Currency ? dto.Currency : '';
    this.price = dto && dto.PricePerTicket ? dto.PricePerTicket : 0;
    this.isPrivate = dto && dto.IsPrivate ? dto.IsPrivate : null;
    this.isAvailableForDistribution = dto && dto.IsAvailableForDistribution ? dto.IsAvailableForDistribution : null;
  }
}
