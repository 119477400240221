import React from 'react';
import { ErrorContent } from './interfaceErrorContent';

type StateType = {
  hasError: boolean;
  error: any;
  errorInfo: any;
};

export class ErrorBoundary extends React.Component<any, StateType> {
  constructor(props) {
    super(props);
    this.state = { error: null, hasError: false, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo,
      hasError: true,
    });
  }

  render() {
    if (this.state.hasError) {
      return <ErrorContent error={this.state.error} errorInfo={this.state.errorInfo} />;
    }
    return this.props.children;
  }
}
