import React, { FC, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { IEventStoreState, ISeatStoreState } from '../../../../store/event/types';
import { IBookStoreState } from '../../../../store/bookV1/types';
import { FormError } from '../../../../components/forms';

export const WidgetError: FC<ErrorPropsType> = memo(({ seats, event, book }) => {
  const { t } = useTranslation();

  // const error = useSelector<IGlobalStoreState, IErrorStoreState>((state) => state.app.error);
  const [errors, setErrors] = useState<string>(null);
  //ToDo: create error handler
  // useEffect(() => {
  //   if (!error) {
  //     return;
  //   }
  //   setErrors(error?.details || error?.message);
  // }, [error?.details, error?.message]);

  useEffect(() => {
    const { maxPurchase } = event;
    const seatsCount = seats.length;

    if (seatsCount >= maxPurchase) {
      setErrors('WidgetBook.YouHaveReachedYourEventTicketPurchaseLimit');
    }
  }, [event?.maxPurchase, seats?.length]);

  if (!event || !seats || !book) {
    return null;
  }

  // return errors ? (
  //   <AnimatePresence>
  //     <ErrorWrapper variants={PopUp} key="form-error">
  //       {t(errors)}
  //     </ErrorWrapper>
  //   </AnimatePresence>
  // ) : null;
});

const ErrorWrapper = styled(FormError as any)`
  width: 100%;
`;

type ErrorPropsType = {
  event: IEventStoreState;
  seats: ISeatStoreState[];
  book: IBookStoreState;
};
