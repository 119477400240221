import React, { FC } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: 'rgb(28, 28, 28)',
    overflow: 'hidden auto',
    minHeight: '100vh',
  },
  button: {
    background: 'rgb(255, 191, 0)',
    width: '100%',
    height: '40px',
    borderRadius: '24px',
    color: 'white',
    fontSize: '14px',
    fontFamily:
      'Intro-Bold, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji',
    ':hover': {
      background: 'rgb(212, 161, 8)',
    },
  },
  buttonWrapper: {
    width: '100%',
    padding: '0px 35px',
  },
  content: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '768px',
    width: '100%',
    boxShadow: 'black 0px 0px 15px 2px',
    background: 'rgb(28, 28, 28)',
    position: 'relative',
  },
  message: {
    color: 'white',
    fontFamily:
      'Intro-Bold, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji',
  },
  image: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '100%',
    maxWidth: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  errorDetails: {
    whiteSpace: 'pre-wrap',
    color: 'white',
  },
}));

export const ErrorContent: FC<ErrorContentType> = ({ error, errorInfo }) => {
  const { classes } = useStyles();

  const customWindow: any = window;
  const isShow = customWindow.ENVIRONMENT === 'development';

  const handleButtonClick = () => {
    location.replace(location.origin);
  };

  return (
    <div className={classes.container}>
      <Grid container className={classes.content}>
        <img className={classes.image} src={'content/img/bg@3x.jpg'} alt={'bg@3x'} />
        <Grid container direction="column" alignItems="center" rowGap="20px" zIndex={1}>
          <Grid item>
            <Typography className={classes.message}>Oops, something went wrong</Typography>
          </Grid>
          <Grid item whiteSpace="pre-line" textAlign="center">
            <Typography className={classes.message} variant="body1" mb="20px">
              Please, reload the page.
            </Typography>
          </Grid>
          {isShow && (
            <details className={classes.errorDetails}>
              {error && error.toString()}
              <br />
              {errorInfo.componentStack}
            </details>
          )}
          <Grid item whiteSpace="pre-line" textAlign="center" className={classes.buttonWrapper}>
            <Button className={classes.button} onClick={handleButtonClick}>
              Go to main page
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

type ErrorContentType = {
  error: Error;
  errorInfo: any;
};
