import styled from 'styled-components';

export const Label = styled.label`
  margin-bottom: 0;
  font-size: 14px;
`;

interface IRadioLabel {
  checked: boolean;
  disabled?: boolean;
}

export const RadioLabel = styled(Label as any)<IRadioLabel>`
  display: flex;
  align-items: center;
  position: relative;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  user-select: none;
  padding: 20px 25px 20px 60px;
  width: 100%;
  background: #121212;
  border-bottom: 1px solid ${(props) => props.theme.colors.accent};
  opacity: ${(props) => props.disabled && 0.3};
  pointer-events: ${(props) => props.disabled && 'none'};

  > input {
    position: absolute;
    opacity: 0;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  }

  &:before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    left: 25px;
    top: 17px;
    border: 1px solid ${(props) => props.theme.colors.accent};
  }

  &:after {
    content: '';
    position: absolute;
    display: ${(props) => (props.checked ? 'block' : 'none')};
    width: 12px;
    height: 12px;
    left: 30px;
    top: 22px;
    background-color: ${(props) => props.theme.colors.accent};
    border-radius: 50%;
  }

  &:hover {
    filter: ${(props) => !props.disabled && 'brightness(0.7)'};
  }

  &:active {
    filter: ${(props) => !props.disabled && 'brightness(0.5)'};
  }
`;
