import React, { FC } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { setLocale } from 'yup';
import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { AnimatePresence } from 'framer-motion';
import { Button, TextInput } from '../UI';
import { FieldError, Fieldset, FieldWrapper } from './commonForm';
import Constants from '../../store/constants';
import { PopUp } from '../../store/animations';

export type ResetPasswordFormType = {
  password: string;
  passwordConfirm: string;
};

type ResetPasswordProps = {
  submitTitle: string;
  onSubmit: (ResetPasswordFormType) => void;
  isLoading: boolean;
};

export const ResetPasswordForm: FC<ResetPasswordProps> = ({ submitTitle, onSubmit, isLoading }) => {
  const { t } = useTranslation();

  setLocale({
    string: {
      min: ({ min }) => t('ResetPassword.MaxLengthOfPassword', { min }),
    },
  });

  const ValidationSchema = Yup.object().shape({
    password: Yup.string().required(t('ResetPassword.PasswordRequired')).min(Constants.MinPasswordLength),
    passwordConfirm: Yup.mixed().test(
      'match',
      t('ResetPassword.PasswordsNotMatch'),
      (passwordConfirm, context) => passwordConfirm === context.parent.password
    ),
  });

  const initialValues = {
    password: '',
    passwordConfirm: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values: ResetPasswordFormType) => {
        if (onSubmit) {
          onSubmit(values);
        }
      }}
      validationSchema={ValidationSchema}
    >
      {({ isValid, handleChange, handleBlur, values }) => (
        <StyledForm autoComplete="on">
          <Fieldset>
            <Field name="password" type="password">
              {({ field, form }) => (
                <FieldWrapper noted={!!form.touched.password && !!form.errors && !!form.errors.password}>
                  <TextInput
                    placeholder={t('ResetPassword.EnterNewPassword')}
                    type="password"
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    error={!!form.touched.password && !!form.errors && !!form.errors.password}
                    {...field}
                  />
                </FieldWrapper>
              )}
            </Field>
            <Field name="passwordConfirm" type="password">
              {({ field, form }) => (
                <FieldWrapper noted={!!form.touched.passwordConfirm && !!form.errors && !!form.errors.passwordConfirm}>
                  <TextInput
                    placeholder={t('ResetPassword.ConfirmNewPassword')}
                    type="password"
                    name="passwordConfirm"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.passwordConfirm}
                    error={!!form.touched.passwordConfirm && !!form.errors && !!form.errors.passwordConfirm}
                    {...field}
                  />
                  <AnimatePresence>
                    {!!form.touched.passwordConfirm && !!form.errors && !!form.errors.passwordConfirm && (
                      <FieldError key="error" variants={PopUp} initial={'enter'} animate={'exit'}>
                        {t(form.errors.passwordConfirm, { min: Constants.MinPasswordLength })}
                      </FieldError>
                    )}
                  </AnimatePresence>
                </FieldWrapper>
              )}
            </Field>
          </Fieldset>
          <StyledButton isLoading={isLoading} type="submit" disabled={!isValid}>
            {submitTitle}
          </StyledButton>
        </StyledForm>
      )}
    </Formik>
  );
};

const StyledForm = styled(Form)`
  width: 100%;
`;

const StyledButton = styled(Button)`
  margin-top: 20px;
`;
