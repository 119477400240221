import { Grid, Skeleton, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { FC, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Events } from '../eventsV2/events';
import { GenreType } from '../../core/models/events';
import { useLazyGetEventsByGenreQuery } from '../../core/api/eventsSlice';
import { GenreEventsSkeleton } from '../skeletons/genreEventsSkeleton';

const useStyles = makeStyles()(() => ({
  gridTitle: {
    fontSize: 14,
    lineHeight: '14px',
    textTransform: 'uppercase',
    display: 'inline-block',
    '&:hover': {
      textDecoration: 'none',
      cursor: 'pointer',
    },
  },
}));

export const GenresEventsCarousel: FC<GenreEventsCarouselType> = memo(({ genres, isLoadingGenres }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const [getEventsByGenreRequest] = useLazyGetEventsByGenreQuery();

  const loadGenreEvents = useCallback(async (genreId: number) => {
    const { data } = await getEventsByGenreRequest(genreId);
    return data;
  }, []);

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center" spacing={0}>
      {isLoadingGenres && <GenreEventsSkeleton />}
      {!isLoadingGenres &&
        genres
          .slice()
          .sort((a, b) => b.eventsCount - a.eventsCount)
          .map((genre) => (
            <Grid key={genre.id} item lg={12} xs={12} sm={12} md={12} xl={12}>
              <div>
                {!genres.length && (
                  <Typography variant="h6" className={classes.gridTitle}>
                    <Skeleton variant="rectangular" width={140} height={25} />
                  </Typography>
                )}
                {genres.length && (
                  <Typography variant="h6" className={classes.gridTitle}>
                    {genre.title}
                  </Typography>
                )}
              </div>
              <Events load={() => loadGenreEvents(genre.id)} />
            </Grid>
          ))}
    </Grid>
  );
});

type GenreEventsCarouselType = {
  genres: GenreType[];
  isLoadingGenres: boolean;
};
