import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AlertNotificationType, AppStoreState } from './types';

const appReducer = createSlice({
  name: 'appReducer',
  initialState: new AppStoreState(),
  reducers: {
    init: (state) => {
      return { ...state, isInitializing: false, isRedirection: false };
    },
    finalize: (state) => {
      return { ...state, isFinalized: true };
    },
    AppAuthorize: (state) => {
      return { ...state, isAuthorized: true };
    },
    AppDeAuthorize: (state) => {
      return { ...state, isAuthorized: false };
    },
    setError: (state, action: PayloadAction<AlertNotificationType>) => {
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = null;
    },
    redirect: (state) => {
      return { ...state, isRedirection: false };
    },
  },
});

export default appReducer;
