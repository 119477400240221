import React, { FC, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { useLocation } from 'react-router';
import { Chip, ClickAwayListener, Grid, Typography } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DropDownPaper } from './dropDownPaper';
import { useUtils } from '../../services/useUtils';
import { FilterParams } from '../../store/enums';

type StyleProps = {
  border: string;
  display: string;
  paddingRight: string;
};

const useStyles = makeStyles<StyleProps>()((theme, { border, display, paddingRight }) => ({
  container: {
    position: 'relative',
  },
  selectSortTitle: {
    paddingLeft: 4,
    color: theme.palette.secondary.main,
    fontSize: '0.75rem',
    [theme.breakpoints.down(425)]: {
      fontSize: '0.625rem',
    },
  },
  sortChip: {
    color: 'inherit',
    border,
    paddingLeft: '10px',
    '.MuiChip-icon': {
      marginRight: 0,
      marginLeft: 0,
    },
    '.MuiChip-label': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    ':hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.secondary.dark,
    },
    '& .MuiChip-deleteIcon': {
      display,
      color: 'rgba(255, 255, 255, 0.26)',
      '&:hover': {
        color: 'rgba(255, 255, 255, 0.4)',
      },
    },
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  listItem: {
    padding: '0 0 0 0',
  },
  sortTitle: {
    padding: '0 8px',
    fontSize: '0.75rem',
    paddingLeft: '3px',
    paddingRight,
    [theme.breakpoints.down(425)]: {
      fontSize: '0.625rem',
    },
  },
  link: {
    color: 'white',
    textDecoration: 'none',
    fontSize: '0.75rem',
    ':visited': {
      color: 'white',
    },
    ':hover': {
      color: theme.palette.secondary.main,
    },
  },
  paper: {
    position: 'absolute',
    marginTop: '4px',
    padding: '17px 24px',
    background: 'linear-gradient(180.62deg, rgba(28, 28, 28, 1) -91.79%, rgba(18, 17, 17, 1) 157.66%)',
    boxShadow: '4px -4px 16px rgb(0 0 0 / 63%), -4px 4px 16px rgba(0, 0, 0, 0.63)',
    borderRadius: '12px',
    zIndex: 1,
  },
}));

export const QuantityButton: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isOpen, setIsOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const paramQuantity = +searchParams.get(FilterParams.Quantity);
  const [selectTitle, setSelectTitle] = useState(paramQuantity);

  const location = useLocation();
  const ref = useRef(null);
  const { getTicketsPostfixByCount } = useUtils();

  const borderStyle = {
    border: isActive ? '1px solid #FFBF00' : '1px solid rgba(107, 107, 107, 0.6)',
    display: selectTitle ? 'inherit' : 'none',
    paddingRight: selectTitle ? '10px' : '20px',
  };
  const { classes } = useStyles(borderStyle);

  useEffect(() => {
    setIsActive(searchParams.has(FilterParams.Quantity));
    if (!searchParams.has(FilterParams.Quantity)) {
      setSelectTitle(null);
    }
  }, [location.search]);

  const handleOpenClick = () => setIsOpen((open) => !open);
  const handleAwayClick = () => setIsOpen(false);

  const handleQuantitySelect = (quantity: number) => {
    setSearchParams((params) => {
      params.set(FilterParams.Quantity, `${quantity}`);
      return params;
    });

    setSelectTitle(quantity);
    setIsOpen(false);
  };

  const handleDeleteQuantity = () => {
    setSearchParams((params) => {
      params.delete(FilterParams.Quantity);
      return params;
    });
    setSelectTitle(null);
    setIsOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleAwayClick} ref={ref.current}>
      <Grid className={classes.container}>
        <Chip
          className={classes.sortChip}
          label={
            <Typography component={'span'} className={classes.sortTitle}>
              {selectTitle ? `${selectTitle} ${getTicketsPostfixByCount(selectTitle)}` : `Quantity`}
            </Typography>
          }
          icon={isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          onDelete={handleDeleteQuantity}
          onClick={handleOpenClick}
        />
        {isOpen && (
          <DropDownPaper
            ref={ref}
            selectTitle={selectTitle}
            data={[1, 2, 3, 4, 5]}
            onChangeClick={handleQuantitySelect}
          />
        )}
      </Grid>
    </ClickAwayListener>
  );
};
