import styled from 'styled-components';

interface IPageHeaderProps {
  diagonal?: boolean;
  stretch?: boolean;
}

export const PageHeader = styled('header')<IPageHeaderProps>`
  padding: 20px 35px;
  display: flex;
  flex-direction: column;
  min-height: 300px;
  max-height: ${(props) => !props.stretch && '50vh'};
  height: ${(props) => !props.stretch && '110vw'};
  align-items: center;
  justify-content: flex-end;
  position: relative;

  &:after {
    content: ${(props) => (props.diagonal ? '""' : 'none')};
    position: absolute;
    border-bottom: 110px solid ${(props) => props.theme.colors.bg};
    border-left: ${(props) => props.theme.maxWidth} solid transparent;
    bottom: 0;
    left: 0;
    right: 0;
  }

  flex: ${(props) => props.stretch && 1};
`;
