import React, { FC } from 'react';
import { Skeleton } from '@mui/material';
import { Count, IItemProps, ItemContainer, StatsList, Title } from '../userV1';
import { AvatarWrapper, ButtonsWrapper, FullName, UserInfo } from '../../containers/pages';

const Item: FC<IItemProps> = ({ icon }) => (
  <ItemContainer icon={icon}>
    <Title>
      <Skeleton animation={'wave'} height={13} width={60} sx={{ margin: 'auto', transform: 'scale(1)' }} />
    </Title>
    <Count>
      <Skeleton animation={'wave'} height={25} width={35} sx={{ margin: 'auto', transform: 'scale(1)' }} />
    </Count>
  </ItemContainer>
);

export const ProfileSkeletonV1 = () => {
  const avatarOverrideStyle = {
    borderRadius: '50%',
    transform: 'scale(1)',
  };

  const buttonOverrideStyle = {
    height: '45px',
    width: '100%',
    borderRadius: '50px',
    transform: 'scale(1)',
    marginTop: '10px',
  };

  return (
    <>
      <AvatarWrapper>
        <Skeleton animation={'wave'} height={130} width={130} sx={avatarOverrideStyle} />
      </AvatarWrapper>
      <UserInfo>
        <FullName>
          <Skeleton animation={'wave'} height={30} width={155} sx={{ margin: 'auto' }} />
        </FullName>
        <StatsList>
          <Item icon={'meet'} />
          <Item icon={'soldTicket'} />
        </StatsList>
        <StatsList>
          {Array.from(new Array(2)).map((item, index) => (
            <Item key={index} icon={'star'} />
          ))}
        </StatsList>
      </UserInfo>
      <ButtonsWrapper>
        <Skeleton animation={'wave'} sx={buttonOverrideStyle} />
        <Skeleton animation={'wave'} sx={buttonOverrideStyle} />
      </ButtonsWrapper>
    </>
  );
};
