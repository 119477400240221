export interface IAppSettingsState {
  showLogo: boolean;
  useNewDashboard: boolean;
  showIntercom: boolean;
  showDashboardDatepicker: boolean;
  showDashboardPopularEvents: boolean;
  showDashboardCategories: boolean;
  showDashboardPopularTours: boolean;
  showDashboardEventsByCategories: boolean;
  dashboardCarouselColumnEventCount: number;
  dashboardCarouselEventCount: number;
  bookSchemeZoomFactor: number;
  bookSchemeMaxZoom: number;
  bookSchemeMinZoom: number;
}

export class AppSettingsState implements IAppSettingsState {
  showLogo: boolean;
  useNewDashboard: boolean;
  showIntercom: boolean;
  showDashboardDatepicker: boolean;
  showDashboardPopularEvents: boolean;
  showDashboardCategories: boolean;
  showDashboardPopularTours: boolean;
  showDashboardEventsByCategories: boolean;
  dashboardCarouselColumnEventCount: number;
  dashboardCarouselEventCount: number;
  bookSchemeZoomFactor: number;
  bookSchemeMaxZoom: number;
  bookSchemeMinZoom: number;

  constructor(dto?: any) {
    this.showLogo = dto && dto.ShowLogo ? dto.ShowLogo : false;
    this.useNewDashboard = dto && dto.UseNewDashboard ? dto.UseNewDashboard : false;
    this.showIntercom = dto && dto.ShowIntercom ? dto.ShowIntercom : false;
    this.showDashboardDatepicker = dto && dto.ShowDashboardDatepicker ? dto.ShowDashboardDatepicker : false;
    this.showDashboardPopularEvents = dto && dto.ShowDashboardPopularEvents ? dto.ShowDashboardPopularEvents : false;
    this.showDashboardCategories = dto && dto.ShowDashboardCategories ? dto.ShowDashboardCategories : false;
    this.showDashboardPopularTours = dto && dto.ShowDashboardPopularTours ? dto.ShowDashboardPopularTours : false;
    this.showDashboardEventsByCategories =
      dto && dto.ShowDashboardEventsByCategories ? dto.ShowDashboardEventsByCategories : false;
    this.dashboardCarouselColumnEventCount =
      dto && dto.DashboardCarouselColumnEventCount ? dto.DashboardCarouselColumnEventCount : 0;
    this.dashboardCarouselEventCount = dto && dto.DashboardCarouselEventCount ? dto.DashboardCarouselEventCount : 0;
    this.bookSchemeZoomFactor = dto && dto.BookSchemeZoomFactor ? dto.BookSchemeZoomFactor : 0.1;
    this.bookSchemeMaxZoom = dto && dto.BookSchemeMaxZoom ? dto.BookSchemeMaxZoom : 3;
    this.bookSchemeMinZoom = dto && dto.BookSchemeMinZoom ? dto.BookSchemeMinZoom : 0.5;
  }
}
