import { checkoutReducer } from './reducers';

export const {
  setEmail,
  finishPaymentComplete,
  clearPayment,
  setPaymentMethod,
  finishPaymentProcess,
  paymentProcess,
  paymentInitiate,
  setPoints,
  redo,
} = checkoutReducer.actions;
