import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Header, Title } from '../checkout';
import { PageContent } from '../../../components/UI';
import { EmailForm, IEmailForm } from '../../../components/forms';
import { IUserStoreState } from '../../../store/userV1/types';
import { CheckoutType } from '../../../store/checkout/types';
import { DistributionType } from '../../../store/distribution/types';
import { useCheckoutHelpers } from '../../../helpers/customHooks';

export const EmailCheckoutForm: FC<EmailFormType> = memo(
  ({ checkout, isAuthorized, checkoutSteps, user, distribution, handleEmailFormSubmit }) => {
    const { t } = useTranslation();
    const { getDistributionInfo } = useCheckoutHelpers();

    const distributionInfo = getDistributionInfo(checkout, distribution);
    const email = user ? user.email : checkout.email;
    const title = isAuthorized ? t('Checkout.BookTicketsNoteAuthorized') : t('Checkout.BookTicketsNote');

    return (
      <PageContent roundCorners>
        <Header>
          {t('Checkout.Step')} 1 {t('Checkout.Of')} {checkoutSteps}
        </Header>
        <Title>{title}</Title>
        <EmailForm
          email={email}
          emailPlaceholder={t('Checkout.EnterEmail')}
          emailConfirmPlaceholder={t('Checkout.RepeatEmail')}
          warning={t(distributionInfo, { distributor: distribution.distributorSlug })}
          submitTitle={t('Checkout.Next')}
          onSubmit={(formModel: IEmailForm) => handleEmailFormSubmit(formModel)}
        />
      </PageContent>
    );
  }
);

type EmailFormType = {
  checkout: CheckoutType;
  checkoutSteps: number;
  distribution: DistributionType;
  handleEmailFormSubmit: (formModel: IEmailForm) => void;
  user: IUserStoreState;
  isAuthorized: boolean;
};
