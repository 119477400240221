import React, { FC } from 'react';
import { ListItem } from './eventCheckoutHeaderV1';
import { EventInfoItem } from '../eventInfoItem';

type TicketsGroupInfoType = {
  title: string;
};

export const TicketsGroupInfo: FC<TicketsGroupInfoType> = ({ title }) => (
  <ListItem>
    <EventInfoItem icon={'person'} themed title={title} />
  </ListItem>
);
