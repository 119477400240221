import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DistributionType } from './types';

const initialState: DistributionType = {
  distributorSlug: null,
  expirationDate: null,
};

export const distributionSlice = createSlice({
  name: 'distributionSlice',
  initialState,
  reducers: {
    init: (state, action: PayloadAction<DistributionType>) => {
      return action.payload;
    },
    distributionClear: () => {
      return initialState;
    },
  },
});
