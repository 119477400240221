import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { PopUp } from '../../../../store/animations';
import { IProfileTicketQuotaStoreState } from '../../../../store/ticket/ProfileTicketQuotaStoreState';
import { EmailForm, IEmailForm } from '../../../../components/forms';
import { Subtitle } from '../userTicketDetailsPage';

export const RenderForm: FC<RenderFormPropsType> = memo(({ handleEmailFormSubmit }) => {
  const { t } = useTranslation();

  return (
    <FormWrapper key="form-wrapper" variants={PopUp} initial={'enter'} animate={'exit'}>
      <Label>{t('TicketDetails.TheTicketWillBeSentToEmail')}</Label>
      <EmailForm
        emailPlaceholder={t('TicketDetails.EnterTheRecipientsEmailAddress')}
        emailConfirmPlaceholder={t('TicketDetails.RetryRecipientEmail')}
        submitTitle={t('TicketDetails.SendTicket')}
        onSubmit={(formModel: IEmailForm) => handleEmailFormSubmit(formModel)}
      />
    </FormWrapper>
  );
});

export const RenderIssuedInfo: FC<{ issuedTo: string }> = memo(({ issuedTo }) => {
  const { t } = useTranslation();
  return (
    <InfoWrapper key="info" variants={PopUp} initial={'enter'} animate={'exit'}>
      <InfoItem>{t('TicketDetails.TheTicketWasSentByEmail')}</InfoItem>
      <InfoItem>{issuedTo}</InfoItem>
    </InfoWrapper>
  );
});

export const RenderTicketSector: FC<TicketSectorPropsType> = memo(({ ticketQuota, rowTitle, seatTitle }) => {
  const { t } = useTranslation();

  if (!ticketQuota) {
    return null;
  }

  let title = ticketQuota.sector ? ticketQuota.sector.title : ticketQuota.title;
  if (rowTitle) {
    title += ` ${t('TicketDetails.Row')} ${rowTitle}`;
  }
  if (seatTitle) {
    title += ` ${t('TicketDetails.Seat')} ${seatTitle}`;
  }
  return <Subtitle>{title}</Subtitle>;
});

const Label = styled.h3`
  font-size: 12px;
  margin: 0 0 15px 0;
  text-align: center;
`;

const InfoWrapper = styled(motion.div)`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const InfoItem = styled.p`
  font-size: 14px;
  text-align: center;
  margin: 0;
  padding: 0;
  line-height: 19px;
`;

const FormWrapper = styled(motion.div)``;

type TicketSectorPropsType = {
  ticketQuota: IProfileTicketQuotaStoreState;
  rowTitle: string;
  seatTitle: string;
};

type RenderFormPropsType = {
  handleEmailFormSubmit: (formModel: IEmailForm) => void;
};
