import dayjs from 'dayjs';
import { IProfileTicketQuotaStoreState, ProfileTicketQuotaStoreState } from './ProfileTicketQuotaStoreState';
import { EventDetailsStoreState, IEventDetailsStoreState } from '../event/eventDetails/EventDetailsStoreState';

export interface IProfileTicketStoreState {
  masterTicketId: string;
  ticketId: string;
  status: string;
  issuedTo: string;
  saleUser: string;
  currency: string;
  price: number;
  issuedAt: dayjs.Dayjs;
  event: IEventDetailsStoreState;
  sentAt?: dayjs.Dayjs;
  ticketQuota?: IProfileTicketQuotaStoreState;
  rowTitle?: string;
  seatTitle?: string;
  seatId?: number;
  token: string;
  expiredPaymentTime: dayjs.Dayjs;
  percentageComission: number;
  comissionDescription: string;
  priceComission: number;
}

export class ProfileTicketStoreState implements IProfileTicketStoreState {
  masterTicketId: string;
  ticketId: string;
  status: string;
  issuedTo: string;
  saleUser: string;
  currency: string;
  price: number;
  issuedAt: dayjs.Dayjs;
  event: IEventDetailsStoreState;
  sentAt?: dayjs.Dayjs;
  ticketQuota?: IProfileTicketQuotaStoreState;
  rowTitle?: string;
  seatTitle?: string;
  seatId?: number;
  token: string;
  expiredPaymentTime: dayjs.Dayjs;
  percentageComission: number;
  comissionDescription: string;
  priceComission: number;

  constructor(dto?: any) {
    this.masterTicketId = dto && dto.MasterTicketId ? dto.MasterTicketId : '';
    this.ticketId = dto && dto.TicketId ? dto.TicketId : '';
    this.status = dto && dto.Status ? dto.Status : '';
    this.issuedTo = dto && dto.IssuedTo ? dto.IssuedTo : '';
    this.currency = dto && dto.Currency ? dto.Currency : '';
    this.price = dto && dto.Price ? dto.Price : 0;
    this.issuedAt = dto && dto.IssuedAt ? dayjs(dto.IssuedAt) : dayjs();
    this.event = dto && dto.Event ? new EventDetailsStoreState(dto.Event) : null;
    this.sentAt = null;
    this.ticketQuota = dto && dto.TicketQuota ? new ProfileTicketQuotaStoreState(dto.TicketQuota) : null;
    this.rowTitle = dto && dto.RowTitle ? dto.RowTitle : '';
    this.seatTitle = dto && dto.SeatTitle ? dto.SeatTitle : '';
    this.seatId = dto && dto.SeatId ? dto.SeatId : 0;
    this.saleUser = dto && dto.SaleUser ? dto.SaleUser : '';
    this.token = dto && dto.Token ? dto.Token : '';
    this.expiredPaymentTime = dto && dto.ExpiredPaymentTime ? dayjs(dto.ExpiredPaymentTime) : dayjs();
    this.percentageComission = dto && dto.PercentageComission ? dto.PercentageComission : null;
    this.comissionDescription = dto && dto.ComissionDescription ? dto.ComissionDescription : '';
    this.priceComission = dto && dto.PriceComission ? dto.PriceComission : '';
  }
}
