import * as ReactGA from 'react-ga';
import { baseSlice, baseSliceV2 } from './apiSlice';
import {
  IPaymentCancel,
  IPaymentComplete,
  IPaymentCompleteResult,
  IPaymentInitiateInfo,
  IPaymentInitiateResult,
  IPaymentProcessInfo,
  IPaymentProcessResult,
  PaymentCompleteResult,
  PaymentInitiateResult,
  PaymentProcessResult,
} from '../../store/paymentV1/types';
import EventHelper from '../../helpers/eventHelper';
import { AppSettings } from '../../settings/appSettings';
import {
  clearPayment,
  finishPaymentComplete,
  finishPaymentProcess,
  paymentInitiate,
} from '../../store/checkout/actions';
import { finishPaymentInitV1, finishPaymentProcessV1, paymentInitV1 } from '../../store/checkoutV1/actions';
import { GlobalStateType } from '../../configureStore';
import { payment } from '../constants/constants';
import {
  PaymentInitiate,
  PaymentInitiateResponse,
  PaymentProcessResponseType,
  PaymentProcessType,
} from '../models/payment';
import { toCamel } from './eventsSlice';
import { clearBook } from '../../store/book/actions';
import { InitiateType } from '../../store/checkout/types';

const paymentApi = baseSlice.injectEndpoints({
  endpoints: (build) => ({
    initiateV1: build.mutation<IPaymentInitiateResult, IPaymentInitiateInfo>({
      query: (body: IPaymentInitiateInfo) => {
        const domain = window.location.hostname;
        const resultDomainType = EventHelper.getDomainType(domain);

        return {
          url: `/payments/initiate?dtype=${resultDomainType}`,
          method: 'POST',
          body: JSON.stringify(body),
        };
      },
      transformResponse: (response) => new PaymentInitiateResult(response),
      onQueryStarted: async (arg, { queryFulfilled, dispatch, getState }) => {
        dispatch(paymentInitV1(arg));
        const { data } = await queryFulfilled;
        const { bookV1 } = getState() as GlobalStateType;

        data.event = bookV1.event;
        dispatch(finishPaymentInitV1(data));
      },
    }),
    processV1: build.mutation<IPaymentProcessResult, IPaymentProcessInfo>({
      query: (body: IPaymentProcessInfo) => {
        const domain = window.location.hostname;
        const resultDomainType = EventHelper.getDomainType(domain);
        return {
          url: `/payments/process?dtype=${resultDomainType}`,
          method: 'POST',
          body: JSON.stringify(body),
        };
      },
      transformResponse: (response: IPaymentProcessResult) => new PaymentProcessResult(response),
      onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
        const { data } = await queryFulfilled;
        if (!AppSettings.IS_DEV) {
          ReactGA.event({
            category: 'Payment',
            action: 'Process',
          });
        }
        dispatch(finishPaymentProcessV1(data));
      },
    }),
  }),
});
export const { useInitiateV1Mutation, useProcessV1Mutation } = paymentApi;

const paymentApiV2 = baseSliceV2.injectEndpoints({
  endpoints: (build) => ({
    complete: build.mutation<IPaymentCompleteResult, IPaymentComplete>({
      query: (body: IPaymentComplete) => ({
        url: payment.complete,
        method: 'POST',
        body: JSON.stringify(body),
      }),
      transformResponse: (response: IPaymentCompleteResult) => new PaymentCompleteResult(response),
      onQueryStarted: async (arg, { queryFulfilled, dispatch }) => {
        const { data } = await queryFulfilled;

        if (!AppSettings.IS_DEV && data.isCompleted && data.isSuccess) {
          ReactGA.event({
            category: 'Payment',
            action: 'Success',
          });
        }

        dispatch(finishPaymentComplete(data));
      },
    }),
    cancel: build.mutation<IPaymentCompleteResult, IPaymentCancel>({
      query: (body: IPaymentCancel) => ({
        url: payment.cancel,
        method: 'POST',
        body: JSON.stringify(body),
      }),
      transformResponse: (response: IPaymentCompleteResult) => new PaymentCompleteResult(response),
      onQueryStarted: async (arg, { queryFulfilled, dispatch }) => {
        dispatch(clearPayment());
        dispatch(clearBook());

        const { data } = await queryFulfilled;
        dispatch(finishPaymentComplete(data));
      },
    }),
    initiate: build.mutation<PaymentInitiateResponse, PaymentInitiate>({
      query: (body: PaymentInitiate) => {
        const domain = window.location.hostname;
        const dtype = EventHelper.getDomainType(domain);

        return {
          url: payment.initiate,
          method: 'POST',
          body: JSON.stringify(body),
          params: { dtype },
        };
      },
      transformResponse: (response) => toCamel(response),
      onQueryStarted: async (arg, { queryFulfilled, dispatch, getState }) => {
        const { data } = await queryFulfilled;
        const { book } = getState() as GlobalStateType;

        const initiate: InitiateType = {
          event: book.event,
          ticketGroups: book.ticketGroups,
          ...data,
        };

        dispatch(paymentInitiate(initiate));
      },
    }),
    process: build.mutation<PaymentProcessResponseType, PaymentProcessType>({
      query: (body: PaymentProcessType) => {
        const domain = window.location.hostname;
        const dtype = EventHelper.getDomainType(domain);
        return {
          url: `/payments/process`,
          method: 'POST',
          body: JSON.stringify(body),
          params: { dtype },
        };
      },
      transformErrorResponse: () => ({
        status: 412,
        data: {
          Details: 'This ticket has already been selected by another user. Back to seat selection',
        },
      }),
      transformResponse: (response: PaymentProcessResponseType) => toCamel(response),
      onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
        const { data } = await queryFulfilled;
        if (!AppSettings.IS_DEV) {
          ReactGA.event({
            category: 'Payment',
            action: 'Process',
          });
        }
        dispatch(finishPaymentProcess(data));
      },
    }),
  }),
});

export const { useCompleteMutation, useCancelMutation, useInitiateMutation, useProcessMutation } = paymentApiV2;
