import dayjs from 'dayjs';
import { UserType } from './types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TicketType } from '../../core/models/tickets';
import { UserEventType } from '../../core/models/events';

const initialState: UserType = {
  email: '',
  emailConfirmed: false,
  phoneNumber: '',
  phoneNumberConfirmed: false,
  fullName: '',
  referralSlug: '',
  referredBySlug: '',
  imageUrl: '',
  stats: null,
  phoneCodeSentAt: null,
  phoneCodeSentMessage: null,
};

export const userReducer = createSlice({
  name: 'userReducer',
  initialState,
  reducers: {
    userInitiate: (state, action: PayloadAction<UserType>) => {
      return action.payload;
    },
    updateAccount: (state, action: PayloadAction<UserType>) => {
      return action.payload;
    },
    clear: () => {
      return initialState;
    },
    sendPhoneCode: (state, action) => {
      return { ...state, phoneCodeSentMessage: action.payload, phoneCodeSentAt: dayjs() };
    },
    phoneCurrentUpdate: (state, action) => {
      return { ...state, phoneNumber: action.payload, phoneNumberConfirmed: false };
    },
  },
});

export const userEventsReducer = createSlice({
  name: 'userEventsReducer',
  initialState: [] as UserEventType[],
  reducers: {
    initDistributionEvents: (state, action: PayloadAction<UserEventType[]>) => {
      return action.payload;
    },
    clearEvents: () => {
      return [];
    },
  },
});

export const userTicketsReducer = createSlice({
  name: 'userTicketsReducer',
  initialState: [] as Array<TicketType>,
  reducers: {
    initiateTickets: (state, action: PayloadAction<TicketType[]>) => {
      const tickets = action.payload.filter((t: TicketType) => !t.ticketId);
      for (const newTicket of action.payload.filter((t: TicketType) => !!t.ticketId)) {
        let ticketToAdd = state.find((t: TicketType) => t.ticketId === newTicket.ticketId);
        if (ticketToAdd) {
          ticketToAdd = { ...ticketToAdd, ...newTicket };
        } else {
          ticketToAdd = newTicket;
        }
        tickets.push(ticketToAdd);
      }
      return tickets;
    },
    clearTickets: () => {
      return [];
    },
  },
});
