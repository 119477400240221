import * as React from 'react';
import { FC } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { Image, PageHeader } from '../../UI';
import { ICheckoutStoreState } from '../../../store/checkoutV1/types';
import { RenderPriceInfoV1 } from './renderPriceInfoV1';
import { RenderTicketsInfoV1 } from './renderTicketsInfoV1';
import EventInfoItemV1 from '../eventInfoItemV1';

export const EventCheckoutHeaderV1: FC<EventCheckoutHeaderProps> = ({ checkout, isAuthorized }) => {
  const { event } = checkout.init;
  let posterUrl = event.optionPosterUrl;

  if (!posterUrl) {
    posterUrl = event.posterUrl;
  }

  return (
    <Header>
      <Poster src={posterUrl} />
      <InfoWrapper>
        <Title>{event.title}</Title>
        <List>
          <ListItem>
            <EventInfoItemV1
              icon="calendar"
              themed
              title={dayjs(event.date).format('DD MMMM YYYY')}
              subtitle={dayjs(event.date).format('LT')}
            />
          </ListItem>
          {event.venue && (
            <ListItem>
              <EventInfoItemV1
                icon="location"
                themed
                title={event.venue.title}
                subtitle={`${event.venue.address}, ${event.venue.city}`}
              />
            </ListItem>
          )}
          <RenderTicketsInfoV1 tickets={checkout.init.tickets} />
          <RenderPriceInfoV1 checkoutModel={checkout} isAuthorized={isAuthorized} />
        </List>
      </InfoWrapper>
    </Header>
  );
};

const InfoWrapper = styled.div`
  z-index: 1;
  width: 100%;
  margin-bottom: 4vh;
`;

const Title = styled.h1`
  font-size: 36px;
  margin: 0 0 20px 0;
  text-align: center;
  font-family: ${(props) => props.theme.fonts.bolderFont};
`;

const Poster = styled(Image as any)`
  position: absolute;
  object-position: 50% 20%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  padding-left: 50px;
`;

export const ListItem = styled.li`
  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;

export const Header = styled(PageHeader as any)`
  max-height: 60vh;
`;

type EventCheckoutHeaderProps = {
  checkout: ICheckoutStoreState;
  isAuthorized: boolean;
};
