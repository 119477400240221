import { CheckoutType, InitiateType, ProcessType } from './types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPaymentCompleteResult } from '../paymentV1/types';

const Checkout: CheckoutType = {
  initiate: null,
  process: null,
  complete: null,
  isRedoing: false,
  email: null,
  paymentMethod: null,
  returnUrl: null,
  returnErrorUrl: null,
  retailWebsite: null,
  usePoints: false,
};

const initialState: CheckoutType = Checkout;
export const checkoutReducer = createSlice({
  name: 'checkoutReducer',
  initialState,
  reducers: {
    paymentInitiate: (state, action: PayloadAction<InitiateType>) => {
      return { ...state, initiate: action.payload };
    },
    paymentProcess: (state, action) => {
      return { ...state, ...action.payload };
    },
    finishPaymentProcess: (state, action: PayloadAction<ProcessType>) => {
      return { ...state, process: action.payload };
    },
    finishPaymentComplete: (state, action: PayloadAction<IPaymentCompleteResult>) => {
      return { ...state, complete: action.payload };
    },
    clearPayment: () => {
      return initialState;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    redo: (state) => {
      return { ...state, isRedoing: true };
    },
    setPoints: (state, action: PayloadAction<boolean>) => {
      state.usePoints = action.payload;
    },
    setPaymentMethod: (state, action: PayloadAction<string>) => {
      return { ...state, paymentMethod: action.payload };
    },
  },
});
