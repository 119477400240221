import dayjs from 'dayjs';
import Constants from '../constants';
import { distributionSlice } from './reducers';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { GlobalStateType } from '../../configureStore';

export const distributionInit = createAsyncThunk<void, string, { state: GlobalStateType }>(
  'distribution/distributionInit',
  (distributorSlug: string, { dispatch, getState }) => {
    const state = getState();

    const isDistributorSlugExisted = !!state.distribution && state.distribution.distributorSlug === distributorSlug;
    if (!distributorSlug || isDistributorSlugExisted) {
      return;
    }
    const expirationDate = dayjs().add(Constants.DistributionExpirationMins, 'm');

    dispatch(init({ distributorSlug, expirationDate }));
  }
);

export const { init, distributionClear } = distributionSlice.actions;
