import React, { FC } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';

import { useTranslation } from 'react-i18next';
import Constants from '../../store/constants';
import { RLink } from '../UI';
import { SellStatus } from '../../store/enums';
import Timer from '../timer/timer';
import { IProfileTicketStoreState } from '../../store/ticket/ProfileTicketStoreState';
import { useRoutesHelper } from '../../helpers/routesHelper';

const UserTicketItem: FC<UserTicketItemPropsType> = ({ ticket, go, cancelSellTicket }) => {
  const { t } = useTranslation();
  const { getUserTicketRouteV1, getUserTicketSellRouteV1, getUserTicketPurchaseRouteV1, getUserTicketsRouteV1 } =
    useRoutesHelper();

  const { event } = ticket;
  const isSale = ticket.status === SellStatus.Sale || ticket.status === SellStatus.InitiatedSale;
  const isSold = ticket.status === SellStatus.Sold;
  const isOffered = ticket.status === SellStatus.OfferedForPurchase;
  const paymentInProcess = ticket.status === SellStatus.PaymentInProcess;
  const paymentDeclined = ticket.status === SellStatus.PaymentDeclined;

  const renderIssueFooter = (ticket: IProfileTicketStoreState) => {
    const isIssued = !!ticket.issuedTo;
    const issueTitle = !isIssued ? t('MyTickets.GetTicket') : t('MyTickets.TicketIssued');
    const sellTitle = t('MyTickets.SellTicket');
    const ticketRoute = getUserTicketRouteV1(ticket.masterTicketId, ticket.ticketId);
    const ticketSellRoute = getUserTicketSellRouteV1(ticket.masterTicketId, ticket.ticketId);
    const isBought = ticket.status === SellStatus.Bought;
    return (
      <Footer>
        {isBought && (
          <InfoItem>
            {t('MyTickets.TicketPurchasedFrom')} {ticket.saleUser}
          </InfoItem>
        )}
        <Link to={ticketRoute} title={issueTitle}>
          <Action disabled={isIssued}>{issueTitle}</Action>
        </Link>
        {false && !isIssued && (
          <Link to={ticketSellRoute} title={issueTitle}>
            <Action disabled={isIssued}>{sellTitle}</Action>
          </Link>
        )}
      </Footer>
    );
  };

  const renderSaleFooter = (ticket: IProfileTicketStoreState) => {
    const title = t('MyTickets.CancelSell');
    const ticketRoute = '';
    return (
      <Footer>
        <InfoItem>
          {t('MyTickets.PaymentExpectedFrom')} {ticket.saleUser}
        </InfoItem>
        <Link to={ticketRoute} title={title} onClick={(e) => handleCancelSell(e)}>
          <Action disabled={false}>{title} </Action>
        </Link>
      </Footer>
    );
  };

  const renderPaymentInProcessInfo = () => (
    <Footer>
      <InfoItem>{t('MyTickets.PaymentProcessing')}</InfoItem>
    </Footer>
  );

  const renderPaymentDeclinedInfo = () => (
    <Footer>
      <InfoItem>{t('MyTickets.PaymentErrorOccurredContactSupportTeam')}</InfoItem>
    </Footer>
  );

  const renderSoldInfo = (ticket: IProfileTicketStoreState) => (
    <Footer>
      <InfoItem>
        {t('MyTickets.TicketSoldToUser')} {ticket.saleUser}
      </InfoItem>
    </Footer>
  );

  const renderOfferFooter = (ticket: IProfileTicketStoreState) => {
    const title = t('MyTickets.PayTicket');
    const ticketRoute = getUserTicketPurchaseRouteV1(ticket.masterTicketId, ticket.ticketId);

    const date = new Date();
    const offsetInHours = date.getTimezoneOffset() / 60;
    const endTime =
      ticket.expiredPaymentTime > dayjs()
        ? ticket.expiredPaymentTime
        : dayjs(ticket.expiredPaymentTime).add(-offsetInHours, 'hour');

    return (
      <Footer>
        <Timer endTime={endTime} />
        <Link to={ticketRoute} title={title}>
          <Action disabled={false}>{title} </Action>
        </Link>
        <InfoItem>{ticket.saleUser}</InfoItem>
      </Footer>
    );
  };

  const handleCancelSell = (e: any) => {
    cancelSellTicket(ticket.ticketId).then((ticket) => {
      //ToDo: add in the future
      // this.setState({ ...this.state, ticketV1 });
      go(getUserTicketsRouteV1());
    });
  };

  const renderTicketSector = (ticket: IProfileTicketStoreState) => {
    if (!ticket || !ticket.ticketQuota) {
      return null;
    }
    const quota = ticket.ticketQuota;
    let title = quota?.sector?.title ?? quota.title;
    if (ticket.rowTitle) {
      title += ` ${t('MyTickets.Row')} ${ticket.rowTitle}`;
    }
    if (ticket.seatTitle) {
      title += ` ${t('MyTickets.Seat')} ${ticket.seatTitle}`;
    }
    return <InfoItem>{title}</InfoItem>;
  };

  return (
    <Container>
      <Content>
        <Header>
          <Title>{event.title}</Title>
        </Header>
        <InfoWrapper>
          <InfoItem>{dayjs(event.date).format(`${Constants.DateFormat}, LT`)}</InfoItem>
          {event.venue && <InfoItem>{`${event.venue.city}, ${event.venue.title}`}</InfoItem>}
          {renderTicketSector(ticket)}
        </InfoWrapper>
        {!isSale && !isSold && !isOffered && !paymentInProcess && !paymentDeclined && renderIssueFooter(ticket)}
        {false && isSale && renderSaleFooter(ticket)}
        {false && isOffered && renderOfferFooter(ticket)}
        {false && isSold && renderSoldInfo(ticket)}
        {false && paymentInProcess && renderPaymentInProcessInfo()}
        {false && paymentDeclined && renderPaymentDeclinedInfo()}
      </Content>
      <Poster bg={event.posterUrl} />
    </Container>
  );
};

const Link = styled(RLink)`
  display: block;

  &:hover {
    filter: brightness(80%);
  }
`;

const Container = styled('article')<{ theme: { colors: string } }>`
  display: flex;
  border-top: 1px solid ${(props) => props.theme.colors.text};
  border-bottom: 1px solid ${(props) => props.theme.colors.text};
  min-height: 210px;
  height: 35vw;
  max-height: 25vh;
`;

const Poster = styled.div<{ bg: string; theme: { colors: string } }>`
  flex: 1;
  width: 50%;
  background: url(${(props) => props.bg}) top no-repeat;
  background-size: cover;
  mask-position: 0 0;
  mask-size: auto;
  mask-image: radial-gradient(
    circle at calc(100% + 12px) 50%,
    transparent 42px,
    ${(props) => props.theme.colors.text} 43px
  );
  position: relative;

  &:after {
    content: '';
    position: absolute;
    background: white;
    width: 100%;
    height: 100%;
    clip-path: circle(44px at calc(100% + 12px));
  }
`;

const Content = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 50%;
  border-right: 1px dashed ${(props) => props.theme.colors.text};
  padding: 10px 20px;
`;

const Header = styled.header``;

const Title = styled.h1`
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  font-size: 18px;
  font-family: ${(props) => props.theme.fonts.bolderFont};
`;

const InfoWrapper = styled.section`
  flex: 1;
  padding: 10px 0;
`;

const InfoItem = styled.p`
  margin: 5px 0;
  font-size: 12px;
`;

const Footer = styled.footer``;

const Action = styled.span<{ theme: { fonts: string; colors: string }; disabled: boolean }>`
  font-size: 12px;
  font-family: ${(props) => props.theme.fonts.boldFont};
  color: ${(props) => props.theme.colors.accent};
  text-transform: uppercase;
  opacity: ${(props: any) => (props.disabled ? 0.7 : 1)};
`;

export default UserTicketItem;

type UserTicketItemPropsType = {
  ticket: IProfileTicketStoreState;

  cancelSellTicket: (ticketId: string) => Promise<IProfileTicketStoreState>;
  go: (url: string) => void;
};
