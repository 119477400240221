import { baseSlice, baseSliceV2 } from './apiSlice';
import {
  IChangePasswordInfo,
  IResetPasswordConfirmInfo,
  IUpdateUserStoreState,
  IUserStoreState,
  PhoneNumberType,
  RegisterUserType,
  ResetPasswordType,
  UserRegistrationStoreState,
  UserStoreState,
} from '../../store/userV1/types';
import { update, userInit } from '../../store/userV1/actions';
import { ActionResponseStoreState, IActionResponseStoreState } from '../../store/app/types';
import { setResultInfo } from '../../store/resultInformarion/actions';
import { AlertType, KeySuccess, Pages } from '../../store/enums';
import { AuthStoreState, IAuthStoreState } from '../../store/auth/types';
import { AppAuthorize } from '../../store/app/actions';
import { authorize } from '../../store/auth/actions';
import { store } from '../../index';
import { account } from '../constants/constants';
import { toCamel } from './eventsSlice';
import { UserType } from '../../store/user/types';
import { updateAccount, userInitiate } from '../../store/user/actions';
import { UpdateUserType } from '../models/account';

const accountSlice = baseSlice.injectEndpoints({
  endpoints: (build) => ({
    getCurrentAccount: build.query<IUserStoreState, void>({
      query: () => account.me,
      transformResponse: (response) => new UserStoreState(response),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data: user } = await queryFulfilled;
        dispatch(userInit(user));
      },
    }),
    confirmPhone: build.mutation<void, { code: string; phoneNumber: string }>({
      query: (body: { code: string; phoneNumber: string }) => ({
        url: account.confirmPhone,
        method: 'POST',
        body: JSON.stringify(body),
      }),
    }),
    changePassword: build.mutation<IActionResponseStoreState, IChangePasswordInfo>({
      query: (body: IChangePasswordInfo) => ({
        url: account.changePassword,
        method: 'POST',
        body: JSON.stringify(body),
      }),
      transformResponse: (response: IActionResponseStoreState) => new ActionResponseStoreState(response),
    }),
    updateCurrentAccountV1: build.mutation<IUserStoreState, IUpdateUserStoreState>({
      query: (body: IUpdateUserStoreState) => ({
        url: account.updateCurrentAccount,
        method: 'POST',
        body: JSON.stringify(body),
      }),
      transformResponse: (response: IUpdateUserStoreState) => new UserStoreState(response),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        dispatch(update(data));
      },
    }),
    resetPassword: build.mutation<IActionResponseStoreState, ResetPasswordType>({
      query: (body: ResetPasswordType) => ({
        url: account.resetPassword,
        method: 'POST',
        body: JSON.stringify(body),
      }),
      transformResponse: (response: IActionResponseStoreState) => new ActionResponseStoreState(response),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        if (data) {
          dispatch(
            setResultInfo({
              type: AlertType.Info,
              key: KeySuccess.EmailSent,
              title: data.message,
              message: data.details,
            })
          );
        }
      },
    }),
    confirmResetPassword: build.mutation<IAuthStoreState, IResetPasswordConfirmInfo>({
      query: (body: IResetPasswordConfirmInfo) => ({
        url: account.confirmResetPassword,
        method: 'POST',
        body: JSON.stringify(body),
      }),
      transformResponse: (response: AuthStoreState) => new AuthStoreState(response),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        if (data) {
          dispatch(AppAuthorize());
          dispatch(authorize(data));
        }
      },
    }),
    updatePhone: build.mutation<IActionResponseStoreState, PhoneNumberType>({
      query: (body: PhoneNumberType) => ({
        url: account.phone,
        method: 'POST',
        body: JSON.stringify(body),
      }),
      transformResponse: (response: IActionResponseStoreState) => new ActionResponseStoreState(response),
    }),
    registerUser: build.mutation<IActionResponseStoreState, RegisterUserType>({
      query: (arg: RegisterUserType) => {
        const state = store.getState();

        const { origin, protocol, host } = location;
        const baseUrl = origin || `${protocol}//${host}`;
        const redirectUrl = `${baseUrl}/${Pages.User}/${Pages.EmailConfirm}`;

        const { email, password } = arg;

        const model = new UserRegistrationStoreState(
          email,
          password,
          redirectUrl,
          true,
          state.distribution.distributorSlug
        );

        return {
          url: account.signUp,
          method: 'POST',
          body: JSON.stringify(model),
        };
      },
      transformResponse: (response: IActionResponseStoreState) => new ActionResponseStoreState(response),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        if (data) {
          dispatch(
            setResultInfo({
              type: AlertType.Info,
              key: KeySuccess.EmailSent,
              title: data.message,
              message: data.details,
            })
          );
        }
      },
    }),
  }),
});

export const {
  useLazyGetCurrentAccountQuery,
  useConfirmPhoneMutation,
  useUpdateCurrentAccountV1Mutation,
  useChangePasswordMutation,
  useResetPasswordMutation,
  useConfirmResetPasswordMutation,
  useUpdatePhoneMutation,
  useRegisterUserMutation,
} = accountSlice;

const accountSliceV2 = baseSliceV2.injectEndpoints({
  endpoints: (build) => ({
    getAccount: build.query<UserType, void>({
      query: () => account.me,
      transformResponse: (response) => toCamel(response),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data: user } = await queryFulfilled;
        dispatch(userInitiate(user));
      },
    }),
    updateAccount: build.mutation<UserType, UpdateUserType>({
      query: (body: UpdateUserType) => ({
        url: account.me,
        method: 'PUT',
        body: JSON.stringify(body),
      }),
      transformResponse: (response: UpdateUserType) => toCamel(response),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        dispatch(updateAccount(data));
      },
    }),
  }),
});

export const { useLazyGetAccountQuery, useUpdateAccountMutation } = accountSliceV2;
