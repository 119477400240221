import React, { FC, memo, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { isEmpty } from 'lodash';
import { IEventDetailsStoreState } from '../../../../store/event/eventDetails/EventDetailsStoreState';
import { EventHelper } from '../../../../helpers';
import { Button, PageFooter } from '../../../../components/UI';
import { Routes } from '../../../../store/routes';
import { IEventStoreState } from '../../../../store/event/types';
import { useRoutesHelper } from '../../../../helpers/routesHelper';
import { useStartDistributeV1Mutation } from '../../../../core/api/eventsSlice';
import { GlobalStateType } from '../../../../configureStore';

type EventDetailsFooterType = {
  event: IEventDetailsStoreState;
  isInitializing: boolean;
};

export const EventDetailsFooterV1: FC<EventDetailsFooterType> = memo(({ event, isInitializing }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { getBookRoute } = useRoutesHelper();

  const isAuthorized = useSelector<GlobalStateType, boolean>((state) => state.app.isAuthorized);
  const userEvents = useSelector<GlobalStateType, Array<IEventStoreState>>((state) => state.userEventsV1);

  const [startDistributeRequest, { isLoading: isLoadingStartDistribute }] = useStartDistributeV1Mutation();

  if (!event) {
    return null;
  }
  const isDomain = isEmpty(event.domains);

  const isDistributed = EventHelper.isEventDetailsDistributed(event, userEvents);
  const sellTicketButtonTitle = isDistributed ? t('Event.SellTicket') : t('Event.BecomeDistributor');
  const showDistributionButton = isAuthorized && !isInitializing && event.isDistributionAvailable;
  const isBookingEnabled = !event.notAvailableForPurchase;

  const getBookTicketsButtonTitle = () => {
    let bookTicketsButtonTitle = t('Event.BuyTicket');

    if (!isBookingEnabled) {
      bookTicketsButtonTitle = t(event.notAvailableForPurchaseReason);
    }
    if (isDomain) {
      bookTicketsButtonTitle = t('Event.TemporarilyUnavailable');
    }

    return bookTicketsButtonTitle;
  };

  const bookTicketsButtonTitle = getBookTicketsButtonTitle();

  const handleOnBookClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (e) {
      e.preventDefault();
    }
    navigate(getBookRoute(event.slug, null));
  };

  const handleOnDistributeClick = (e: MouseEvent<HTMLButtonElement>, event: IEventDetailsStoreState) => {
    if (e) {
      e.preventDefault();
    }

    if (EventHelper.isEventDetailsDistributed(event, userEvents)) {
      navigate(getBookRoute(event.slug, null, null, null, null, true));
      return;
    }

    (async () => await startDistributeRequest(event.slug))().then(() => {
      navigate(Routes.UserEventsV1);
    });
  };

  return (
    <PageFooter>
      <Button disabled={!isBookingEnabled || isDomain} onClick={(e) => handleOnBookClick(e)}>
        {bookTicketsButtonTitle}
      </Button>
      {showDistributionButton && (
        <Button
          disabled={isDomain || isLoadingStartDistribute}
          isLoading={isLoadingStartDistribute}
          onClick={(e) => handleOnDistributeClick(e, event)}
        >
          {sellTicketButtonTitle}
        </Button>
      )}
    </PageFooter>
  );
});
