import { CheckoutStoreState, ICheckoutCompleteStoreState, ICheckoutStoreState } from './types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPaymentCompleteResult } from '../paymentV1/types';

const initialState = new CheckoutStoreState();
const checkoutReducerV1 = createSlice({
  name: 'checkoutReducer',
  initialState,
  reducers: {
    paymentInitV1: (state, action) => {
      return { ...state, ...action.payload, process: null, complete: null, isRedoing: false };
    },
    finishPaymentInitV1: (state, action) => {
      return { ...state, init: action.payload, process: null, complete: null, isRedoing: false };
    },
    paymentProcessV1: (state, action) => {
      return { ...state, ...action.payload, complete: null, isRedoing: false };
    },
    finishPaymentProcessV1: (state, action) => {
      return { ...state, process: action.payload, complete: null, isRedoing: false };
    },
    finishPaymentCompleteV1: (state, action: PayloadAction<IPaymentCompleteResult>) => {
      return { ...state, complete: { result: action.payload }, isRedoing: false };
    },
    clearPaymentV1: () => {
      return initialState;
    },
    setEmailV1: (state: ICheckoutStoreState, action) => {
      state.email = action.payload;
    },
    redoV1: (state) => {
      return { ...state, isRedoing: true };
    },
    setPoints: (state) => {
      state.usePoints = true;
    },
  },
});

export default checkoutReducerV1;
