import { useLayoutEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useDispatch } from '../../configureStore';
import { useRoutesHelper } from '../../helpers/routesHelper';
import { AlertType, EmailConfirmStatus, KeyErrors, KeySuccess } from '../../store/enums';
import { setResultInfo } from '../../store/resultInformarion/actions';

const EmailConfirmPage = () => {
  const dispatch = useDispatch();
  const { status } = useParams<{ status: string }>();
  const navigate = useNavigate();
  const { getProfileRoute, getResultStatusRoute } = useRoutesHelper();

  useLayoutEffect(() => {
    if (status) {
      const isSuccess = status === EmailConfirmStatus.Success;
      const alertType = isSuccess ? AlertType.Success : AlertType.Error;
      const alertKey = isSuccess ? KeySuccess.EmailConfirmed : KeyErrors.EmailConfirmFailed;
      const message = isSuccess
        ? 'Alert.NowYouCanLogInUsingYourEmailAndPassword'
        : 'Alert.EmailVerificationLinkIsInvalidOrOutOfDate';
      const title = isSuccess ? 'Alert.EmailConfirmed' : 'Alert.SomethingWentWrong';

      dispatch(
        setResultInfo({
          type: alertType,
          key: alertKey,
          title,
          message,
          buttons: [
            {
              title: 'Registration.GoToLogin',
              callback: () => navigate(getProfileRoute()),
            },
          ],
        })
      );
      navigate(getResultStatusRoute(alertType, alertKey));
    }
  }, [dispatch, getProfileRoute, status]);

  return null;
};

export default EmailConfirmPage;
