import dayjs from 'dayjs';

import { IUserStoreState, UserStoreState } from './types';
import { IEventStoreState } from '../event/types';
import { IProfileTicketStoreState } from '../ticket/ProfileTicketStoreState';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = new UserStoreState();
export const userReducerV1 = createSlice({
  name: 'userReducerV1',
  initialState: initialState as IUserStoreState,
  reducers: {
    userInit: (state, action: PayloadAction<IUserStoreState>) => {
      return action.payload;
    },
    update: (state, action: PayloadAction<IUserStoreState>) => {
      return action.payload;
    },
    clear: () => {
      return initialState;
    },
    sendPhoneCode: (state, action) => {
      return { ...state, phoneCodeSentMessage: action.payload, phoneCodeSentAt: dayjs() };
    },
    phoneCurrentUpdate: (state, action) => {
      state.phoneNumber = action.payload;
      state.phoneNumberConfirmed = false;
      return state;
    },
  },
});

export const userEventsReducerV1 = createSlice({
  name: 'userEventsReducerV1',
  initialState: [] as Array<IEventStoreState>,
  reducers: {
    initDistributionEventsV1: (state, action) => {
      return [...action.payload];
    },
    clearEvents: () => {
      return [];
    },
  },
});

export const userTicketsReducerV1 = createSlice({
  name: 'userTicketsReducerV1',
  initialState: [] as Array<IProfileTicketStoreState>,
  reducers: {
    initTickets: (state, action) => {
      const tickets = action.payload.filter((t: IProfileTicketStoreState) => !t.ticketId);
      for (const newTicket of action.payload.filter((t: IProfileTicketStoreState) => !!t.ticketId)) {
        let ticketToAdd = state.find((t: IProfileTicketStoreState) => t.ticketId === newTicket.ticketId);
        if (ticketToAdd) {
          ticketToAdd = { ...ticketToAdd, ...newTicket };
        } else {
          ticketToAdd = newTicket;
        }
        tickets.push(ticketToAdd);
      }
      return tickets;
    },
    clearTickets: () => {
      return [];
    },
    updateTicket: (state, action) => {
      const tickets = state.slice();
      tickets.forEach((t) => {
        if (t.ticketId === action.payload.ticketId) {
          t = action.payload;
        }
      });
      return tickets;
    },
  },
});
