import { BookType } from './types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: BookType = {
  event: null,
  ticketGroups: [],
};

export const bookReducer = createSlice({
  name: 'bookReducer',
  initialState,
  reducers: {
    initiateBook: (state, action: PayloadAction<BookType>) => {
      return action.payload;
    },
    clearBook: () => {
      return initialState;
    },
  },
});
