import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { AppSettings } from '../../settings/appSettings';
import { IGlobalStoreState } from '../../store';

const prepareHeaders = (headers, getState) => {
  const { auth } = getState() as IGlobalStoreState;
  const authorization = auth && auth.access_token ? `Bearer ${auth.access_token}` : '';

  headers.set('Content-type', 'application/json; charset=UTF-8');

  if (authorization) {
    headers.set('authorization', `${authorization}`);
  }
  return headers;
};

const baseQuery = fetchBaseQuery({
  baseUrl: AppSettings.BASE_API_URL,
  prepareHeaders: (headers, { getState }) => prepareHeaders(headers, getState),
});

export const baseSlice = createApi({
  reducerPath: 'baseApiV1',
  baseQuery,
  endpoints: () => ({}),
});

const baseQueryV2 = fetchBaseQuery({
  baseUrl: AppSettings.BASE_API_URL_V2,
  prepareHeaders: (headers, { getState }) => prepareHeaders(headers, getState),
});

export const baseSliceV2 = createApi({
  reducerPath: 'baseApiV2',
  baseQuery: baseQueryV2,
  endpoints: () => ({}),
});
