export interface IEventDetailsVenueStoreState {
  title: string;
  slug: string;
  address: string;
  city: string;
}

export class EventDetailsVenueStoreState implements IEventDetailsVenueStoreState {
  title: string;
  slug: string;
  address: string;
  city: string;

  constructor(dto?: any) {
    this.title = dto && dto.Title ? dto.Title : '';
    this.slug = dto && dto.Slug ? dto.Slug : '';
    this.address = dto && dto.Address ? dto.Address : '';
    this.city = dto && dto.City ? dto.City : '';
  }
}
