import { Grid, Skeleton, Theme } from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  gridTile: {
    height: '223px',
    width: 230,
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      height: '265px',
      width: 330,
    },
  },
  skeletonImage: {
    height: '223px',
    width: '100%',
    borderRadius: 8,
  },
  skeletonText: {
    height: 20,
    marginTop: '5px',
  },
}));

export const HorizontalCarouselSkeleton = () => {
  const { classes } = useStyles();
  return (
    <>
      {Array.from(new Array(6)).map((item, index) => (
        <Grid item key={index} className={classes.gridTile} xs={12} sm={6} md={4} lg={4} xl={4}>
          <Grid container spacing={0} direction="row">
            <Grid item lg={12} xs={12} sm={12} md={12} xl={12}>
              <Skeleton animation="wave" variant="rectangular" className={classes.skeletonImage} />
            </Grid>
            <Grid item lg={12} xs={12} sm={12} md={12} xl={12}>
              <Skeleton animation="wave" variant="rectangular" className={classes.skeletonText} />
            </Grid>
            <Grid item lg={12} xs={12} sm={12} md={12} xl={12}>
              <Skeleton animation="wave" variant="rectangular" width="50%" className={classes.skeletonText} />
            </Grid>
          </Grid>
        </Grid>
      ))}
    </>
  );
};
