import styled from 'styled-components';

interface IInputProps {
  error?: boolean;
}

export const TextInput = styled.input<IInputProps>`
  border: 1px solid ${(props) => (props.error ? props.theme.colors.error : props.theme.colors.text)};
  width: 100%;
  padding: 15px 40px 15px 20px;
  font-size: 12px;
  background: transparent;
  border-radius: 24px;
  outline: none;
  color: ${(props) => (props.error ? props.theme.colors.error : props.theme.colors.text)};
  transition: width 0.2s ease-in;

  &:focus {
    border-color: ${(props) => props.theme.colors.accent};
  }

  &::placeholder {
    color: ${(props) => props.theme.colors.text};
    opacity: 0.5;
  }

  &:read-only {
    opacity: 1;
  }
`;

export const RadioButton = styled.input<IInputProps>``;

interface IInputWrapperProps {
  valid?: boolean;
}

export const InputWrapper = styled.div<IInputWrapperProps>`
  display: flex;
  align-items: center;
  position: relative;

  &:after {
    content: ${(props) => (props.valid ? '""' : '')};
    display: inline-block;
    width: 6px;
    height: 12px;
    border: solid ${(props) => props.theme.colors.accent};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    position: absolute;
    right: 20px;
    bottom: 17px;
  }

  > input {
    color: ${(props) => (props.valid ? props.theme.colors.accent : props.theme.colors.text)};
  }
`;

export default TextInput;
