import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useRoutesHelper } from '../../../helpers/routesHelper';
import { useUtils } from '../../../services/useUtils';
import { useDispatch, useSelector } from '../../../configureStore';
import { useQuery } from '../../../helpers/customHooks';
import { useConfirmResetPasswordMutation } from '../../../core/api/accountSlice';
import { setResultInfo } from '../../../store/resultInformarion/actions';
import { AlertType, KeyErrors } from '../../../store/enums';
import { PagesRoutes } from '../../../store/routes';
import { ResetPasswordForm, ResetPasswordFormType } from '../../../components/forms';
import { PageContent } from '../../../components/UI';

const ResetPasswordPage = () => {
  const { getResultStatusRoute, getProfileRoute } = useRoutesHelper();
  const { t } = useTranslation();
  const { toPageTitle } = useUtils();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();

  const token = query.get('token');
  const encodedEmail = query.get('email');

  const isInitializing = useSelector((state) => state.app.isInitializing);

  const [confirmResetPassword, { isLoading }] = useConfirmResetPasswordMutation();

  useEffect(() => {
    if (!token || !encodedEmail) {
      dispatch(
        setResultInfo({
          key: KeyErrors.EmailConfirmFailed,
          type: AlertType.Error,
          title: 'Alert.SomethingWentWrong',
          message: 'Alert.EmailVerificationLinkIsInvalidOrOutOfDate',
          buttons: [
            {
              title: 'PasswordRecovery.GoToMainPage',
              callback: () => navigate(PagesRoutes.Main, { replace: true }),
            },
          ],
        })
      );
      navigate(getResultStatusRoute(AlertType.Error, KeyErrors.EmailConfirmFailed), { replace: true });
    }
  }, []);

  if (isInitializing) {
    return null;
  }

  const handleFormSubmit = (formModel: ResetPasswordFormType) => {
    if (formModel) {
      (async () => confirmResetPassword({ newPassword: formModel.password, token, email: encodedEmail }))().then(() => {
        navigate(getProfileRoute(), { replace: true });
      });
    }
  };

  return (
    <PageContent center>
      <Helmet>
        <title>{toPageTitle(t('PasswordRecovery.PasswordRecovery'))}</title>
      </Helmet>
      <Header>{t('PasswordRecovery.PasswordRecovery')}</Header>
      <ResetPasswordForm
        submitTitle={t('PasswordRecovery.SignIn')}
        onSubmit={(formModel: ResetPasswordFormType) => handleFormSubmit(formModel)}
        isLoading={isLoading}
      />
    </PageContent>
  );
};

const Header = styled.h1`
  font-family: ${(props) => props.theme.fonts.boldFont};
  font-size: 18px;
  text-transform: uppercase;
  text-align: center;
`;

export default ResetPasswordPage;
