import React, { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Chip,
  ClickAwayListener,
  InputAdornment,
  List,
  ListItem,
  Paper,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from 'tss-react/mui';
import SearchIcon from '@mui/icons-material/Search';
import { useGetLocationQuery } from '../../core/api/eventsSlice';
import { FilterParams } from '../../store/enums';

type StyleProps = {
  display: string;
  marginRight: string;
  overflow;
};

const useStyles = makeStyles<StyleProps>()((theme: Theme, { display, marginRight, overflow }) => ({
  containerLocation: {
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    width: '226px',
    padding: '16px',
    background: 'linear-gradient(180.62deg, rgba(28, 28, 28, 1) -91.79%, rgba(18, 17, 17, 1) 157.66%)',
    boxShadow: '4px -4px 16px rgb(0 0 0 / 63%), -4px 4px 16px rgba(0, 0, 0, 0.63)',
    borderRadius: '12px',
    zIndex: 1,
    transform: 'translateX(-50%)',
    marginTop: 10,
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '260px',
  },
  chipLocationInfo: {
    border: '1px solid rgb(107, 107, 107, 0.6)',
    borderRadius: 35,
    height: 30,
    fontSize: 12,
    color: 'white',
    lineHeight: '12px',
    '& span': {
      marginTop: 1,
      marginRight,
    },
    '& .MuiChip-label': {
      overflow,
    },
    '& .MuiChip-deleteIcon': {
      display,
      color: 'rgba(255, 255, 255, 0.26)',
      '&:hover': {
        color: 'rgba(255, 255, 255, 0.4)',
      },
    },
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
    [theme.breakpoints.down(425)]: {
      width: 100,
    },
  },
  notFoundText: {
    color: 'white',
    textDecoration: 'none',
    fontSize: '0.75rem',
    position: 'relative',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  listItem: {
    padding: '0 0 0 0',
  },
  title: {
    color: 'white',
    textDecoration: 'none',
    fontSize: '0.75rem',
    cursor: 'pointer',
    ':visited': {
      color: 'white',
    },
    ':hover': {
      color: theme.palette.secondary.main,
    },
  },
  searchIcon: {
    color: '#FFBF00',
    height: '14px',
    width: '14px',
  },
  searchInput: {
    paddingBottom: '24px',
    '& .MuiOutlinedInput-root': {
      borderRadius: '35px',
      border: '0.5px solid white',
      height: '28px',
      fontSize: '0.625rem',
      lineHeight: '13px',
      '& input': {
        padding: 0,
      },
      '&.Mui-focused fieldset': {
        border: `1px solid ${theme.palette.secondary.main}`,
        bottom: '-1px',
        right: '-1px',
        top: '-6px',
        left: '-1px',
      },
    },
    '.MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
    },
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    maxHeight: '100%',
    overflow: 'auto',
  },
}));

export const LocationDropDown: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const cityParam = searchParams.get(FilterParams.City);

  const [open, setOpen] = useState(false);
  const [currentCity, setCurrentCity] = useState<string>(cityParam || null);
  const [search, setSearch] = useState('');
  const [filteredCities, setFilteredCities] = useState([]);

  const styles = {
    display: currentCity ? 'inherit' : 'none',
    marginRight: currentCity ? '0px' : '5px',
    overflow: currentCity ? 'hidden' : 'inherit',
  };
  const { classes } = useStyles(styles);

  const { t } = useTranslation();
  const { data: cities } = useGetLocationQuery();

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    if (!cities?.length) {
      return;
    }

    const filteredCities = cities.filter((item) => item?.city?.toLowerCase()?.includes(search?.toLowerCase()));
    setFilteredCities(filteredCities);
  }, [search]);

  const handleOpenClick = () => setOpen((open) => !open);

  const handleAwayClick = () => {
    setSearch('');
    setOpen(false);
  };

  const handleCitySelect = (city: string) => {
    searchParams.set(FilterParams.City, `${city}`);
    setSearchParams(searchParams);

    setCurrentCity(city);
    setSearch('');
    setOpen(false);
  };

  const handleCityReset = () => {
    searchParams.delete(FilterParams.City);
    setSearchParams(searchParams);

    setCurrentCity(null);
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleAwayClick}>
      <div className={classes.containerLocation}>
        <Chip
          className={classes.chipLocationInfo}
          label={cityParam || 'Location'}
          icon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          onClick={handleOpenClick}
          onDelete={handleCityReset}
        />
        {open && (
          <Paper className={classes.paper}>
            <TextField
              id={'location'}
              value={search}
              onChange={handleChange}
              placeholder={'Type city name...'}
              className={classes.searchInput}
              variant="outlined"
              sx={{
                '& .MuiInputBase-root': {
                  color: 'primary.main',
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position={'start'}>
                    <SearchIcon className={classes.searchIcon} />
                  </InputAdornment>
                ),
              }}
            />
            <List className={classes.list}>
              {search
                ? filteredCities.map((city) => (
                    <ListItem key={city.id} className={classes.listItem}>
                      <Typography
                        component={'div'}
                        style={city.city === currentCity ? { color: '#FFBF00' } : undefined}
                        className={classes.title}
                        onClick={() => handleCitySelect(city.city)}
                      >
                        {`${city.city}, ${city.country}`}
                      </Typography>
                    </ListItem>
                  ))
                : cities?.map((city) => (
                    <ListItem key={city.id} className={classes.listItem}>
                      <Typography
                        component={'div'}
                        style={city.city === currentCity ? { color: '#FFBF00' } : undefined}
                        className={classes.title}
                        onClick={() => handleCitySelect(city.city)}
                      >
                        {`${city.city}, ${city.country}`}
                      </Typography>
                    </ListItem>
                  ))}
              {!cities?.length && (
                <ListItem className={classes.listItem}>
                  <Typography component={'div'} className={classes.notFoundText}>
                    {t<string>(`Dashboard.NothingFound`)}
                  </Typography>
                </ListItem>
              )}
            </List>
          </Paper>
        )}
      </div>
    </ClickAwayListener>
  );
};
