import { Pages } from './enums';

export class Routes {
  public static Main = '/v1/';
  public static MainSortLocation = '/v1?city=city';
  public static Category = `/v1/${Pages.CategoriesV1}/:categorySlug`;
  public static Event = `/v1/${Pages.EventDetails}/:eventSlug`;
  public static TokenReferral = '/tr/:distributorSlug/:eventSlug/:token';
  public static Referral = `/${Pages.Referral}/:distributorSlug/:eventSlug/:sectorSlug`;
  public static RegistrationReferral = `/v1/r/:distributorSlug`;
  public static CheckoutV1 = `/v1/${Pages.BookV1}/:eventSlug/${Pages.Checkout}`;
  public static BookEventSlug = `:eventSlug`;
  public static BookSectorSlug = `:sectorSlug`;
  public static BookQuotaId = `:quotaId`;
  public static StripeBookResultV1 = `/v1/${Pages.BookV1}/payment/result`;
  public static UserV1 = `/v1/${Pages.User}/*`;
  public static RegistrationV1 = `/v1/${Pages.User}/${Pages.Registration}`;
  public static LoginV1 = `/v1/${Pages.User}/${Pages.Login}`;
  public static ProfileV1 = `/v1/${Pages.User}/${Pages.Profile}`;
  public static EditProfileV1 = `/v1/${Pages.User}/${Pages.EditProfile}`;
  public static UserConfirmEmailV1 = `/v1/${Pages.EmailConfirm}/:status`;
  public static UserEventsV1 = `/v1/${Pages.User}/${Pages.UserEvents}`;
  public static UserTicketsV1 = `/v1/${Pages.User}/${Pages.UserTickets}`;
  public static UserTicketDetails = `${Routes.UserTicketsV1}/:masterTicketId/:ticketId`;
  public static UserTicketSellDetails = `${Routes.UserTicketsV1}/sell/:masterTicketId/:ticketId?`;
  public static UserTicketPurchase = `${Routes.UserTicketsV1}/purchase/:masterTicketId/:ticketId?`;
  public static Share = `/v1/${Pages.Sell}/:eventSlug`;
  public static ShareSectorSlug = `/v1/${Pages.Sell}/:eventSlug/:sectorSlug`;
  public static ShareTickets = `/v1/${Pages.Sell}/:eventSlug/:sectorSlug/:tickets`;
  public static ShareLink = `/v1/${Pages.Share}/:eventSlug/:token`;
  public static PasswordRecovery = `/v1/${Pages.User}/${Pages.PasswordRecovery}`;
  public static Contacts = `/v1/${Pages.Contacts}`;
  public static PaymentsInfo = `/${Pages.PaymentsInfo}`;
  public static WidgetBook = `/${Pages.Widget}/${Pages.BookV1}/:token`;
  public static WidgetCheckout = `/${Pages.Widget}/${Pages.BookV1}/:token/checkout`;
  public static WidgetBookResult = `/${Pages.Widget}/${Pages.BookV1}/:token/result`;
  public static WidgetBookResultType = `/${Pages.Widget}/${Pages.BookV1}/:token/result/:type`;
  public static InformationSuccess = `/v1/${Pages.Success}/:keySuccess`;
  public static InformationError = `/v1/${Pages.Failed}/:keyFailed`;
}

export enum PagesRoutes {
  Main = '/',
  EventDetails = `/event/:eventSlug`,
  UserEvents = `/user/my-events`,
  Book = `/book/:eventSlug/:ticketGroupId?`,
  Checkout = `/book/:eventSlug/checkout`,
  GenreEvents = `/genres/:genreSlug`,
  StripeBookResult = `/book/payment/result`,
  Contacts = `/contacts`,
  PaymentsInfo = `/payments-info`,
  InformationSuccess = `/success/:keySuccess`,
  InformationError = `/failed/:keyFailed`,
  Profile = `/user/profile`,
  Registration = `/user/registration`,
  Login = `/user/login`,
  EditProfile = `/user/edit-profile`,
  UserTickets = `/user/my-tickets`,
  UserConfirmEmail = `email-confirmation/:status`,
  PasswordRecovery = `/user/password-recovery`,
  ResetPassword = `password-reset`,
  User = `/user/*`,
  UserTicketDetails = `/user/my-tickets/:ticketId`,
  Share = `/sell/:eventSlug`,
  Referral = `/referral/:distributorSlug/:eventSlug/`,
  RegistrationReferral = `/r/:distributorSlug`,
}
