import React, { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AnalyticService } from '../../services';
import { useDispatch, useSelector } from '../../configureStore';
import { useRoutesHelper } from '../../helpers/routesHelper';
import { useLazyGetBookEventQuery, useLazyGetBookEventTicketGroupsQuery } from '../../core/api/eventsSlice';
import {
  useCheckout,
  useCheckoutHelper,
  useEventHelpers,
  useInitiate,
  useTicketTotalCalculate,
} from '../../helpers/customHooks';
import { PagesRoutes } from '../../store/routes';
import { setResultInfo } from '../../store/resultInformarion/actions';
import { AlertType, FilterParams, KeyErrors } from '../../store/enums';
import { Page, PageContent } from '../../components/UI';
import { CheckoutSkeleton } from '../../components/skeletons/checkoutSkeleton';
import { EventBookType, EventTicketGroupsParams, SectorType } from '../../core/models/events';
import { initiateBook } from '../../store/book/actions';
import { BookType } from '../../store/book/types';
import { Order } from './bookComponents/order';
import { Footer } from './bookComponents/footer';
import { clearPayment } from '../../store/checkout/actions';
import TESchemaSectorSelector from '../../components/book/TESchemaSectorSelector';
import { TicketGroups } from './bookComponents/ticketGroups';

export const Book: FC = () => {
  const analyticSrv = new AnalyticService();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { getResultStatusRoute, getEventRoute } = useRoutesHelper();
  const { getEventMetaDescription } = useEventHelpers();
  const { isCompleted, isProcessed } = useCheckoutHelper();

  const { eventSlug, distributorSlug, ticketGroupId } = useParams<BookParamsType>();
  const [searchParams] = useSearchParams();

  const lowPriceParam = searchParams.get(FilterParams.LowPrice);
  const rangePriceParams = searchParams.get(FilterParams.Price);
  const quantityParam = searchParams.get(FilterParams.Quantity);
  const parkingParam = searchParams.get(FilterParams.Parking);
  const binocularsParam = searchParams.get(FilterParams.Binoculars);
  const disabledPersonParam = searchParams.get(FilterParams.DisabledPerson);
  const sectorParam = searchParams.get(FilterParams.Sec);

  const [eventBook, setEventBook] = useState<EventBookType>(null);
  const [selectedTicketGroups, setSelectedTicketGroups] = useState<SelectedTicketGroupType[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSections, setSelectedSections] = useState<string[]>([]);
  const [ticketGroups, setTicketGroups] = useState<TicketGroupTEType[]>([]);
  const [filteredTicketGroups, setFilteredTicketGroups] = useState<TicketGroupTEType[]>([]);
  const [isLoadingTicketGroups, setIsLoadingTicketGroups] = useState(false);

  const isRedirection = useSelector((state) => state.app.isRedirection);
  const isFinalized = useSelector((state) => state.app.isFinalized);
  const distribution = useSelector((state) => state.distribution);
  const checkout = useSelector((state) => state.checkout);

  const [getBookEventRequest, { isFetching: isFetchingBookEvent }] = useLazyGetBookEventQuery();

  const [getTicketGroupsRequest] = useLazyGetBookEventTicketGroupsQuery();

  const { checkoutCancel } = useCheckout();
  const { initiate } = useInitiate();

  const initiateHandleCheckout = async (): Promise<void> => {
    setIsLoading(true);
    if (isProcessed(checkout) && !isCompleted(checkout)) {
      await checkoutCancel(checkout);
    }
    dispatch(clearPayment());

    const book: BookType = {
      event: eventBook,
      ticketGroups: selectedTicketGroups,
    };
    dispatch(initiateBook(book));
    await initiate(book, distribution);

    setIsLoading(false);
  };

  const totalPrice = useTicketTotalCalculate(selectedTicketGroups, eventBook?.currency, eventBook?.fees);

  useEffect(() => {
    if (!ticketGroups.length) return;
    const filteredTicketGroup = ticketGroups.filter((ticketGroup) =>
      selectedSections.includes(ticketGroup.tevo_section_name)
    );
    setFilteredTicketGroups(filteredTicketGroup);
  }, [selectedSections, ticketGroups]);

  useEffect(() => {
    setIsLoadingTicketGroups(true);

    const rangePrice = rangePriceParams?.split('-');
    const params: EventTicketGroupsParams = {
      eventSlug,
      lowPrice: Boolean(lowPriceParam) || undefined,
      priceFrom: rangePrice ? Math.round(+rangePrice.at(0)) : undefined,
      priceTo: rangePrice ? Math.round(+rangePrice.at(1)) : undefined,
      sectorSlug: sectorParam || undefined,
      minTicketCount: +quantityParam || undefined,
      parking: Boolean(parkingParam) || undefined,
      wheelchair: Boolean(disabledPersonParam) || undefined,
      binoculars: binocularsParam || undefined,
    };

    getTicketGroupsRequest(params)
      .unwrap()
      .then((sectors: SectorType[]) => {
        const ticketGroups: TicketGroupTEType[] = sectors
          .reduce(
            (acc, sector) => [
              ...acc,
              ...sector.ticketGroups
                .filter((ticketGroup) => !!ticketGroup.splits.length)
                .map((ticketGroup) => ({
                  id: ticketGroup.tEvoId,
                  type: ticketGroup.type,
                  row: ticketGroup.row,
                  section: ticketGroup.section,
                  quantity: ticketGroup.quantity,
                  available_quantity: ticketGroup.availableQuantity,
                  wholesale_price: ticketGroup.wholesalePrice,
                  eticket: ticketGroup.eticket,
                  instant_delivery: ticketGroup.instantDelivery,
                    public_notes : ticketGroup.publicNotes,
                  intended_for_instant_delivery: ticketGroup.intendedForInstantsDelivery,
                  in_hand: ticketGroup.inHand,
                  in_hand_on: ticketGroup.inHandOn,
                  remote_id: null,
                  private_notes: null,
                  exchange_notes: null,
                  splits: ticketGroup.splits,
                  ticket_states: {
                    available: 1,
                  },
                  ticket_hold_ids: ['NULL'],
                  seats: [],
                  tickets: [['available', 'NULL']],
                  has_spec_tickets: false,
                  featured: false,
                  retail_price: ticketGroup.retailPrice,
                  format: ticketGroup.format,
                  face_value: null,
                  view_type: null,
                  broadcast: 't',
                  wheelchair: ticketGroup.wheelchair,
                  evopay_discount: 0,
                  evopay_discount_price: 0,
                  ticket_costs: [null],
                  ticket_purchase_order_ids: [null],
                  freshness: '< 30 mins',
                  tevo_section_name: ticketGroup.sectionName,
                })),
            ],
            []
          )
          .sort((a, b) => a.retail_price - b.retail_price);

        setTicketGroups(ticketGroups);
      })
      .finally(() => setIsLoadingTicketGroups(false));
  }, [
    eventSlug,
    lowPriceParam,
    rangePriceParams,
    parkingParam,
    quantityParam,
    sectorParam,
    disabledPersonParam,
    binocularsParam,
  ]);

  useEffect(() => {
    if (isRedirection || !isFinalized) {
      return;
    }

    if (!eventSlug) {
      navigate(PagesRoutes.Main);
      return;
    }

    getBookEventRequest({ eventSlug, distributorSlug: distribution.distributorSlug ?? '' })
      .unwrap()
      .then((event) => {
        if (!event || !event.slug) {
          dispatch(
            setResultInfo({
              type: AlertType.Error,
              key: KeyErrors.EventNotFound,
              title: 'Alert.SomethingWentWrong',
              message: 'Alert.CheckLinkNoEventFound',
            })
          );
          navigate(getResultStatusRoute(AlertType.Error, KeyErrors.EventNotFound), { replace: true });
          return;
        }

        if (!event.isTicketsAvailable) {
          navigate(getEventRoute(event.slug));
          return;
        }

        setEventBook(event);
      });
  }, [isFinalized, eventSlug, distributorSlug]);

  useEffect(() => {
    analyticSrv.trackEvent('TicketsDetails');
  }, []);

  const handleTicketsCount = useCallback((count: number, ticketGroup: TicketGroupTEType) => {
    if (!count) {
      return;
    }

    const selectTicketGroup: SelectedTicketGroupType = {
      price: ticketGroup.retail_price,
      ticketGroupId: ticketGroup.id,
      count,
    };

    setSelectedTicketGroups([selectTicketGroup]);
  }, []);

  if (isRedirection || isLoading) {
    return <CheckoutSkeleton />;
  }

  const getQuantityTicketGroups = () => {
    if (!ticketGroups) {
      return;
    }

    return filteredTicketGroups.length > 0 || searchParams.size
      ? `${filteredTicketGroups.length || ticketGroups.length} listings`
      : `${ticketGroups.length} listings in total`;
  };

  const quantityTicketGroups = getQuantityTicketGroups();

  const selectedTicketGroup = ticketGroups?.find((ticketGroup) => ticketGroup.id === +ticketGroupId);

  return (
    <Page
      isForm
      title={`${t('Book.TicketPurchase')} | ${eventBook?.title} - ${eventBook?.subTitle ?? ''}`}
      description={getEventMetaDescription(eventBook)}
      keywords={[eventBook?.title, eventBook?.venue?.title, t('Book.BuyTicket'), t('Book.Concert')]}
      imageUrl={eventBook?.posterUrl}
    >
      <Content>
        <TESchemaSectorSelector
          event={eventBook}
          ticketGroups={ticketGroups}
          tEVenueId={eventBook?.venue?.tEVenueId}
          configurationId={eventBook?.venue?.configurationId}
          setSelectedSections={setSelectedSections}
          selectedSections={selectedSections}
          isLoading={isFetchingBookEvent}
          selectTicketGroupId={ticketGroupId}
        />
        {!ticketGroupId && (
          <TicketGroups
            eventSlug={eventBook?.slug}
            title={quantityTicketGroups}
            ticketGroups={filteredTicketGroups.length ? filteredTicketGroups : ticketGroups}
            isLoading={!eventBook?.currency || !eventBook?.slug || isLoadingTicketGroups || isFetchingBookEvent}
            currency={eventBook?.currency}
          />
        )}
        {ticketGroupId && (
          <Order
            event={eventBook}
            ticketGroup={selectedTicketGroup}
            handleTicketsCount={handleTicketsCount}
            totalPrice={totalPrice}
            distribution={distribution}
            setSelectedTicketGroups={setSelectedTicketGroups}
          />
        )}
        {ticketGroupId && (
          <Footer
            event={eventBook}
            selectedTicketGroups={selectedTicketGroups}
            isLoading={isLoading}
            paymentHandleClick={initiateHandleCheckout}
          />
        )}
      </Content>
    </Page>
  );
};

const Content = styled(PageContent)`
  gap: 16px;
  padding: 20px 16px 25px 16px;

  @media (max-width: 425px) {
    padding: 13px 8px;
  }
`;

export const CostTitle = styled.span`
  font-size: 12px;
  font-family: ${(props) => props.theme.fonts.lighterFont};
`;

type BookParamsType = {
  eventSlug: string;
  distributorSlug: string;
  ticketGroupId: string;
};

export type SelectedTicketGroupType = {
  price: number;
  ticketGroupId: number;
  count: number;
};

export type BookSectorType = {
  id: number;
  slug: string;
};

export type TicketGroupTEType = {
  id: number;
  url: string;
  type: string;
  row: string;
  section: string;
  quantity: number;
  available_quantity: number;
  wholesale_price: number;
  eticket: boolean;
  instant_delivery: boolean;
  intended_for_instant_delivery: boolean;
  in_hand: boolean;
  in_hand_on: string;
  remote_id: any;
  public_notes: string;
  private_notes: any;
  exchange_notes: any;
  created_at: string;
  updated_at: string;
  splits: number[];
  ticket_states: TicketStatesTEType;
  ticket_hold_ids: string[];
  seats: any[];
  tickets: string[][];
  has_spec_tickets: boolean;
  featured: boolean;
  retail_price: number;
  format: string;
  face_value: any;
  view_type: any;
  broadcast: string;
  wheelchair: boolean;
  evopay_discount: number;
  evopay_discount_price: number;
  ticket_costs: any[];
  ticket_purchase_order_ids: any[];
  freshness: string;
  signature: string;
  tevo_section_name: string;
};

export type TicketStatesTEType = {
  available: number;
};
