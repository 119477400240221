import React, { FC } from 'react';
import styled from 'styled-components';
import { Image, PageHeader } from '../../../components/UI';

type EventDetailsHeaderType = {
  event: any;
  diagonal?: boolean;
  noTitle?: boolean;
  stretch?: boolean;
};

export const EventDetailsHeader: FC<EventDetailsHeaderType> = ({ event, stretch, diagonal, noTitle }) => {
  if (!event) {
    return null;
  }

  let posterUrl = event.optionPosterUrl;
  if (!posterUrl) {
    posterUrl = event.posterUrl;
  }

  return (
    <Header diagonal={diagonal} stretch={stretch}>
      <Poster src={posterUrl} alt={event.title} title={event.title} />
      {!noTitle && (
        <TitleWrapper>
          <Title>{event.title}</Title>
          <Subtitle>{event.subTitle}</Subtitle>
        </TitleWrapper>
      )}
    </Header>
  );
};

const TitleWrapper = styled.div`
  z-index: 1;
  margin-bottom: 10vh;
`;

const Title = styled.h1`
  font-size: 36px;
  font-family: ${(props) => props.theme.fonts.bolderFont};
  text-align: center;
  margin: 0 0 5px 0;
`;

const Subtitle = styled.h2`
  font-size: 14px;
  margin: 0;
  text-align: center;
`;

export const Poster = styled(Image)`
  position: absolute;
  object-position: 50% 20%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
`;

const Header = styled(PageHeader)`
  justify-content: flex-end;
`;
