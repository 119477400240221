import React, { FC, memo, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button } from './button';
import { Utils } from '../../services';

const CopyButton: FC<CopyButtonPropsType> = memo(({ value, text, title, isLoading }) => {
  const { t } = useTranslation();
  let inputDOM: any;
  const copyDelayMs = 5 * 1000;

  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (!isCopied) {
      return;
    }
    setTimeout(() => setIsCopied(false), copyDelayMs);
  }, [copyDelayMs, isCopied]);

  const copyToClipboard = (e: any) => {
    if (e) {
      e.preventDefault();
    }
    if (inputDOM) {
      if (Utils.isIOs()) {
        const range = document.createRange();
        range.selectNodeContents(inputDOM);
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        inputDOM.setSelectionRange(0, 999999);
      } else {
        inputDOM.select();
      }
      document.execCommand('copy');
      setIsCopied(true);
    }
  };

  const btnText = isCopied ? t('Share.Copied') : text;
  return (
    <>
      <Text ref={(item) => (inputDOM = item)} tabIndex={-1} value={value} readOnly />
      <Button
        key="big"
        copy={!btnText}
        type="button"
        onClick={(e) => copyToClipboard(e)}
        title={title}
        disabled={isLoading || isCopied}
      >
        {btnText}
      </Button>
    </>
  );
});

const Text = styled.textarea`
  width: 0;
  height: 0;
  opacity: 0;
  flex: 0;
`;

export default CopyButton;

type CopyButtonPropsType = {
  value: string;
  title?: string;
  text?: string;
  isLoading?: boolean;
};
