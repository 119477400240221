import dayjs from 'dayjs';

export type EventInformationType = {
  title: string;
  slug: string;
  posterUrl: string;
  eventDate: Date;
  city: string;
  domainTypes: number[];
};

export type EventGenreType = {
  key: string;
  title: string;
  imageUrl: string;
  subTitle: string;
  date: string;
  city: string;
  domainTypes: number[];
};

export type GenreType = {
  id: number;
  slug: string;
  title: string;
  imageUrl: string;
  eventsCount: number;
};

export type GetEventByTypeParams = {
  eventType: EventType;
  search?: string;
  date?: Date;
  genreId?: number | string;
  count?: number;
  city?: string;
};

export type BookParams = {
  eventSlug: string;
  distributorSlug?: string;
};

export type EventTicketGroupsParams = {
  eventSlug: string;
  sectorSlug: string;
  priceFrom: number;
  priceTo: number;
  minTicketCount: number;
  lowPrice: boolean;
  parking: boolean;
  wheelchair: boolean;
  binoculars: string;
};

export enum EventType {
  None = 0,
  Popular = 1,
  Tour = 2,
  Near = 4,
  Genre = 8,
  Date = 16,
}

export type VenueType = {
  title: string;
  slug: string;
  address: string;
  city: string;
  schemaUrl?: string;
  schema?: string;
  country: string;
  tEVenueId: string;
  configurationId: number;
};

export type EventBookType = {
  title: string;
  subTitle: string;
  slug: string;
  posterUrl: string;
  optionPosterUrl?: string;
  eventDate: Date;
  maxPurchase: number;
  isTicketsAvailable: boolean;
  isRegionAvailable: boolean;
  isCached: boolean;
  isDistributionAvailable: boolean;
  domainTypes: number[];
  venue: VenueType;
  currency: string;
  maxPrice: number;
  minPrice: number;
  fees: FeesType[];
  showFees: boolean;
};

export type FeesType = {
  feeTitle: string;
  value: number;
};

export type UserEventType = {
  eventId: number;
  slug: string;
  title: string;
  posterUrl: string;
  eventDate: dayjs.Dayjs;
  city: string;
  hall: string;
};

export type SearchEventsParams = {
  name: string;
  city: string;
};

export type SectorsType = {
  sectors: SectorType[];
};
export type SectorType = {
  id: number;
  slug: string;
  ticketGroups: TicketGroupType[];
};

export type TicketGroupType = {
  id: number;
  rowId: number;
  quantity: number;
  price: number;
  rowTitle: string;
  sectionName: string;
  splits: number[];
  tEvoId: number;
  type: string;
  row: string;
  section: string;
  availableQuantity: number;
  wholesalePrice: number;
  eticket: boolean;
  instantDelivery: boolean;
  intendedForInstantsDelivery: boolean;
  inHand: boolean;
  inHandOn?: string;
  featured: boolean;
  retailPrice: number;
  format: string;
  faceValue: any;
  wheelchair: boolean;
  publicNotes: string;
};