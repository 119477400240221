import { useLocation } from 'react-router';
import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { useSelector } from '../configureStore';
import { useDispatch } from '../store';
import { IVenueSectorStoreState } from '../store/ticket/types';
import { IEventStoreState, ISeatStoreState } from '../store/event/types';
import { GetTickets, paymentProcessV1 } from '../store/checkoutV1/actions';
import CheckoutHelper from './checkoutHelper';
import * as PT from '../store/paymentV1/types';
import { IPaymentInitiateInfo, PaymentCancel, PaymentComplete } from '../store/paymentV1/types';
import {
  useCancelMutation,
  useCompleteMutation,
  useInitiateMutation,
  useInitiateV1Mutation,
  useProcessMutation,
  useProcessV1Mutation,
} from '../core/api/paymentSlice';
import { Pages, PaymentMethod, PaymentProvider } from '../store/enums';
import { distributionClearV1 } from '../store/distributionV1/actions';
import { IBookStoreState } from '../store/bookV1/types';
import { IDistributionStoreState } from '../store/distributionV1/types';
import { useRoutesHelper } from './routesHelper';
import { ICheckoutStoreState } from '../store/checkoutV1/types';
import Constants from '../store/constants';
import { distributionClear } from '../store/distribution/actions';
import { useUtils } from '../services/useUtils';
import { SelectedTicketGroupType } from '../containers/book/book';
import { PaymentInitiate, PaymentProcessType } from '../core/models/payment';
import { BookType } from '../store/book/types';
import { DistributionType } from '../store/distribution/types';
import { CheckoutType } from '../store/checkout/types';
import { paymentProcess, setPaymentMethod, setPoints } from '../store/checkout/actions';
import { IUserBalanceStoreState, IUserStoreState } from '../store/userV1/types';
import { FeesType } from '../core/models/events';

export const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

export const useContractData = () => {
  const menuStorage = useSelector((state) => state.menu.contracts);

  const bonusProgramItems = menuStorage?.bonusPrograms?.map((bonusProgram) => ({
    path: bonusProgram.fileUrl,
    title: bonusProgram.language,
  }));
  const publicContractItems = menuStorage?.publicContracts?.map((publicContract) => ({
    path: publicContract.fileUrl,
    title: publicContract.language,
  }));

  return { bonusProgramItems, publicContractItems };
};

export const useScript = (url, callback) => {
  useEffect(() => {
    const script = document.createElement('script');

    script.defer = true;
    script.async = true;
    script.src = url;
    script.onload = callback;

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, [url, callback]);
};

export const usePriceCalculate = (
  sector: IVenueSectorStoreState,
  event: IEventStoreState,
  seats: ISeatStoreState[],
  quotaId?: string
) => {
  if (!event || !sector || !sector.ticketQuotas || !quotaId) {
    return {};
  }
  const quota = sector.ticketQuotas.find((q) => q.id === +quotaId);

  const tickets = seats.filter((s) => s.quotaId === +quotaId);
  const defaultPaymentMethod = event.paymentMethods && event.paymentMethods.length > 0 ? event.paymentMethods[0] : null;
  const ticketPrice = quota?.price;
  const ticketsCount = tickets.length;
  let total = ticketPrice * ticketsCount;
  let { currency } = quota;
  let sectorTitle = '';

  if (defaultPaymentMethod.useConvertion) {
    total = Math.round(total * defaultPaymentMethod.convertionRate * 100) / 100;
    currency = defaultPaymentMethod.convertionCurrency;
  }

  if (!sector.noSeats && !!seats && !!seats.length) {
    sectorTitle = `${ticketsCount} x ${sector.title}`;
  }

  return {
    currency,
    sectorTitle,
    total,
    quota,
    tickets,
    ticketPrice,
  };
};

export const useTicketTotalCalculateV1 = (event: IEventStoreState, seats: ISeatStoreState[]) => {
  if (!seats.length) {
    return {};
  }

  const defaultPaymentMethod = event.paymentMethods && event.paymentMethods.length > 0 ? event.paymentMethods[0] : null;
  let total = 0;
  let currency: string;

  seats.forEach((ticket) => {
    event.sectors.forEach((sector) => {
      const countTickets = sector.ticketQuotas.find((st) => st.id === ticket.quotaId);
      if (countTickets) {
        total += countTickets.price;
        currency = countTickets.currency;
      }
    });
  });

  if (defaultPaymentMethod.useConvertion) {
    total = Math.round(total * defaultPaymentMethod.convertionRate * 100) / 100;
    currency = defaultPaymentMethod.convertionCurrency;
  }
  return {
    total,
    currency,
  };
};

export const useHandleSeatSelectedV1 = (seats: ISeatStoreState[], event: IEventStoreState) => {
  const { t } = useTranslation();
  return seats.map((ticket) => {
    const sector = event.sectors.find((x) => x.slug === ticket.sectorSlug);
    const quota = sector.ticketQuotas.find((x) => x.id === ticket.quotaId);
    const title = `${t('Book.Sector')} ${ticket.sectorTitle}${
      ticket.rowSlug ? `, ${t('Book.Row')} ${ticket.rowSlug}` : ``
    }${ticket.seatSlug ? `, ${t('Book.Seat')} ${ticket.seatSlug}.` : ``}`;
    const price = `${quota.price} ${quota.currency}`;
    return { title, price };
  });
};

export const usePrevious = <T>(value: T): T => {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

export const useInitiateV1 = () => {
  const dispatch = useDispatch();
  const [initiateRequest] = useInitiateV1Mutation();

  const bookPaymentModel = (
    book: IBookStoreState,
    distribution: IDistributionStoreState,
    usePoints?: boolean
  ): IPaymentInitiateInfo => {
    const paymentModel = new PT.PaymentInitiateInfo();
    paymentModel.eventSlug = book.event.slug;
    paymentModel.paymentMethod = book.event.paymentMethods[0].method;
    paymentModel.token = book.token;
    paymentModel.tickets = GetTickets(book.seats);

    if (usePoints) {
      paymentModel.usePoints = usePoints;
    }
    if (CheckoutHelper.getIsDistributionInfoValid(distribution)) {
      paymentModel.distributorSlug = distribution.distributorSlug;
    } else {
      dispatch(distributionClearV1());
    }
    return paymentModel;
  };

  const initiate = async (book: IBookStoreState, distribution: IDistributionStoreState, usePoints?: boolean) => {
    const model = bookPaymentModel(book, distribution, usePoints);
    await initiateRequest(model);
  };

  return {
    initiate,
  };
};

export const useCheckoutV1 = () => {
  const dispatch = useDispatch();
  const { getBaseUrl } = useRoutesHelper();

  const [completeRequest] = useCompleteMutation();
  const [cancelRequest] = useCancelMutation();
  const [processRequest] = useProcessV1Mutation();

  const paymentCompleteModel = (externalPaymentId: string) =>
    new PaymentComplete({
      externalPaymentId,
      paymentProvider: PaymentProvider.Stripe,
    });

  const checkoutComplete = async (externalPaymentId: string) => {
    const model = paymentCompleteModel(externalPaymentId);
    return await completeRequest(model);
  };

  const paymentProcessModel = (checkout, distribution, usePoints) => {
    const paymentModel = new PT.PaymentProcessInfo(checkout);
    const bookResultLinkTemplate = `${getBaseUrl()}/${Pages.BookV1}/payment/result`;
    paymentModel.paymentMethod = checkout.paymentMethod;
    paymentModel.returnUrl = `${bookResultLinkTemplate}`;
    paymentModel.returnErrorUrl = bookResultLinkTemplate;
    paymentModel.retailWebsite = getBaseUrl();

    if (usePoints) {
      paymentModel.usePoints = usePoints;
    }
    if (CheckoutHelper.getIsDistributionInfoValid(distribution)) {
      paymentModel.distributorSlug = distribution.distributorSlug;
    } else {
      dispatch(distributionClearV1());
    }

    dispatch(paymentProcessV1(paymentModel));
    return paymentModel;
  };

  const checkoutProcess = async (checkout, distribution, usePoints?: boolean) => {
    const model = paymentProcessModel(checkout, distribution, usePoints);
    await processRequest(model);
  };

  const paymentCancelModel = (checkout: ICheckoutStoreState) =>
    new PaymentCancel({
      referenceNumber: checkout.process.referenceNumber,
      paymentProvider: PaymentProvider.Stripe,
    });

  const checkoutCancel = async (checkout: ICheckoutStoreState) => {
    const model = paymentCancelModel(checkout);
    await cancelRequest(model);
  };

  return {
    checkoutComplete,
    checkoutCancel,
    checkoutProcess,
  };
};

export const useEventHelpers = () => {
  const getEventMetaDescription = (event): string => {
    if (!event) {
      return null;
    }
    const title = `${event.title} - ${event.subTitle}`;
    const date = `${dayjs(event.eventDate).format(Constants.DateFormat)}`;
    const place = event.venue ? `${event.venue.title}, ${event.venue.city}` : '';
    return `${title} ${Constants.BulletDelimiter} ${date} ${place && `${Constants.BulletDelimiter} ${place}`}`;
  };

  return { getEventMetaDescription };
};

export const useCheckoutHelpers = () => {
  const { isInitiated } = useCheckoutHelper();

  const getIsDistributionInfoValid = (distribution: DistributionType): boolean =>
    distribution?.distributorSlug && dayjs().isBefore(distribution.expirationDate);

  const getDistributionInfo = (checkout: CheckoutType, distributionInfo: DistributionType): string => {
    if (!getIsDistributionInfoValid(distributionInfo)) {
      return null;
    }
    const isValidDistributor = !checkout || (isInitiated(checkout) && checkout.initiate.isDistributorExist);
    return isValidDistributor ? 'Book.NameOfYourDistributor' : 'Book.DistributorNotFound';
  };

  return {
    getDistributionInfo,
  };
};

export const useTicketTotalCalculate = (
  ticketGroups: SelectedTicketGroupType[],
  currency: string,
  fees: FeesType[] = []
): string => {
  const { getEmojiFlagByCurrency, getTicketsPostfixByCount } = useUtils();
  if (!ticketGroups.length || !currency) {
    return;
  }

  let total = 0;
  let count = 0;

  ticketGroups.forEach((ticket) => {
    // const addedFees = fees.reduce((acc, currentValue) => acc + currentValue.value, 0);
    // const ticketWithFees = ticket.price + ticket.price * (1 / (1 - addedFees) - 1);
    // total += ticketWithFees * ticket.count;
    // count += ticket.count;
    total += ticket.price * ticket.count;
    count += ticket.count;
  });

  const ticketPostfixByCount = getTicketsPostfixByCount(count);

  return `${getEmojiFlagByCurrency(currency)} ${total.toFixed(2)} for ${count} ${ticketPostfixByCount}`;
};

export const useCheckout = () => {
  const dispatch = useDispatch();
  const { getBaseUrl } = useRoutesHelper();
  const { isDistributionValid } = useCheckoutHelper();

  const [completeRequest] = useCompleteMutation();
  const [cancelRequest] = useCancelMutation();
  const [processRequest] = useProcessMutation();

  const paymentCompleteModel = (externalPaymentId: string) =>
    new PaymentComplete({
      externalPaymentId,
      paymentProvider: PaymentProvider.Stripe,
    });

  const checkoutComplete = async (externalPaymentId: string) => {
    const model = paymentCompleteModel(externalPaymentId);
    return await completeRequest(model);
  };

  const paymentProcessModel = (checkout: CheckoutType, distribution: DistributionType, usePoints: boolean) => {
    const bookResultLinkTemplate = `${getBaseUrl()}/book/payment/result`;

    const paymentModel: PaymentProcessType = {
      eventSlug: checkout.initiate.event.slug,
      ticketGroups: checkout.initiate.ticketGroups,
      email: checkout.email,
      paymentMethod: checkout.paymentMethod,
      returnUrl: bookResultLinkTemplate,
      returnErrorUrl: bookResultLinkTemplate,
      retailWebsite: getBaseUrl(),
      usePoints,
      distributorSlug: distribution.distributorSlug,
    };

    if (!isDistributionValid(distribution)) {
      paymentModel.distributorSlug = null;
      dispatch(distributionClear());
    }

    dispatch(
      paymentProcess({
        returnUrl: paymentModel.returnUrl,
        returnErrorUrl: paymentModel.returnErrorUrl,
        retailWebsite: paymentModel.retailWebsite,
        usePoints: paymentModel.usePoints,
      })
    );
    return paymentModel;
  };

  const checkoutProcess = async (checkout: CheckoutType, distribution: DistributionType, usePoints?: boolean) => {
    const model = paymentProcessModel(checkout, distribution, usePoints);
    await processRequest(model);
  };

  const paymentCancelModel = (checkout: CheckoutType) =>
    new PaymentCancel({
      referenceNumber: checkout.process.referenceNumber,
      paymentProvider: PaymentProvider.Stripe,
    });

  const checkoutCancel = async (checkout: CheckoutType) => {
    const model = paymentCancelModel(checkout);
    await cancelRequest(model);
  };

  return {
    checkoutComplete,
    checkoutCancel,
    checkoutProcess,
  };
};

export const useInitiate = () => {
  const dispatch = useDispatch();
  const { isDistributionValid } = useCheckoutHelper();
  const [initiateRequest] = useInitiateMutation();

  const bookPaymentModel = (book: BookType, distribution: DistributionType, usePoints: boolean) => {
    const paymentModel: PaymentInitiate = {
      eventSlug: book.event.slug,
      ticketGroups: book.ticketGroups,
      paymentMethod: PaymentMethod.StripeBankCard,
      usePoints,
      distributorSlug: distribution.distributorSlug,
    };

    if (!isDistributionValid(distribution)) {
      paymentModel.distributorSlug = null;
      dispatch(distributionClear());
    }
    dispatch(setPoints(usePoints));
    dispatch(setPaymentMethod(paymentModel.paymentMethod));

    return paymentModel;
  };

  const initiate = async (book: BookType, distribution: DistributionType, usePoints = false) => {
    const model = bookPaymentModel(book, distribution, usePoints);
    await initiateRequest(model);
  };

  return {
    initiate,
  };
};

export const useCheckoutHelper = () => {
  const { getBalanceByCurrency } = useUserHelper();

  const isInitiated = (checkout: CheckoutType): boolean => !!checkout?.initiate;

  const isDistributionValid = (distribution: DistributionType): boolean =>
    distribution?.distributorSlug && dayjs().isBefore(distribution?.expirationDate);

  const isEmailSet = (checkout: CheckoutType): boolean => isInitiated(checkout) && !!checkout?.email;

  const isProcessed = (checkout: CheckoutType): boolean => isEmailSet(checkout) && !!checkout?.process;

  const isCompleted = (checkout: CheckoutType): boolean => isProcessed(checkout) && checkout?.complete?.isCompleted;

  const isInProcess = (checkout: CheckoutType): boolean => isProcessed(checkout) && checkout?.complete?.isProcessing;

  const isRedirectingMethod = (checkout: CheckoutType): boolean =>
    isProcessed(checkout) &&
    [PaymentProvider.Fanaticka].includes(checkout.process.paymentProvider as PaymentProvider) &&
    !!checkout.process.paymentRedirectUrl;

  const isAvailableForProcessing = (checkout: CheckoutType): boolean => {
    if (isEmpty(checkout.initiate?.paymentMethods)) {
      return false;
    }
    const { method } = checkout.initiate.paymentMethods[0];

    return (
      isEmailSet(checkout) &&
      !isProcessed(checkout) &&
      (method === PaymentMethod.BankCard || method === PaymentMethod.StripeBankCard)
    );
  };

  const getIsDistributionInfoValid = (distribution: DistributionType): boolean =>
    distribution?.distributorSlug && dayjs().isBefore(distribution.expirationDate);

  const getDistributionInfo = (checkout: CheckoutType, distributionInfo: DistributionType): string => {
    if (!getIsDistributionInfoValid(distributionInfo)) {
      return null;
    }
    const isValidDistributor = !checkout || (isInitiated(checkout) && checkout.initiate.isDistributorExist);
    return isValidDistributor ? 'Book.NameOfYourDistributor' : 'Book.DistributorNotFound';
  };

  const getIsEnoughPoints = (checkout: CheckoutType, user: IUserStoreState, currency: string): boolean => {
    if (!user || !isEmailSet(checkout)) {
      return false;
    }
    const currencyBalance = getBalanceByCurrency(user, currency);
    if (!currencyBalance) {
      return false;
    }

    const minPricePayByPoints = checkout.initiate.pricePayByPoints;
    return currencyBalance.amount >= minPricePayByPoints;
  };

  const hasPaymentMethods = (checkout: CheckoutType): boolean => {
    if (isEmpty(checkout.initiate?.paymentMethods)) {
      return false;
    }
    return isInitiated(checkout) && checkout.initiate.paymentMethods.length > 0;
  };

  const hasDifferentPaymentMethods = (checkout: CheckoutType): boolean =>
    hasPaymentMethods(checkout) && checkout.initiate.paymentMethods.length > 1;

  const isNeedPaymentForm = (checkout: CheckoutType): boolean =>
    isProcessed(checkout) &&
    !hasDifferentPaymentMethods(checkout) &&
    [PaymentProvider.Stripe].includes(checkout.process.paymentProvider as PaymentProvider);

  return {
    isInitiated,
    isEmailSet,
    isDistributionValid,
    isProcessed,
    isCompleted,
    isInProcess,
    isAvailableForProcessing,
    isRedirectingMethod,
    getDistributionInfo,
    getIsEnoughPoints,
    isNeedPaymentForm,
  };
};

const useUserHelper = () => {
  const getBalanceByCurrency = (user: IUserStoreState, currency: string): IUserBalanceStoreState =>
    !!user && !!currency && user.stats.balances.find((b) => b.currency === currency);

  return { getBalanceByCurrency };
};

export const useDebouncedFunction = (callback, delay, cleanUp = false) => {
  const timeoutRef = useRef(null);

  function clearTimer() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = undefined;
    }
  }

  useEffect(() => (cleanUp ? clearTimer : undefined), [cleanUp]);

  return (...args) => {
    clearTimer();
    timeoutRef.current = setTimeout(() => callback(...args), delay);
  };
};
