import React, { ChangeEvent, FC, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { makeStyles, withStyles } from 'tss-react/mui';
import Dialog from '@mui/material/Dialog';
import { DialogContent, Grid, ListItem, ListItemText } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import Divider from '@mui/material/Divider';
import InputBase from '@mui/material/InputBase';
import { IGlobalStoreState } from '../../store';
import { ILocationStoreState } from '../../store/location/types';
import { Routes } from '../../store/routes';
import { useGetLocationV1Query } from '../../core/api/eventsSlice';
import { useRoutesHelper } from '../../helpers/routesHelper';

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    height: '54px',
    borderTopRightRadius: 35,
    borderBottomRightRadius: 35,
    borderRadius: 0,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    minWidth: '100%',
  },
  iconButton: {
    padding: 10,
  },
  locationPaper: {
    borderRadius: 0,
  },
  gridLocation: {
    minWidth: 50,
  },
  gridIconLocation: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    right: -5,
  },
  itemCity: {
    width: '100%',
    height: 400,
    maxWidth: 300,
    backgroundColor: theme.palette.background.paper,
  },
  paperInput: {
    borderRadius: '40px',
    minHeight: 44,
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #D8D8D8',
    overflow: 'hidden',
  },
  gridInput: {
    marginBottom: 19,
    padding: '0px 24px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 16px',
    },
  },
  dialogContent: {
    padding: 0,
  },
  listItemText: {
    '& span': {
      fontSize: 14,
      lineHeight: '14px',
      padding: '0px 24px',
      [theme.breakpoints.down('sm')]: {
        padding: '0px 16px',
      },
    },
  },
  dialog: {
    width: 528,
    height: 599,
    borderRadius: 12,
  },
  listLink: {
    padding: 0,
    height: 35,
  },
  locationIcon: {
    color: '1px solid #1C1C1C',
    position: 'absolute',
    top: 3,
  },
}));

const styles = (theme): ReturnType<typeof styles> => ({
  container: {
    margin: 0,
    maxWidth: 528,
    padding: '20px 10px 8px 24px',
    [theme.breakpoints.down('sm')]: {
      padding: '20px 10px 8px 16px',
    },
    display: 'flex',
    justifyContent: 'space-between',
  },
  closeButton: {
    color: theme.palette.grey[500],
    padding: 10,
  },
  titleText: {
    fontSize: 14,
    lineHeight: '14px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    alignSelf: 'center',
  },
});

export interface DialogTitleProps {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
  classes: Record<string, string>;
}

const DialogTitle = withStyles((props: DialogTitleProps) => {
  const { children, classes, onClose } = props;
  return (
    <div className={classes.container}>
      <Typography className={classes.titleText}>{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose} size="large">
          <CloseIcon />
        </IconButton>
      ) : null}
    </div>
  );
}, styles);

export const ListItemLink = (props) => <ListItem key={props.id} button component={'a'} {...props} />;

export const PopupLocationV1: FC<popupLocationType> = memo(({ open, handleClose, setCity }) => {
  const locationCity = useSelector<IGlobalStoreState, ILocationStoreState>((state) => state.location);
  const [search, setSearch] = useState<string>('');

  const { data: cities, isLoading } = useGetLocationV1Query();

  const [filteredCities, setFilteredCities] = useState(!isLoading ? cities : []);

  const { getEventsLocationV1 } = useRoutesHelper();
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    if (!cities?.length) {
      return;
    }

    const filteredCities = cities.filter((item) =>
      Object.values(item.value).join('').toLowerCase().includes(search.toLowerCase())
    );
    setFilteredCities(filteredCities);
  }, [search]);

  const handleRouteCity = (city: string) => {
    const parseCity = city.split(',')[0].trim();
    setCity(parseCity);
    navigate(getEventsLocationV1(parseCity), { replace: true });
    handleClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      classes={{ paper: classes.dialog }}
    >
      <DialogTitle classes={classes} id="customized-dialog-title" onClose={handleClose}>
        {t<string>('Dashboard.ChooseCity')}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid item lg={12} xs={12} sm={12} md={12} xl={12} className={classes.gridInput}>
          <Paper elevation={0} className={classes.paperInput}>
            <Grid container>
              <Grid item xs={1} sm={1} md={1} lg={1} xl={1} className={classes.gridIconLocation}>
                <LocationOnOutlinedIcon className={classes.locationIcon} />
              </Grid>
              <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
                <InputBase
                  value={search}
                  onChange={onChangeHandler}
                  className={classes.input}
                  placeholder={locationCity?.location}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Divider orientation="horizontal" variant={'fullWidth'} />
        {!isLoading ? (
          <Grid container style={{ marginTop: 14.5 }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              {search.length
                ? filteredCities.map((city) => (
                    <ListItemLink
                      key={city.id}
                      to={Routes.MainSortLocation}
                      onClick={() => handleRouteCity(city.value)}
                      className={classes.listLink}
                    >
                      <ListItemText primary={city.value} className={classes.listItemText} />
                    </ListItemLink>
                  ))
                : cities.map((city) => (
                    <ListItemLink
                      key={city.id}
                      to={Routes.MainSortLocation}
                      onClick={() => handleRouteCity(city.value)}
                      className={classes.listLink}
                    >
                      <ListItemText primary={city.value} className={classes.listItemText} />
                    </ListItemLink>
                  ))}
            </Grid>
          </Grid>
        ) : null}
      </DialogContent>
    </Dialog>
  );
});

type popupLocationType = {
  open: boolean;
  handleClose: () => void;
  setCity: (city: string) => void;
};
