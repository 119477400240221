import React from 'react';
import { Skeleton } from '@mui/material';
import { Page } from '../UI';
import PageFooter from '../UI/page/pageFooter';
import { EventInfoWrapper } from '../../containers/eventDetails/eventDetails';

export const SkeletonEventDetails = () => {
  const imageOverrideStyle = {
    transformOrigin: '0 0',
    height: '51vh',
    width: '100%',
    transform: 'scale(1) skewY(-9deg)',
  };
  const buttonOverrideStyle = {
    width: '100%',
    height: '44px',
    borderRadius: '24px',
    transform: 'scale(1)',
  };
  const contentOverrideStyle = {
    width: '260px',
    height: '20vh',
    transform: 'scale(1)',
  };
  const descriptionOverrideStyle = {
    width: '95%',
    height: '100px',
    transform: 'scale(1)',
    marginTop: '10px',
  };

  return (
    <Page>
      <Skeleton animation="wave" sx={imageOverrideStyle} />
      <EventInfoWrapper>
        <Skeleton animation={'wave'} sx={contentOverrideStyle} />
        <Skeleton animation={'wave'} sx={descriptionOverrideStyle} />
      </EventInfoWrapper>
      <PageFooter>
        <Skeleton animation="wave" sx={buttonOverrideStyle} />
      </PageFooter>
    </Page>
  );
};
