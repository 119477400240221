import React, { FC } from 'react';
import styled from 'styled-components';

import { AnimatePresence, motion } from 'framer-motion';
import UserTicketItem from './userTicketItem';
import { IProfileTicketStoreState } from '../../store/ticket/ProfileTicketStoreState';
import { PopUp } from '../../store/animations';

interface IUserTicketsListProps {
  tickets: Array<IProfileTicketStoreState>;

  cancelSellTicket: (ticketId: string) => Promise<IProfileTicketStoreState>;
  go: (url: string) => void;
}

export const UserTicketsList: FC<IUserTicketsListProps> = ({ tickets, go, cancelSellTicket }) => (
  <AnimatePresence>
    <TicketsList variants={PopUp} initial={'enter'} animate={'exit'}>
      {tickets.map((ticket, index) => (
        <TicketsListItem key={index}>
          <UserTicketItem
            ticket={ticket}
            cancelSellTicket={(ticketId) => cancelSellTicket(ticketId)}
            go={(url) => go(url)}
          />
        </TicketsListItem>
      ))}
    </TicketsList>
  </AnimatePresence>
);

const TicketsList = styled(motion.ul)`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const TicketsListItem = styled.li`
  margin-bottom: 10px;
`;
