import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import styled from 'styled-components';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { useDispatch } from '../../../store';
import { EventTypeV1 } from '../../../store/event/types';
import { citySelectorHide, citySelectorShow } from '../../../store/header/actions';
import { AnalyticService } from '../../../services';
import { SellEventButton } from '../../../components/event/sellEventButton';
import { CategoryCarouselV1 } from '../../../components/event/categoryCarouselV1';
import { CategorizedEventCarouselV1 } from '../../../components/event/categorizedEventCarouselV1';
import { SearchInputV1 } from '../../../components/event/searchInputV1';
import { DatePicker } from '../../../components/event/datePicker/datePicker';
import { DatepickerCarousel } from '../../../components/event/datePicker/datepickerCarousel';
import { Footer } from '../../footer';
import { EventsV1 } from '../../../components/event/eventsV1';
import { useQuery } from '../../../helpers/customHooks';
import { Routes } from '../../../store/routes';
import { GlobalStateType } from '../../../configureStore';
import { useGetEventsCategoriesQuery, useLazyGetEventsByTypeV1Query } from '../../../core/api/eventsSlice';

const useStyles = makeStyles()(() => ({
  gridCategories: {
    marginBottom: 43,
  },
  containerPlayer: {
    position: 'absolute',
    width: '100%',
    height: '470px',
    top: 0,
    zIndex: -1,
  },
  gradientPlayer: {
    position: 'absolute',
    zIndex: 10,
    background: 'linear-gradient(#000000 0%, rgba(0, 0, 0, 0.4) 23.03%, rgba(28, 28, 28, 0.3) 81.88%, #1C1C1C 100%)',
    minWidth: '100%',
    minHeight: '100%',
  },
  contentPage: {
    marginTop: '134px',
    zIndex: 1,
    padding: 16,
  },
  headingText: {
    textTransform: 'uppercase',
    fontSize: 14,
    lineHeight: '14px',
    cursor: 'default',
  },
  searchInput: {
    paddingTop: '50px',
    paddingBottom: '170px',
  },
  videoPlayer: {
    height: 'inherit',
    objectFit: 'cover',
    width: '100%',
    zIndex: 0,
  },
  contentPlayer: {
    position: 'relative',
    height: '100%',
    width: '100%',
  },
}));

export const DashboardPageV1 = () => {
  const dispatch = useDispatch();
  const analyticSrv = new AnalyticService();
  const query = useQuery();
  const { t } = useTranslation();

  const cityQuery = query
    .get('city')
    ?.trim()
    .replace(/[0-9,%]/, ' ');

  const showDatepicker = useSelector<GlobalStateType, boolean>((state) => state.appSettings.showDashboardDatepicker);
  const showPopularEvents = useSelector<GlobalStateType, boolean>(
    (state) => state.appSettings.showDashboardPopularEvents
  );
  const showCategories = useSelector<GlobalStateType, boolean>((state) => state.appSettings.showDashboardCategories);
  const showPopularTours = useSelector<GlobalStateType, boolean>(
    (state) => state.appSettings.showDashboardPopularTours
  );
  const showDashboardEventsByCategories = useSelector<GlobalStateType, boolean>(
    (state) => state.appSettings.showDashboardEventsByCategories
  );
  const dashboardCarouselColumnEventCount = useSelector<GlobalStateType, number>(
    (state) => state.appSettings.dashboardCarouselColumnEventCount
  );
  const dashboardCarouselEventCount = useSelector<GlobalStateType, number>(
    (state) => state.appSettings.dashboardCarouselEventCount
  );

  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [hidePopularTours, setHideDashboardPopularTours] = useState<boolean>(false);
  const [city, setCity] = useState<string>(cityQuery || '');
  const [selectedCategoryId, setSelectedCategoryId] = useState<number>(null);

  const { classes } = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    analyticSrv.trackEvent('EventsList');
    dispatch(citySelectorShow());

    return () => {
      dispatch(citySelectorHide());
    };
  }, []);

  const { data: categories, isFetching: isLoadingCategories } = useGetEventsCategoriesQuery();
  const [getEventsByType] = useLazyGetEventsByTypeV1Query();

  const loadEvents = async (
    eventType: EventTypeV1,
    search?: string,
    date?: Date,
    count?: number,
    city?: string,
    categoryId?: number
  ) => {
    const { data } = await getEventsByType({
      eventType,
      search,
      date,
      count,
      city,
      categoryId,
    });

    return data;
  };

  const loadEventsByType = async (eventType) => {
    const events = await loadEvents(eventType);

    if (events.length <= 1) {
      setHideDashboardPopularTours(true);
    }

    return events;
  };

  const handleDelete = useCallback(() => {
    setCity(null);
    navigate(Routes.Main, { replace: true });
  }, []);

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center" className={classes.contentPage}>
      <div className={classes.containerPlayer}>
        <div className={classes.contentPlayer}>
          <div className={classes.gradientPlayer} />
          <video className={classes.videoPlayer} preload="metadata" autoPlay loop muted playsInline>
            <source type="video/mp4" src="content/videos/demo_video.mp4" />
          </video>
        </div>
      </div>
      {false && (
        <Grid item lg={12} xs={12} sm={12} md={12} xl={12}>
          <TextContainer>
            <p>{t<string>('Dashboard.SloganFirstPart')}</p>
            <p>{t<string>('Dashboard.SloganSecondPart')}</p>
          </TextContainer>
        </Grid>
      )}

      <Grid item lg={12} xs={12} sm={12} md={12} xl={12} className={classes.searchInput}>
        <SearchInputV1 setCity={setCity} city={city} />
      </Grid>

      {false && (
        <Grid item>
          <SellEventButton />
        </Grid>
      )}

      {showPopularEvents && (
        <EventsV1
          city={city}
          load={() => loadEventsByType(EventTypeV1.Popular)}
          title={t<string>('Dashboard.PopularEvents')}
        />
      )}

      {showDashboardEventsByCategories && !city && (
        <Grid item lg={12} xs={12} sm={12} md={12} xl={12} className={classes.gridCategories}>
          <div>
            <Typography variant="h6" className={classes.headingText}>
              категории
            </Typography>
          </div>
          <CategoryCarouselV1 categories={categories} isLoading={isLoadingCategories} />
        </Grid>
      )}
      {showDatepicker && (
        <Grid item lg={12} xs={12} sm={12} md={12} xl={12}>
          <DatePicker
            selectedDate={selectedDate}
            handleDelete={handleDelete}
            city={city}
            onDateChanged={setSelectedDate}
            categories={categories}
            onCategoryChange={setSelectedCategoryId}
          />
        </Grid>
      )}

      {showDatepicker && (
        <Grid item lg={12} xs={12} sm={12} md={12} xl={12}>
          <DatepickerCarousel
            reloadDependencyValue={{ selectedDate, city, selectedCategoryId }}
            load={() =>
              loadEvents(EventTypeV1.Date, city, selectedDate, dashboardCarouselEventCount, '', selectedCategoryId)
            }
            defaultRowsCount={dashboardCarouselColumnEventCount}
          />
        </Grid>
      )}

      {showPopularTours && !hidePopularTours && (
        <EventsV1 title={t<string>('Dashboard.PopularTours')} load={() => loadEventsByType(EventTypeV1.Tour)} />
      )}

      {showCategories && (
        <CategorizedEventCarouselV1 categories={categories} isLoadingCategories={isLoadingCategories} />
      )}
      <Footer />
    </Grid>
  );
};

const TextContainer = styled.div`
  padding: 15px 25px;

  p {
    text-align: center;
    font-size: 14px;
    color: ${(props) => props.theme.colors.text};
    margin: 0;
    font-style: normal;
    font-weight: normal;
    line-height: 130%;
    /* or 18px */
  }

  @media screen and (min-width: 576px) {
    p {
      font-size: 18px;
    }
  }
`;
