import {
  Box,
  ButtonBase,
  Chip,
  createTheme,
  Dialog,
  Grid,
  Skeleton,
  SxProps,
  Theme,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { useTranslation } from 'react-i18next';
import ruLocale from 'date-fns/locale/ru';
import enLocale from 'date-fns/locale/en-US';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { RenderDays } from './renderDays';
import { CategoriesAccordion } from './categoriesAccordion';
import { IEventCategory } from '../../../store/event/types';
import { AppTheme } from '../../../settings/appTheme';
import { Pages } from '../../../store/enums';
import { useGetDatepickerDatesQuery } from '../../../core/api/eventsSlice';
import { useRoutesHelper } from '../../../helpers/routesHelper';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#FFBF00',
      light: '#000',
      dark: '#000',
      contrastText: '#fff',
    },
  },
});

const useStyles = makeStyles()((theme: Theme) => ({
  selectDateButton: {
    position: 'relative',
    height: '30px',
    minWidth: '110px',
    borderRadius: '20px',
    '&:hover, &$focusVisible': {
      zIndex: 1,
      '& $imageBackdrop': {
        opacity: 0.25,
      },
    },
  },
  selectDateImageBackdrop: {
    borderRadius: '24px',
    border: '1px solid rgb(107, 107, 107, 0.6)',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    transition:
      'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
  selectDateImageButton: {
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '24px',
  },
  selectDateImageTitle: {
    borderRadius: '24px',
    position: 'relative',
    padding: `${theme.spacing(0)} ${theme.spacing(1.75)}`,
    fontSize: '12px',
    lineHeight: '12px',
    fontFamily: 'inherit',
    zIndex: -1,
  },
  box: {
    overflow: 'scroll',
    height: '90px',
    overflowY: 'hidden',
    '&::-webkit-scrollbar': {
      height: 8,
    },
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
    margin: '0px',
  },
  skeletonItem: {
    borderRadius: '16px',
    width: '33px',
    height: '44px',
    marginRight: 5,
  },
  currentMonthDatePickerContainer: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '30px',
    marginBottom: 20,
    position: 'relative',
  },
  skeletonContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  skeleton: {
    display: 'flex',
    marginTop: 15,
  },
  chipCity: {
    display: 'flex',
    marginLeft: 'auto',
  },
  avatarLocation: {
    color: '#FFF',
    height: '16px !important',
    width: '16px !important',
  },
  chipLocationInfo: {
    border: '1px solid rgb(107, 107, 107, 0.6)',
    borderRadius: 35,
    height: 30,
    width: 110,
    overflow: 'auto',
    textOverflow: 'ellipsis',
    fontSize: 12,
    color: 'white',
    lineHeight: '12px',
    fontFamily: AppTheme.fonts.regularFont,
    display: 'flex',
    justifyContent: 'space-around',
    '& .MuiChip-deleteIcon': {
      color: 'rgba(255, 255, 255, 0.26)',
      '&:hover': {
        color: 'rgba(255, 255, 255, 0.4)',
      },
    },
  },
  backdrop: {
    zIndex: 1,
  },
}));

const popperSx: SxProps = {
  top: '50%!important',
  left: '50%!important',
  marginTop: '-129px',
  marginLeft: '-160px',
  width: '100%',
  height: '100%',
  '& .MuiPaper-root': {
    boxShadow:
      '0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)',
    background: '#424242',
    color: 'white',
    position: 'absolute',
    transformOrigin: 'center',
  },
  '& .MuiPickersDay-root': {
    backgroundColor: '#424242',
    color: 'white',
  },
  '& .MuiTypography-root': {
    color: 'rgba(255, 255, 255, 0.5)',
  },
  '& .MuiSvgIcon-root': {
    color: 'white',
  },
  '& .MuiButtonBase-root.MuiPickersDay-root.Mui-selected': {
    backgroundColor: '#FFBF00',
  },
  '& .MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected)': {
    border: 'none',
  },
};

export const DatePicker: FC<DatePickerType> = memo(
  ({ selectedDate, city, onDateChanged, handleDelete, categories, onCategoryChange }) => {
    const currentDay = new Date();
    const skeletonArray = Array.from(new Array(20));
    const { classes } = useStyles();

    const [baseDate, setBaseDate] = useState<Date>(currentDay);
    const [open, setOpen] = useState(false);

    const { i18n, t } = useTranslation();
    const { isActivePage } = useRoutesHelper();

    const isCategoryPage = isActivePage(Pages.CategoriesV1);

    const localeMap = {
      en: enLocale,
      ru: ruLocale,
    };
    const { data: dates, isLoading } = useGetDatepickerDatesQuery(baseDate);

    useEffect(() => {
      onDateChanged(baseDate);
    }, [baseDate]);

    const handleClickOpen = () => {
      setOpen((open) => !open);
    };

    const handleClickClose = () => {
      setOpen(false);
    };

    const RenderInput = useCallback(
      () => (
        <ButtonBase focusRipple onClick={handleClickOpen} className={classes.selectDateButton}>
          <span className={classes.selectDateImageBackdrop} />
          <span className={classes.selectDateImageButton}>
            <Typography component="span" variant="body1" color="inherit" className={classes.selectDateImageTitle}>
              {t<string>('Dashboard.ChooseDate')}
            </Typography>
          </span>
        </ButtonBase>
      ),
      []
    );

    const renderSkeleton = (
      <div className={classes.skeletonContainer}>
        <Skeleton animation="wave" variant="rectangular" height={'15px'} width={'100px'} />
        <div className={classes.skeleton}>
          {skeletonArray.map((item, idx) => (
            <Grid key={idx} item>
              <Skeleton animation="wave" variant="rectangular" className={classes.skeletonItem} />
            </Grid>
          ))}
        </div>
      </div>
    );

    return (
      <>
        <div className={classes.currentMonthDatePickerContainer}>
          <ThemeProvider theme={darkTheme}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={localeMap[i18n.language]}>
              <DesktopDatePicker
                value={baseDate}
                components={{
                  ActionBar: () => (
                    <Dialog className={classes.backdrop} disableEnforceFocus onClick={handleClickClose} open={open} />
                  ),
                }}
                open={open}
                onChange={(date: Date | null) => {
                  setBaseDate(date);
                  onDateChanged(date);
                }}
                onClose={handleClickClose}
                PopperProps={{ sx: popperSx }}
                renderInput={(params) => <RenderInput {...params} />}
              />
            </LocalizationProvider>
          </ThemeProvider>

          {!isCategoryPage && <CategoriesAccordion categories={categories} onCategoryChange={onCategoryChange} />}
          <div className={classes.chipCity}>
            {city && (
              <Chip
                className={classes.chipLocationInfo}
                avatar={<LocationOnOutlinedIcon className={classes.avatarLocation} />}
                label={city}
                onDelete={handleDelete}
                variant={'outlined'}
              />
            )}
          </div>
        </div>
        <Box id="dateContainer" className={`carousel ${classes.box}`}>
          <Grid container className={classes.gridList}>
            {isLoading ? (
              renderSkeleton
            ) : (
              <RenderDays dates={dates} onDateChanged={onDateChanged} selectedDate={selectedDate} />
            )}
          </Grid>
        </Box>
      </>
    );
  }
);

type DatePickerType = {
  onDateChanged: (date: Date) => void;
  city?: string;
  handleDelete?: () => void;
  selectedDate?: Date;
  categories?: IEventCategory[];
  onCategoryChange?: (genre: number) => void;
};
