import { HeaderStoreState } from './types';
import { createSlice } from '@reduxjs/toolkit';

const headerReducer = createSlice({
  name: 'headerReducer',
  initialState: new HeaderStoreState(),
  reducers: {
    playerShow: (state) => {
      return { ...state, showPlayer: true };
    },
    playerHide: (state) => {
      return { ...state, showPlayer: false };
    },
    citySelectorShow: (state) => {
      return { ...state, showCitySelector: true };
    },
    citySelectorHide: (state) => {
      return { ...state, showCitySelector: false };
    },
  },
});

export default headerReducer;
