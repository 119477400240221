import { createSlice } from '@reduxjs/toolkit';
import { MenuStoreState } from './types';

const menuReducer = createSlice({
  name: 'menuReducer',
  initialState: new MenuStoreState(null),
  reducers: {
    showMenu: (state) => {
      return { ...state, isShow: true };
    },
    hideMenu: (state) => {
      return { ...state, isShow: false };
    },
    initContract: (state, action) => {
      return { ...state, contracts: action.payload };
    },
  },
});

export default menuReducer;
