import React, { FC, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Header, Title } from '../checkout';
import { useInitiate } from '../../../helpers/customHooks';
import { PageContent } from '../../../components/UI';
import { IPaymentForm } from '../../../components/forms';
import { IUserStoreState } from '../../../store/userV1/types';
import { BookType } from '../../../store/book/types';
import { DistributionType } from '../../../store/distribution/types';
import { PaymentForm } from '../../../components/forms/paymentForm';
import { CheckoutType } from '../../../store/checkout/types';

export const PointsSelector: FC<PointsSelectorPropsType> = memo(
  ({
    checkout,
    book,
    redirectUrl,
    checkoutSteps,
    user,
    isAuthorized,
    handlePaymentFormSubmit,
    distribution,
    isLoading,
  }) => {
    const { t } = useTranslation();
    const { initiate } = useInitiate();

    if (redirectUrl) {
      window.location.href = redirectUrl;
    }

    const handleUsePointsChange = useCallback(async (usePoints: boolean) => {
      await initiate(book, distribution, usePoints);
    }, []);

    return (
      <PageContent roundCorners>
        <Header>
          {t('Checkout.Step')} 2 {t('Checkout.Of')} {checkoutSteps}
        </Header>
        <Title>{t<string>('Checkout.PayByCard')}</Title>
        {!redirectUrl && isAuthorized && (
          <PaymentForm
            isLoading={isLoading}
            checkout={checkout}
            book={book}
            user={user}
            onUsePointsChange={(usePoints: boolean) => handleUsePointsChange(usePoints)}
            onSubmit={(formModel: IPaymentForm) => {
              if (formModel) {
                handlePaymentFormSubmit(formModel.usePoints);
              }
            }}
          />
        )}
      </PageContent>
    );
  }
);

type PointsSelectorPropsType = {
  isLoading: boolean;
  checkout: CheckoutType;
  book: BookType;
  distribution: DistributionType;
  redirectUrl: string;
  checkoutSteps: number;
  isAuthorized: boolean;
  user: IUserStoreState;
  handlePaymentFormSubmit: (usePoints: boolean) => void;
};
