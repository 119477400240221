import React, { FC } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { AnimatePresence, motion } from 'framer-motion';
import { PopUp } from '../../../store/animations';
import { useUtils } from '../../../services/useUtils';

type PagePropsType = {
  bg?: any;
  fullsize?: boolean;
  isForm?: boolean;
  title?: string;
  description?: string;
  keywords?: Array<string>;
  imageUrl?: string;
  children: React.ReactNode;
};

export const Page: FC<PagePropsType> = ({ isForm, description, keywords, title, children, bg, imageUrl, fullsize }) => {
  const { toPageTitle, toMetaKeywords } = useUtils();

  const renderMeta = () => (
    <Helmet>
      <title>{toPageTitle(title)}</title>
      <meta name="og:title" content={toPageTitle(title)} />
      {description && <meta name="description" content={description} />}
      {description && <meta name="og:description" content={description} />}
      {keywords && <meta name="keywords" content={toMetaKeywords(keywords)} />}
      {imageUrl && <meta name="og:image" content={imageUrl} />}
    </Helmet>
  );

  return (
    <AnimatePresence>
      <AnimatedContainer fullsize={fullsize}>
        {renderMeta()}
        {bg && (
          <picture>
            <source srcSet={`${bg}.webp 1x, ${bg}@2x.webp 2x, ${bg}@3x.webp 3x`} type="image/webp" />
            <Background src={`${bg}.jpg`} srcSet={`${bg}@2x.jpg 2x, ${bg}@3x.jpg 3x`} />
          </picture>
        )}
        {isForm && <AnimatedForm noValidate>{children}</AnimatedForm>}
        {!isForm && children}
      </AnimatedContainer>
    </AnimatePresence>
  );
};

const Container = styled(motion.main)<PagePropsType>`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  padding-top: ${(props) => props.fullsize && '60px'};
  position: relative;
`;

const AnimatedContainer = (props) => (
  <Container variants={PopUp} initial={'enter'} animate={'exit'} ref={props.hostRef} {...props} />
);

const AnimatedForm = ({ hostRef, children }: any) => <Form ref={hostRef}>{children}</Form>;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Background = styled.img`
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  max-width: ${(props) => props.theme.maxWidth};
  height: 100%;
  object-fit: cover;
  object-position: center;
`;
