import React, { useState } from 'react';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import styled from 'styled-components';
import { useDispatch } from '../../../configureStore';
import { useRoutesHelper } from '../../../helpers/routesHelper';
import { PagesRoutes } from '../../../store/routes';
import { setError } from '../../../store/app/actions';
import { Button } from '../../../components/UI';
import { AppTheme } from '../../../settings/appTheme';

export const CheckoutForm: React.FC = () => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const { getBaseUrl } = useRoutesHelper();

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${getBaseUrl()}${PagesRoutes.StripeBookResult}`,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the paymentV1. Otherwise, your customer will be redirected to
    // your `return_url`. For some paymentV1 methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the paymentV1, then
    // redirected to the `return_url`.
    if (error.type) {
      dispatch(setError({ message: error.message, type: 'error' }));
    } else {
      dispatch(setError({ message: 'An unexpected error occurred.', type: 'error' }));
    }

    setIsLoading(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentContainer>
        <PaymentElement id="payment-element" options={{ layout: 'tabs' }} />
      </PaymentContainer>
      <div style={{ marginTop: '20px' }}>
        <Button disabled={isLoading || !stripe || !elements} onClick={handleSubmit}>
          {'pay now'}
        </Button>
      </div>
      <Notes>
        <Logos />
      </Notes>
    </form>
  );
};
const Notes = styled.div`
  margin-top: 20px;
`;

const Logos = styled.div`
  display: block;
  background: url(${AppTheme.img.payment.formLogos}) center no-repeat;
  background-size: contain;
  height: 40px;
  margin: 15px auto 0;
`;

const PaymentContainer = styled.div`
  width: 100%;
  min-height: 205px;

  @media (max-width: 600px) {
    min-height: 282px;
  }
`;
