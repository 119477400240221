import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Helmet from 'react-helmet';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { IGlobalStoreState } from '../../../store';
import { Button, Message, MessageButtonsWrapper, PageContent } from '../../../components/UI';
import { IUserStoreState } from '../../../store/userV1/types';
import { UserEventsListV1 } from '../../../components/event';
import { Routes } from '../../../store/routes';
import { UserEventsSkeleton } from '../../../components/skeletons/userEventsSkeleton';
import { useGetUserEventsV1Query } from '../../../core/api/eventsSlice';
import { Utils } from '../../../services';

const UserEventsPage = () => {
  const isInitializing = useSelector<IGlobalStoreState, boolean>((state) => state.app.isInitializing);
  const isAuthorized = useSelector<IGlobalStoreState, boolean>((state) => state.app.isAuthorized);
  const user = useSelector<IGlobalStoreState, IUserStoreState>((state) => state.user);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const { data: events = [], isFetching } = useGetUserEventsV1Query();

  useEffect(() => {
    if (!isAuthorized) {
      navigate(Routes.LoginV1);
    }
  }, []);

  useEffect(() => {
    if (!user.phoneNumberConfirmed) {
      navigate(Routes.EditProfileV1);
    }
  }, []);

  if (isInitializing || !user) {
    return null;
  }

  return (
    <PageContent noPadding>
      <Helmet>
        <title>{Utils.toPageTitle(t('Profile.MyEvents'))}</title>
      </Helmet>
      {isFetching && <UserEventsSkeleton />}
      {!isFetching && !!events.length && <UserEventsListV1 events={events} />}
      {!events.length && !isFetching && (
        <Message
          title={t('MyEvents.NoEvents')}
          description={t('MyEvents.NoEventsDescription')}
          renderButtons={() => (
            <MessageButtonsWrapper>
              <Button title={t('MyEvents.AllEvents')} onClick={() => navigate(Routes.Main)}>
                {t<string>('MyEvents.AllEvents')}
              </Button>
            </MessageButtonsWrapper>
          )}
        />
      )}
    </PageContent>
  );
};

export default UserEventsPage;
