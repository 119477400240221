import React, { FC } from 'react';
import { IEventStoreState } from '../../../../store/event/types';
import { IUserStoreState } from '../../../../store/userV1/types';
import { SellControls } from './sellControls';
import { PrivateSellControls } from './privateSellControls';

export const Footer: FC<FooterType> = ({ isLoading, event, sectorSlug, eventSlug, user, ticketsOrRow, rowSlug }) => {
  const sector = event.sectors.find((x) => x.slug === sectorSlug);

  if (!event || !sector) {
    return null;
  }

  const isPrivateQuota = sectorSlug && event ? sector.ticketQuotas.find((q) => q.isPrivate) : null;
  return isPrivateQuota ? (
    <PrivateSellControls ticketsOrRow={ticketsOrRow} eventSlug={eventSlug} sectorSlug={sectorSlug} />
  ) : (
    <SellControls
      isLoading={isLoading}
      event={event}
      eventSlug={eventSlug}
      sectorSlug={sectorSlug}
      user={user}
      rowSlug={rowSlug}
      ticketsOrRow={ticketsOrRow}
    />
  );
};

type FooterType = {
  isLoading: boolean;
  event: IEventStoreState;
  sectorSlug: string;
  eventSlug: string;
  user: IUserStoreState;
  rowSlug: string;
  ticketsOrRow: number;
};
