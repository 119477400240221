import React, { FC, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { AnimatePresence } from 'framer-motion';
import { useRoutesHelper } from '../../helpers/routesHelper';
import { useDispatch, useSelector } from '../../configureStore';
import {
  useChangePasswordMutation,
  useConfirmPhoneMutation,
  useLazyGetAccountQuery,
  useUpdateAccountMutation,
  useUpdatePhoneMutation,
} from '../../core/api/accountSlice';
import { PageContent } from '../../components/UI';
import { PopUp } from '../../store/animations';
import { useUtils } from '../../services/useUtils';
import { ChangePasswordInfo } from '../../store/userV1/types';
import { EditProfileForm } from '../../components/forms/editProfileForm';
import { phoneCurrentUpdate, sendPhoneCode } from '../../store/user/actions';
import { ActionResponseType } from '../../core/models/account';

export type IEditProfileForm = {
  code: string;
  oldPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
  phoneNumber: string;
  fullName: string;
  referralSlug: string;
  imageUrl: string;
  email: string;
  emailConfirmed: boolean;
  phoneNumberConfirmed: boolean;
};

const EditProfilePage: FC = () => {
  const { getProfileRoute } = useRoutesHelper();
  const { toPageTitle } = useUtils();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isAuthorized = useSelector((state) => state.app.isAuthorized);
  const user = useSelector((state) => state.user);

  const [updatePhoneRequest, { isLoading: isLoadingUpdatePhone }] = useUpdatePhoneMutation();
  const [confirmPhoneRequest, { isLoading: isLoadingConfirmPhone }] = useConfirmPhoneMutation();
  const [updateAccountRequest, { isLoading: isLoadingUpdateAccount }] = useUpdateAccountMutation();
  const [changePasswordRequest, { isLoading: isLoadingChangePassword }] = useChangePasswordMutation();
  const [getAccountRequest, { isLoading: isLoadingCurrentAccount }] = useLazyGetAccountQuery();

  useEffect(() => {
    if (!isAuthorized) {
      const profileRoute = getProfileRoute();

      navigate(`${profileRoute}`, { replace: true });
      return;
    }
  }, []);

  const handleSendCodeClick = useCallback((phoneNumber: string) => {
    if (!phoneNumber) {
      return;
    }
    updatePhoneRequest({ phoneNumber })
      .unwrap()
      .then((data: ActionResponseType) => {
        dispatch(sendPhoneCode(data.details || data.message));
        dispatch(phoneCurrentUpdate(phoneNumber));
      });
  }, []);

  const handleFormSubmit = async (formModel: IEditProfileForm) => {
    if (!formModel) {
      return;
    }

    const updatePasswordModel =
      !!formModel.oldPassword && !!formModel.newPassword
        ? new ChangePasswordInfo(formModel.oldPassword, formModel.newPassword)
        : null;
    const isPhoneConfirmed = user.phoneNumberConfirmed;
    const isPhoneChanged = formModel.phoneNumber !== user.phoneNumber;
    const isFullNameChanged = formModel.fullName !== user.fullName;
    const isReferralSlugChanged = formModel.referralSlug !== user.referralSlug;

    if (updatePasswordModel) {
      const { currentPassword, newPassword } = updatePasswordModel;
      await changePasswordRequest({ currentPassword, newPassword });
    }

    if (isFullNameChanged || isReferralSlugChanged) {
      await updateAccountRequest({
        fullName: formModel.fullName,
        referralSlug: formModel.referralSlug,
      });
    }

    if ((!isPhoneConfirmed || isPhoneChanged) && formModel.code) {
      const { code, phoneNumber } = formModel;
      await confirmPhoneRequest({ code, phoneNumber });
      await getAccountRequest();
    }

    const profileRoute = getProfileRoute();
    navigate(`${profileRoute}`);
  };

  return (
    <AnimatePresence>
      <PageContent center variants={PopUp} initial={'enter'} animate={'exit'}>
        <Helmet>
          <title>{toPageTitle(t('EditProfileForm.EditingProfile'))}</title>
        </Helmet>
        <Header>{t<string>('EditProfileForm.Editing')}</Header>
        {isAuthorized && (
          <EditProfileForm
            user={user}
            submitTitle={t('EditProfileForm.Save')}
            onSendCodeClick={(phone: string) => handleSendCodeClick(phone)}
            onSubmit={(formModel: IEditProfileForm) => handleFormSubmit(formModel)}
            isLoading={
              isLoadingConfirmPhone ||
              isLoadingUpdateAccount ||
              isLoadingChangePassword ||
              isLoadingCurrentAccount ||
              isLoadingUpdatePhone
            }
          />
        )}
      </PageContent>
    </AnimatePresence>
  );
};

const Header = styled.h1`
  font-family: ${(props) => props.theme.fonts.boldFont};
  font-size: 18px;
  text-transform: uppercase;
  text-align: center;
`;

export default EditProfilePage;
