import React, { useEffect } from 'react';
import Helmet from 'react-helmet';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useSelector } from '../../configureStore';
import { useGetUserEventsQuery } from '../../core/api/eventsSlice';
import { PagesRoutes } from '../../store/routes';
import { Button, Message, MessageButtonsWrapper, PageContent } from '../../components/UI';
import { useUtils } from '../../services/useUtils';
import { UserEventsSkeleton } from '../../components/skeletons/userEventsSkeleton';
import { UserEventsList } from '../../components/event/userEventsList';

const UserEventsPage = () => {
  const isInitializing = useSelector((state) => state.app.isInitializing);
  const isAuthorized = useSelector((state) => state.app.isAuthorized);
  const user = useSelector((state) => state.user);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const { toPageTitle } = useUtils();

  const { data: events = [], isFetching } = useGetUserEventsQuery();

  useEffect(() => {
    if (!isAuthorized) {
      navigate(PagesRoutes.Login);
    }
  }, []);

  useEffect(() => {
    if (!user.phoneNumberConfirmed) {
      navigate(PagesRoutes.EditProfile);
    }
  }, []);

  if (isInitializing || !user) {
    return null;
  }

  return (
    <PageContent noPadding>
      <Helmet>
        <title>{toPageTitle(t('Profile.MyEvents'))}</title>
      </Helmet>
      {isFetching && <UserEventsSkeleton />}
      {!isFetching && !!events.length && <UserEventsList events={events} />}
      {!events.length && !isFetching && (
        <Message
          title={t('MyEvents.NoEvents')}
          description={t('MyEvents.NoEventsDescription')}
          renderButtons={() => (
            <MessageButtonsWrapper>
              <Button title={t('MyEvents.AllEvents')} onClick={() => navigate(PagesRoutes.Main)}>
                {t<string>('MyEvents.AllEvents')}
              </Button>
            </MessageButtonsWrapper>
          )}
        />
      )}
    </PageContent>
  );
};

export default UserEventsPage;
