import * as PT from '../paymentV1/types';
import { ISeatStoreState } from '../event/types';
import checkoutReducerV1 from './reducers';

// export const widgetCheckoutInit = createAsyncThunk<void, string, { state: IGlobalStoreState }>(
//   'checkoutV1/widgetCheckoutInit',
//   async (token: string, thunkAPI) => {
//     const { bookV1, checkoutV1 } = thunkAPI.getState();
//     const { dispatch } = thunkAPI;
//
//     const paymentModel = new PT.PaymentInitiateInfo();
//     paymentModel.eventSlug = bookV1.event.slug;
//     paymentModel.paymentMethod = bookV1.event.paymentMethods[0].method;
//     const tickets = GetTickets(bookV1.seats);
//     paymentModel.tickets = tickets;
//     paymentModel.token = token;
//
//     dispatch(distributionClear());
//     dispatch(getCurrentAccount());
//     dispatch(showSpinner());
//
//     if (CheckoutHelper.getIsProcessed(checkoutV1) && !CheckoutHelper.getIsCompleted(checkoutV1)) {
//       const paymentCompleteModel = new PT.PaymentCompleteInfo();
//       paymentCompleteModel.referenceNumber = checkoutV1.process.referenceNumber;
//       paymentCompleteModel.cancel = true;
//
//       dispatch(paymentComplete(paymentCompleteModel));
//
//       const paymentResult = await thunkService.api.payments.complete(paymentCompleteModel);
//       const isPaymentProcessing = paymentResult.isProcessing;
//       if (!isPaymentProcessing) {
//         dispatch(clearPayment());
//       }
//       dispatch(finishPaymentComplete(paymentResult));
//
//       if (isPaymentProcessing) {
//         dispatch(hideSpinner());
//         return;
//       }
//     } else {
//       dispatch(clearPayment());
//     }
//
//     dispatch(paymentInit(paymentModel));
//     try {
//       const paymentResult = await thunkService.api.payments.widgetInitiate(paymentModel);
//       paymentResult.event = bookV1.event;
//       dispatch(finishPaymentInit(paymentResult));
//     } finally {
//       dispatch(hideSpinner());
//     }
//   }
// );
// export const widgetCheckoutProcess = createAsyncThunk<
//   Promise<PT.PaymentProcessResult>,
//   string,
//   { state: IGlobalStoreState }
// >('checkoutV1/widgetCheckoutProcess', async (token: string, thunkAPI) => {
//   const routeSrv = new RouteService();
//   const { checkoutV1 } = thunkAPI.getState();
//   const { dispatch } = thunkAPI;
//
//   const paymentModel = new PT.PaymentProcessInfo(checkoutV1);
//
//   paymentModel.token = token;
//   paymentModel.paymentMethod = checkoutV1.paymentMethod;
//   paymentModel.returnUrl = `${routeSrv.getBaseUrl()}/${Pages.Widget}/${Pages.Book}/${token}/result`;
//   paymentModel.returnErrorUrl = `${routeSrv.getBaseUrl()}/${Pages.Widget}/${Pages.Book}/${token}/result/${
//     Pages.Failed
//   }`;
//   paymentModel.retailWebsite = routeSrv.getBaseUrl();
//
//   dispatch(distributionClear());
//   dispatch(showSpinner());
//   dispatch(paymentProcess(paymentModel));
//   try {
//     const paymentResult = await thunkService.api.payments.widgetProcess(paymentModel);
//     dispatch(finishPaymentProcess(paymentResult));
//     return paymentResult;
//   } catch (e) {
//     const alertType = AlertType.Error;
//     const alertKey = KeyErrors.PaymentFailed;
//     const title = 'CheckoutWidget.Error';
//     const message = e.response.data;
//
//     dispatch(
//       setResultInfo({
//         type: alertType,
//         key: alertKey,
//         title,
//         message,
//       })
//     );
//   } finally {
//     dispatch(hideSpinner());
//   }
// });

export function GetTickets(seats: ISeatStoreState[]) {
  const map = new Map<number, ISeatStoreState[]>();
  // group by quotaID
  seats.forEach((item) => {
    const key = item.quotaId;
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  const tickets = [];
  map.forEach((value, key) => {
    const quotaId = key;
    const ticket = new PT.PaymentInitiateTicketInfo();
    ticket.quotaId = quotaId;
    ticket.sector = new PT.PaymentInitiateSectorInfo();
    ticket.sector.sectorRows = [];
    value
      .filter((r) => !!r && !!r.sectorSlug)
      .forEach((item) => {
        ticket.sector.sectorSlug = item.sectorSlug;
        if (!!item.rowSlug && !!item.seatSlug) {
          ticket.sector.sectorRows.push(
            new PT.PaymentInitiateSectorRowInfo({
              rowSlug: item.rowSlug,
              rowSeatSlugs: [item.seatSlug],
            })
          );
          ticket.sector.quantity = ticket.sector.sectorRows.reduce(
            (result, current) => (result += current.rowSeatSlugs.length),
            0
          );
        } else {
          ticket.sector.quantity = seats.filter((s) => s.quotaId === quotaId).length;
        }
      });

    tickets.push(ticket);
  });
  return tickets;
}

export const {
  setEmailV1,
  finishPaymentCompleteV1,
  clearPaymentV1,
  finishPaymentProcessV1,
  paymentProcessV1,
  finishPaymentInitV1,
  paymentInitV1,
  redoV1,
} = checkoutReducerV1.actions;
