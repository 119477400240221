import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { PageContent } from '../../../../components/UI';
import { EmailForm, IEmailForm } from '../../../../components/forms';
import { Header, Title } from '../widgetCheckoutPage';

export const CheckoutEmailForm: FC<CheckoutEmailFormPropsType> = memo(
  ({ email, isAuthorized, checkoutSteps, handleEmailFormSubmit }) => {
    const { t } = useTranslation();
    const title = isAuthorized ? t('CheckoutWidget.BookTicketsNoteAuthorized') : t('CheckoutWidget.BookTicketsNote');

    return (
      <PageContent roundCorners>
        <Header>
          {t('CheckoutWidget.Step')} 1 {t('CheckoutWidget.Of')} {checkoutSteps}
        </Header>
        <Title>{title}</Title>
        <EmailForm
          email={email}
          emailPlaceholder={t('CheckoutWidget.EnterEmail')}
          emailConfirmPlaceholder={t('CheckoutWidget.RepeatEmail')}
          submitTitle={t('CheckoutWidget.Next')}
          onSubmit={handleEmailFormSubmit}
        />
      </PageContent>
    );
  }
);

type CheckoutEmailFormPropsType = {
  email: string;
  isAuthorized: boolean;
  checkoutSteps: number;
  handleEmailFormSubmit: (formModel: IEmailForm) => void;
};
