import dayjs from 'dayjs';
import { baseSlice, baseSliceV2 } from './apiSlice';
import {
  EventCategory,
  EventShortInformation,
  EventStoreState,
  EventTypeV1,
  IEventCategory,
  IEventShortInformation,
  IEventStoreState,
  ISchema,
  LocationShortInformationType,
  Schema,
} from '../../store/event/types';
import EventHelper from '../../helpers/eventHelper';
import { EventDetailsStoreState, IEventDetailsStoreState } from '../../store/event/eventDetails/EventDetailsStoreState';
import { initEvents } from '../../store/event/actions';
import { initDistributionEventsV1 } from '../../store/userV1/actions';
import { events } from '../constants/constants';
import { EventDetailsBySlugType, EventDetailsType, SearchEventsType } from '../models/eventDetails';
import {
  BookParams,
  EventBookType,
  EventInformationType,
  EventTicketGroupsParams,
  EventType,
  GenreType,
  GetEventByTypeParams,
  SearchEventsParams,
  SectorsType, SectorType,
  TicketGroupType,
  UserEventType
} from "../models/events";
import { LocationType } from '../models/location';
import { initDistributionEvents } from '../../store/user/actions';

type GetBookEventTypeV1 = {
  id: string;
  distributorSlug?: string;
  token?: string;
};

type EventDetailsBySlugTypeV1 = {
  id: string;
  distributorSlug?: string;
  token?: string;
};

export type GetCategoryParams = {
  categorySlug: string;
};
export type GetSectorSchemaParams = {
  venueSlug: string;
  sectorSlug: string;
  schemaVersion: number;
};
export type GetVenueSchemaParams = {
  venueSlug: string;
  schemaVersion: number;
};

export type GetEventByTypeParamsV1 = {
  eventType: EventTypeV1;
  search?: string;
  date?: Date;
  categoryId?: number;
  count?: number;
  city?: string;
};

export const EventsApi = baseSlice.injectEndpoints({
  endpoints: (build) => ({
    getDatepickerDates: build.query<Date[], Date>({
      query: (date) => {
        const baseDate = `${dayjs(date).format('YYYY-MM-DDTHH:mm:ss')}`;
        return {
          url: events.dates,
          params: { baseDate },
        };
      },
      transformResponse: (response: Date[]) =>
        response ? response.map((item: string | number | Date) => new Date(item)) : [],
    }),
    getCategory: build.query<IEventCategory, GetCategoryParams>({
      query: (categorySlug) => ({
        url: events.categories,
        params: { categorySlug },
      }),
      transformResponse: (response: IEventCategory) => new EventCategory(response),
    }),
    getEventsCategories: build.query<IEventCategory[], void>({
      query: () => {
        const domain = window.location.hostname;
        const dtype = EventHelper.getDomainType(domain);
        return {
          url: events.categories,
          params: { dtype },
        };
      },
      transformResponse: (response: IEventCategory[]) =>
        response ? response.map((item) => new EventCategory(item)) : [],
    }),
    getEventsByCategoriesV1: build.query<IEventShortInformation[], number>({
      query: (categoryId) => {
        const domain = window.location.hostname;
        const dtype = EventHelper.getDomainType(domain);
        return {
          url: events.events,
          params: { categoryId, eventType: EventTypeV1.Category, dtype },
        };
      },
      transformResponse: (response: IEventCategory[]) =>
        response ? response.map((item) => new EventShortInformation(item)) : [],
    }),
    getEventsByTypeV1: build.query<IEventShortInformation[], GetEventByTypeParamsV1>({
      query: (eventParams: GetEventByTypeParamsV1) => {
        const { eventType, search, city, count, categoryId } = eventParams;
        const date = dayjs(eventParams.date).format('YYYY-MM-DDTHH:mm:ss');

        const domain = window.location.hostname;
        const dtype = EventHelper.getDomainType(domain);
        const category = categoryId ?? '';

        return {
          url: events.events,
          params: { eventType, dtype, date, search, city, count, categoryId: category },
        };
      },
      transformResponse: (response: IEventCategory[]) =>
        response ? response.map((item) => new EventShortInformation(item)) : [],
    }),
    getLocationV1: build.query<LocationShortInformationType[], void>({
      query: () => events.locations,
      transformResponse: (response: any) =>
        response
          ? response.map((item) => ({
              id: item.Id,
              value: `${item.City}, ${item.Country}`,
            }))
          : [],
    }),
    searchEventsV1: build.query({
      query: (search: string) => `/events/search?name=${search}&count=6`,
      transformResponse: (response: any) =>
        response
          ? response.map((item) => ({
              id: item.Slug,
              value: `${item.Title}, ${item.City}`,
            }))
          : [],
    }),
    getVenueSchema: build.query<ISchema, GetVenueSchemaParams>({
      query: (eventParams: GetVenueSchemaParams) => {
        const { venueSlug, schemaVersion } = eventParams;
        return {
          url: `/events/venue/${venueSlug}/schema`,
          params: { version: schemaVersion },
        };
      },
      transformResponse: (response: ISchema) => new Schema(response),
    }),
    getSectorSchema: build.query<ISchema, GetSectorSchemaParams>({
      query: (eventParams: GetSectorSchemaParams) => {
        const { venueSlug, sectorSlug, schemaVersion } = eventParams;
        return {
          url: `/events/venue/${venueSlug}/sector/${sectorSlug}/schema`,
          params: { version: schemaVersion },
        };
      },
      transformResponse: (response) => new Schema(response),
    }),
    getEventDetailsBySlugV1: build.query<IEventDetailsStoreState, EventDetailsBySlugTypeV1>({
      query: ({ id, distributorSlug = '', token = '' }) => {
        const domain = window.location.hostname;
        const dtype = EventHelper.getDomainType(domain);

        return {
          url: `/events/details/${id}/${distributorSlug ? `/${distributorSlug}` : ''}${token ? `/${token}` : ''}`,
          params: { dtype },
        };
      },
      transformResponse: (response: IEventDetailsStoreState) => new EventDetailsStoreState(response),
    }),
    getUserEventsV1: build.query<IEventStoreState[], void>({
      query: () => {
        const domain = window.location.hostname;
        const dtype = EventHelper.getDomainType(domain);

        return {
          url: events.my,
          params: { dtype },
        };
      },
      transformResponse: (response: { Events: IEventStoreState[] }) =>
        response.Events.map((item) => new EventStoreState(item)),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        dispatch(initDistributionEventsV1(data));
      },
    }),
    getSellEventDetails: build.query<IEventStoreState, string>({
      query: (slug: string) => `/events/sell/details/${slug}`,
      transformResponse: (response) => new EventStoreState(response),
    }),
    getEvents: build.query<IEventStoreState[], string>({
      query: (city: string) => {
        const domain = window.location.hostname;
        const dtype = EventHelper.getDomainType(domain);
        return {
          url: events.list,
          params: { dtype },
        };
      },
      transformResponse: (response: IEventStoreState[]) => response.map((item: any) => new EventStoreState(item)),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        dispatch(initEvents(data));
      },
    }),
    getEventById: build.query<IEventStoreState, GetBookEventTypeV1>({
      query: ({ id, distributorSlug, token }) => {
        const domain = window.location.hostname;
        const dtype = EventHelper.getDomainType(domain);
        return {
          url: `/events/book/details/${id}${distributorSlug ? `/${distributorSlug}` : ''}${token ? `/${token}` : ''}`,
          params: { dtype },
        };
      },
      transformResponse: (response: IEventStoreState) => new EventStoreState(response),
    }),
    startDistributeV1: build.mutation<void, string>({
      query: (id: string) => ({
        url: `/events/my/${id}`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useGetDatepickerDatesQuery,
  useGetCategoryQuery,
  useGetEventsCategoriesQuery,
  useLazyGetEventsByCategoriesV1Query,
  useLazyGetEventsByTypeV1Query,
  useGetLocationV1Query,
  useLazySearchEventsV1Query,
  useGetVenueSchemaQuery,
  useGetSectorSchemaQuery,
  useGetEventDetailsBySlugV1Query,
  useGetUserEventsV1Query,
  useGetSellEventDetailsQuery,
  useLazyGetEventsQuery,
  useLazyGetEventByIdQuery,
  useStartDistributeV1Mutation,
  useLazyGetUserEventsV1Query,
} = EventsApi;

export function toCamel(o) {
  var newO, origKey, newKey, value;
  if (o instanceof Array) {
    return o.map(function (value) {
      if (typeof value === 'object') {
        value = toCamel(value);
      }
      return value;
    });
  } else {
    newO = {};
    for (origKey in o) {
      if (o.hasOwnProperty(origKey)) {
        newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString();
        value = o[origKey];
        if (value instanceof Array || (value !== null && value.constructor === Object)) {
          value = toCamel(value);
        }
        newO[newKey] = value;
      }
    }
  }
  return newO;
}

const EventsApiV2 = baseSliceV2.injectEndpoints({
  endpoints: (build) => ({
    eventDetails: build.query<EventDetailsType, EventDetailsBySlugType>({
      query: ({ eventSlug, distributorSlug }) => {
        const domain = window.location.hostname;
        const dtype = EventHelper.getDomainType(domain);
        return {
          url: `/events/details/${eventSlug}`,
          params: { distributorSlug, dtype },
        };
      },
      transformResponse: (response: EventDetailsType) => toCamel(response),
    }),
    getEventsByType: build.query<EventInformationType[], GetEventByTypeParams>({
      query: (eventParams: GetEventByTypeParams) => {
        const { eventType, search, city, count, genreId } = eventParams;
        const date = dayjs(eventParams.date).format('YYYY-MM-DDTHH:mm:ss');

        const domain = window.location.hostname;
        const dtype = EventHelper.getDomainType(domain);

        return {
          url: events.events,
          params: { eventType, dtype, date, search, city, count, genreId },
        };
      },
      transformResponse: (response: EventInformationType[]) => response.map((item) => toCamel(item)),
    }),
    getEventsByGenre: build.query<EventInformationType[], number>({
      query: (genreId) => {
        const domain = window.location.hostname;
        const dtype = EventHelper.getDomainType(domain);
        return {
          url: events.events,
          params: { genreId, eventType: EventType.Genre, dtype },
        };
      },
      transformResponse: (response: IEventCategory[]) => response.map((item) => toCamel(item)),
    }),
    getLocation: build.query<Array<{ id: number; city: string; country: string }>, void>({
      query: () => events.locations,
      transformResponse: (response: LocationType[]) => toCamel(response),
    }),
    getGenres: build.query<GenreType[], void>({
      query: () => {
        const domain = window.location.hostname;
        const dtype = EventHelper.getDomainType(domain);
        return {
          url: events.genres,
          params: { dtype },
        };
      },
      transformResponse: (response: GenreType[]) => response.map((item) => toCamel(item)),
    }),
    getGenreEvents: build.query<GenreType, string>({
      query: (genreSlug) => ({
        url: events.genres,
        params: { genreSlug },
      }),
      transformResponse: (response: GenreType) => toCamel(response),
    }),
    getBookEvent: build.query<EventBookType, BookParams>({
      query: ({ eventSlug, distributorSlug }) => {
        const domain = window.location.hostname;
        const dtype = EventHelper.getDomainType(domain);
        return {
          url: `/events/book/details/${eventSlug}`,
          params: { dtype, distributorSlug },
        };
      },
      transformResponse: (response: EventBookType) => toCamel(response),
    }),
    getBookEventTicketGroups: build.query<SectorType[], EventTicketGroupsParams>({
      query: ({
        eventSlug,
        sectorSlug,
        lowPrice,
        priceTo,
        priceFrom,
        minTicketCount,
        parking,
        wheelchair,
        binoculars,
      }) => {
        const domain = window.location.hostname;
        const dtype = EventHelper.getDomainType(domain);
        return {
          url: `/events/book/details/${eventSlug}/ticketGroups`,
          params: { sectorSlug, lowPrice, priceTo, priceFrom, minTicketCount, parking, wheelchair, binoculars, dtype },
        };
      },
      transformResponse: (response: SectorsType) => toCamel(response).sectors,
    }),
    getUserEvents: build.query<UserEventType[], void>({
      query: () => {
        const domain = window.location.hostname;
        const dtype = EventHelper.getDomainType(domain);

        return {
          url: events.my,
          params: { dtype },
        };
      },
      forceRefetch: () => true,
      transformResponse: (response) => toCamel(response),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        dispatch(initDistributionEvents(data));
      },
    }),
    startDistribute: build.mutation<void, number>({
      query: (id: number) => ({
        url: `/events/my/${id}`,
        method: 'POST',
      }),
    }),
    searchEvents: build.query<SearchEventsType[], SearchEventsParams>({
      query: ({ name, city }) => ({
        url: `/events/search?count=6`,
        params: { city, name },
      }),
      transformResponse: (response: SearchEventsType[]) => toCamel(response),
    }),
  }),
});

export const {
  useEventDetailsQuery,
  useLazyGetEventsByTypeQuery,
  useLazyGetEventsByGenreQuery,
  useGetLocationQuery,
  useGetGenresQuery,
  useGetGenreEventsQuery,
  useGetBookEventQuery,
  useLazyGetBookEventQuery,
  useLazyGetBookEventTicketGroupsQuery,
  useLazyGetUserEventsQuery,
  useGetUserEventsQuery,
  useStartDistributeMutation,
  useLazySearchEventsQuery,
} = EventsApiV2;
