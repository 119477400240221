import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ICheckoutStoreState } from '../../../../store/checkoutV1/types';
import { CheckoutHelper } from '../../../../helpers';
import { PageContent } from '../../../../components/UI';
import { EmailForm, IEmailForm } from '../../../../components/forms';
import { IDistributionStoreState } from '../../../../store/distributionV1/types';
import { IUserStoreState } from '../../../../store/userV1/types';
import { Header, Title } from '../checkoutPageV1';

export const EmailCheckoutFormV1: FC<EmailFormPropsType> = memo(
  ({ checkout, isAuthorized, checkoutSteps, user, distribution, handleEmailFormSubmit }) => {
    const { t } = useTranslation();

    const distributionInfo = CheckoutHelper.getDistributionInfo(checkout, distribution);
    const email = user ? user.email : checkout.email;
    const title = isAuthorized ? t('Checkout.BookTicketsNoteAuthorized') : t('Checkout.BookTicketsNote');

    return (
      <PageContent roundCorners>
        <Header>
          {t('Checkout.Step')} 1 {t('Checkout.Of')} {checkoutSteps}
        </Header>
        <Title>{title}</Title>
        <EmailForm
          email={email}
          emailPlaceholder={t('Checkout.EnterEmail')}
          emailConfirmPlaceholder={t('Checkout.RepeatEmail')}
          warning={t(distributionInfo, { distributor: distribution.distributorSlug })}
          submitTitle={t('Checkout.Next')}
          onSubmit={(formModel: IEmailForm) => handleEmailFormSubmit(formModel)}
        />
      </PageContent>
    );
  }
);

type EmailFormPropsType = {
  checkout: ICheckoutStoreState;
  checkoutSteps: number;
  distribution: IDistributionStoreState;
  handleEmailFormSubmit: (formModel: IEmailForm) => void;
  user: IUserStoreState;
  isAuthorized: boolean;
};
