import React, { useEffect, useLayoutEffect } from 'react';
import Helmet from 'react-helmet';

import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useSelector } from '../../configureStore';
import { useRoutesHelper } from '../../helpers/routesHelper';
import { Button, Message, MessageButtonsWrapper, PageContent } from '../../components/UI';
import { useUtils } from '../../services/useUtils';
import { UserTicketsList } from '../../components/ticket';
import { PagesRoutes } from '../../store/routes';
import { useLazyGetUserTicketsQuery } from '../../core/api/ticketsSlice';

const UserTicketsPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { toPageTitle } = useUtils();

  const { getProfileRoute } = useRoutesHelper();

  const isInitializing = useSelector((state) => state.app.isInitializing);
  const isAuthorized = useSelector((state) => state.app.isAuthorized);
  const user = useSelector((state) => state.user);
  const tickets = useSelector((state) => state.userTickets);

  const [getUserTicketsRequest] = useLazyGetUserTicketsQuery();

  useEffect(() => {
    if (!isAuthorized || !user.phoneNumberConfirmed) {
      navigate(getProfileRoute());
      return;
    }
  }, [isAuthorized, user?.phoneNumberConfirmed]);

  useLayoutEffect(() => {
    getUserTicketsRequest();
  }, [getUserTicketsRequest]);

  if (isInitializing || !user) {
    return null;
  }

  return (
    <PageContent noPadding>
      <Helmet>
        <title>{toPageTitle(t('Profile.MyTickets'))}</title>
      </Helmet>
      {!!tickets.length && <UserTicketsList tickets={tickets} />}
      {!tickets.length && (
        <Message
          title={t('MyTickets.NoTickets')}
          description={t('MyTickets.NoTicketsDescription')}
          renderButtons={() => (
            <MessageButtonsWrapper>
              <Button title={t('MyTickets.AllEvents')} onClick={() => navigate(PagesRoutes.Main)}>
                {t<string>('MyTickets.AllEvents')}
              </Button>
            </MessageButtonsWrapper>
          )}
        />
      )}
    </PageContent>
  );
};

export default UserTicketsPage;
