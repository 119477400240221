import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button } from './button';

type NumberInputPropsType = {
  value: number;
  max?: number;
  min?: number;
  postfix?: string;

  handleTicketsCount?: (value: number) => void;
};

const NumberInputV1: FC<NumberInputPropsType> = ({ value, max, min, postfix, handleTicketsCount }) => {
  const [count, setCount] = useState(value);
  const { t } = useTranslation();

  const handleDownClick = (e: any) => {
    if (e) {
      e.preventDefault();
    }
    setCount((count) => count - 1);
  };

  useEffect(() => {
    handleTicketsCount(count);
  }, [count]);

  const handleUpClick = (e: any) => {
    if (e) {
      e.preventDefault();
    }

    setCount((count) => count + 1);
  };

  const displayValue = postfix ? `${count} ${t(postfix)}` : count;
  const isMax = max === count;
  const isMin = min === count;

  return (
    <InputWrapper>
      <Button round outline small onClick={(e) => handleDownClick(e)} disabled={isMin}>
        -
      </Button>
      <DisplayValue>{displayValue}</DisplayValue>
      <Button round outline small onClick={(e) => handleUpClick(e)} disabled={isMax}>
        +
      </Button>
    </InputWrapper>
  );
};

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const DisplayValue = styled.label`
  margin: 0;
  padding: 0 10px;
  font-size: 12px;
  font-family: ${(props) => props.theme.fonts.boldFont};
`;

export default NumberInputV1;
