import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { AnimatePresence } from 'framer-motion';
import { useDispatch } from '../../../store';
import { Page, PageContent } from '../../../components/UI';
import { EventDetailsHeaderV1 } from '../../../components/event';
import { IEmailForm } from '../../../components/forms';
import { EventHelper } from '../../../helpers';
import { IProfileTicketStoreState } from '../../../store/ticket/ProfileTicketStoreState';
import { useRoutesHelper } from '../../../helpers/routesHelper';
import {
  RenderForm,
  RenderIssuedInfo,
  RenderTicketSector,
} from './userTicketDetailsComponents/userTicketDetailsComponents';
import { setResultInfo } from '../../../store/resultInformarion/actions';
import { GlobalStateType } from '../../../configureStore';
import { AlertType, KeyErrors } from '../../../store/enums';
import { Routes } from '../../../store/routes';
import { useIssueTicketMutation, useLazyGetUserTicketsV1Query } from '../../../core/api/ticketsSlice';
import { IssueTicketInfo } from '../../../store/ticket/types';

export type UserTicketDetailsParamsType = {
  masterTicketId: string;
  ticketId: string;
};

const UserTicketDetailsPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { getProfileRoute } = useRoutesHelper();
  const navigate = useNavigate();

  const isFinalized = useSelector<GlobalStateType, boolean>((state) => state.app.isFinalized);
  const isAuthorized = useSelector<GlobalStateType, boolean>((state) => state.app.isAuthorized);
  const userTickets = useSelector<GlobalStateType, IProfileTicketStoreState[]>((state) => state.userTicketsV1);

  const [ticket, setTicket] = useState<IProfileTicketStoreState>(null);

  const [issueTicketRequest] = useIssueTicketMutation();
  const [getUserTicketsRequest] = useLazyGetUserTicketsV1Query();

  const { masterTicketId, ticketId } = useParams<UserTicketDetailsParamsType>();

  const initTicket = useCallback(
    (masterTicketId: string, ticketId?: string) => {
      if (!masterTicketId) {
        return;
      }

      const ticket = userTickets.find((item: IProfileTicketStoreState) => {
        const isMasterId = item.masterTicketId === masterTicketId;
        const isTicketId = (!ticketId && !item.ticketId) || item.ticketId === ticketId;
        return isTicketId && isMasterId;
      });

      if (!ticket || !ticket.masterTicketId) {
        dispatch(
          setResultInfo({
            type: AlertType.Error,
            key: KeyErrors.TicketNotFound,
            title: 'Alert.WaitForTheServerToCompleteThePayment',
            buttons: [
              {
                title: 'Result.GoToMainPage',
                callback: () => navigate(Routes.Main),
              },
            ],
          })
        );
        return;
      }

      setTicket(ticket);
    },
    [dispatch, navigate, userTickets]
  );

  useEffect(() => {
    if (isFinalized) {
      initTicket(masterTicketId, ticketId);
    }
  }, [initTicket, isFinalized, masterTicketId, ticketId]);

  useEffect(() => {
    if (isFinalized || isAuthorized) {
      initTicket(masterTicketId, ticketId);
    }
  }, [isFinalized, isAuthorized, masterTicketId, ticketId]);

  useEffect(() => {
    if (!isAuthorized) {
      const profileRoute = getProfileRoute();

      navigate(`${profileRoute}`, { replace: true });
    }
  }, [getProfileRoute, navigate, isAuthorized]);

  const handleEmailFormSubmit = useCallback(
    (formModel: IEmailForm) => {
      const { email } = formModel;
      if (formModel) {
        const model = new IssueTicketInfo(ticketId, email, false);

        issueTicketRequest(model)
          .unwrap()
          .then((ticket: any) => {
            setTicket(ticket);
          });

        getUserTicketsRequest();
      }
    },
    [dispatch, ticketId]
  );

  if (!isFinalized || !ticket || !ticket.masterTicketId) {
    return null;
  }
  const { event, ticketQuota, seatTitle, rowTitle, issuedTo } = ticket;
  const isIssued = !!ticket.issuedTo;
  return (
    <Page title={`${t('TicketDetails.TicketIssue')} | ${EventHelper.getEventDetailsMetaDescription(event)}`}>
      <EventDetailsHeaderV1 event={ticket.event} noTitle stretch />
      <Content roundCorners>
        <Title>{event.title}</Title>
        <Subtitle>{event.subtitle}</Subtitle>
        {<RenderTicketSector ticketQuota={ticketQuota} seatTitle={seatTitle} rowTitle={rowTitle} />}
        <AnimatePresence>
          {!isIssued && <RenderForm handleEmailFormSubmit={handleEmailFormSubmit} />}
          {isIssued && <RenderIssuedInfo issuedTo={issuedTo} />}
        </AnimatePresence>
      </Content>
    </Page>
  );
};

const Content = styled(PageContent as any)`
  padding-top: 0;
  flex: 0;
  min-height: 250px;
`;

const Title = styled.h1`
  font-size: 36px;
  font-family: ${(props) => props.theme.fonts.bolderFont};
  text-align: center;
  margin: 0 0 5px 0;
  position: relative;
  top: -20px;
`;

export const Subtitle = styled.h2`
  font-size: 14px;
  margin: 0 0 15px 0;
  text-align: center;
  position: relative;
  top: -20px;
`;

export default UserTicketDetailsPage;
