import { IEventStoreState } from '../event/types';

export interface IShareStoreState {
  event: IEventStoreState;
}

export class ShareStoreState implements IShareStoreState {
  public event: IEventStoreState;

  constructor() {
    this.event = null;
  }
}

export type GetTicketShareTokenType = {
  eventSlug: string;
  sectorSlug: string;
  tickets: number;
};
