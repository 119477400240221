import React, { FC } from 'react';
import { SellControls } from './sellControls';
import { UserType } from '../../../store/user/types';
import { EventBookType } from '../../../core/models/events';

export const Footer: FC<FooterType> = ({ isLoading, event, eventSlug, user }) => {
  if (!event) {
    return null;
  }

  return <SellControls isLoading={isLoading} event={event} eventSlug={eventSlug} user={user} />;
};

type FooterType = {
  isLoading: boolean;
  event: EventBookType;
  user: UserType;
  eventSlug: string;
};
