import { AppSettings } from './appSettings';

export const AppTheme = {
  colors: {
    accent: '#FFBF00',
    accentDimmed: '#FF9C01',
    accentDisabled: '#8D6D0D',
    error: '#FF003F',
    bg: '#1C1C1C',
    text: 'white',
    textSecond: '#B2B2B2',
  },
  maxWidth: '768px',
  fonts: {
    regularFont: `Intro, ${AppSettings.DEFAULT_FONTS}`,
    googleMediumFont: `Google-Medium-Sans, ${AppSettings.DEFAULT_FONTS}`,
    regularAltFont: `Intro-Regular-Alt, ${AppSettings.DEFAULT_FONTS}`,
    boldFont: `Intro-Bold, ${AppSettings.DEFAULT_FONTS}`,
    bolderFont: `Intro-Black, ${AppSettings.DEFAULT_FONTS}`,
    lightFont: `Intro-Light, ${AppSettings.DEFAULT_FONTS}`,
    lighterFont: `Intro-Book, ${AppSettings.DEFAULT_FONTS}`,
    blackCapsFont: `Intro-Black-Caps, ${AppSettings.DEFAULT_FONTS}`,
    boldCapsFont: `Intro-Bold-Caps, ${AppSettings.DEFAULT_FONTS}`,
  },
  img: {
    icons: {
      purse: `${AppSettings.IMAGES_PATH}/icPurse.svg`,
      location: `${AppSettings.IMAGES_PATH}/icLocation.svg`,
      back: `${AppSettings.IMAGES_PATH}/icBack.svg`,
      person: `${AppSettings.IMAGES_PATH}/icPerson.svg`,
      calendar: `${AppSettings.IMAGES_PATH}/icCalendar.svg`,
      rock: `${AppSettings.IMAGES_PATH}/icRock.svg`,
      error: `${AppSettings.IMAGES_PATH}/icError.svg`,
      mail: `${AppSettings.IMAGES_PATH}/icMail.svg`,
      profile: `${AppSettings.IMAGES_PATH}/icProfile.svg`,
      edit: `${AppSettings.IMAGES_PATH}/icEdit.svg`,
      star: `${AppSettings.IMAGES_PATH}/icStar.svg`,
      meet: `${AppSettings.IMAGES_PATH}/icMeet.svg`,
      ticket: `${AppSettings.IMAGES_PATH}/icTicket.svg`,
      soldTicket: `${AppSettings.IMAGES_PATH}/icSoldTicket.svg`,
      copy: `${AppSettings.IMAGES_PATH}/icCopy.svg`,
      plane: `${AppSettings.IMAGES_PATH}/icPlane.svg`,
      menu: `${AppSettings.IMAGES_PATH}/icMenu.svg`,
      close: `${AppSettings.IMAGES_PATH}/icClose.svg`,
    },
    logo: `${AppSettings.IMAGES_PATH}/logo.svg`,
    payment: {
      formLogos: `${AppSettings.IMAGES_PATH}/payment/paymentLogos.png`,
      logos: [
        { id: 'visa', url: `${AppSettings.IMAGES_PATH}/payment/visa`, title: 'VISA' },
        // { id: 'visaVerified', url: `${AppSettings.IMAGES_PATH}/payment/visaVerified`, title: 'Verified by VISA' },
        { id: 'masterCard', url: `${AppSettings.IMAGES_PATH}/payment/mc`, title: 'MasterCard' },
        {
          id: 'masterCardSecureCode',
          url: `${AppSettings.IMAGES_PATH}/payment/mcSecureCode`,
          title: 'MasterCard SecureCode',
        },
        // { id: 'belCard', url: `${AppSettings.IMAGES_PATH}/payment/belCard`, title: 'БЕЛКАРТ' },
        // { id: 'bePaid', url: `${AppSettings.IMAGES_PATH}/payment/bePaid`, title: 'bePaid', website: PaymentSettings.BEPAID_URL },
        // { id: 'mtbank', url: `${AppSettings.IMAGES_PATH}/payment/mtb`, title: 'МТБанк' },
      ],
    },
    backgrounds: {
      bg1: `${AppSettings.IMAGES_PATH}/bg`,
      bg2: `${AppSettings.IMAGES_PATH}/bg2`,
    },
  },
  scrollbarHeight: '8px',
};
