export class ValidationService {
  private static _instance: ValidationService;

  constructor() {
    if (typeof ValidationService._instance === 'undefined') {
      ValidationService._instance = this;
    }
    return ValidationService._instance;
  }

  public isRequired(value: string): boolean {
    return !!value;
  }

  public isEmail(value: string): boolean {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(value);
  }

  public isConfirmed(firstValue: string, secondValue: string): boolean {
    return firstValue === secondValue;
  }
}
