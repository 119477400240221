import dayjs from 'dayjs';

import { IDistributionInfo, DistributionInfo } from '../userV1/types';
import { IEventStoreState, EventStoreState } from '../event/types';
import { PaymentProvider } from '../enums';

export interface IPaymentStoreState {
  method: string;
  methodDescription: string;
  comission: number;
  comissionDescription: string;
  useConvertion: boolean;
  convertionRate: number;
  convertionCurrency: string;
}

export class PaymentStoreState implements IPaymentStoreState {
  method: string;
  methodDescription: string;
  comission: number;
  comissionDescription: string;
  useConvertion: boolean;
  convertionRate: number;
  convertionCurrency: string;

  constructor(dto?: any) {
    this.method = dto && dto.Method ? dto.Method : '';
    this.methodDescription = dto && dto.MethodDescription ? dto.MethodDescription : '';
    this.comission = dto && dto.Comission ? dto.Comission : 0;
    this.comissionDescription = dto && dto.ComissionDescription ? dto.ComissionDescription : '';

    this.useConvertion = dto && dto.UseConvertion ? dto.UseConvertion : '';
    this.convertionRate = dto && dto.ConvertionRate ? dto.ConvertionRate : '';
    this.convertionCurrency = dto && dto.ConvertionCurrency ? dto.ConvertionCurrency : '';
  }
}

export interface IPaymentInitiateSectorRowInfo {
  rowSlug: string;
  rowSeatSlugs: string[];
}

export class PaymentInitiateSectorRowInfo implements IPaymentInitiateSectorRowInfo {
  public rowSlug: string;
  public rowSeatSlugs: string[];

  constructor(dto?: any) {
    this.rowSlug = dto && dto.rowSlug ? dto.rowSlug : '';
    this.rowSeatSlugs = dto && dto.rowSeatSlugs ? dto.rowSeatSlugs : [];
  }
}

export interface IPaymentInitiateSectorInfo {
  sectorSlug: string;
  quantity: number;
  sectorRows: IPaymentInitiateSectorRowInfo[];
}

export class PaymentInitiateSectorInfo implements IPaymentInitiateSectorInfo {
  public sectorSlug: string;
  public quantity: number;
  public sectorRows: IPaymentInitiateSectorRowInfo[];

  constructor(dto?: any) {
    this.sectorSlug = dto && dto.sectorSlug ? dto.sectorSlug : '';
    this.quantity = dto && dto.quantity ? dto.quantity : 0;
    this.sectorRows = dto && dto.sectorRows ? dto.sectorRows.map((r: any) => new PaymentInitiateSectorRowInfo(r)) : [];
  }
}

export interface IPaymentInitiateTicketInfo {
  quotaId: number;
  sector: IPaymentInitiateSectorInfo;
}

export class PaymentInitiateTicketInfo implements IPaymentInitiateTicketInfo {
  quotaId: number;
  sector: IPaymentInitiateSectorInfo;

  constructor(dto?: any) {
    this.quotaId = dto && dto.quotaId ? dto.quotaId : 0;
    this.sector = dto && dto.sector ? new PaymentInitiateSectorInfo(dto.sector) : null;
  }
}

export interface IPaymentDistributionResult extends IDistributionInfo {
  distributorFound?: boolean;
}

export class PaymentDistributionResult extends DistributionInfo implements IPaymentDistributionResult {
  distributorFound?: boolean;

  constructor(dto?: any) {
    super(dto && dto.DistributorSlug ? dto.DistributorSlug : '');
    this.distributorFound = dto && dto.DistributorFound ? dto.DistributorFound : false;
  }
}

export interface IPaymentInitiateInfo extends IDistributionInfo {
  eventSlug: string;
  paymentMethod: string;
  usePoints: boolean;
  tickets: Array<IPaymentInitiateTicketInfo>;
}

export class PaymentInitiateInfo extends DistributionInfo implements IPaymentInitiateInfo {
  eventSlug: string;
  paymentMethod: string;
  usePoints: boolean;
  token: string;
  tickets: Array<IPaymentInitiateTicketInfo>;

  constructor(dto?: any) {
    super(dto && dto.distributorSlug ? dto.distributorSlug : '');
    this.eventSlug = dto && dto.eventSlug ? dto.eventSlug : '';
    this.paymentMethod = dto && dto.paymentMethod ? dto.paymentMethod : '';
    this.usePoints = dto && dto.usePoints ? dto.usePoints : false;
    this.tickets = dto && dto.tickets ? dto.tickets.map((item: any) => new PaymentInitiateTicketInfo(item)) : [];
    this.token = dto && dto.token ? dto.token : '';
  }
}

export interface IPaymentInitiateTicketResult {
  title: string;
  currency: string;
  pricePerTicket: number;
  quantity: number;
  priceTotal: number;
}

export class PaymentInitiateTicketResult implements IPaymentInitiateTicketResult {
  title: string;
  currency: string;
  pricePerTicket: number;
  quantity: number;
  priceTotal: number;

  constructor(dto?: any) {
    this.title = dto && dto.Title ? dto.Title : '';
    this.currency = dto && dto.Currency ? dto.Currency : '';
    this.pricePerTicket = dto && dto.PricePerTicket ? dto.PricePerTicket : 0;
    this.quantity = dto && dto.Quantity ? dto.Quantity : 0;
    this.priceTotal = dto && dto.PriceTotal ? dto.PriceTotal : 0;
  }
}

export interface IPaymentInitiateResult extends IPaymentDistributionResult {
  event: IEventStoreState;
  tickets: Array<IPaymentInitiateTicketResult>;
  currency: string;
  priceSubtotal: number;
  priceComission: number;
  priceTotal: number;
  pricePayByCard: number;
  pricePayByPoints: number;
}

export class PaymentInitiateResult extends PaymentDistributionResult implements IPaymentInitiateResult {
  event: IEventStoreState;
  tickets: Array<IPaymentInitiateTicketResult>;
  currency: string;
  priceSubtotal: number;
  priceComission: number;
  priceTotal: number;
  pricePayByCard: number;
  pricePayByPoints: number;

  constructor(dto?: any) {
    super(dto);
    this.event = dto && dto.Event ? new EventStoreState(dto.Event) : null;
    this.tickets = dto && dto.Tickets ? dto.Tickets.map((item: any) => new PaymentInitiateTicketResult(item)) : [];
    this.currency = dto && dto.Currency ? dto.Currency : '';
    this.priceSubtotal = dto && dto.PriceSubtotal ? dto.PriceSubtotal : 0;
    this.priceComission = dto && dto.PriceComission ? dto.PriceComission : 0;
    this.priceTotal = dto && dto.PriceTotal ? dto.PriceTotal : 0;
    this.pricePayByCard = dto && dto.PricePayByCard ? dto.PricePayByCard : 0;
    this.pricePayByPoints = dto && dto.PricePayByPoints ? dto.PricePayByPoints : 0;
  }
}

export interface IPaymentProcessInfo extends IPaymentInitiateInfo {
  email: string;
  returnUrl: string;
}

export class PaymentProcessInfo extends PaymentInitiateInfo implements IPaymentProcessInfo {
  email: string;
  returnUrl: string;
  returnErrorUrl: string;
  retailWebsite: string;

  constructor(dto?: any) {
    super(dto);

    this.email = dto && dto.email ? dto.email : '';
    this.returnUrl = dto && dto.returnUrl ? dto.returnUrl : '';
    this.returnErrorUrl = dto && dto.returnErrorUrl ? dto.returnErrorUrl : '';
    this.retailWebsite = dto && dto.retailWebsite ? dto.retailWebsite : '';
    this.token = dto && dto.token ? dto.token : '';
  }
}

export interface IPaymentProcessResult extends IPaymentDistributionResult {
  referenceNumber: string;
  paymentProvider: string;
  paymentToken: string;
  paymentRedirectUrl: string;
  paymentTimeoutAt: dayjs.Dayjs;
}

export class PaymentProcessResult extends PaymentDistributionResult implements IPaymentProcessResult {
  referenceNumber: string;
  paymentProvider: string;
  paymentToken: string;
  paymentRedirectUrl: string;
  paymentTimeoutAt: dayjs.Dayjs;

  constructor(dto?: any) {
    super(dto);
    this.referenceNumber = dto && dto.ReferenceNumber ? dto.ReferenceNumber : '';
    this.paymentProvider = dto && dto.PaymentProvider ? dto.PaymentProvider : '';
    this.paymentToken = dto && dto.PaymentToken ? dto.PaymentToken : '';
    this.paymentRedirectUrl = dto && dto.PaymentRedirectUrl ? dto.PaymentRedirectUrl : '';
    this.paymentTimeoutAt = dto && dto.PaymentTimeoutAt ? dayjs(dto.PaymentTimeoutAt) : dayjs();
  }
}

export interface IPaymentCompleteResult {
  userMessage: string;
  userMessageDetails: string;
  isCompleted: boolean;
  isSuccess: boolean;
  isProcessing: boolean;
}

export class PaymentCompleteResult {
  userMessage: string;
  userMessageDetails: string;
  isCompleted: boolean;
  isSuccess: boolean;
  isProcessing: boolean;

  constructor(dto?: any) {
    this.userMessage = dto && dto.UserMessage ? dto.UserMessage : '';
    this.userMessageDetails = dto && dto.UserMessageDetails ? dto.UserMessageDetails : '';
    this.isCompleted = dto && dto.IsCompleted ? dto.IsCompleted : false;
    this.isSuccess = dto && dto.IsSuccess ? dto.IsSuccess : false;
    this.isProcessing = dto && dto.IsProcessing ? dto.IsProcessing : false;
  }
}

export interface IPaymentCancel {
  referenceNumber: string;
  paymentProvider: PaymentProvider;
}

export class PaymentCancel implements IPaymentCancel {
  referenceNumber: string;
  paymentProvider: PaymentProvider;

  constructor(model: IPaymentCancel) {
    this.referenceNumber = model.referenceNumber;
    this.paymentProvider = model.paymentProvider;
  }
}

export interface IPaymentComplete {
  externalPaymentId: string;
  paymentProvider: PaymentProvider;
}

export class PaymentComplete implements IPaymentComplete {
  externalPaymentId: string;
  paymentProvider: PaymentProvider;

  constructor(model: IPaymentComplete) {
    this.externalPaymentId = model.externalPaymentId;
    this.paymentProvider = model.paymentProvider;
  }
}
