import React, { FC } from 'react';
import { Box, Grid, Skeleton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  box: {
    overflow: 'scroll',
    height: '280px',
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  gridTile: {
    height: '100%',
  },
  skeletonImage: {
    height: '170px',
    width: '240px',
    borderRadius: '10px',
  },
  skeletonText: {
    height: 20,
    marginTop: '10px',
  },
}));

export const SkeletonEventCarousel: FC<SkeletonEventCarouselType> = ({ skeletonCount }) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.box}>
      <Grid container className={classes.gridList} spacing={2}>
        {Array.from(new Array(skeletonCount)).map((value, index) => (
          <Grid key={index} item className={classes.gridTile}>
            <Grid container spacing={0} direction="row">
              <Grid item lg={12} xs={12} sm={12} md={12} xl={12}>
                <Skeleton animation="wave" variant="rectangular" className={classes.skeletonImage} />
              </Grid>
              <Grid item lg={12} xs={12} sm={12} md={12} xl={12}>
                <Skeleton animation="wave" variant="rectangular" className={classes.skeletonText} />
              </Grid>
              <Grid item lg={12} xs={12} sm={12} md={12} xl={12}>
                <Skeleton animation="wave" variant="rectangular" width="35%" className={classes.skeletonText} />
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

type SkeletonEventCarouselType = {
  skeletonCount: number;
};
