import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Page } from '../../../components/UI';
import { EventCheckoutHeaderV1 } from '../../../components/event';
import { PointsSelectorV1 } from './checkoutComponents/pointsSelectorV1';
import { EmailCheckoutFormV1 } from './checkoutComponents/emailCheckoutFormV1';
import { PaymentCheckoutFormV1 } from './checkoutComponents/paymentCheckoutFormV1';
import { IEmailForm } from '../../../components/forms';
import { AnalyticService, Utils } from '../../../services';
import { setEmailV1 } from '../../../store/checkoutV1/actions';
import { CheckoutHelper, EventHelper } from '../../../helpers';
import { ICheckoutStoreState } from '../../../store/checkoutV1/types';
import { IBookStoreState } from '../../../store/bookV1/types';
import { IUserStoreState } from '../../../store/userV1/types';
import { useDispatch } from '../../../store';
import { IDistributionStoreState } from '../../../store/distributionV1/types';
import { BookStatus } from '../../../store/enums';
import { useRoutesHelper } from '../../../helpers/routesHelper';
import { useCheckoutV1, usePrevious } from '../../../helpers/customHooks';
import { GlobalStateType } from '../../../configureStore';

const CheckoutPageV1 = () => {
  const analyticSrv = new AnalyticService();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [redirectUrl, setRedirectUrl] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const checkout = useSelector<GlobalStateType, ICheckoutStoreState>((state) => state.checkoutV1);
  const isInitializing = useSelector<GlobalStateType, boolean>((state) => state.app.isInitializing);
  const book = useSelector<GlobalStateType, IBookStoreState>((state) => state.bookV1);
  const user = useSelector<GlobalStateType, IUserStoreState>((state) => state.user);
  const isAuthorized = useSelector<GlobalStateType, boolean>((state) => state.app.isAuthorized);
  const distribution = useSelector<GlobalStateType, IDistributionStoreState>((state) => state.distributionV1);

  const { checkoutProcess } = useCheckoutV1();

  const checkoutSteps = isAuthorized ? 3 : 2;

  const { eventSlug, sectorSlug, rowSlug, tickets } = useParams<ParamsType>();

  const { getBookRoute, getEventRouteV1, getBookRouteFromBookV1, getBookResultRoute } = useRoutesHelper();
  const prevCheckout = usePrevious(checkout);
  const prevBook = usePrevious(book);
  const isChangedCheckout = Utils.isEqual(checkout, prevCheckout);
  const isChangedBook = Utils.isEqual(book, prevBook);

  const handlePaymentFormSubmit = useCallback(
    async (usePoints: boolean) => {
      setLoading(true);
      await checkoutProcess(checkout, distribution, usePoints);
      analyticSrv.trackEvent('PurchaseProcess');
      setLoading(false);
    },
    [checkout, distribution]
  );

  const init = useCallback(
    async (checkout: ICheckoutStoreState) => {
      if (!book || !book.event) {
        navigate(getBookRoute(eventSlug, null, sectorSlug, rowSlug, +tickets));
        return;
      } else if (book.event.notAvailableForPurchase) {
        navigate(getEventRouteV1(book.event.slug));
        return;
      }

      if (!CheckoutHelper.getIsInitiated(checkout)) {
        navigate(getBookRouteFromBookV1(book));
        return;
      } else if (checkout.isRedoing) {
        return;
      } else if (CheckoutHelper.getIsAvailableForProcessing(checkout) && !isAuthorized) {
        await handlePaymentFormSubmit(checkout.usePoints);
      } else if (CheckoutHelper.getIsInProcess(checkout)) {
        navigate(getBookResultRoute(BookStatus.InProcess));
        return;
      } else if (CheckoutHelper.getIsCompleted(checkout)) {
        navigate(getBookResultRoute(BookStatus.Success));
        return;
      }
    },
    [isChangedCheckout, isChangedBook, eventSlug, isAuthorized, rowSlug, sectorSlug, tickets]
  );

  useEffect(() => {
    (async () => await init(checkout))();
  }, []);

  const handleEmailFormSubmit = (formModel: IEmailForm) => {
    if (formModel) {
      dispatch(setEmailV1(formModel.email));
      analyticSrv.trackEvent('InitiateCheckout');
    }
  };

  useEffect(() => {
    if (CheckoutHelper.isRedirectingMethod(checkout)) {
      setRedirectUrl(checkout.process.paymentRedirectUrl);
    }
  }, [isChangedCheckout]);

  useEffect(() => {
    const isEmailChanged = prevCheckout?.email !== checkout?.email;
    const isAvailableForProcessing =
      isEmailChanged && CheckoutHelper.getIsAvailableForProcessing(checkout) && !isAuthorized;
    if (isAvailableForProcessing) {
      (async () => await handlePaymentFormSubmit(checkout.usePoints))();
    }
  }, [isAuthorized, checkout?.email]);

  useEffect(() => {
    const IsInProcess = CheckoutHelper.getIsProcessed(prevCheckout) && CheckoutHelper.getIsInProcess(checkout);

    if (IsInProcess) {
      (async () => await init(checkout))();
    }
  }, [isChangedCheckout]);

  const isInitiated = CheckoutHelper.getIsInitiated(checkout);
  if (!isInitiated || isInitializing || !checkout) {
    return null;
  }

  const isEmailSet = CheckoutHelper.getIsEmailSet(checkout);
  const isProcessed = CheckoutHelper.getIsProcessed(checkout);
  const { event } = checkout.init;

  return (
    <Page
      title={`${t('Checkout.TicketPurchase')} | ${event.title} - ${event.subtitle}`}
      description={EventHelper.getMetaDescription(event)}
      keywords={[event.title, event.venue.title, t('Checkout.BuyTicket'), t('Checkout.Concert')]}
      imageUrl={event.posterUrl}
    >
      <EventCheckoutHeaderV1 checkout={checkout} isAuthorized={isAuthorized} />
      {isInitiated && !isEmailSet && (
        <EmailCheckoutFormV1
          checkoutSteps={checkoutSteps}
          checkout={checkout}
          isAuthorized={isAuthorized}
          user={user}
          handleEmailFormSubmit={handleEmailFormSubmit}
          distribution={distribution}
        />
      )}
      {isEmailSet && !isProcessed && (
        <PointsSelectorV1
          checkout={checkout}
          book={book}
          distribution={distribution}
          redirectUrl={redirectUrl}
          checkoutSteps={checkoutSteps}
          isAuthorized={isAuthorized}
          user={user}
          handlePaymentFormSubmit={handlePaymentFormSubmit}
          isLoading={isLoading}
        />
      )}
      {isProcessed && (
        <PaymentCheckoutFormV1
          checkoutSteps={checkoutSteps}
          checkout={checkout}
          isAuthorized={isAuthorized}
          user={user}
          redirectUrl={redirectUrl}
        />
      )}
    </Page>
  );
};

export const Header = styled.h2`
  font-size: 14px;
  margin: 0 0 10px 0;
  text-align: center;
  font-family: ${(props) => props.theme.fonts.boldFont};
`;

export const RedirectTitle = styled(Header)``;

export const Title = styled.h1`
  font-size: 14px;
  text-align: center;
`;

export default CheckoutPageV1;

type ParamsType = {
  eventSlug: string;
  sectorSlug: string;
  rowSlug: string;
  tickets: string;
};
