import React, { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from '../../configureStore';
import { Page, PageContent } from '../../components/UI';
import { SkeletonEventDetails } from '../../components/skeletons/eventDetailsSkeleton';
import { AlertType, KeyErrors } from '../../store/enums';
import { setResultInfo } from '../../store/resultInformarion/actions';
import { useRoutesHelper } from '../../helpers/routesHelper';
import { AnalyticService } from '../../services';
import { useEventDetailsQuery } from '../../core/api/eventsSlice';
import { DescriptionCollapse } from './eventDetailsComponents/descriptionCollapse';
import { EventDetailsFooter } from './eventDetailsComponents/eventDetailsFooter';
import { EventDetailsHeader } from './eventDetailsComponents/eventDetailsHeader';
import { useEventHelpers } from '../../helpers/customHooks';
import { EventDate } from './eventDetailsComponents/eventDate';
import { EventPrice } from './eventDetailsComponents/eventPrice';
import { EventInfoItem } from '../../components/event/eventInfoItem';

export const EventDetails: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const analyticSrv = new AnalyticService();

  const { eventSlug } = useParams<{ eventSlug: string }>();

  const isInitializing = useSelector((state) => state.app.isInitializing);
  const distribution = useSelector((state) => state.distribution);

  const { getResultStatusRoute } = useRoutesHelper();
  const { getEventMetaDescription } = useEventHelpers();

  let distributorSlug;

  if (distribution) {
    distributorSlug = distribution.distributorSlug || distributorSlug;
  }

  const {
    data: event,
    isFetching,
    isError,
    error,
  } = useEventDetailsQuery({
    eventSlug,
    distributorSlug,
  });

  useEffect(() => {
    if (!error) {
      return;
    }

    dispatch(
      setResultInfo({
        type: AlertType.Error,
        key: KeyErrors.EventNotFound,
        title: 'Alert.SomethingWentWrong',
        message: 'Book.EventWasntFound',
      })
    );
    navigate(getResultStatusRoute(AlertType.Error, KeyErrors.EventNotFound), { replace: true });
  }, [error]);

  useEffect(() => {
    analyticSrv.trackEvent('EventsDetails');
  }, []);

  return (
    <>
      {isFetching && <SkeletonEventDetails />}
      {!isFetching && !isError && event && (
        <Page
          title={`${event?.title} ${event?.subTitle ? ` - ${event?.subTitle}` : ''}`}
          description={getEventMetaDescription(event)}
          keywords={[event?.title, event?.venue?.title, t('Event.BuyTicket'), t('Event.Concert')]}
          imageUrl={event?.posterUrl}
        >
          <EventDetailsHeader event={event} diagonal />
          <EventInfoWrapper>
            <List>
              <EventDate date={event?.eventDate} />
              {event?.venue && (
                <ListItem>
                  <ListItemWrapper>
                    <EventInfoItem
                      icon="location"
                      sm
                      title={event?.venue?.title}
                      subtitle={`${event?.venue?.address}, ${event?.venue?.city}`}
                    />
                  </ListItemWrapper>
                </ListItem>
              )}
              <EventPrice event={event} />
            </List>
            {event?.description && (
              <DescriptionCollapse title={`${t('Event.MoreAboutTheEvent')}`} subtitle={`${event?.description}`} />
            )}
          </EventInfoWrapper>
          <EventDetailsFooter event={event} isInitializing={isInitializing} />
        </Page>
      )}
    </>
  );
};

export const EventInfoWrapper = styled(PageContent)`
  padding: 20px 35px;
  margin-top: 10px;
`;

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

export const ListItem = styled.li`
  position: relative;
  border-left: 1px solid ${(props) => props.theme.colors.text};
  padding-bottom: 25px;

  &:last-child {
    padding-bottom: 0;
    border-left-color: transparent;
  }
`;

export const ListItemWrapper = styled.div`
  top: -10px;
  position: relative;
  padding-left: 35px;

  &:before {
    content: '';
    position: absolute;
    width: 7px;
    height: 7px;
    background: ${(props) => props.theme.colors.accent};
    top: 6px;
    border-radius: 50%;
    left: -4px;
  }
`;
