import React from 'react';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Helmet from 'react-helmet';

import { useTranslation } from 'react-i18next';
import { AnimatePresence } from 'framer-motion';
import { IGlobalStoreState } from '../../../../store';
import { PageContent } from '../../../../components/UI';
import { PasswordRecoveryForm, PasswordRecoveryFormType } from '../../../../components/forms';
import { PopUp } from '../../../../store/animations';
import { AlertType, KeySuccess } from '../../../../store/enums';
import { useRoutesHelper } from '../../../../helpers/routesHelper';
import { useUtils } from '../../../../services/useUtils';
import { useResetPasswordMutation } from '../../../../core/api/accountSlice';

const PasswordRecoveryPage = () => {
  const { getResultStatusRoute, getResetPasswordRedirectUrlV1 } = useRoutesHelper();
  const { toPageTitle } = useUtils();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [resetPassword, { isLoading }] = useResetPasswordMutation();

  const isInitializing = useSelector<IGlobalStoreState, boolean>((state) => state.app.isInitializing);

  const handleFormSubmit = (formModel: PasswordRecoveryFormType) => {
    if (formModel) {
      const redirectUrl = getResetPasswordRedirectUrlV1();

      (async () => resetPassword({ email: formModel.email, returnUrl: redirectUrl }))().then(() => {
        navigate(getResultStatusRoute(AlertType.Info, KeySuccess.EmailSent), { replace: true });
      });
    }
  };

  if (isInitializing) {
    return null;
  }

  return (
    <AnimatePresence>
      <PageContent center variants={PopUp} initial={'enter'} animate={'exit'}>
        <Helmet>
          <title>{toPageTitle(t('PasswordRecovery.PasswordRecovery'))}</title>
        </Helmet>
        <Header>{t('PasswordRecovery.PasswordRecovery')}</Header>
        <PasswordRecoveryForm
          submitTitle={t('PasswordRecovery.Reestablish')}
          onSubmit={(formModel: PasswordRecoveryFormType) => handleFormSubmit(formModel)}
          isLoading={isLoading}
        />
      </PageContent>
    </AnimatePresence>
  );
};

const Header = styled.h1`
  font-family: ${(props) => props.theme.fonts.boldFont};
  font-size: 18px;
  text-transform: uppercase;
  text-align: center;
`;

export default PasswordRecoveryPage;
