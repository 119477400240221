import React, { FC, memo, useRef, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export const DescriptionCollapse: FC<EventInfoItemType> = memo(({ title, subtitle }) => {
  const [isCollapse, setIsCollapse] = useState(false);
  const subtitleRef = useRef(null);
  const { t } = useTranslation();

  let heightSubtitle = `${subtitleRef?.current?.scrollHeight}px`;

  const handleCollapse = () => {
    setIsCollapse((collapse) => !collapse);
  };

  return (
    <Container>
      <Title>{title}</Title>
      {subtitle && (
        <Subtitle ref={subtitleRef} isCollapse={isCollapse} height={heightSubtitle}>
          {subtitle}
        </Subtitle>
      )}
      <CollapseButton collapse={isCollapse} onClick={handleCollapse}>
        {isCollapse ? t('Event.Collapse') : t('Event.SeeMore')}
      </CollapseButton>
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 35px;
  position: relative;
`;

const Subtitle = styled.span<SubtitleType>`
  font-size: 13px;
  line-height: 17px;
  font-family: ${(props) => props.theme.fonts.lightFont};
  color: ${(props) => props.theme.colors.textSecond};
  height: ${(props) => (props.isCollapse ? props.height : '50px')};
  transition: height 0.2s ease-out;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => (props.isCollapse ? 'none' : 3)};
  -webkit-box-orient: vertical;
  margin-bottom: 30px;
`;

const Title = styled.span`
  font-size: 14px;
  margin-bottom: 5px;
  font-family: ${(props) => props.theme.fonts.boldFont};
  text-transform: uppercase;
`;

const CollapseButton = styled.div<{ collapse: boolean }>`
  right: 0;
  font-size: 14px;
  line-height: 18px;
  font-family: ${(props) => props.theme.fonts.regularAltFont};
  color: ${(props) => props.theme.colors.text};
  bottom: 0;
  position: absolute;
  cursor: pointer;
  width: 80px;

  &:before {
    width: 7px;
    height: 1px;
    background: ${(props) => props.theme.colors.accent};
    right: 0;
    top: 8px;
    content: '';
    transform: ${(props) => (props.collapse ? 'rotate(-45deg)' : 'rotate(45deg)')};
    position: absolute;
    transition: transform 0.1s ease-out;
    border-radius: 20px;
  }

  &:after {
    width: 7px;
    height: 1px;
    background: ${(props) => props.theme.colors.accent};
    right: -4.5px;
    top: 8px;
    content: '';
    transform: ${(props) => (props.collapse ? 'rotate(45deg)' : 'rotate(-45deg)')};
    position: absolute;
    transition: transform 0.1s ease-out;
    border-radius: 20px;
  }
`;

type EventInfoItemType = {
  title: string;
  subtitle?: string;
};

type SubtitleType = {
  isCollapse: boolean;
  height: string;
};
