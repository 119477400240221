import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { AnimatePresence } from 'framer-motion';
import {
  RenderForm,
  RenderTicketSector,
  RenderIssuedInfo,
} from './userTicketDetailsComponents/userTicketDetailsComponents';
import { useDispatch, useSelector } from '../../configureStore';
import { useRoutesHelper } from '../../helpers/routesHelper';
import { useLazyGetUserTicketsQuery, useSendTicketMutation } from '../../core/api/ticketsSlice';
import { setResultInfo } from '../../store/resultInformarion/actions';
import { AlertType, KeyErrors } from '../../store/enums';
import { PagesRoutes } from '../../store/routes';
import { IEmailForm } from '../../components/forms';
import { Page, PageContent } from '../../components/UI';
import { useEventHelpers } from '../../helpers/customHooks';
import { TicketType } from '../../core/models/tickets';
import { EventDetailsHeader } from '../eventDetails/eventDetailsComponents/eventDetailsHeader';

const UserTicketDetailsPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { getProfileRoute } = useRoutesHelper();
  const { getEventMetaDescription } = useEventHelpers();
  const navigate = useNavigate();
  const [email, setEmail] = useState<string | null>(null);

  const isFinalized = useSelector((state) => state.app.isFinalized);
  const isAuthorized = useSelector((state) => state.app.isAuthorized);
  const userTickets = useSelector((state) => state.userTickets);

  const [ticket, setTicket] = useState<TicketType>(null);

  const [sendTicketRequest] = useSendTicketMutation();
  const [getUserTicketsRequest] = useLazyGetUserTicketsQuery();

  const { ticketId } = useParams();

  const initTicket = useCallback(
    (ticketId: string) => {
      if (!ticketId) {
        return;
      }

      const ticket = userTickets.find((ticket: TicketType) => ticket.ticketId === +ticketId);

      if (!ticket) {
        dispatch(
          setResultInfo({
            type: AlertType.Error,
            key: KeyErrors.TicketNotFound,
            title: 'Alert.WaitForTheServerToCompleteThePayment',
            buttons: [
              {
                title: 'Result.GoToMainPage',
                callback: () => navigate(PagesRoutes.Main),
              },
            ],
          })
        );
        return;
      }

      setTicket(ticket);
    },
    [dispatch, navigate, userTickets]
  );

  useEffect(() => {
    if (isFinalized) {
      initTicket(ticketId);
    }
  }, [initTicket, isFinalized, ticketId]);

  useEffect(() => {
    if (isFinalized || isAuthorized) {
      initTicket(ticketId);
    }
  }, [isFinalized, isAuthorized, ticketId]);

  useEffect(() => {
    if (!isAuthorized) {
      const profileRoute = getProfileRoute();

      navigate(`${profileRoute}`, { replace: true });
    }
  }, [getProfileRoute, navigate, isAuthorized]);

  const handleEmailFormSubmit = useCallback(
    (formModel: IEmailForm) => {
      const { email } = formModel;
      if (formModel) {
        sendTicketRequest({ ticketId, email })
          .unwrap()
          .then(() => {
            setEmail(email);
          });

        getUserTicketsRequest();
      }
    },
    [dispatch, ticketId]
  );

  if (!isFinalized || !ticket || !ticket?.ticketId) {
    return null;
  }

  const { event, sector, row, seat, issuedTo } = ticket;

  return (
    <Page title={`${t('TicketDetails.TicketIssue')} | ${getEventMetaDescription(event)}`}>
      <EventDetailsHeader event={ticket.event} noTitle stretch />
      <Content roundCorners>
        <Title>{event.title}</Title>
        <RenderTicketSector sector={sector} seatTitle={seat} rowTitle={row} />
        <AnimatePresence>
          {!issuedTo && !email && <RenderForm handleEmailFormSubmit={handleEmailFormSubmit} />}
          {(issuedTo || email) && <RenderIssuedInfo email={email || issuedTo} />}
        </AnimatePresence>
      </Content>
    </Page>
  );
};

const Content = styled(PageContent as any)`
  padding-top: 0;
  flex: 0;
  min-height: 250px;
`;

const Title = styled.h1`
  font-size: 36px;
  font-family: ${(props) => props.theme.fonts.bolderFont};
  text-align: center;
  margin: 0 0 5px 0;
  position: relative;
  top: -20px;
`;

export const Subtitle = styled.h2`
  font-size: 14px;
  margin: 0 0 15px 0;
  text-align: center;
  position: relative;
  top: -20px;
`;

export default UserTicketDetailsPage;
