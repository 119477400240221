import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';

import { Skeleton } from '@mui/material';
import { IGlobalStoreState, useDispatch } from '../../../store';
import { Page, PageContent } from '../../../components/UI';
import { SchemaSectorSelectorV1 } from '../../../components/book';
import { AlertType, KeyErrors, Pages } from '../../../store/enums';
import { clear, init } from '../../../store/share/actions';
import { IUserStoreState } from '../../../store/userV1/types';
import { useRoutesHelper } from '../../../helpers/routesHelper';
import { setResultInfo } from '../../../store/resultInformarion/actions';
import { Routes } from '../../../store/routes';
import { ShareStoreState } from '../../../store/share/types';
import { Footer } from './shareComponents/footer';
import { TicketsCountSelector } from './shareComponents/ticketsCountSelector';
import { useGetSellEventDetailsQuery } from '../../../core/api/eventsSlice';

const SharePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { getProfileRoute, getBookRoute, getEventRouteV1, getResultStatusRoute } = useRoutesHelper();

  const user = useSelector<IGlobalStoreState, IUserStoreState>((state) => state.user);
  const isRedirection = useSelector<IGlobalStoreState, boolean>((state) => state.app.isRedirection);
  const isFinalized = useSelector<IGlobalStoreState, boolean>((state) => state.app.isFinalized);

  const { eventSlug, sectorSlug, rowSlug, tickets } = useParams<{
    eventSlug: string;
    sectorSlug: string;
    rowSlug: string;
    tickets: string;
  }>();

  const { data: event, isError, isFetching } = useGetSellEventDetailsQuery(eventSlug);

  let ticketsOrRow = +tickets || +rowSlug;
  if (isNaN(ticketsOrRow)) {
    ticketsOrRow = 0;
  }
  useEffect(() => {
    if (isRedirection || !isFinalized) {
      return;
    }
    if (!eventSlug) {
      navigate(Routes.Main);
      return;
    }
  }, [eventSlug, isFinalized, isRedirection]);

  useEffect(() => {
    if (!sectorSlug) {
      const shareStore = new ShareStoreState();
      shareStore.event = event;

      dispatch(init(shareStore));
      return;
    }
    const shareStore = new ShareStoreState();
    shareStore.event = event;

    dispatch(init(shareStore));
  }, []);

  useEffect(() => {
    if (isError) {
      dispatch(
        setResultInfo({
          type: AlertType.Error,
          key: KeyErrors.EventNotFound,
          title: 'Alert.SomethingWentWrong',
          message: 'Alert.CheckLinkNoEventFound',
        })
      );
      navigate(getResultStatusRoute(AlertType.Error, KeyErrors.EventNotFound), { replace: true });
      return;
    } else if (!event?.isDistributionAvailable && !isFetching) {
      navigate(getEventRouteV1(event?.slug));
      return;
    }
  }, [event?.isDistributionAvailable, event?.slug, isError, isFetching]);

  useEffect(() => {
    if (!user) {
      navigate(getProfileRoute());
      return;
    }
  }, [user]);

  useEffect(
    () => () => {
      dispatch(clear());
    },
    []
  );

  useEffect(() => {
    if (!isFetching && !event) {
      navigate(`/${Pages.User}/${Pages.UserEvents}`);
    }
  }, [event, isFetching]);

  const handleSchemaSectorSelect = (sectorSlug: string) => {
    navigate(getBookRoute(eventSlug, null, sectorSlug, null, null, true));
  };

  return (
    <>
      {isFetching && (
        <SkeletonWrapper>
          <Skeleton animation={'wave'} variant={'rectangular'} width={'40vw'} height={'40vh'} />
        </SkeletonWrapper>
      )}
      {!isFetching && (
        <Page isForm title={`${t('Share.TicketSell')} | ${event?.title} - ${event?.subtitle}`}>
          <PageContent>
            <SchemaSectorSelectorV1
              venue={event.venue}
              sectors={event.sectors}
              selectedSector={sectorSlug}
              onChange={(sectorSlug: string) => handleSchemaSectorSelect(sectorSlug)}
            />
            <TicketsCountSelector
              ticketsOrRow={ticketsOrRow}
              sectorSlug={sectorSlug}
              event={event}
              eventSlug={eventSlug}
            />
          </PageContent>
          <Footer
            isLoading={isFetching}
            sectorSlug={sectorSlug}
            event={event}
            eventSlug={eventSlug}
            ticketsOrRow={ticketsOrRow}
            rowSlug={rowSlug}
            user={user}
          />
        </Page>
      )}
    </>
  );
};

const SkeletonWrapper = styled.div`
  display: flex;
  justify-content: center;
  z-index: 0;
`;

export default SharePage;
