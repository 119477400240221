import React from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { AnimatePresence } from 'framer-motion';
import { ModalWindow } from './socialNetworkLogin/modalWindow';
import { Divider, isMobile } from './loginPage';
import { useDispatch, useSelector } from '../../configureStore';
import { useRoutesHelper } from '../../helpers/routesHelper';
import { useRegisterUserMutation } from '../../core/api/accountSlice';
import { RegistrationForm, RegistrationFormType } from '../../components/forms';
import { AlertType, KeySuccess } from '../../store/enums';
import { useUtils } from '../../services/useUtils';
import { PageContent } from '../../components/UI';
import { PopUp } from '../../store/animations';
import { PagesRoutes } from '../../store/routes';
import { distributionClear } from '../../store/distribution/actions';

const RegistrationPage = () => {
  const isInitializing = useSelector((state) => state.app.isInitializing);
  const isAuthorized = useSelector((state) => state.app.isAuthorized);
  const distributor = useSelector((state) => state.distribution.distributorSlug);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { getResultStatusRoute } = useRoutesHelper();
  const { toPageTitle } = useUtils();

  const [registerUser, { isLoading }] = useRegisterUserMutation();

  if (isAuthorized) {
    return <Navigate replace to={PagesRoutes.Main} />;
  }

  if (isInitializing) {
    return null;
  }

  const handleFormSubmit = (formModel: RegistrationFormType) => {
    if (!formModel) {
      return;
    }

    if (distributor) {
      formModel.distributorSlug = distributor;
      formModel.isDistributor = true;
    }

    registerUser(formModel)
      .unwrap()
      .then(() => {
        dispatch(distributionClear());
        navigate(getResultStatusRoute(AlertType.Info, KeySuccess.EmailSent), { replace: true });
      });
  };

  return (
    <AnimatePresence>
      <PageContent center variants={PopUp} initial={'enter'} animate={'exit'}>
        <Helmet>
          <title>{toPageTitle(t('Registration.Registration'))}</title>
        </Helmet>
        <Header>{t('Registration.Registration')}</Header>
        <RegistrationForm
          submitTitle={t('Login.SignUp')}
          onSubmit={(formModel: RegistrationFormType) => handleFormSubmit(formModel)}
          isLoading={isLoading}
        />
        {false && (
          <>
            <Divider isMobile={isMobile}>{t('Login.Or')}</Divider>
            <ModalWindow />
          </>
        )}
      </PageContent>
    </AnimatePresence>
  );
};
const Header = styled.h1`
  font-family: ${(props) => props.theme.fonts.boldFont};
  font-size: 18px;
  text-transform: uppercase;
  text-align: center;
`;

export default RegistrationPage;
