import { AppSettingsState } from './types';
import { createSlice } from '@reduxjs/toolkit';

const appSettingsReducer = createSlice({
  name: 'appSettingsReducer',
  initialState: new AppSettingsState(),
  reducers: {
    appSettingInit: (state, action) => {
      return action.payload;
    },
  },
});

export default appSettingsReducer;
