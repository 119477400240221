import { BookStoreState, IBookStoreState } from './types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = new BookStoreState();

const bookReducerV1 = createSlice({
  name: 'bookReducer',
  initialState,
  reducers: {
    init: (state, action: PayloadAction<IBookStoreState>) => {
      return action.payload;
    },
    setSector: (state, action: PayloadAction<string>) => {
      return { ...state, sectorSlug: action.payload };
    },
    clearBook: () => {
      return initialState;
    },
  },
});

export default bookReducerV1;
