import { DistributionStoreState, IDistributionStoreState } from './types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = new DistributionStoreState();

const distributionReducerV1 = createSlice({
  name: 'distributionReducer',
  initialState,
  reducers: {
    init: (state, action: PayloadAction<IDistributionStoreState>) => {
      return action.payload;
    },
    distributionClearV1: () => {
      return initialState;
    },
  },
});

export default distributionReducerV1;
