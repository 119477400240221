import React, { FC, useEffect } from 'react';
import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDispatch } from '../../../../store';
import { Page, PageContent } from '../../../../components/UI';
import { EventDetailsHeaderV1 } from '../../../../components/event';
import { AnalyticService } from '../../../../services';
import { AlertType, KeyErrors } from '../../../../store/enums';
import { EventHelper } from '../../../../helpers';
import { IEventDetailsStoreState } from '../../../../store/event/eventDetails/EventDetailsStoreState';
import EventInfoItemV1 from '../../../../components/event/eventInfoItemV1';
import { EventDetailsFooterV1 } from './eventDetailsFooterV1';
import { DescriptionCollapseV1 } from './descriptionCollapseV1';
import { setResultInfo } from '../../../../store/resultInformarion/actions';
import { useRoutesHelper } from '../../../../helpers/routesHelper';
import { IDistributionStoreState } from '../../../../store/distributionV1/types';
import { IBookStoreState } from '../../../../store/bookV1/types';
import { SkeletonEventDetails } from '../../../../components/skeletons/eventDetailsSkeleton';
import { useGetEventDetailsBySlugV1Query } from '../../../../core/api/eventsSlice';
import { GlobalStateType } from '../../../../configureStore';

export const EventDetailsPageV1: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const analyticSrv = new AnalyticService();

  const { eventSlug } = useParams<{ eventSlug: string }>();

  const isInitializing = useSelector<GlobalStateType, boolean>((state) => state.app.isInitializing);
  const distribution = useSelector<GlobalStateType, IDistributionStoreState>((state) => state.distributionV1);
  const book = useSelector<GlobalStateType, IBookStoreState>((state) => state.bookV1);

  const { getResultStatusRoute } = useRoutesHelper();
  let distributorSlug;
  let token;
  if (distribution) {
    distributorSlug = distribution.distributorSlug || distributorSlug;
  }
  if (book.token) {
    token = book.token || token;
  }

  const {
    data: event,
    isFetching,
    isError,
  } = useGetEventDetailsBySlugV1Query({
    id: eventSlug,
    distributorSlug,
    token,
  });

  useEffect(() => {
    if (isInitializing || !event || !event.slug) {
      return;
    }

    if (isError) {
      dispatch(
        setResultInfo({
          type: AlertType.Error,
          key: KeyErrors.EventNotFound,
          title: 'Alert.SomethingWentWrong',
          message: 'Alert.CheckLinkNoEventFound',
        })
      );
      navigate(getResultStatusRoute(AlertType.Error, KeyErrors.EventNotFound), { replace: true });
      return;
    }
  }, [event?.slug, isError, isInitializing, navigate]);

  useEffect(() => {
    analyticSrv.trackEvent('EventsDetails');
  }, []);

  const renderDateBlock = (date: dayjs.Dayjs) => {
    const day = dayjs(date).date();
    const month = dayjs(date).format('MMMM YYYY');
    const time = dayjs(date).format('LT');
    return (
      <ListItem>
        <DateTimeItem dateTime={dayjs(date).format()}>
          <DayTimeWrapper>
            <Day>{day}</Day>
            <Time>{time}</Time>
          </DayTimeWrapper>
          <MonthYearWrapper>
            <Month>{month}</Month>
          </MonthYearWrapper>
        </DateTimeItem>
      </ListItem>
    );
  };

  const renderPriceBlock = (event: IEventDetailsStoreState) => {
    const { minPrice, maxPrice, currency } = event;

    if (!minPrice && !maxPrice) {
      return null;
    }

    const priceTitle = minPrice !== maxPrice ? `${minPrice}–${maxPrice}` : minPrice;

    return (
      <ListItem>
        <ListItemWrapper>
          <EventInfoItemV1 icon="purse" sm title={`${currency} ${priceTitle}`} />
        </ListItemWrapper>
      </ListItem>
    );
  };

  return (
    <>
      {isFetching && <SkeletonEventDetails />}
      {!isFetching && !isError && (
        <Page
          title={`${event.title} - ${event.subtitle}`}
          description={EventHelper.getEventDetailsMetaDescription(event)}
          keywords={[event.title, event.venue.title, t('Event.BuyTicket'), t('Event.Concert')]}
          imageUrl={event.posterUrl}
        >
          <EventDetailsHeaderV1 event={event} diagonal />
          <EventInfoWrapper>
            <List>
              {renderDateBlock(event.date)}
              {event.venue && (
                <ListItem>
                  <ListItemWrapper>
                    <EventInfoItemV1
                      icon="location"
                      sm
                      title={event.venue.title}
                      subtitle={`${event.venue.address}, ${event.venue.city}`}
                    />
                  </ListItemWrapper>
                </ListItem>
              )}
              {renderPriceBlock(event)}
            </List>
            {event?.description && (
              <DescriptionCollapseV1 title={`${t('Event.MoreAboutTheEvent')}`} subtitle={`${event.description}`} />
            )}
          </EventInfoWrapper>
          <EventDetailsFooterV1 event={event} isInitializing={isInitializing} />
        </Page>
      )}
    </>
  );
};

export const EventInfoWrapper = styled(PageContent)`
  padding: 20px 35px;
  margin-top: 10px;
`;

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const ListItem = styled.li`
  position: relative;
  border-left: 1px solid ${(props) => props.theme.colors.text};
  padding-bottom: 25px;

  &:last-child {
    padding-bottom: 0;
    border-left-color: transparent;
  }
`;

const ListItemWrapper = styled.div`
  top: -10px;
  position: relative;
  padding-left: 35px;

  &:before {
    content: '';
    position: absolute;
    width: 7px;
    height: 7px;
    background: ${(props) => props.theme.colors.accent};
    top: 6px;
    border-radius: 50%;
    left: -4px;
  }
`;

const Title = styled.span`
  font-size: 14px;
  margin-bottom: 5px;
`;

const Subtitle = styled.span`
  font-size: 12px;
`;

const DateTimeItem = styled.time`
  display: flex;
  flex-direction: column;
  width: 180px;
  top: -10px;
  position: relative;
  padding-left: 35px;
  font-family: ${(props) => props.theme.fonts.boldFont};

  &:before {
    content: '';
    position: absolute;
    width: 7px;
    height: 7px;
    background: ${(props) => props.theme.colors.accent};
    top: 6px;
    border-radius: 50%;
    left: -4px;
  }
`;

const DayTimeWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const MonthYearWrapper = styled.div`
  text-align: right;
  margin-top: 15px;
`;

const Day = styled.span`
  font-size: 64px;
  line-height: 40px;
  font-family: ${(props) => props.theme.fonts.bolderFont};
`;

const Time = styled(Subtitle as any)`
  text-align: right;
  margin-left: 10px;
  padding-bottom: 8px;
  border-bottom: 2px solid ${(props) => props.theme.colors.text};
  flex: 1;
`;

const Month = styled(Title as any)``;
