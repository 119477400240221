import { ITokenTicketQuotaStoreState, TokenTicketQuotaStoreState } from '../../store/ticket/types';
import { baseSlice } from './apiSlice';

type GetTokenType = {
  eventSlug: string;
  sectorSlug: string;
  tickets: number;
};
const shareApi = baseSlice.injectEndpoints({
  endpoints: (build) => ({
    getToken: build.query<string, GetTokenType>({
      query: ({ eventSlug, sectorSlug, tickets }) => ({
        url: `/share/${eventSlug}/${sectorSlug}/${tickets}`,
        responseHandler: (response) => response.text(),
      }),
    }),
    getTicketQuotaByToken: build.query<ITokenTicketQuotaStoreState, string>({
      query: (token: string) => `/share/token/${token}`,
      transformResponse: (response) => new TokenTicketQuotaStoreState(response),
    }),
  }),
});
export const { useLazyGetTokenQuery, useLazyGetTicketQuotaByTokenQuery } = shareApi;
