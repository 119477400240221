import { IMenuStoreState } from './menu/types';
import { IAppStoreState } from './app/types';
import { IEventStoreState } from './event/types';
import { IBookStoreState } from './bookV1/types';
import { ICheckoutStoreState } from './checkoutV1/types';
import { IDistributionStoreState } from './distributionV1/types';
import { IAuthStoreState } from './auth/types';
import { IUserStoreState } from './userV1/types';
import { IRegionalInfoStoreState } from './region/types';
import { IHeaderStoreState } from './header/types';
import { IShareStoreState } from './share/types';
import { IProfileTicketStoreState } from './ticket/ProfileTicketStoreState';
import { IAppSettingsState } from './appSettings/types';
import { ILocationStoreState } from './location/types';
import { ResultInfoType } from './resultInformarion/types';
import { store } from '../index';
import { useDispatch as useReduxDispatch } from 'react-redux';

export interface IGlobalStoreState {
  app: IAppStoreState;
  events: Array<IEventStoreState>;
  book: IBookStoreState;
  checkout: ICheckoutStoreState;
  distribution: IDistributionStoreState;
  auth: IAuthStoreState;
  user: IUserStoreState;
  userEvents: Array<IEventStoreState>;
  userTickets: Array<IProfileTicketStoreState>;
  menu: IMenuStoreState;
  region: IRegionalInfoStoreState;
  header: IHeaderStoreState;
  share: IShareStoreState;
  appSettings: IAppSettingsState;
  location: ILocationStoreState;
  resultInfo: ResultInfoType;
}

export type AppDispatch = typeof store.dispatch;
export const useDispatch = () => useReduxDispatch<AppDispatch>();
