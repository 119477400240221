import React, { FC } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';
import { PopUp } from '../../store/animations';
import { UserEventType } from '../../core/models/events';
import { UserEventItem } from './userEventItem';

type UserEventsListType = {
  events: UserEventType[];
};

export const UserEventsList: FC<UserEventsListType> = ({ events }) => (
  <AnimatePresence>
    <EventsList variants={PopUp} initial={'enter'} animate={'exit'}>
      {events.map((event, index) => (
        <EventsListItem key={index}>
          <UserEventItem event={event} />
        </EventsListItem>
      ))}
    </EventsList>
  </AnimatePresence>
);

const EventsList = styled(motion.ul)`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const EventsListItem = styled.li`
  margin-bottom: 10px;
`;
