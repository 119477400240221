import React, { FC, useEffect, useState } from 'react';
import { Alert, Slide } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from '../../store';
import { clearError } from '../../store/app/actions';
import { useSelector } from '../../configureStore';

const useStyles = makeStyles()(() => ({
  alert: {
    boxShadow: 'rgba(0, 0, 0, 0.63) 4px -4px 16px, rgba(0, 0, 0, 0.63) -4px 4px 16px',
    '& .MuiAlert-icon': {
      alignItems: 'center',
      padding: '0px 0px',
    },
    '& .MuiAlert-action': {
      alignItems: 'center',
      padding: '0 0 0 16px',
    },
  },
}));

export const AlertError: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { classes } = useStyles();

  const [close, setClose] = useState(true);

  const message = useSelector((state) => state.app.error?.message);
  const type = useSelector((state) => state.app.error?.type);

  const closeHandle = () => {
    setClose(false);
    dispatch(clearError());
  };

  useEffect(() => {
    if (!message) {
      return;
    }

    scrollTo({ top: 0, behavior: 'smooth' });
    const timeout = setTimeout(() => {
      setClose(false);
      dispatch(clearError());
    }, 5000);

    return () => {
      clearTimeout(timeout);
      setClose(true);
    };
  }, [dispatch, message]);

  if (!message) {
    return null;
  }

  return (
    <Slide in={close} direction={'left'}>
      <Alert
        className={classes.alert}
        variant="filled"
        severity={type}
        action={
          <IconButton aria-label="close" color="inherit" size="small" onClick={closeHandle}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        sx={{ position: 'absolute', top: 0, right: 0, margin: '10px', borderRadius: '50px', zIndex: 999 }}
      >
        {t(message)}
      </Alert>
    </Slide>
  );
};
