import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Page } from '../../../components/UI';
import { useDispatch } from '../../../store';
import { IEventStoreState } from '../../../store/event/types';
import { citySelectorHide, citySelectorShow } from '../../../store/header/actions';
import EventHeadline from '../../../components/event/eventHeadline';
import { EventHelper } from '../../../helpers';
import { AnalyticService } from '../../../services';
import { GlobalStateType } from '../../../configureStore';

export const EventsPageV1 = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const events = useSelector<GlobalStateType, IEventStoreState[]>((state) => state.events);
  const isInitializing = useSelector<GlobalStateType, boolean>((state) => state.app.isInitializing);
  const userEvents = useSelector<GlobalStateType, IEventStoreState[]>((state) => state.userEventsV1);

  const analyticSrv = new AnalyticService();

  useEffect(() => {
    analyticSrv.trackEvent('EventsList');
    dispatch(citySelectorShow());

    return () => {
      dispatch(citySelectorHide());
    };
  }, []);

  if (isInitializing || !events.length) {
    return null;
  }

  return (
    <Page
      title={t('EventPage.Events')}
      description={t('EventPage.UpcomingEvents')}
      keywords={events.map((e) => e.title)}
    >
      <EventHeadline event={events[0]} isMain isDistributed={EventHelper.isEventDistributed(events[0], userEvents)} />
      {events.slice(1).map((event, index) => (
        <EventHeadline key={index} event={event} isDistributed={EventHelper.isEventDistributed(event, userEvents)} />
      ))}
    </Page>
  );
};
