import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useDispatch } from '../../../configureStore';
import { useRoutesHelper } from '../../../helpers/routesHelper';
import { useFacebookTokenMutation } from '../../../core/api/authSlice';
import { useScript } from '../../../helpers/customHooks';
import { AuthSettings } from '../../../settings/appSettings';
import { PagesRoutes } from '../../../store/routes';

export const FacebookLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { getProfileRoute } = useRoutesHelper();

  const [facebookUser, setFacebookUser] = useState<facebookUserInfo>(null);
  const [facebookLogin] = useFacebookTokenMutation();

  useScript(AuthSettings.FACEBOOK_SDK, () => {
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: AuthSettings.FACEBOOK_APP_ID,
        cookie: true,
        xfbml: true,
        version: 'v14.0',
      });
    };
  });

  const onLoginClick = () => {
    window.FB.login(
      (response) => {
        const { authResponse } = response;
        setFacebookUser({ accessToken: authResponse.accessToken, clientId: AuthSettings.FACEBOOK_APP_ID });
      },
      { scope: 'public_profile, email' }
    );
  };

  useEffect(() => {
    if (!facebookUser) {
      return;
    }
    const token = {
      access_token: facebookUser.accessToken,
      client_id: AuthSettings.FACEBOOK_APP_ID,
    };
    (async () => await facebookLogin(token))()
      .then(() => {
        navigate(PagesRoutes.Profile, { replace: true });
      })
      .catch(() => {
        navigate(PagesRoutes.Login, { replace: true });
      });
  }, [facebookUser]);

  const FACEBOOK_LOGO = '../../../content/img/facebookLogo.svg';

  return (
    <FacebookButton onClick={onLoginClick}>
      <FacebookImg src={FACEBOOK_LOGO} alt="facebookLogo" />
      <Title>{t('Login.ContinueWithFacebook')}</Title>
    </FacebookButton>
  );
};

const FacebookImg = styled.img`
  width: 18px;
  height: 18px;
`;

const Title = styled.span`
  margin-left: 8px;
  font-family: ${(props) => props.theme.fonts.googleMediumFont};
  font-size: 14px;
  font-weight: 500;
  color: #3c4043;
  letter-spacing: 0.25px;
  -webkit-font-smoothing: antialiased;
`;

const FacebookButton = styled.div`
  width: 302px;
  height: 40px;
  border-radius: 50px;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

type facebookUserInfo = {
  clientId: string;
  accessToken: string;
};

declare global {
  interface Window {
    FB: any;
    getLoginStatus: any;
    fbAsyncInit: any;
  }
}
