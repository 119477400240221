import { Grid, Skeleton, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { FC, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { IEventCategory } from '../../store/event/types';
import { EventsV1 } from './eventsV1';
import { useLazyGetEventsByCategoriesV1Query } from '../../core/api/eventsSlice';
import { GenreEventsSkeleton } from '../skeletons/genreEventsSkeleton';

const useStyles = makeStyles()(() => ({
  gridTitle: {
    fontSize: 14,
    lineHeight: '14px',
    textTransform: 'uppercase',
    display: 'inline-block',
    '&:hover': {
      textDecoration: 'none',
      cursor: 'pointer',
    },
  },
}));

export const CategorizedEventCarouselV1: FC<CategorizedEventCarouselType> = memo(
  ({ categories, isLoadingCategories }) => {
    const { classes } = useStyles();
    const { t } = useTranslation();

    const [getEventsByCategories] = useLazyGetEventsByCategoriesV1Query();

    const loadCategorisedEvents = useCallback(async (categoryId: number) => {
      const { data } = await getEventsByCategories(categoryId);
      return data;
    }, []);

    return (
      <Grid container direction="row" justifyContent="center" alignItems="center" spacing={0}>
        {isLoadingCategories && <GenreEventsSkeleton />}
        {!isLoadingCategories &&
          categories
            .slice()
            .sort((a, b) => b.eventsCount - a.eventsCount)
            .map((category) => (
              <Grid key={category.id} item lg={12} xs={12} sm={12} md={12} xl={12}>
                <div>
                  {!categories.length && (
                    <Typography variant="h6" className={classes.gridTitle}>
                      <Skeleton variant="rectangular" width={140} height={25} />
                    </Typography>
                  )}
                  {categories.length && (
                    <Typography variant="h6" className={classes.gridTitle}>
                      {t<string>(`Dashboard.Categories.${category.slug}`)}
                    </Typography>
                  )}
                </div>
                <EventsV1 load={() => loadCategorisedEvents(category.id)} />
              </Grid>
            ))}
      </Grid>
    );
  }
);

type CategorizedEventCarouselType = {
  categories: IEventCategory[];
  isLoadingCategories: boolean;
};
