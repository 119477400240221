import React, { FC, memo, useRef } from 'react';
import { KeyboardArrowUp } from '@mui/icons-material';
import { Fab, Grid, Skeleton, Slide, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { SortButton } from './sortButton';
import { TicketGroup } from './ticketGroup';
import { TicketGroupsSkeleton } from '../../../components/skeletons/ticketGroupsSkeleton';
import { TicketGroupTEType } from '../book';

const useStyles = makeStyles()((theme) => ({
  ticketGroupsContainer: {
    height: '530px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    background: 'linear-gradient(180.62deg, rgba(28, 28, 28, 0) -91.79%, rgba(18, 17, 17, 0.41) 157.66%)',
    boxShadow: '4px -4px 16px rgb(0 0 0 / 63%), -4px 4px 16px rgba(0, 0, 0, 0.63)',
    borderRadius: '12px',
    padding: '32px',
    [theme.breakpoints.down(425)]: {
      padding: '18px 16px',
    },
  },
  container: {
    overflow: 'hidden',
    position: 'relative',
    height: '100%',
  },
  header: {
    display: 'flex',
    padding: '0 32px 29px 32px',
    justifyContent: 'space-between',

    [theme.breakpoints.down(425)]: {
      padding: '0 8px 16px 8px',
    },
  },
  title: {
    fontSize: '0.75rem',
    cursor: 'default',

    [theme.breakpoints.down(425)]: {
      fontSize: '0.625rem',
    },
  },
  ticketGroups: {
    height: '100%',
    width: '100%',
    overflow: 'auto',
    scrollBehavior: 'smooth',
  },
  downButton: {
    position: 'absolute',
    bottom: '16px',
    right: 0,
    width: '37px',
    height: '37px',
    border: '0.5px solid #FFFFFF',
    color: theme.palette.primary.contrastText,
    transform: 'translateX(-50%)',

    [theme.breakpoints.down(425)]: {
      right: '50%',
      left: '50%',
      bottom: '-30px',
      transform: 'translate(-50%, -50%)',
    },
    ':hover': {
      backgroundColor: 'rgb(43,42,42)',
    },
  },
  skeletonSortButton: {
    borderRadius: '35px',
    width: '152px',
    height: '32px',
  },
  noTickets: {
    fontSize: '1.4rem',
    position: 'absolute',
    whiteSpace: 'nowrap',
    top: '45%',
    right: 0,
    left: 0,
    margin: 'auto',
    textAlign: 'center',
  },
}));

export const TicketGroups: FC<TicketGroupsType> = memo(({ ticketGroups, title, eventSlug, isLoading, currency }) => {
  const { classes } = useStyles();
  const ticketGroupsRef = useRef(null);

  const scrollTop = () => {
    const ticketGroupsElement = ticketGroupsRef.current;
    ticketGroupsElement.scrollTo(0, 0);
  };
  return (
    <Grid className={classes.ticketGroupsContainer}>
      <header className={classes.header}>
        <Typography className={classes.title} component={'div'} alignSelf={'center'}>
          {isLoading && <Skeleton animation="wave" variant="rectangular" width={'70px'} height={'18px'} />}
          {!isLoading && title}
        </Typography>
        {isLoading && <Skeleton animation="wave" variant="rectangular" className={classes.skeletonSortButton} />}
        {!isLoading && <SortButton />}
      </header>
      <Grid container className={classes.container}>
        <Grid item className={classes.ticketGroups} ref={ticketGroupsRef}>
          {isLoading && <TicketGroupsSkeleton />}
          {!isLoading &&
            ticketGroups.map((ticketGroup) => (
              <TicketGroup key={ticketGroup.id} ticketGroup={ticketGroup} eventSlug={eventSlug} currency={currency} />
            ))}
          {!isLoading && !ticketGroups?.length && (
            <Slide direction="down" in={!ticketGroups?.length} container={ticketGroupsRef.current}>
              <Typography className={classes.noTickets} component={'div'}>
                There aren’t any tickets
              </Typography>
            </Slide>
          )}
        </Grid>
      </Grid>
      <Fab className={classes.downButton} onClick={scrollTop}>
        <KeyboardArrowUp />
      </Fab>
    </Grid>
  );
});

type TicketGroupsType = {
  eventSlug: string;
  ticketGroups: TicketGroupTEType[];
  title: string;
  isLoading: boolean;
  currency: string;
};
