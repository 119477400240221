import styled from 'styled-components';

import { motion } from 'framer-motion';
import { Label } from '../UI';

export interface IBaseFormProps<TFormModel> {
  submitTitle?: string;
  errors?: any;
  onSubmit?: (formModel: TFormModel) => void;
}

interface IFieldWrapperProps {
  inline?: boolean;
  noted?: boolean;
}

interface IFieldsetProps {
  fullWidth?: boolean;
}

export const Fieldset = styled.fieldset<IFieldsetProps>`
  border: none;
  display: flex;
  flex-direction: column;
  margin: ${(props) => (props.fullWidth ? '0 -25px' : '0')};
  padding: 0;
`;

export const FieldWrapper = styled(motion.div)<IFieldWrapperProps>`
  margin-bottom: ${(props) => (props.noted ? '0' : '15px')};
  display: flex;
  flex-direction: ${(props) => (props.inline ? 'row' : 'column')};

  > input:not([type='radio']),
  > div {
    flex: 1;

    &:not(:last-child) {
      margin-right: ${(props) => (props.inline ? '10px' : '0')};
    }
  }
`;

export const FieldsetLabel = styled(Label)`
  margin-bottom: 10px;
  font-size: 12px;
  text-align: center;
`;

export const FieldInfo = styled(motion.span)`
  color: ${(props) => props.theme.colors.accent};
  font-size: 10px;
  display: block;
  margin: 10px 21px;
  font-family: ${(props) => props.theme.fonts.lighterFont};
`;

export const FieldError = styled(FieldInfo)<{ theme: { colors: { error: string } } }>`
  color: ${(props) => props.theme.colors.error};
`;

export const FormError = styled(FieldError)`
  font-size: 12px;
  margin: 5px 0;
  padding: 10px 21px;
`;

export const FormInfo = styled(FieldInfo)`
  font-size: 12px;
  line-height: 15px;
  margin: 5px 0;
  padding: 10px 21px;
`;
