import { ResultInfoType } from './types';
import resultInfoReducer from './reducers';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const setResultInfo = createAsyncThunk('resultInformation/setResultInfo', (config: ResultInfoType, thunkAPI) => {
  const { dispatch } = thunkAPI;
  if (!config) {
    return;
  }
  dispatch(resultInfo(config));
});

export const { resultInfo, clearInfo } = resultInfoReducer.actions;
