import { Grid } from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { AppTheme } from '../settings/appTheme';

const useStyles = makeStyles()(() => ({
  copyright: {
    marginTop: 5,
    '&:nth-of-type(n+1)': {
      fontSize: 14,
      lineHeight: '14px',
      color: '#6B6B6B',
    },
  },
  footer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  copyrightCompany: {
    fontSize: 14,
    lineHeight: '14px',
  },
  logo: {
    width: 104,
    height: 24,
  },
  logoFooter: {
    marginLeft: 15,
  },
}));

export const Footer = () => {
  const { classes } = useStyles();
  return (
    <Grid container className={classes.footer} alignItems={'center'}>
      <Grid item className={classes.logoFooter}>
        <img className={classes.logo} src={AppTheme.img.logo} alt={AppTheme.img.logo} />
      </Grid>
      <Grid item className={classes.copyright}>
        <span>ⓒ </span>
        <span>{`2019-${new Date().getFullYear()} `}</span>
        <span className={classes.copyrightCompany}>Fanaticka</span>
      </Grid>
    </Grid>
  );
};
