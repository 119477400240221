import React, { FC } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';
import { RLink } from '../UI';
import { useRoutesHelper } from '../../helpers/routesHelper';
import { UserEventType } from '../../core/models/events';
import Constants from '../../store/constants';

interface IUserEventItemProps {
  event: UserEventType;
}

export const UserEventItem: FC<IUserEventItemProps> = ({ event }) => {
  const { t } = useTranslation();
  const { getSellEventRoute } = useRoutesHelper();

  return (
    <Link to={getSellEventRoute(event.slug)} title={t('MyEvents.Sell')}>
      <Container>
        <Poster bg={event.posterUrl} />
        <Content>
          <Header>
            <Title>{event.title}</Title>
          </Header>
          <InfoWrapper>
            <InfoItem>{dayjs(event.eventDate).format(Constants.DateFormat)}</InfoItem>
            <InfoItem>{`${event.city}, ${event.hall}`}</InfoItem>
          </InfoWrapper>
          <Footer>
            <Action>{t('MyEvents.Sell')}</Action>
          </Footer>
        </Content>
      </Container>
    </Link>
  );
};

const Link = styled(RLink)`
  display: block;

  &:hover {
    filter: brightness(80%);
  }
`;

const Container = styled('article')`
  display: flex;
  border-top: 1px solid ${(props) => props.theme.colors.text};
  border-bottom: 1px solid ${(props) => props.theme.colors.text};
  min-height: 150px;
  height: 35vw;
  max-height: 25vh;
`;

const Poster: any = styled.div`
  flex: 1;
  width: 50%;
  background: url(${(props: any) => props.bg}) top no-repeat;
  background-size: cover;
`;

const Content = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 50%;
  border-left: 1px solid ${(props) => props.theme.colors.text};
  padding: 10px 15px;
`;

const Header = styled.header``;

const InfoWrapper = styled.section`
  flex: 1;
  padding: 10px 0;
`;

const InfoItem: any = styled.p`
  margin: 5px 0;
  font-size: 12px;
`;

const Title = styled.h1`
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  font-size: 18px;
  font-family: ${(props) => props.theme.fonts.bolderFont};
`;

const Footer = styled.footer``;

const Action: any = styled.span`
  font-size: 12px;
  font-family: ${(props) => props.theme.fonts.boldFont};
  color: ${(props) => props.theme.colors.accent};
  text-transform: uppercase;
  opacity: ${(props: any) => (props.disabled ? 0.7 : 1)};
`;
