import 'normalize.css';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { I18nextProvider } from 'react-i18next';
import ruLocale from 'date-fns/locale/ru';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import i18n from './i18n';
import configureStore from './configureStore';
import { AnalyticsSettings } from './settings/appSettings';
import App from './containers/app';

//dayjs.locale((window.navigator.language === 'ru-RU' || window.navigator.language === 'ru') ? 'ru' : 'en');
dayjs.extend(LocalizedFormat);

if (AnalyticsSettings.APP_INSIGHTS_ID) {
  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: AnalyticsSettings.APP_INSIGHTS_ID,
    },
  });
  appInsights.loadAppInsights();
  appInsights.trackPageView();
}

export const muiCache = createCache({
  key: 'mui',
  prepend: true,
});

export const { persistor, store } = configureStore();

const root = createRoot(document.getElementById('root'));
root.render(
  <I18nextProvider i18n={i18n}>
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ruLocale}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <CacheProvider value={muiCache}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </CacheProvider>
        </PersistGate>
      </Provider>
    </LocalizationProvider>
  </I18nextProvider>
);
