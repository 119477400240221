import React, { useEffect, useLayoutEffect } from 'react';
import { Route, Routes as Switch } from 'react-router-dom';
import { useDispatch } from '../../configureStore';
import { useNavigate, useParams } from 'react-router';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import RegistrationPage from './registrationPage';
import LoginPage from './loginPage';
import EmailConfirmPage from './emailConfirmPage';
import ProfilePage from './profilePage';
import EditProfilePage from './editProfilePage';
import UserEventsPage from './userEventsPage';
import UserTicketsPage from './userTicketsPage';
import PasswordRecoveryPage from './password/passwordRecoveryPage';
import ResetPasswordPage from './password/resetPasswordPage';
import { AppTheme } from '../../settings/appTheme';
import { Page } from '../../components/UI';
import { PagesRoutes } from '../../store/routes';
import { distributionInit } from '../../store/distribution/actions';

export const User = () => {
  const dispatch = useDispatch();
  const { distributorSlug } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!distributorSlug) {
      return;
    }
    dispatch(distributionInit(distributorSlug));
    navigate(PagesRoutes.Registration, { replace: true });
  }, []);

  return (
    <Page bg={AppTheme.img.backgrounds.bg2} fullsize>
      <RouteContainer>
        <Switch>
          <Route key="registration" path={'registration'} element={<RegistrationPage />} />
          <Route key="login" path={'login'} element={<LoginPage />} />
          <Route key="profile" path={'profile'} element={<ProfilePage />} />
          <Route key="edit-profile" path={'edit-profile'} element={<EditProfilePage />} />
          <Route key="email-confirm" path={PagesRoutes.UserConfirmEmail} element={<EmailConfirmPage />} />
          <Route key="user-events" path={'my-events'} element={<UserEventsPage />} />
          <Route key="user-tickets" path={'my-tickets'} element={<UserTicketsPage />} />
          <Route key="password-recovery" path={'password-recovery'} element={<PasswordRecoveryPage />} />
          <Route key="password-reset" path={'password-reset'} element={<ResetPasswordPage />} />
        </Switch>
      </RouteContainer>
    </Page>
  );
};

const RouteContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`;
