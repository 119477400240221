import React, { FC, useState } from 'react';
import { Chip, ClickAwayListener, List, ListItem, Paper, Theme, Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import { GenreType } from '../../core/models/events';
import { FilterParams } from '../../store/enums';

type StyleProps = {
  display: string;
  marginRight: string;
  overflow;
};

const useStyles = makeStyles<StyleProps>()((theme: Theme, { overflow, display, marginRight }) => ({
  containerGenresAccordion: {
    position: 'relative',
    marginLeft: 'auto',
  },
  paperListGenres: {
    position: 'absolute',
    minWidth: '190px',
    padding: '16px',
    background: 'linear-gradient(180.62deg, rgba(28, 28, 28, 1) -91.79%, rgba(18, 17, 17, 1) 157.66%)',
    boxShadow: '4px -4px 16px rgb(0 0 0 / 63%), -4px 4px 16px rgba(0, 0, 0, 0.63)',
    borderRadius: '12px',
    zIndex: 1,
    transform: 'translateX(-50%)',
    marginTop: 10,
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '260px',
  },
  listItemText: {
    '& span': {
      fontSize: 14,
      lineHeight: '14px',
      zIndex: 1,
      color: 'black',
    },
  },
  chipLocationInfo: {
    border: '1px solid rgb(107, 107, 107, 0.6)',
    borderRadius: 35,
    height: 30,
    fontSize: 12,
    color: 'white',
    lineHeight: '12px',
    '& span': {
      marginTop: 1,
      marginRight,
    },
    '& .MuiChip-label': {
      overflow,
    },
    '& .MuiChip-deleteIcon': {
      display,
      color: 'rgba(255, 255, 255, 0.26)',
      '&:hover': {
        color: 'rgba(255, 255, 255, 0.4)',
      },
    },
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
    [theme.breakpoints.down(425)]: {
      width: 100,
    },
  },
  notFoundText: {
    color: 'white',
    textDecoration: 'none',
    fontSize: '0.75rem',
    position: 'relative',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    maxHeight: '100%',
    overflow: 'auto',
  },
  listItem: {
    padding: '0 0 0 0',
  },
  title: {
    color: 'white',
    textDecoration: 'none',
    fontSize: '0.75rem',
    cursor: 'pointer',
    ':visited': {
      color: 'white',
    },
    ':hover': {
      color: theme.palette.secondary.main,
    },
  },
}));

export const GenresDropDown: FC<GenresDropDownType> = ({ genres }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const genreParam = +searchParams.get(FilterParams.Genre);

  const [open, setOpen] = useState(false);
  const [currentGenreId, setCurrentGenreId] = useState<number>(genreParam || null);

  const styles = {
    display: currentGenreId ? 'inherit' : 'none',
    marginRight: currentGenreId ? '0px' : '5px',
    overflow: currentGenreId ? 'hidden' : 'inherit',
  };
  const { classes } = useStyles(styles);

  const { t } = useTranslation();

  const handleOpenClick = () => setOpen((open) => !open);
  const handleAwayClick = () => setOpen(false);

  const handleGenresFiltered = (genre: number) => {
    searchParams.set(FilterParams.Genre, `${genre}`);
    setSearchParams(searchParams);

    setCurrentGenreId(genre);
    setOpen(false);
  };

  const genreTitle = () => {
    const genre = genres?.length && currentGenreId && genres.find((genre) => genre.id === currentGenreId);
    return genres?.length && currentGenreId ? genre.title : t(`Dashboard.Genres`);
  };

  const handleDeleteGenre = () => {
    searchParams.delete(FilterParams.Genre);
    setSearchParams(searchParams);

    setCurrentGenreId(null);
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleAwayClick}>
      <div className={classes.containerGenresAccordion}>
        <Chip
          className={classes.chipLocationInfo}
          label={genreTitle()}
          icon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          onClick={handleOpenClick}
          onDelete={currentGenreId && handleDeleteGenre}
        />
        {open && (
          <Paper className={classes.paperListGenres}>
            <List className={classes.list}>
              {genres?.length ? (
                genres?.map((genre) => (
                  <ListItem key={genre.slug} className={classes.listItem}>
                    <Typography
                      component={'div'}
                      style={genre.id === currentGenreId ? { color: '#FFBF00' } : undefined}
                      className={classes.title}
                      onClick={() => handleGenresFiltered(genre.id)}
                    >
                      {genre.title}
                    </Typography>
                  </ListItem>
                ))
              ) : (
                <ListItem className={classes.listItem}>
                  <Typography component={'div'} className={classes.notFoundText}>
                    {t<string>(`Dashboard.NothingFound`)}
                  </Typography>
                </ListItem>
              )}
            </List>
          </Paper>
        )}
      </div>
    </ClickAwayListener>
  );
};

type GenresDropDownType = {
  genres: GenreType[];
};
