import React, { FC, memo } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { Grid, Tooltip, Typography} from '@mui/material';
import { useRoutesHelper } from '../../../helpers/routesHelper';
import { useUtils } from '../../../services/useUtils';
import { TicketGroupTEType } from '../book';

const useStyles = makeStyles()((theme) => ({
  link: {
    color: theme.palette.primary.contrastText,
    textDecoration: 'none',
    ':visited': {
      color: theme.palette.primary.contrastText,
    },
  },
  title: {
    fontSize: '0.75rem',
    [theme.breakpoints.down(425)]: {
      fontSize: '0.625rem',
    },
  },
  quantityTickets: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  price: {
    display: 'flex',
    gap: 11,
    alignItems: 'center',
  },
  arrowContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  arrow: {
    height: '0.75rem',
    width: '10px',
    transition: 'transform 0.2s ease-in',
    [theme.breakpoints.down(425)]: {
      height: '0.625rem',
    },
  },
  ticketGroup: {
    display: 'flex',
    padding: '10px 12px',
    borderTop: '0.5px solid #a8a8a8',
    gap: '10px',
    alignItems: 'center',

    ':hover': {
      backgroundColor: theme.palette.secondary.dark,
      '& .mui-1uo9f69-arrow': {
        transform: 'translateX(10px)',
      },
    },
    '&:last-child': {
      borderBottom: '0.5px solid #a8a8a8',
    },
    [theme.breakpoints.down(425)]: {
      gap: '5px',
    },
  },
  image: {
    height: '20px',
    width: '20px',
    [theme.breakpoints.down(425)]: {
      height: '15px',
      width: '15px',
    },
  },
}));

export const TicketGroup: FC<TicketGroupPropsType> = memo(({ eventSlug, ticketGroup, currency }) => {
  const { classes } = useStyles();
  const { getTicketGroupRoute } = useRoutesHelper();
  const { getEmojiFlagByCurrency } = useUtils();

  if (!ticketGroup) {
    return null;
  }

  const { row, format, retail_price, tevo_section_name, id, available_quantity, in_hand, in_hand_on, public_notes, instant_delivery } = ticketGroup;

  const IC_PURSE_PATH = 'content/img/icPurse.svg';
  const IC_TICKET_PATH = 'content/img/icTicket.svg';
  const IC_ARROW_PATH = 'content/img/arrow.svg';

  const place = `section ${tevo_section_name}${row ? `, row ${row}` : ''}`;

  const costs = currency && `${getEmojiFlagByCurrency(currency)}${retail_price} each`;
  const isEticket = format === 'Eticket';
  const isTmMobile = format === 'TM_modile';
  const inHandDate = `${format}, in hand date: ${in_hand_on}. ${public_notes}`;

  return (
    <Link className={classes.link} to={getTicketGroupRoute(eventSlug, id)}>
      <Grid item component={'div'} xs={12} className={classes.ticketGroup}>
        <Grid item xs={5}>
          <Typography className={classes.title} component={'span'}>
            {place}
          </Typography>
          <Tooltip title={in_hand ? `${format}, already in hand. ${public_notes}` : inHandDate}>
            <span>{isEticket ? '💻' : '📱'}</span>
          </Tooltip>
        <Tooltip title={in_hand ? `${format}, already in hand. ${public_notes}` : inHandDate}>
          <span>{in_hand ? '✋' : ''}</span>
        </Tooltip>
          <Tooltip title={in_hand ? `${format}, already in hand. ${public_notes}` : inHandDate}>
            <span>{instant_delivery ? '⚡' : ''}</span>
          </Tooltip>
        </Grid>
        <Grid item xs={3} className={classes.quantityTickets}>
          <img className={classes.image} src={IC_TICKET_PATH} alt="icTicket" />
          <Typography className={classes.title} component={'span'}>{`${available_quantity} tickets`}</Typography>
        </Grid>
        <Grid item xs={3} className={classes.price}>
          <img className={classes.image} src={IC_PURSE_PATH} alt={'wallet'} />
          <Typography className={classes.title} component={'span'}>
            {costs}
          </Typography>
        </Grid>
        <Grid item xs={1} className={classes.arrowContainer}>
          <img className={classes.arrow} src={IC_ARROW_PATH} alt={'arrow'} />
        </Grid>
      </Grid>
    </Link>
  );
});

type TicketGroupPropsType = {
  ticketGroup: TicketGroupTEType;
  eventSlug: string;
  currency: string;
};
