import React, { ChangeEvent, FC, memo } from 'react';
import styled from 'styled-components';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import { useTranslation } from 'react-i18next';
import { AnimatePresence, motion } from 'framer-motion';
import { Button, RadioButton, RadioLabel } from '../UI';
import { FieldError, Fieldset, FieldWrapper, IBaseFormProps } from './commonForm';
import { PopUp } from '../../store/animations';
import { IUserStoreState } from '../../store/userV1/types';
import { Utils } from '../../services';
import { useUtils } from '../../services/useUtils';
import UserHelper from '../../helpers/userHelper';
import { CheckoutType } from '../../store/checkout/types';
import { useCheckoutHelper } from '../../helpers/customHooks';
import { BookType } from '../../store/book/types';
import { useSelector } from '../../configureStore';

export interface IPaymentForm {
  usePoints: boolean;
}

interface IPaymentFormProps extends IBaseFormProps<IPaymentForm> {
  checkout: CheckoutType;
  user: IUserStoreState;
  isLoading?: boolean;
  book?: BookType;

  onUsePointsChange?: (usePoints: boolean) => void;
}

export const PaymentForm: FC<IPaymentFormProps> = memo(
  ({ checkout, onUsePointsChange, user, onSubmit, isLoading, book }) => {
    const { t } = useTranslation();
    const { getEmojiFlagByCurrency } = useUtils();
    const { getIsEnoughPoints } = useCheckoutHelper();
    const ticketGroups = useSelector((state) => state.book.ticketGroups);

    const { currency } = book.event;

    const ValidationSchema = Yup.object().shape({
      usePoints: Yup.boolean().required(),
    });
    const isEnoughPoints = getIsEnoughPoints(checkout, user, currency);

    const currencyBalance = UserHelper.getBalanceByCurrency(user, currency);
    const balanceDescription = `${t('Book.Available')} ${currencyBalance ? currencyBalance.amount : 0}
  ${t('Book.Points')} ${getEmojiFlagByCurrency(currency)}`;
    const pointsDescription = `${!isEnoughPoints ? `${t('Book.NotEnoughPoints')} ` : ''}${balanceDescription}`;

    const pointsUseChange = (e: ChangeEvent<HTMLInputElement>, usePoints: boolean) => {
      if (e) {
        e.preventDefault();
      }
      if (onUsePointsChange) {
        onUsePointsChange(usePoints);
      }
    };

    const totalPrice = ticketGroups.reduce((acc, { price, count }) => {
      acc = price * count;
      return acc;
    }, 0);
    const priceWithFees = checkout?.process?.pricePayByCard || checkout?.process?.priceTotal;

    const getSubmitTitle = (): string => {
      const amount = Utils.round(priceWithFees || totalPrice);
      const { currency } = book.event;
      return `${t('Checkout.ToPay')} ${amount} ${currency}`;
    };

    return (
      <Formik
        isInitialValid={!!checkout.paymentMethod}
        enableReinitialize
        initialValues={{ usePoints: checkout.usePoints }}
        validationSchema={ValidationSchema}
        onSubmit={(values: IPaymentForm) => {
          if (onSubmit) {
            onSubmit(values);
          }
        }}
      >
        {({ isValid }) => (
          <StyledForm autoComplete="on">
            <StyledFieldset fullWidth>
              <Field name="usePoints">
                {({ field, form }) => (
                  <>
                    <RadioWrapper>
                      <RadioLabel checked={!field.value}>
                        <RadioButton
                          {...field}
                          type="radio"
                          error={!!form.touched.usePoints && !!form.errors && !!form.errors.usePoints}
                          value={0}
                          checked={!field.value}
                          onChange={(e) => pointsUseChange(e, false)}
                        />{' '}
                        {t('Book.DontUsePoints')}
                      </RadioLabel>
                    </RadioWrapper>
                    <RadioWrapper>
                      <RadioLabel checked={field.value} disabled={!isEnoughPoints}>
                        <RadioButton
                          {...field}
                          type="radio"
                          error={!!form.touched.usePoints && !!form.errors && !!form.errors.usePoints}
                          value={1}
                          checked={field.value}
                          onChange={(e) => pointsUseChange(e, true)}
                        />{' '}
                        {t('Book.UsePoints')}
                      </RadioLabel>
                      <AnimatePresence>
                        <RadioDescription variants={PopUp} initial={'enter'} animate={'exit'}>
                          <RadioDescriptionTitle
                            variants={PopUp}
                            initial={'enter'}
                            animate={'exit'}
                            error={!isEnoughPoints}
                          >
                            {pointsDescription}
                          </RadioDescriptionTitle>
                        </RadioDescription>
                      </AnimatePresence>
                    </RadioWrapper>
                    <AnimatePresence>
                      {!!form.touched.usePoints && !!form.errors && !!form.errors.usePoints && (
                        <FieldError variants={PopUp} initial={'enter'} animate={'exit'} key="error">
                          {form.errors.usePoints}
                        </FieldError>
                      )}
                    </AnimatePresence>
                  </>
                )}
              </Field>
            </StyledFieldset>
            {
              <Button type="submit" disabled={!isValid || isLoading} isLoading={isLoading}>
                go to payment
              </Button>
            }
          </StyledForm>
        )}
      </Formik>
    );
  }
);

const StyledForm = styled(Form)`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const StyledFieldset = styled(Fieldset)`
  flex: 1;
  margin-bottom: 20px;
`;

const RadioWrapper = styled(FieldWrapper)`
  margin-bottom: 0;
`;

const RadioDescription = styled(motion.div)`
  padding: 10px 25px;
  font-size: 10px;
`;

const RadioDescriptionTitle: any = styled(motion.span)`
  color: ${(props: RadioDescriptionTitleType) => (props.error ? props.theme.colors.error : props.theme.colors.accent)};
`;

type RadioDescriptionTitleType = {
  error: boolean;
  theme: {
    colors: {
      error: string;
      accent: string;
    };
  };
};
