import dayjs from 'dayjs';

import { IDistributionInfo, DistributionInfo } from '../userV1/types';

export interface IDistributionStoreState extends IDistributionInfo {
  expirationDate?: dayjs.Dayjs;
}

export class DistributionStoreState extends DistributionInfo implements IDistributionStoreState {
  expirationDate?: dayjs.Dayjs;

  constructor(distributionSlug?: string, expirationDate?: dayjs.Dayjs) {
    super(distributionSlug);
    this.expirationDate = expirationDate || null;
  }
}
