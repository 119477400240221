import { Grid, Skeleton } from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<void>()(() => ({
  gridTitle: {
    cursor: 'pointer',
    width: 157,
    height: 'min-content',
    marginRight: 10,
    marginBottom: 20,
  },
  skeletonImage: {
    height: '105px',
    width: '144px',
    borderRadius: '10px',
  },
  skeletonText: {
    height: 20,
    marginTop: '10px',
  },
}));

export const GenreCarouselSkeleton = () => {
  const { classes } = useStyles();
  return (
    <>
      {Array.from(new Array(6)).map((value, index) => (
        <Grid key={index} item className={classes.gridTitle}>
          <Grid container spacing={0} direction="row">
            <Grid item lg={12} xs={12} sm={12} md={12} xl={12}>
              <Skeleton animation="wave" variant="rectangular" className={classes.skeletonImage} />
            </Grid>
            <Grid item lg={12} xs={12} sm={12} md={12} xl={12}>
              <Skeleton animation="wave" variant="rectangular" width="75%" className={classes.skeletonText} />
            </Grid>
          </Grid>
        </Grid>
      ))}
    </>
  );
};
