import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { AnimatePresence } from 'framer-motion';
import { ModalWindow } from './socialNetworkLogin/modalWindow';
import { useDispatch, useSelector } from '../../configureStore';
import { useTokenMutation } from '../../core/api/authSlice';
import { useCheckout, useCheckoutHelper } from '../../helpers/customHooks';
import { clear, clearEvents, clearTickets } from '../../store/user/actions';
import { AppDeAuthorize, setError } from '../../store/app/actions';
import { deAuthorize } from '../../store/auth/actions';
import { LoginForm, LoginFormType } from '../../components/forms';
import { PagesRoutes } from '../../store/routes';
import { PageContent, RLink } from '../../components/UI';
import { PopUp } from '../../store/animations';
import { useUtils } from '../../services/useUtils';

export const isMobile = window.matchMedia('only screen and (max-width: 479px)').matches;

const LoginPage = () => {
  const isAuthorized = useSelector((state) => state.app.isAuthorized);
  const isInitializing = useSelector((state) => state.app.isInitializing);
  const checkout = useSelector((state) => state.checkout);

  const [login, { isLoading }] = useTokenMutation();

  const { checkoutCancel } = useCheckout();
  const { toPageTitle } = useUtils();
  const { isProcessed, isCompleted } = useCheckoutHelper();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const logout = async () => {
    dispatch(clear());
    dispatch(clearEvents());
    dispatch(clearTickets());
    dispatch(AppDeAuthorize());
    dispatch(deAuthorize());

    if (isProcessed(checkout) && !isCompleted(checkout)) {
      await checkoutCancel(checkout);
    }
  };

  const handleFormSubmit = (model: LoginFormType) => {
    if (!model) {
      return;
    }

    login(model)
      .unwrap()
      .then(() => {
        if (isProcessed(checkout) && !isCompleted(checkout)) {
          (async () => await checkoutCancel(checkout))();
        }
        navigate(PagesRoutes.Profile, { replace: true });
      });
  };

  useEffect(() => {
    if (isInitializing) {
      return;
    }

    if (isAuthorized) {
      navigate(PagesRoutes.Profile, { replace: true });
    }
  }, [isAuthorized, isInitializing, navigate]);

  return (
    <AnimatePresence>
      <PageContent center variants={PopUp} initial={'enter'} animate={'exit'}>
        <Helmet>
          <title>{toPageTitle(t('Login.Login'))}</title>
        </Helmet>
        <Header>{t<string>('Login.Login')}</Header>
        <LoginForm
          isLoading={isLoading}
          submitTitle={t('Login.Login')}
          onSubmit={(formModel: LoginFormType) => handleFormSubmit(formModel)}
        />
        <Link to={PagesRoutes.Registration}>{t('Login.SignUp')}</Link>
        {false && (
          <>
            <Divider isMobile={isMobile}>{t('Login.Or')}</Divider>
            <ModalWindow />
          </>
        )}
      </PageContent>
    </AnimatePresence>
  );
};
const Header = styled.h1`
  font-family: ${(props) => props.theme.fonts.boldFont};
  font-size: 18px;
  text-transform: uppercase;
  text-align: center;
`;

const Link = styled(RLink)<{ theme: { fonts: { boldFont: string } } }>`
  margin-top: 15px;
  text-transform: uppercase;
  font-size: 14px;
  font-family: ${(props) => props.theme.fonts.boldFont};
`;

export const Divider = styled.div<{ isMobile: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  padding: 30px 0;
  user-select: none;

  &:after {
    content: '';
    position: absolute;
    height: 0.5px;
    width: ${(props) => (props.isMobile ? '33%' : '35%')};
    display: block;
    left: 10%;
    background: #fff;
    top: 50%;
  }

  &:before {
    content: '';
    position: absolute;
    height: 0.5px;
    width: ${(props) => (props.isMobile ? '33%' : '35%')};
    display: block;
    right: 10%;
    background: #fff;
    top: 50%;
  }
`;

export default LoginPage;
