import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useRoutesHelper } from '../../../helpers/routesHelper';
import CopyButton from '../../../components/UI/copyButton';
import { TextInput } from '../../../components/UI';
import { UserType } from '../../../store/user/types';
import { EventBookType } from '../../../core/models/events';

export const SellControls: FC<SellControlsType> = ({ isLoading, event, user, eventSlug }) => {
  const { t } = useTranslation();
  const { generateReferralUrl } = useRoutesHelper();

  if (!event) {
    return null;
  }

  const { domainTypes } = event;
  const referralLink = generateReferralUrl(eventSlug, user?.referralSlug, domainTypes);

  return (
    <ButtonGroup>
      <ReferralLinkInput type="text" readOnly tabIndex={-1} value={referralLink} />
      <CopyButton isLoading={isLoading} value={referralLink} title={t('Share.CopyLink')} text={t('Share.CopyLink')} />
    </ButtonGroup>
  );
};

const ButtonGroup = styled.div`
  padding: 0 35px 25px;
`;

const ReferralLinkInput = styled(TextInput as any)`
  color: ${(props) => props.theme.colors.accent};
  padding: 15px 20px;

  &::placeholder {
    color: ${(props) => props.theme.colors.accent};
    opacity: 1;
  }

  &:focus {
    border-color: ${(props) => props.theme.colors.text};
  }
`;

type SellControlsType = {
  isLoading: boolean;
  event: EventBookType;
  user: UserType;
  eventSlug: string;
};
