import React, { FC } from 'react';
import { EventDetailsType } from '../../../core/models/eventDetails';
import { ListItem, ListItemWrapper } from '../eventDetails';
import { EventInfoItem } from '../../../components/event/eventInfoItem';

type EventPriceType = {
  event: EventDetailsType;
};

export const EventPrice: FC<EventPriceType> = ({ event }) => {
  const { minPrice, maxPrice, currency } = event;

  if (!minPrice && !maxPrice) {
    return null;
  }

  const priceTitle = minPrice !== maxPrice ? `${minPrice}–${maxPrice}` : minPrice;

  return (
    <ListItem>
      <ListItemWrapper>
        <EventInfoItem icon="purse" sm title={`${currency} ${priceTitle}`} />
      </ListItemWrapper>
    </ListItem>
  );
};
