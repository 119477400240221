import React, { FC, useEffect, useState } from 'react';
import { Chip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useSearchParams } from 'react-router-dom';
import { useLocation } from 'react-router';

type StyleProps = {
  border: string;
};

const useStyles = makeStyles<StyleProps>()((theme, { border }) => ({
  filterByChip: {
    height: '32px',
    width: '32px',
    color: 'inherit',
    border,
    padding: 12,
    margin: 0,
    position: 'relative',
    '.MuiChip-icon': {
      position: 'absolute',
      marginRight: 0,
      marginLeft: 0,
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
}));

export const FilterByTag: FC<FilterByTagType> = ({ icon }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isActive, setIsActive] = useState(false);
  const location = useLocation();

  const borderStyle = {
    border: isActive ? '1px solid #FFBF00' : '1px solid rgba(107, 107, 107, 0.6)',
  };

  const { classes } = useStyles(borderStyle);

  const images = {
    disabledPerson: 'content/img/disabledIcon.svg',
    parking: 'content/img/parking.svg',
  };

  useEffect(() => {
    const key = Object.keys(images).find((key) => key === icon);
    setIsActive(searchParams.has(key));
  }, [location.search]);

  const handleFilter = () => {
    const key = Object.keys(images).find((key) => key === icon);

    setSearchParams((params) => {
      if (searchParams.has(key)) {
        params.delete(key);
        return params;
      }
      params.set(key, `true`);
      return params;
    });
  };

  return <Chip className={classes.filterByChip} icon={<img src={images[icon]} alt={icon} />} onClick={handleFilter} />;
};

type FilterByTagType = {
  icon: string;
};
