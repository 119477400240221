import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Chip, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useLocation } from 'react-router';
import { FilterParams } from '../../store/enums';

type StyleProps = {
  border: string;
};

const useStyles = makeStyles<StyleProps>()((theme, { border }) => ({
  lowPriceTitle: {
    fontSize: '0.75rem',
    [theme.breakpoints.down(425)]: {
      fontSize: '0.625rem',
    },
  },
  lowPriceChip: {
    color: 'inherit',
    border,
    '.MuiChip-icon': {
      paddingRight: '20px',
      paddingLeft: '20px',
    },
    ':hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.secondary.dark,
    },
  },
}));

export const LowPriceButton = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isActive, setIsActive] = useState(false);
  const location = useLocation();

  const borderStyle = {
    border: isActive ? '1px solid #FFBF00' : '1px solid rgba(107, 107, 107, 0.6)',
  };

  const { classes } = useStyles(borderStyle);

  useEffect(() => {
    setIsActive(searchParams.has(FilterParams.LowPrice));
  }, [location.search]);

  const handleFilter = () => {
    setSearchParams((params) => {
      if (searchParams.has(FilterParams.LowPrice)) {
        params.delete(FilterParams.LowPrice);
        return params;
      }
      params.set(FilterParams.LowPrice, `true`);
      return params;
    });
  };

  return (
    <Chip
      className={classes.lowPriceChip}
      label={
        <Typography component={'span'} className={classes.lowPriceTitle}>
          Low Price
        </Typography>
      }
      onClick={handleFilter}
    />
  );
};
