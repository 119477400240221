import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Price, PriceTitle } from '../../bookV1';
import { TicketsInfoPropsType } from '../../bookV1/bookComponents/ticketInfoV1';

export const TicketsInfo: FC<TicketsInfoPropsType> = memo(({ selectedSeats, total, currency }) => {
  const { t } = useTranslation();

  if (!selectedSeats.length) return null;

  return (
    <>
      <Container>
        {t('WidgetBook.SelectedTickets')}
        {selectedSeats.map((ticket, index) => (
          <Row key={index}>
            <Data>
              <CostTitle>{ticket.title}</CostTitle>
            </Data>
            <Data>
              <CostTitle>{ticket.price}</CostTitle>
            </Data>
          </Row>
        ))}
      </Container>
      <Container>
        <Row>
          <Data>
            <TotalPriceTitle>{t('WidgetBook.Total')}</TotalPriceTitle>
          </Data>
          <Data>
            <TotalPrice>
              {total} {currency}
            </TotalPrice>
          </Data>
        </Row>
      </Container>
    </>
  );
});

const CostTitle = styled.span`
  font-size: 12px;
  font-family: ${(props) => props.theme.fonts.lighterFont};
`;

const TotalPriceTitle = styled(PriceTitle)`
  font-size: 14px;
  font-family: ${(props) => props.theme.fonts.regularFont};
`;
const TotalPrice = styled(Price as any)`
  font-size: 14px;
  font-family: ${(props) => props.theme.fonts.boldFont};
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 2px 0;
  border-top: 1px solid ${(props) => props.theme.colors.accent};
  width: 55%;
  margin-left: auto;
  min-width: 330px;
`;
const Row = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;

const Data = styled.div`
  width: 50%;
`;
