import React, { FC, useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Autocomplete from '@mui/material/Autocomplete';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useRoutesHelper } from '../../helpers/routesHelper';
import { useLazySearchEventsQuery } from '../../core/api/eventsSlice';
import { useDebouncedFunction } from '../../helpers/customHooks';

type StyleProps = {
  borderBottomLeftRadius: number;
  borderBottomRightRadius: number;
  boxShadow: string;
  backdropFilter: string;
  border: string;
};

const useStyles = makeStyles<StyleProps>({ name: 'SearchInput' })(
  (theme, { borderBottomRightRadius, borderBottomLeftRadius, backdropFilter, boxShadow, border }) => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      height: '54px',
      borderRadius: 0,
      borderTopLeftRadius: 35,
      borderBottomLeftRadius,
      borderTopRightRadius: 35,
      borderBottomRightRadius,
      background: 'rgba(255, 255, 255, 0.1)',
      boxShadow,
      backdropFilter,
      position: 'relative',
      ':after': {
        position: 'absolute',
        content: "''",
        bottom: '0px',
        left: 0,
        right: 0,
        margin: '0 16px',
        border,
      },
    },
    input: {
      flex: 1,
      paddingLeft: 45,
      color: 'white',
      fontSize: '0.875rem',
      '& .MuiInputBase-input:focus': {
        '&::placeholder': {
          opacity: 0,
        },
      },
    },
    iconButton: {
      padding: 10,
      marginRight: 15,
    },
    divider: {
      height: 28,
      margin: 4,
    },
    locationPaper: {
      height: '54px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    gridLocation: {
      minWidth: 72,
    },
    autocompleteGridContainer: {
      display: 'flex',
      flexWrap: 'nowrap',
    },
    searchIcon: {
      color: '#FFBF00',
    },
    btnLocation: {
      padding: 10,
      height: 47,
      width: 47,
    },
    popper: {
      inset: '0px auto auto 0px !important',
    },
    paper: {
      color: 'white',
      borderRadius: 0,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 35,
      borderBottomLeftRadius: 35,
      background: 'rgba(255, 255, 255, 0.1)',
      boxShadow: 'inset 0px -20px 19.4px rgb(194 194 194 / 10%), inset 0px 20px 0px rgb(255 255 255 / 0%)',
      backdropFilter: 'blur(19.4px)',
    },
    focused: {
      backgroundColor: 'inherit',
    },
    listbox: {
      padding: '8px 45px',
    },
    endAdornment: {
      display: 'none',
    },
    option: {
      paddingLeft: '0px !important',
      paddingRight: '0px !important',
      color: 'white',
      fontSize: '0.875rem',
    },
    noOptions: {
      padding: '14px 45px',
      color: 'white',
      fontSize: '0.875rem',
    },
    loading: {
      padding: '14px 45px',
      color: 'white',
      fontSize: '0.875rem',
    },
  })
);

export type SearchType = {
  id: string;
  value: string;
};

export const SearchInput: FC<SearchInputType> = ({ city }) => {
  const { getEventRoute } = useRoutesHelper();

  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState<string>('');
  const [options, setOptions] = useState<SearchType[]>([]);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const [searchEventsRequest, { isFetching }] = useLazySearchEventsQuery();

  const handleSearchIconClick = () => {
    setOpen(false);
  };

  const handleAutocompleteOnClick = (event, value) => {
    const eventShortInformation = value as SearchType;
    navigate(getEventRoute(eventShortInformation.id));
  };

  const getEventsBySearch = () => {
    searchEventsRequest({ name: search, city })
      .unwrap()
      .then((response) => {
        const events = response
          ? response.map((item) => ({
              id: item.slug,
              value: `${item.title}, ${item.city}, ${item.venue}`,
            }))
          : [];

        setOptions(events);
      });
  };

  const debouncedEventsBySearch = useDebouncedFunction(getEventsBySearch, 500);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  useEffect(() => {
    if (!search) {
      setOptions([]);
    }

    debouncedEventsBySearch();
  }, [search]);

  const styles = {
    borderBottomLeftRadius: open ? 0 : 35,
    borderBottomRightRadius: open ? 0 : 35,
    boxShadow: open
      ? 'inset 19.4px -19.4px 0px rgb(255 255 255 / 0%), inset 0px 20px 19.4px rgb(255 255 255 / 10%)'
      : 'inset 13.3px -13.3px 13.3px rgb(194 194 194 / 2%), inset -13.3px 13.3px 13.3px rgb(255 255 255 / 2%)',
    backdropFilter: open ? 'blur(19.4px)' : 'blur(1px)',
    border: open ? '0.5px solid #d8d8d84f' : 'none',
  };
  const { classes } = useStyles(styles);

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center" spacing={0}>
      <Grid item xs={12} sm={9}>
        <Autocomplete
          id="search"
          open={open}
          noOptionsText={t<string>('Dashboard.NothingFound')}
          loadingText={t<string>('Dashboard.Loading')}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) => option.value}
          onChange={handleAutocompleteOnClick}
          options={options}
          loading={isFetching}
          classes={{
            popper: classes.popper,
            endAdornment: classes.endAdornment,
            paper: classes.paper,
            focused: classes.focused,
            listbox: classes.listbox,
            option: classes.option,
            noOptions: classes.noOptions,
            loading: classes.loading,
          }}
          renderInput={(params) => {
            const { InputLabelProps, InputProps, ...rest } = params;
            return (
              <div ref={InputProps.ref}>
                <Grid container className={classes.autocompleteGridContainer}>
                  <Grid item xs={12}>
                    <Paper className={classes.root}>
                      <InputBase
                        {...params.InputProps}
                        {...rest}
                        id={'searchInput'}
                        value={search}
                        onChange={(event) => setSearch(event.target.value)}
                        className={classes.input}
                        placeholder={t<string>('Search by Artist, Event or Venue ')}
                        sx={{
                          input: {
                            '&::placeholder': {
                              opacity: 1,
                            },
                          },
                        }}
                      />
                      <IconButton
                        className={classes.iconButton}
                        onClick={handleSearchIconClick}
                        disabled={false}
                        size="large"
                      >
                        <SearchIcon className={classes.searchIcon} />
                      </IconButton>
                    </Paper>
                  </Grid>
                </Grid>
              </div>
            );
          }}
        />
      </Grid>
    </Grid>
  );
};

type SearchInputType = {
  city?: string;
};
